import React from 'react'
import { TextInput, DisabledInput, ReferenceInput, SelectInput } from 'react-admin'
import { required, maxLength } from 'react-admin'
import LanguagesNav from '../../../../components/languagesNav'

export const PositionsForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <TextInput fullWidth source="name" label="Name" validate={[required(), maxLength(255)]} />
    <TextInput fullWidth source="pluralName" label="Plural Name" validate={[required(), maxLength(255)]} />
    <TextInput fullWidth source="femaleName" label="Female Name" validate={[required(), maxLength(255)]} />
    <TextInput fullWidth source="femalePluralName" label="Female Plural Name" validate={[required(), maxLength(255)]} />
    <DisabledInput fullWidth source="slug" label="Slug" />
  </LanguagesNav>
)

export default PositionsForm
