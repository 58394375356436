import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import moment from 'moment'
import React from 'react'
import { BooleanInput, DisabledInput, LongTextInput, maxLength, NumberInput, ReferenceInput, required, TextInput } from 'react-admin'

import { AutocompleteInput, DateSingleInput, DictionaryInput, ImageInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'
import { getResource } from '../../../../config/resources'

export const VenuesForm = ({ isEdit, ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12}>
        <BooleanInput source="active" label="Active" />
        <TextInput fullWidth source="name" label="Nombre" validate={[required(), maxLength(255)]} />
        <TextInput fullWidth source="email" label="Email" validate={[maxLength(255)]} />
        <TextInput fullWidth source="web" label="Web" validate={[maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateSingleInput label="Opened" source="opened" rangeStartDate={moment('1810-01-01')} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput fullWidth source="capacity" label="Capacity" validate={[required()]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput fullWidth source="phone" label="Phone" validate={[maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <ImageInput source="image" label="Image" accept="image/*" />
      </Grid>

      <Grid item xs={12}>
        <LongTextInput fullWidth source="address" label="Address" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ReferenceInput label="Country" source="country" reference={getResource('transversal', 'countries')} validate={[required()]}>
          <AutocompleteInput inputValueMatcher={() => null} validate={[required()]} />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput fullWidth source="city" label="City" validate={[required(), maxLength(255)]} />
      </Grid>

      {isEdit && (
        <Grid item xs={12} sm={4}>
          <DisabledInput fullWidth source="timezone" label="Timezone" />
        </Grid>
      )}
      {isEdit && (
        <Grid item xs={12} sm={4}>
          <DisabledInput fullWidth source="latitude" label="Latitude" />
        </Grid>
      )}
      {isEdit && (
        <Grid item xs={12} sm={4}>
          <DisabledInput fullWidth source="longitude" label="Longitude" />
        </Grid>
      )}

      <Grid item xs={12}>
        <Paper>
          <DictionaryInput label="Dictionary" sources={['ldeId', 'optaId']} />
        </Paper>
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default VenuesForm
