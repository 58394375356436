import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import moment from 'moment'
import _ from 'lodash'
import { initialize, isSubmitting, change, formValueSelector } from 'redux-form'
import { SimpleForm, showNotification, fetchStart, fetchEnd, ReferenceInput, GET_LIST, GET_ONE, DELETE_MANY } from 'react-admin'
import { MY_CREATE_MANY } from '../../../../../providers/dataProvider/types'
import { DateSingleInput, AutocompleteInput } from '../../../../../components/inputs'
import { getResource } from '../../../../../config/resources'
import dataProvider from '../../../../../providers'
import { FormStyled } from './style'
import { ListItemsStyled, RowItemStyled } from '../../../utils/styles'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'

const FORM = 'careers-form'
const selector = formValueSelector(FORM)

const styles = (theme) => ({
  header: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
  },
  newCareers: {
    border: '1px solid',
    borderColor: theme.palette.grey[600],
    zIndex: 10,
  },
  rowButton: {
    textAlign: 'right',
  },
  listCareers: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  cellButton: {
    textAlign: 'right',
    paddingRight: '12px !important',
    width: '75px;',
  },
  trashIcon: {
    color: theme.palette.grey[500],
    '&:hover': {
      color: theme.palette.error.dark,
      cursor: 'pointer',
    },
  },
})

class Careers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addedCareers: [],
      deletedCareers: [],
    }
  }

  componentDidMount() {
    this.getCareers(this.props.idPerson)
  }

  getCareers(idPerson) {
    const { fetchStart, fetchEnd, showNotification } = this.props

    fetchStart()
    dataProvider(GET_LIST, getResource('competiciones', 'careers'), {
      sort: {
        field: 'start',
        order: 'ASC',
      },
      pagination: { page: 1, perPage: 50 },
      filter: {
        person: idPerson,
      },
    })
      .then(({ data }) => {
        const newData = {
          careers: data,
        }
        this.props.initialize(FORM, newData)
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  addCareer() {
    const { field, change, fetchStart, fetchEnd, idPerson } = this.props
    if (field('start') && field('end') && field('team.id')) {
      if (moment(moment(field('start'))).isBefore(moment(field('end')))) {
        fetchStart()
        dataProvider(GET_ONE, getResource('competiciones', 'teams'), {
          id: field('team.id'),
        })
          .then(({ data }) => {
            let addedCareers = this.state.addedCareers
            let careers = field('careers')

            careers.push({
              isNew: true,
              start: field('start'),
              end: field('end'),
              team: {
                id: field('team.id'),
                selectorName: data.selectorName,
              },
            })
            addedCareers.push({
              start: field('start'),
              end: field('end'),
              personRole: {
                id: idPerson,
              },
              team: {
                id: field('team.id'),
              },
            })
            this.setState({
              addedCareers: addedCareers,
            })

            change(FORM, 'careers', careers)
            change(FORM, 'team', null)
          })
          .catch((error) => {
            showNotification(error.message, 'error')
          })
          .finally(() => {
            fetchEnd()
          })
      } else {
        showNotification('End must be greater than Start.', 'error')
      }
    }
  }

  deleteCareer(key) {
    const { field, change, fetchStart, fetchEnd } = this.props
    let addedCareers = this.state.addedCareers
    let deletedCareers = this.state.deletedCareers
    let careers = field('careers')

    fetchStart()
    if (careers[key].isNew) {
      let index = _.findIndex(addedCareers, ['team.id', careers[key].team.id])
      if (index !== -1) {
        addedCareers.splice(index, 1)
      }
    } else {
      deletedCareers.push(careers[key].id)
    }
    careers.splice(key, 1)

    change(FORM, 'careers', careers)
    this.setState({
      deletedCareers: deletedCareers,
    })
    fetchEnd()
  }

  updateCareers() {
    const { fetchStart, fetchEnd, showNotification, closeModal } = this.props
    const { addedCareers, deletedCareers } = this.state

    if (addedCareers.length > 0) {
      fetchStart()
      dataProvider(MY_CREATE_MANY, getResource('competiciones', 'careers'), {
        data: addedCareers,
      })
        .then((response) => {
          showNotification('Element updated', 'success')
        })
        .catch((error) => {
          showNotification(error.message, 'error')
        })
        .finally(() => {
          fetchEnd()
        })
    }

    if (deletedCareers.length > 0) {
      fetchStart()
      dataProvider(DELETE_MANY, getResource('competiciones', 'careers'), {
        ids: deletedCareers,
      })
        .then((response) => {
          showNotification('Element updated', 'success')
        })
        .catch((error) => {
          showNotification(error.message, 'error')
        })
        .finally(() => {
          fetchEnd()
        })
    }

    closeModal()
  }

  NewCareer = () => {
    const { classes, field } = this.props
    return (
      <Grid container>
        <Grid item xs={12} sm={6} md={3}>
          <DateSingleInput label="Start" source="start" rangeStartDate={moment('1950-01-01')} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DateSingleInput label="End" source="end" rangeStartDate={moment('1950-01-01')} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ReferenceInput label="Team" source="team.id" reference={getResource('competiciones', 'teams')} fullWidth>
            <AutocompleteInput optionText="selectorName" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} className={classes.rowButton}>
          <Button
            onClick={() => this.addCareer()}
            variant="contained"
            color="primary"
            disabled={!field('start') || !field('end') || !field('team.id')}
            size="small"
            aria-label="Edit"
          >
            Add
          </Button>
        </Grid>
      </Grid>
    )
  }

  ListCareers = () => {
    const { classes, field } = this.props
    const careers = field('careers')

    return (
      <ListItemsStyled>
        <RowItemStyled className="sticky">
          <div className="head">Start</div>
          <div className="head">End</div>
          <div className="head">Team</div>
          <div className={classes.cellButton} />
        </RowItemStyled>
        {careers &&
          careers.map((item, key) => {
            return (
              <RowItemStyled key={key}>
                <div>
                  <Typography variant="body1">{this.formatDate(item.start)}</Typography>
                </div>
                <div>
                  <Typography variant="body1">{this.formatDate(item.end)}</Typography>
                </div>
                <div className="full">
                  <Typography variant="body1">{item.team.selectorName}</Typography>
                </div>
                <div className={classes.cellButton}>
                  <DeleteIcon className={classes.trashIcon} aria-label="Delete" onClick={() => this.deleteCareer(key)} />
                </div>
              </RowItemStyled>
            )
          })}
      </ListItemsStyled>
    )
  }

  formatDate(time) {
    return moment(time).tz('Europe/Madrid').format('L')
  }

  render() {
    const { isSubmitting, classes, field } = this.props
    const { NewCareer, ListCareers } = this
    return (
      <FormStyled>
        <SimpleForm resource={getResource('competiciones', 'careers')} saving={isSubmitting} form={FORM} save={() => this.updateCareers(field('careers'))}>
          <Grid container>
            <Grid item xs={12} className={classes.header}>
              <Typography variant="h2" headlineMapping="h2">
                Career
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.newCareers}>
              <NewCareer />
            </Grid>
            <Grid item xs={12} className={classes.listCareers}>
              <ListCareers />
            </Grid>
          </Grid>
        </SimpleForm>
      </FormStyled>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      isSubmitting: isSubmitting(FORM)(state),
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(Careers)
