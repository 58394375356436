import { Grid } from '@material-ui/core'
import React from 'react'
import { TextInput, required, maxLength } from 'react-admin'

import { ImageInput, MetadataInput, DateTimeInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'
import ListActs from './ListActs'

export const EventForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12}>
        <DateTimeInput source="publishedAt" date time validate={[required()]} />
      </Grid>
      <Grid item xs={12}>
        <TextInput fullWidth source="name" label="Name" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ImageInput source="image" label="Image" options={{ remove: true }} getObject />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ImageInput source="watermark" label="Watermark" options={{ remove: true }} getObject />
      </Grid>
      <Grid item xs={12}>
        <MetadataInput source="metadatas" label="Metadata" />
      </Grid>
      {props.id && (
        <Grid item xs={12}>
          <ListActs idEvent={props.id} />
        </Grid>
      )}
    </Grid>
  </LanguagesNav>
)

export default EventForm
