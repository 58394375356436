import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import React, { Component } from 'react'

import { getResource } from '../../../../config/resources'
import MetadataInput from '../metadata'

const EXPERIENCE = 'experience'
const CATEGORY_EXPERIENCE = 'category_experience'

class ExperienceInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      source: null,
      perPage: 50,
    }
  }

  onChange(metadatas, type) {
    const { onChange } = this.props.input ? this.props.input : this.props
    const newMetadata = this.resetSelects(metadatas, type)
    onChange(newMetadata)
  }

  resetSelects(metadatas, type) {
    let typeToRemove = []
    switch (type) {
      case CATEGORY_EXPERIENCE:
        typeToRemove = [EXPERIENCE]
        break
    }

    return _.differenceWith(metadatas, typeToRemove, (arrVal, othVal) => {
      return arrVal.type === othVal
    })
  }

  getFromMetadata(type) {
    const { value } = this.props.input ? this.props.input : this.props
    const selectedItem = _.find(value, (item) => {
      return item.type === type
    })
    return selectedItem ? selectedItem.value : null
  }

  render() {
    const { value } = this.props.input ? this.props.input : this.props
    const category = this.getFromMetadata(CATEGORY_EXPERIENCE)

    return (
      <Grid container>
        <Grid item xs={12} sm={4}>
          <MetadataInput
            value={value}
            onChange={(metadatas) => this.onChange(metadatas, CATEGORY_EXPERIENCE)}
            label="Category"
            type={CATEGORY_EXPERIENCE}
            reference={getResource('layouts', 'experience-categories')}
            sort={{
              field: 'id',
              order: 'DESC',
            }}
          />
        </Grid>
        {category && (
          <Grid item xs={12} sm={8}>
            <MetadataInput
              value={value}
              onChange={(metadatas) => this.onChange(metadatas, EXPERIENCE)}
              label="Experience"
              type={EXPERIENCE}
              reference={getResource('layouts', 'experiences')}
              sort={{
                field: 'startedAt',
                order: 'DESC',
              }}
              filter={{
                category: category,
              }}
            />
          </Grid>
        )}
      </Grid>
    )
  }
}

export default ExperienceInput
