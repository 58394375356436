import Checkbox from '@material-ui/core/Checkbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import _ from 'lodash'
import React from 'react'
import { GET_LIST } from 'react-admin'
import { ConfirmationDialog } from '../..'
import { getResource } from '../../../config/resources'
import dataProvider from '../../../providers'
import { AddModuleStyled } from './style'

class AddModule extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      modules: [],
    }
  }

  getModules(filter, func) {
    dataProvider(GET_LIST, getResource('layouts', 'modules'), {
      sort: {
        field: 'id',
        order: 'DESC',
      },
      filter: filter,
      pagination: { page: 1, perPage: 50 },
    }).then((response) => {
      func(response.data)
    })
  }

  handleClickOpen = () => {
    const { type, value, area } = this.props
    this.setState({
      open: true,
      isFetching: true,
    })
    const filter = {
      validArea: area,
    }
    this.getModules(filter, (modules) => {
      const { numArea } = this.props
      const newModules = _.map(modules, (item) => {
        const { slug, name } = item
        return {
          module: slug,
          area: parseInt(numArea),
          name: name,
          checked: false,
          id: `${type}/${item.id}`,
          queryParams: item.queryParams,
        }
      })
      this.setState({
        modules: newModules,
        isFetching: false,
      })
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
      modules: [],
    })
  }

  handleSuccess = () => {
    const { onChange } = this.props
    let newModules = []
    _.map(this.state.modules, (module) => {
      if (module.checked) newModules.push(_.omit(module, 'checked'))
    })
    onChange(newModules)
    this.handleClose()
  }

  handleToggle(index) {
    let selectedModules = [...this.state.modules]
    selectedModules[index].checked = !selectedModules[index].checked
    this.setState({ modules: selectedModules })
  }

  render() {
    return (
      <React.Fragment>
        <AddModuleStyled {...this.props} onClick={this.handleClickOpen}>
          <AddCircleIcon color="action" />
        </AddModuleStyled>
        <ConfirmationDialog
          title="Modules"
          isFetching={this.state.isFetching}
          open={this.state.open}
          onSuccess={this.handleSuccess}
          onCancel={this.handleClose}
        >
          <List>
            {_.map(this.state.modules, (item, index) => (
              <ListItem key={item.module} onClick={() => this.handleToggle(index)} button>
                <ListItemText primary={item.name} />
                <ListItemSecondaryAction>
                  <Checkbox onClick={() => this.handleToggle(index)} color="primary" checked={item.checked} value={item.module} />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </ConfirmationDialog>
      </React.Fragment>
    )
  }
}

export default AddModule
