import { FormControlLabel, Switch } from '@material-ui/core'
import React from 'react'

const BooleanInput = ({ label, input }) => {
  const { value, onChange } = input

  const handleChange = (event) => {
    onChange(event.target.checked ? true : null)
  }

  return <FormControlLabel control={<Switch checked={!!value} onChange={handleChange} value={true} color="primary" />} label={label} />
}

export default BooleanInput
