import React from 'react'
import {
  List,
  Datagrid,
  FunctionField,
  TextField,
  EditButton,
  ArrayField,
  SingleFieldList,
  ReferenceField,
  ChipField,
  Filter,
  TextInput,
  SimpleList,
  Responsive,
  ReferenceInput,
} from 'react-admin'
import { TableRowList, ListActions } from '../../../components'
import { ImageField } from '../../../components/field'
import { AutocompleteInput } from '../../../components/inputs'
import { getResource } from '../../../config/resources'

const BroadcastersFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput className={props.classes.input} variant="filled" label="Search" source="q" alwaysOn fullWidth />
      <TextInput className={props.classes.input} variant="filled" label="Nombre" source="name" alwaysOn fullWidth />
      <ReferenceInput label="Country" source="country" reference={getResource('transversal', 'countries')} alwaysOn allowEmpty>
        <AutocompleteInput inputValueMatcher={() => null} />
      </ReferenceInput>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<BroadcastersFilter />} />
}

export const BroadcastersList = (props) => {
  return (
    <List bulkActions={false} title="Operadores" {...props} actions={<Actions />}>
      <Responsive
        small={
          <SimpleList
            leftAvatar={(record) => (
              <ReferenceField
                allowEmpty
                linkType={false}
                record={record}
                basePath={getResource('assets', 'images')}
                label="Image"
                source="image"
                reference={getResource('assets', 'images')}
              >
                <ImageField mini source="asset.fileDir" title="imagen operador" />
              </ReferenceField>
            )}
            primaryText={(record) => record.name}
            secondaryText={(record) => record.web}
          />
        }
        medium={
          <Datagrid>
            <ReferenceField allowEmpty linkType={false} reference={getResource('assets', 'images')} label="Image" source="image">
              <ImageField source="asset.fileDir" title="imagen operador" />
            </ReferenceField>
            <TextField source="name" label="Name" />
            <FunctionField
              label="Web"
              source="web"
              render={(record) => (
                <a target="_blank" href={record.web} rel="noreferrer">
                  {record.web}
                </a>
              )}
            />
            <ArrayField source="broadcasterCountries" label="Countries" sortable={false}>
              <SingleFieldList linkType={false}>
                <ChipField source="country" />
              </SingleFieldList>
            </ArrayField>
            <TableRowList>
              <EditButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}

export default BroadcastersList
