import styled from 'styled-components'
import theme, { colors } from '../../../config/theme'

export const ContainerStyled = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const ItemAllStyled = styled.div`
  flex: 70px;
  text-align: center;
  padding: 10px 10px;
  background: ${colors.common.white};
  fontfamily: ${theme.typography.fontFamily};
  font-size: 0.9rem;
  box-sizing: border-box;
  &.checked {
    background: ${colors.common.black};
    color: ${colors.common.white};
  }
  &:hover {
    background: ${colors.common.black};
    color: ${colors.common.white};
    cursor: pointer;
    opacity: 0.95;
  }
`

export const ItemStyled = styled.div`
  flex: auto;
  padding: 10px 20px;
  background: ${colors.common.white};
  border-left: 1px solid ${colors.grey[100]};
  fontfamily: ${theme.typography.fontFamily};
  font-size: 0.9rem;
  box-sizing: border-box;
  &.checked {
    background: ${colors.common.black};
    color: ${colors.common.white};
  }
  &:hover {
    background: ${colors.common.black};
    color: ${colors.common.white};
    cursor: pointer;
    opacity: 0.95;
  }
`
