import React from 'react'
import { List, ListItem, ListItemText, Collapse, Button, Grid, withStyles, TextField, Typography } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import DeleteIcon from '@material-ui/icons/Delete'
import { RichTextInput } from '../../../../../inputs'

const styles = (theme) => ({
  row: {
    position: 'relative',
    background: theme.palette.grey[50],
    marginBottom: theme.spacing.unit,
    display: 'flex',
  },
  deleteButton: {
    marginRight: '16px',
  },
  drag: {
    flex: '0 0 35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  relativeElement: {
    position: 'relative',
  },
})

class FaqItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  handleClick = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    const { each, classes, isDragged, index, handleChange, handleRemove } = this.props
    return (
      <List>
        <ListItem button onClick={this.handleClick}>
          <div className={classes.drag} data-movable-handle style={{ cursor: isDragged ? 'grabbing' : 'grab' }}>
            <DragHandleIcon />
          </div>
          <ListItemText primary={each.question} />
          <Button className={classes.deleteButton} variant="fab" color="secondary" aria-label="Remove" onClick={handleRemove} mini>
            <DeleteIcon fontSize={12} />
          </Button>
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto">
          <div className={classes.row}>
            <Grid container alignItems="center">
              <Grid item xs={12} className={classes.relativeElement}>
                <TextField
                  fullWidth
                  source={each.question}
                  label="Question"
                  value={each.question}
                  onChange={(value) => {
                    handleChange(index, 'question', value.target.value)
                  }}
                />
                {each.question.length === 0 && (
                  <div style={{ position: 'absolute', top: '10px', left: '70px' }}>
                    <Typography variant="caption" color="error">
                      Required
                    </Typography>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} className={classes.relativeElement}>
                <RichTextInput
                  label="Answer"
                  value={each.answer}
                  onChange={(value) => {
                    handleChange(index, 'answer', value)
                  }}
                />
                {each.answer.length === 0 && (
                  <div style={{ position: 'absolute', top: '12px', left: '60px' }}>
                    <Typography variant="caption" color="error">
                      Required
                    </Typography>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </Collapse>
      </List>
    )
  }
}

export default withStyles(styles)(FaqItem)
