import React from 'react'
import { Create } from 'react-admin'

import BrandsForm from './form'

const BrandTitle = ({ record }) => <span>New Brand:</span>

export const BrandsCreate = (props) => (
  <Create title={<BrandTitle />} {...props}>
    <BrandsForm isEdit={false} />
  </Create>
)

export default BrandsCreate
