import React from 'react'
import get from 'lodash/get'
import moment from 'moment-timezone'
import Typography from '@material-ui/core/Typography'

const toLocaleStringSupportsLocales = (() => {
  try {
    new Date().toLocaleString('i')
  } catch (error) {
    return error instanceof RangeError
  }
  return false
})()

export const DateField = ({ className, locales, options, record, showTime = false, source, ...rest }) => {
  if (!record) return null
  const value = get(record, source)
  if (value === null) return null

  const date = moment(value).toDate()

  let localeOptions = { ...options }
  localeOptions.timeZone = 'Europe/Madrid'

  const dateString = showTime ? date.toLocaleString('es-ES', localeOptions) : date.toLocaleDateString('es-ES', localeOptions)

  return (
    <Typography component="span" body1="body1" className={className} {...rest}>
      {dateString}
    </Typography>
  )
}

export default DateField
