import React from 'react'
import { Create } from 'react-admin'
import MatchForm from './form'

export const MatchCreate = (props) => (
  <Create {...props}>
    <MatchForm isEdit={false} {...props} />
  </Create>
)

export default MatchCreate
