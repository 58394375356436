import React from 'react'
import { Edit } from 'react-admin'

import ImagesForm from './form'
import AdditionalToolbar from './AdditionalToolbar'

const ImageTitle = ({ record }) => {
  return <span>{`Difusión: ${record.nombre}`}</span>
}

export const ImageEdit = (props) => (
  <Edit undoable={false} title={<ImageTitle />} {...props}>
    <ImagesForm {...props} AdditionalToolbar={<AdditionalToolbar />} remove />
  </Edit>
)

export default ImageEdit
