import React from 'react'
import { TextInput, DisabledInput } from 'react-admin'
import { required, maxLength } from 'react-admin'
import LanguagesNav from '../../../../components/languagesNav'

export const TransfersForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <TextInput fullWidth source="name" label="Name" validate={[required(), maxLength(255)]} />
    <DisabledInput fullWidth source="slug" label="Slug" />
  </LanguagesNav>
)

export default TransfersForm
