import React from 'react'
import { DisabledInput, FormDataConsumer, maxLength, required, TextInput } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import { DictionaryInput, ImageInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'
import CompetitionManagement from './competitionManagement'
import SubgroupManagementInput from './competitionManagement/SubgroupManagementInput'
import Toggle from './components/toggle'

const generalToggles = [
  {
    id: 0,
    source: 'mainCompetition',
    label: 'Main Competition',
    helper: 'To determine the main or domestic competition to which the team squads correspond',
  },
  {
    id: 1,
    source: 'importTsv',
    label: 'Import TSV',
    helper: 'BIA dashboard data exportation',
  },
  {
    id: 2,
    source: 'tagging',
    label: 'Tagging',
    helper: 'Alow tagging the competition to pictures and galleries, to be consumed by Multimedia LALIGA tool',
  },
  {
    id: 3,
    source: 'watermark',
    label: 'Watermark',
    helper: 'Embed the competition logo as a watermark on every image tagged with the competition',
  },
]

const webToggles = [
  {
    id: 0,
    source: 'showInCalendar',
    label: 'Calendar filter',
    helper: "Include the competition on the filters of the web's calendar view",
  },
]

const appToggles = [
  {
    id: 0,
    source: 'showInApp',
    label: 'Available in Competition selector',
    helper: "Enable the competition to appear on the app's Competition selector allowing the user to access to that particular competition data",
    showHelper: false,
  },
  {
    id: 1,
    source: 'showInHomeApp',
    label: 'Home relevant matches module',
    helper: "Eligible to appear on the relevant matches module of the app's Home",
    showHelper: false,
  },
  {
    id: 2,
    source: 'detailsApp',
    label: 'Match details',
    helper: 'Ability to double-click into a match belonging to the competition and access to match details view on the app',
    showHelper: false,
  },
]

class CompetitionsForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showSubgroupsManagement: false,
    }
  }

  render() {
    const { isEdit, ...props } = this.props
    return (
      <LanguagesNav {...props}>
        <Grid container>
          <Grid item xs={12}>
            <TextInput fullWidth source="name" label="Nombre" validate={[required(), maxLength(255)]} />
            <DisabledInput fullWidth source="nameConfiguration" label="Nombre Configuración" />
            <DisabledInput fullWidth source="slug" label="slug" />
            <Grid item container>
              <Grid item lg={3}>
                <ImageInput source="image" label="Logo competition App (selector)" accept="image/*" validate={[required()]} />
              </Grid>
              <Grid item lg={3}>
                <ImageInput source="secondaryImage" label="Logo competition App (top bar)" accept="image/*" validate={[required()]} />
              </Grid>
              <Grid item lg={3}>
                <ImageInput source="thirdImage" label="Logo competition App (match details)" accept="image/*" validate={[required()]} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              General
            </Grid>
            <Grid container xs={12}>
              {generalToggles.map((toggle) => (
                <Grid key={toggle.id} item xs={12} sm={4}>
                  <Toggle toggle={toggle} />
                </Grid>
              ))}

              <Grid item xs={12} style={{ marginTop: '24px' }}>
                <FormDataConsumer>
                  {({ formData, ...rest }) => formData && formData.watermark && <ImageInput source="watermarkImage" label="Watermark" accept="image/*" />}
                </FormDataConsumer>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={12}>
              Web
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={4}>
                {webToggles.map((toggle) => (
                  <Toggle key={toggle.id} toggle={toggle} />
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              App
            </Grid>
            <Grid container items xs={12}>
              {appToggles.map((toggle) => (
                <Grid item xs={12} sm={4} key={toggle.id}>
                  <Toggle toggle={toggle} />
                </Grid>
              ))}
            </Grid>
          </Grid>

          {isEdit && (
            <Grid item xs={12} style={{ marginTop: '40px' }}>
              <CompetitionManagement idCompetition={props.id} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Paper>
              <DictionaryInput label="Dictionary" sources={['performId', 'ldeId', 'optaId', 'managerId']} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <SubgroupManagementInput label="qualify" source="qualify" />
          </Grid>
        </Grid>
      </LanguagesNav>
    )
  }
}

export default CompetitionsForm
