import React from 'react'
import { Grid } from '@material-ui/core'
import { required, SelectInput, GET_ONE, fetchEnd, fetchStart, showNotification } from 'react-admin'
import dataProvider from '../../../../../providers'
import { getResource } from '../../../../../config/resources'
import { change, formValueSelector } from 'redux-form'
import _get from 'lodash/get'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

const FORM = 'record-form'
const selector = formValueSelector(FORM)

class MetricTeam extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      teams: [],
    }
  }

  componentDidMount() {
    this.getMatchData()
  }

  getMatchData() {
    dataProvider(GET_ONE, getResource('competiciones', 'matches'), {
      id: this.props.matchId,
    }).then(({ data }) => {
      this.setState({
        teams: [data.awayTeam, data.homeTeam],
      })
    })
  }

  render() {
    return (
      <Grid item xs={12} lg={4}>
        <SelectInput filter={{ type: 'main' }} fullWidth source="teamId" label="Team" validate={required()} choices={this.state?.teams} />
      </Grid>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  )
)

export default enhance(MetricTeam)
