import styled, { keyframes } from 'styled-components'
import { colors, size } from '../../../config/theme'
import { ImageField } from '../../../components/field'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'

const fetching = keyframes`
  from {
    opacity: .3;
  }

  to {
    opacity: 1;
  }
`

export const LabelStyled = styled.label`
  position: relative;
  padding-left: 6px;
  display: block;
  font-size: 12px;
  margin-bottom: 8px;
  color: ${colors.grey[700]};
`

export const ModalStyled = styled.div`
  padding: 10px;
  background-color: ${colors.secondary.main};
`

export const PluginContainerStyled = styled.div`
  padding: 13px;
  display: grid;
  grid-template-columns: 40% 1fr;
  grid-column-gap: 23px;
`

export const ImageContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.grey[300]};
  width: 100%;
  min-width: 200px;
  min-height: 200px;
  position: relative;
  & > img {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: contain;
  }
`

export const OptionsBarStyled = styled.div`
  display: flex;
  align-items: center;
`

export const ContentListStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding: 15px;
  background-color: ${colors.common.white};
  min-height: 388px;
  overflow-y: auto;
  & div[class^='MuiCardContent'] {
    max-width: 100%;
  }
`

export const ContentStyled = styled.div``

export const ItemListStyled = styled.div`
  transition: all 0.3s;
  cursor: pointer;
  box-sizing: border-box;
  animation: ${fetching} 1s;
  ${(props) => (props.mode === 'selection' ? `opacity: ${props.selected ? `1` : `.5`}` : ``)}
  & img {
    width: 100%;
    box-sizing: border-box;
    height: 115px;
    object-fit: cover;
    transition: border 0.2s;
  }
  & span {
    font-size: 13px;
    line-height: 18px;
    display: inline-block;
    min-height: 25px;
    padding-top: 7px;
    word-break: break-all;
  }
  &:hover {
  }
`

export const ImgItemListStyled = styled.div`
  position: relative;
  line-height: 0px;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    transition: all 0.2;
    border: solid 4px ${(props) => (props.selected ? colors.secondary.dark : 'transparent')};
    z-index: 1;
  }
  & > * {
    width: 100%;
    height: 100%;
  }
`

export const ItemListFetchingStyled = styled.div`
  background-color: ${colors.grey[300]};
  height: 133px;
  animation: ${fetching} 1s infinite;
  & span {
    font-weight: bold;
  }
  animation-direction: alternate;
`

export const FooterStyled = styled.div`
  padding-top: 15px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 38px;
  display: flex;
`

export const GrowStyled = styled.div`
  flex-grow: 1;
`

export const InputStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 35px;
  max-width: 200px;
  width: 100%;
  & input {
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    outline: none;
    border-image-outset: none;
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding: 9px 16px;
    padding-right: 30px;
    height: 35px;
    background-image: none;
    background-color: ${colors.common.white};
    border: solid 1px ${colors.grey[300]};
    border-radius: 0px;
    box-shadow: none;
  }
  & svg {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    fill: ${colors.grey[300]};
  }
`

export const FilterBarContainerStyled = styled.div`
  background-color: ${colors.common.white};
  display: inline-block;
  width: 100%;
`

export const FilterBarStyled = styled.div`
  background-color: ${colors.common.white};
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export const FiltersStyled = styled.div`
  padding: 10px;
  border: solid 1px ${colors.primary.main};
  margin: 10px;
  margin-top: -9px;
`

export const FilterOptionsBarStyled = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  align-self: flex-end;
`

export const PlaceholderImgStyled = styled.div`
  width: 100%;
  height: 115px;
  background-color: ${colors.grey[300]};
`
export const ImageFieldStyled = styled(ImageField)`
  width: 100%;
`
export const PreviewContainer = styled.div`
  width: 100%;
`

export const TableContainerStyled = styled.div`
  width: 100%;
  padding: 0px 10px;
  box-sizing: border-box;
  & * {
    box-sizing: border-box;
  }
  & td:first-child {
    padding: 0px;
    padding-right: 10px;
  }
`

export const TableRowStyled = styled.div`
  color: inherit;
  height: 48px;
  display: table-row;
  outline: none;
  position: relative;
  vertical-align: middle;
`

export const ListItemContainerStyled = styled.div`
  background-color: ${colors.common.white};
`

export const ListItemTextStyled = styled(ListItemText)`
  word-break: break-all;
`

export const ListItemStyled = styled(ListItem)`
  @media (max-width: ${size.lg}) {
    flex-wrap: wrap;
  }
`
export const SmallItemListStyled = styled.div`
  display: flex;
  width: 100%;
`

export const SmallItemListOptionsStyled = styled.div`
  display: flex;
  flex-direction: column;
`
