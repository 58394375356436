import React from 'react'
import { BooleanInput, maxLength, required, TextInput, DisabledInput, NumberInput } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import { ImageInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'

class MobileAppsForm extends React.Component {
  render() {
    const { isEdit, ...props } = this.props
    return (
      <LanguagesNav {...props}>
        <Grid container>
          <Grid item xs={12}>
            <TextInput fullWidth source="name" label="Name App" validate={[required(), maxLength(255)]} />
          </Grid>
          <Grid item xs={12}>
            <TextInput fullWidth source="playStoreUrl" label="Play Store URL" validate={[required()]} />
          </Grid>
          <Grid item xs={12}>
            <TextInput fullWidth source="appleStoreUrl" label="Apple Store URL" validate={[required()]} />
          </Grid>
          <Grid item xs={12}>
            <ImageInput source="image" label="Logo App *" accept="image/*" validate={[required()]} />
          </Grid>
          <Grid item xs={12}>
            <BooleanInput source="active" label="Active" />
          </Grid>
          <Grid item xs={2}>
            {isEdit ? <DisabledInput source="positionApp" label="Position App" /> : <NumberInput source="positionApp" label="Position App" defaultValue={0} />}
          </Grid>
        </Grid>
      </LanguagesNav>
    )
  }
}

export default MobileAppsForm
