import { withStyles } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import _get from 'lodash/get'
import React, { useState } from 'react'

import LanguagesNav from '../../../../components/languagesNav'
import ListQuickAccess from './ListQuickAccess'

const PageSubtitle = ({ record }) => <h3>Market: {_get(record, 'market')}</h3>

const styles = (theme) => ({
  row: {
    position: 'relative',
    background: theme.palette.grey[50],
    marginBottom: theme.spacing.unit,
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing.unit,
    '& button': {
      textTransform: 'capitalize',
      paddingLeft: theme.spacing.unit,
      '& svg': {
        fontSize: 20,
        marginRight: theme.spacing.unit / 2,
      },
    },
  },
  deleteButton: {
    position: 'absolute',
    right: theme.spacing.unit / 2,
    top: theme.spacing.unit / 2,
    zIndex: 1,
  },
  drag: {
    flex: '0 0 35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const FORM = 'record-form'
const PageForm = ({ classes, ...props }) => {
  const [tabActive, setTabActive] = useState(0)

  const handleChange = (event, value) => {
    setTabActive(value)
  }

  return (
    <LanguagesNav {...props}>
      <PageSubtitle {...props} />
      <Paper>
        <Tabs value={tabActive} onChange={handleChange} indicatorColor="primary" textColor="primary">
          <Tab label="Generals" />
          <Tab label="Hot topics" />
        </Tabs>
        {tabActive === 0 && <ListQuickAccess source="quickAccessGeneralItems" hideColor />}
        {tabActive === 1 && <ListQuickAccess source="quickAccessHotItems" />}
      </Paper>
    </LanguagesNav>
  )
}

export default withStyles(styles)(PageForm)
