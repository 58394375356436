import Dialog from '@material-ui/core/Dialog'
import List from '@material-ui/core/List'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { Component } from 'react'
import { ReferenceField } from 'react-admin'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { getResource } from '../../../config/resources'
import { colors } from '../../../config/theme'
import { ImageField } from '../../field'
import DropZone from './dropZone'
import FilterBar from './filterBar'
import ImageList from './imageList'
import ImageListSelected from './imageListSelected'
import ItemListLayout from './itemListLayout'
import { ContentStyled, ImageFieldStyled, ModalStyled, PreviewContainer, TableContainerStyled } from './style'

const IMAGES = 'images'
const VIDEOS = 'videos'
const GALLERIES = 'galleries'
const NEWS = 'news'

const LIST_VIEW_LIST = 'list'
const LIST_VIEW_SELECTED = 'listSelected'

const styles = (theme) => ({
  button: {
    padding: '10px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[400],
    width: '100%',
    maxWidth: '30%',
    fontSize: '13px',
    textAlign: 'left',
    borderRadius: '0px',
    margiRight: '11px',
    justifyContent: 'flex-start',
    textTransform: 'capitalize',
    marginRight: '11px',
    '& svg': {
      marginRight: '12px',
    },
  },
  closeButton: {
    width: '30px',
    height: '30px',
    '& svg': {
      fontSize: '18px',
    },
  },
  buttonSelected: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
    },
  },
  editButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    zIndex: '2',
  },
  removeButton: {
    position: 'absolute',
    left: '10px',
    top: '10px',
    zIndex: '2',
  },
  drgItem: {
    paddingBottom: '5px',
    paddingTop: '5px',
    '& > *': {
      backgroundColor: theme.palette.common.white,
    },
  },
  dragList: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
})

const getItems = (count) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k}`,
    content: `item ${k}`,
  }))

const reorder = (list, startIndex, endIndex, position) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  if (position) {
    const positionStart = _.get(result, `[${startIndex}].${position}`)
    const positionEnd = _.get(result, `[${endIndex}].${position}`)

    _.set(result, `[${startIndex}].${position}`, positionEnd)
    _.set(result, `[${startIndex}].${position}`, positionStart)
  }
  return result
}

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : colors.grey[300],

  width: '100%',
})

class GalleryEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
    }
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      items: _.compact(nextProps.value),
    }
  }

  getImageList() {
    const { options, onChange, value, filters, ...rest } = this.props
    const type = options && options.type ? options.type : IMAGES

    return (
      <Dialog open={this.state.open} onClose={() => this.handleClose()} scroll="body" maxWidth="md">
        <ModalStyled>
          <DropZone>
            <React.Fragment>
              {this.getOptionsBar(options && options.type ? options.type : IMAGES)}
              {this.state.selectedView === LIST_VIEW_LIST && (
                <FilterBar value={this.state.filters} onChange={(filters) => this.setState({ filters: filters })} />
              )}
              <ContentStyled>
                {this.state.selectedView === LIST_VIEW_LIST && (
                  <ImageList
                    type={type}
                    filters={this.state.filters}
                    selectedImg={this.state.selectedItems}
                    onSubmit={(item) => this.handleSubmit(item)}
                    onChange={(items) => {
                      this.setState({ selectedItems: items })
                    }}
                    resource={options ? options.resource : null}
                    {...rest}
                  />
                )}
                {this.state.selectedView === LIST_VIEW_SELECTED && (
                  <ImageListSelected
                    type={type}
                    filters={this.state.filters}
                    selectedItems={this.state.selectedItems}
                    onChange={(items) => {
                      this.setState({ selectedItems: items })
                    }}
                    {...rest}
                  />
                )}
              </ContentStyled>
            </React.Fragment>
          </DropZone>
        </ModalStyled>
      </Dialog>
    )
  }

  renderGallerieImage() {
    const { options, value } = this.props
    if (options && options.type === GALLERIES && value) {
      const images = _.find(value.galleryImages, (o) => {
        return o.prominent === true
      })
      const image = images ? images : value.galleryImages[0]
      return <img src={image.content.asset.fileDir} alt="" />
    }
    return null
  }

  renderImage(value, width) {
    const { options } = this.props

    if (!value) return null
    if (options && options.type === VIDEOS) {
      if (value.imageVideo) {
        return <ImageField mini record={value.imageVideo} sizes={['small', 'medium', 'large', 'xlarge']} sourceImage="" />
      } else if (value.providerThumbnails) {
        return <ImageField style={{ width: width }} record={value} source="providerThumbnails.small" title="imagen" mini />
      }
      return null
    }
    if (options && options.type === GALLERIES && value) {
      return (
        <ImageField
          style={{ width: width }}
          record={value}
          sourceImage="prominentImage.content"
          sizes={['small', 'medium', 'large', 'xlarge']}
          title="imagen"
          mini
        />
      )
    }
    if (options && options.type === NEWS && value) {
      return (
        <ReferenceField
          style={{ width: width }}
          linkType={false}
          record={value}
          basePath={getResource('assets', 'images')}
          source="imageDefault"
          reference={getResource('assets', 'images')}
        >
          <ImageField style={{ width: width }} mini sourceImage="" sizes={['small', 'medium', 'large', 'xlarge']} />
        </ReferenceField>
      )
    }
    if (value.asset) {
      return <ImageField record={value} sizes={['small', 'medium', 'large', 'xlarge']} title="imagen" mini />
    }
  }

  onDragEnd(result) {
    const { onChange, position } = this.props
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(this.state.items, result.source.index, result.destination.index, position)

    onChange(items)
  }

  onChangeCheckbox(index, selected) {
    const { options, value, onChange } = this.props
    if (options && options.checkboxActions && options.checkboxActions.prop) {
      const { prop, exclude } = options.checkboxActions
      const newValue = [...value]
      if (exclude || selected) {
        _.map(newValue, (item, indexItem) => {
          _.set(newValue, `[${indexItem}]${prop}`, indexItem === index)
        })
      } else {
        _.set(newValue, `[${index}]${prop}`, selected)
      }
      onChange(newValue)
    }
  }

  renderVideo(item) {
    if (item.imageVideo) {
      return <ImageFieldStyled mini record={item.imageVideo} sizes={['small', 'medium', 'large', 'xlarge']} sourceImage="" />
    } else if (item.providerThumbnails) {
      return <ImageFieldStyled record={item} source="providerThumbnails.small" title="imagen" mini />
    }
  }

  renderList(value) {
    const { options, classes, canChangePosition, content, handleSubmit, ...rest } = this.props
    if (!value) return null
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              <TableContainerStyled>
                <List className={classes.dragList}>
                  {value.map((contentItem, index) => {
                    const item = content ? _.get(contentItem, content) : contentItem

                    return (
                      <Draggable isDragDisabled={!canChangePosition} key={item.id} draggableId={item.id} index={index}>
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} className={classes.drgItem}>
                            {options && options.type === IMAGES && (
                              <ItemListLayout
                                options={options}
                                provided={provided}
                                canChangePosition={canChangePosition}
                                type="horizontal"
                                image={<ImageFieldStyled mini record={item} sizes={['small', 'medium', 'large', 'xlarge']} />}
                                text={item.name}
                                item={item}
                                index={index}
                                selected={options.checkboxActions ? _.get(contentItem, options.checkboxActions.prop) : false}
                                handleChangeCheckbox={(index, e) => this.onChangeCheckbox(index, e)}
                              />
                            )}
                            {options && options.type === VIDEOS && (
                              <ItemListLayout
                                options={options}
                                provided={provided}
                                canChangePosition={canChangePosition}
                                type="horizontal"
                                image={this.renderVideo(item)}
                                text={item.name}
                                item={item}
                                index={index}
                                selected={options.checkboxActions ? _.get(contentItem, options.checkboxActions.prop) : false}
                                handleChangeCheckbox={(index, e) => this.onChangeCheckbox(index, e)}
                              />
                            )}
                            {options && options.type === GALLERIES && (
                              <ItemListLayout
                                options={options}
                                provided={provided}
                                canChangePosition={canChangePosition}
                                type="horizontal"
                                image={
                                  <ImageFieldStyled
                                    mini
                                    sourceImage="prominentImage.content"
                                    sizes={['small', 'medium', 'large', 'xlarge']}
                                    record={item}
                                    title="imagen"
                                  />
                                }
                                text={item.name}
                                item={item}
                                index={index}
                                selected={options.checkboxActions ? _.get(contentItem, options.checkboxActions.prop) : false}
                                handleChangeCheckbox={(index, e) => this.onChangeCheckbox(index, e)}
                              />
                            )}
                            {options && options.type === NEWS && (
                              <ItemListLayout
                                options={options}
                                provided={provided}
                                canChangePosition={canChangePosition}
                                type="horizontal"
                                image={
                                  <ReferenceField
                                    linkType={false}
                                    record={item}
                                    basePath={getResource('assets', 'images')}
                                    source="imageDefault"
                                    reference={getResource('assets', 'images')}
                                  >
                                    <ImageFieldStyled mini sizes={['small', 'medium', 'large', 'xlarge']} />
                                  </ReferenceField>
                                }
                                text={item.title}
                                item={item}
                                index={index}
                                selected={options.checkboxActions ? _.get(contentItem, options.checkboxActions.prop) : false}
                                handleChangeCheckbox={(index, selected) => this.onChangeCheckbox(index, selected)}
                              />
                            )}
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                </List>
              </TableContainerStyled>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  render() {
    const { value, multiselect } = this.props
    return (
      <PreviewContainer>
        {!multiselect && this.renderImage(value)}
        {multiselect && this.renderList(this.state.items)}
      </PreviewContainer>
    )
  }
}

export default withStyles(styles)(GalleryEditor)
