import React from 'react'
import _ from 'lodash'
import { TextInput, DisabledInput, SelectInput } from 'react-admin'
import { required, maxLength } from 'react-admin'
import LanguagesNav from '../../../../components/languagesNav'
import { roleCollection } from '../../utils/constants'

import Grid from '@material-ui/core/Grid'

const choicesCollection = () => {
  let collection = []
  _.map(roleCollection, (n) => {
    collection.push({
      id: n.type,
      name: n.name,
    })
  })
  return collection
}

export const RolesForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12}>
        <TextInput fullWidth source="name" label="Name" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <TextInput fullWidth source="femaleName" label="Female Name" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <DisabledInput fullWidth source="slug" label="Slug" />
      </Grid>
      <Grid item xs={12}>
        <SelectInput allowEmpty source="collection" label="Collection" choices={choicesCollection()} />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default RolesForm
