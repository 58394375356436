import React from 'react'
import { List, Datagrid, TextField, EditButton, Responsive, SimpleList, Filter, TextInput } from 'react-admin'
import { TableRowList, ListActions } from '../../../components'

const SchoolsFilters = (props) => {
  return (
    <Filter {...props}>
      <TextInput className={props.classes.input} variant="filled" label="Name" source="name" alwaysOn fullWidth />
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<SchoolsFilters />} />
}

const SchoolsList = (props) => (
  <List bulkActions={false} title="Schools" actions={<Actions />} {...props}>
    <Responsive
      small={<SimpleList primaryText={(record) => record.name} />}
      medium={
        <Datagrid>
          <TextField source="name" label="Name" />
          <TextField source="slug" label="Slug" />
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default SchoolsList
