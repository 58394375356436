import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import AppIcon from '@material-ui/icons/Apps'
import { withStyles } from '@material-ui/core/styles'
import { setCookie } from '../../../providers/dataProvider/dataProviderUtils'

const styles = {
  button: {
    padding: 0,
  },
  label: {
    marginLeft: '3px',
    color: 'white',
  },
}

class PreviewButton extends Component {
  handleClick = () => {
    const { record } = this.props

    const baseUrl = process.env.REACT_APP_PUBLIC_FRONT
    const token = localStorage.getItem('adal.access.token.keyhttps://graph.microsoft.com')
    const url = baseUrl + `/_preview/layout/${record.id}`
    setCookie('preview_token', token)
    window.open(url)
  }

  render() {
    const { classes } = this.props

    return (
      <Button className={classes.button} color="secondary" label="Approve" onClick={this.handleClick}>
        <AppIcon /> <span className={classes.label}>Preview</span>
      </Button>
    )
  }
}

export default withStyles(styles)(PreviewButton)
