import _ from 'lodash'

export const roleCollection = {
  referee: {
    type: 'referee',
    name: 'Referee',
  },
  squad: {
    type: 'squad',
    name: 'Squad',
  },
}

export const teamType = {
  main: {
    type: 'main',
    name: 'Main',
  },
  promises: {
    type: 'promises',
    name: 'Promises',
  },
  genuine: {
    type: 'genuine',
    name: 'Genuine',
  },
  esports: {
    type: 'esports',
    name: 'ESports',
  },
  others: {
    type: 'others',
    name: 'Others',
  },
}

export const choicesTeamType = () => {
  let collection = []
  _.map(teamType, (n) => {
    collection.push({
      id: n.type,
      name: n.name,
    })
  })
  return collection
}

export const personsRoles = {
  jugador: {
    id: 1,
    slug: 'jugador',
  },
}

export const competitions = {
  laligaSantander: {
    id: 1,
    name: 'LaLiga Santander',
  },
  laliga123: {
    id: 2,
    name: 'LaLiga SmartBank',
  },
  ligaFemenina: {
    id: 15,
    name: 'Liga Femenina Iberdrola',
  },
}
