import React, { Component } from 'react'
import get from 'lodash/get'
import { ImageFieldStyled, PlaceholderImgStyled } from './style'
import { colors } from '../../../config/theme'
import _ from 'lodash'

class ImageField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: null,
      isFetching: false,
    }
  }

  static defaultProps = {
    sizes: ['small', 'medium', 'large', 'xlarge'],
  }

  getImage() {
    const { sourceImage, sizes, record } = this.props
    let newImage = null
    const myRecord = get(record, sourceImage) ? get(record, sourceImage) : record
    if (myRecord) {
      _.map(sizes, (size) => {
        if (!newImage) {
          const image = _.find(myRecord.resizes, { type: size })
          if (image) {
            newImage = image.url
          }
        }
      })
      return newImage ? newImage : get(myRecord, `asset.fileDir`)
    }
    return null
  }

  render() {
    const { record, source, label, state, sourceState, mini } = this.props
    const valueState = sourceState ? get(record, sourceState) : state
    let stateColor = 'transparent'
    let stateColorBorder = 'transparent'
    switch (valueState) {
      case 'published':
        stateColor = colors.states.successLight
        stateColorBorder = colors.states.success
        break
      case 'draft':
        stateColor = colors.states.warningLight
        stateColorBorder = colors.states.warning
        break
      case 'programmed':
        stateColor = colors.primary.main
        stateColorBorder = colors.primary.main
        break
      case 'hidden':
        stateColor = colors.states.errorLight
        stateColorBorder = colors.states.error
        break
      case 'disabled':
        stateColor = colors.grey[200]
        stateColorBorder = colors.grey[300]
        break
      default:
    }

    let image = ''
    if (source) image = _.get(record, source)
    else image = this.getImage()

    if (sourceState || state) {
      return (
        <ImageFieldStyled {...this.props} empty={this.state.value} stateBorder stateColor={stateColor} stateColorBorder={stateColorBorder}>
          {record && image ? <img src={image} alt={label} title={label} /> : <PlaceholderImgStyled mini={mini} />}
        </ImageFieldStyled>
      )
    } else {
      return (
        <ImageFieldStyled {...this.props} empty={this.state.value}>
          {record && image ? <img src={image} alt={label} title={label} /> : <PlaceholderImgStyled mini={mini} />}
        </ImageFieldStyled>
      )
    }
  }
}

export default ImageField
