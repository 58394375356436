import styled from 'styled-components'
import { size } from '../config/theme'

export const ContainerStyled = styled.div`
  & p {
    line-height: 1.5;
    margin-top: 0px;
  }
  & .list-page {
    & > div > div:last-child {
      padding: 20px;
      @media (max-width: ${size.sm}) {
        padding: 0px;
      }
    }
  }
`
export const StyleInlineSimpleFormIterator = styled.div`
  & ul {
    & li {
      & section {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        & .selectKind {
          margin-right: 20px;
        }
      }
    }
  }
`
