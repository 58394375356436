import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import './styleTinyMCE.css'
import { withFormActiveLanguage } from '../../languagesNav/formActiveLanguageContext'
import { additionalLanguages } from '../../../config/languages'

import tinymce from 'tinymce/tinymce'

// Theme
import 'tinymce/themes/silver'
// Toolbar icons
import 'tinymce/icons/default'
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css'

// importing the plugin js.
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/link'
import 'tinymce/plugins/image'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/code'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/media'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/table'
import 'tinymce/plugins/template'
import 'tinymce/plugins/help'

import uiOxideContent from './uiOxideContent.min'
import skinsContentDefault from './skinsContentDefault.min'

const styles = (theme) => ({
  label: {
    display: 'block',
    marginBottom: '8px',
    transform: 'translate( -12.5%, 0px) scale(0.75)',
    color: 'rgba(0, 0, 0, 0.54)',
  },
})

const RichText = (props) => {
  const {
    label,
    classes,
    formLanguageData: { formActiveLanguage },
  } = props
  const { value } = props.input ? props.input : props

  const dir = additionalLanguages.find((lang) => lang.slug === formActiveLanguage).dir || 'ltr'

  const handleChange = (value) => {
    const { onChange } = props.input ? props.input : props
    onChange(value)
  }

  return (
    <div>
      {label && <label className={classes.label}>{label}</label>}
      <Editor
        value={value}
        init={{
          skin: false,
          content_css: false,
          content_style: uiOxideContent() + '\n' + skinsContentDefault(),
          height: 300,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen table',
            'insertdatetime media table paste code help wordcount autoresize',
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | code | table',
          directionality: dir,
        }}
        onEditorChange={handleChange}
      />
    </div>
  )
}

export default withStyles(styles)(withFormActiveLanguage(RichText))
