const BASE_URL = 'http://competiciones.laliga.works'
const ADMIN_URL = `${BASE_URL}/api/admin/v1`

export { BASE_URL, ADMIN_URL }

const API = {
  operadores: {
    prefix: 'operadores',
    url: process.env.REACT_APP_BROADCASTER_SERVICE_ENDPOINT,
  },
  assets: {
    prefix: 'assets',
    url: process.env.REACT_APP_ASSETS_SERVICE_ENDPOINT,
  },
  competiciones: {
    prefix: 'competiciones',
    url: process.env.REACT_APP_COMPETITION_SERVICE_ENDPOINT,
  },
  digitalAssets: {
    prefix: 'digitalAssets',
    url: process.env.REACT_APP_DIGITALASSETS_SERVICE_ENDPOINT,
  },
  transversal: {
    prefix: 'transversal',
    url: process.env.REACT_APP_TRANSVERSAL_SERVICE_ENDPOINT,
  },
  news: {
    prefix: 'news',
    url: process.env.REACT_APP_NEWS_SERVICE_ENDPOINT,
  },
  layouts: {
    prefix: 'layouts',
    url: process.env.REACT_APP_LAYOUTS_SERVICE_ENDPOINT,
  },
  highlights: {
    prefix: 'highlights',
    url: process.env.REACT_APP_HIGHLIGHTS_SERVICE_ENDPOINT,
  },
  middleware: {
    prefix: 'middleware',
    url: process.env.REACT_APP_MIDDLEWARE_SERVICE_ENDPOINT,
  },
  queryParams: {
    prefix: 'queryParams',
    url: process.env.REACT_APP_LAYOUTS_SERVICE_ENDPOINT,
  },
  shortUrls: {
    prefix: 'shortUrls',
    url: process.env.REACT_APP_SHORT_URLS_SERVICE_ENDPOINT,
  },
}

export default API
