import React, { Component } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { BlocksListStyled, ContainerStyled } from './style'

class DndBlockEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
    }
    this.idBase = 0
  }

  componentDidMount() {
    if (this.props.list) this.setState({ items: this.props.list })
  }

  handleChange(value, index) {
    const list = this.props.list
    list[index] = value
    this.props.onChange(list)
  }

  render() {
    const { id } = this.props
    return (
      <DragDropContext onDragStart={null} onDragUpdate={null} onDragEnd={this.props.onDragEnd}>
        <ContainerStyled>
          <Droppable droppableId={id}>
            {(provided) => (
              <div>
                <BlocksListStyled {...provided.droppableProps} innerRef={provided.innerRef}>
                  {this.props.children}
                </BlocksListStyled>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </ContainerStyled>
      </DragDropContext>
    )
  }
}

export default DndBlockEditor
