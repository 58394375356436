import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowFowardIcon from '@material-ui/icons/ArrowForward'
import _ from 'lodash'
import React, { Component } from 'react'

import CheckboxBox from './checkboxBox'
import { CheckboxGroupControlerStyled, ContainerStyled } from './style'

const styles = (theme) => ({
  directionButton: {
    margin: '5px',
  },
})

const prepareData = (value) => {
  let newValue = _.cloneDeep(value)
  let choose = _.cloneDeep(_.filter(newValue, { checked: false }))
  let selectedItems = _.cloneDeep(_.filter(newValue, { checked: true }))

  _.map(selectedItems, (item) => {
    item.checked = false
  })

  return {
    leftBlock: choose,
    rightBlock: selectedItems,
    newValue: newValue,
  }
}

class CheckboxGroup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      prevValue: [],
      newValue: [],
      leftBlock: [],
      rightBlock: [],
    }
  }

  componentDidMount() {
    const { value, onChange } = this.props.input ? this.props.input : this.props
    let newValue = _.cloneDeep(value)
    this.setState(
      {
        prevValue: newValue,
      },
      () =>
        this.setState(prepareData(newValue), () => {
          onChange(newValue)
        })
    )
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value, onChange } = nextProps.input ? nextProps.input : nextProps
    if (!_.isEqual(_.sortBy(value), _.sortBy(prevState.prevValue))) {
      let state = prepareData(value)
      state.prevValue = value
      return state
    } else return null
  }

  handleOnChangeLeft(items) {
    this.setState({ leftBlock: items })
  }

  handleOnChangeRight(items) {
    this.setState({ rightBlock: items })
  }

  handleOnChangeControlerToLeft() {
    const { onChange } = this.props.input ? this.props.input : this.props
    let value = _.cloneDeep(this.state.newValue)
    let rightBlock = _.cloneDeep(this.state.rightBlock)
    let selectedItems = _.cloneDeep(_.filter(rightBlock, { checked: true }))

    _.map(selectedItems, (item) => {
      const index = _.findIndex(value, (o) => {
        return o.id === item.id
      })
      value[index].checked = false
    })
    this.setState(prepareData(value), () => {
      onChange(value)
    })
  }

  handleOnChangeControlerToRight() {
    const { onChange } = this.props.input ? this.props.input : this.props
    let value = _.cloneDeep(this.state.newValue)
    let leftBlock = _.cloneDeep(this.state.leftBlock)
    let selectedItems = _.cloneDeep(_.filter(leftBlock, { checked: true }))

    _.map(selectedItems, (item) => {
      const index = _.findIndex(value, (o) => {
        return o.id === item.id
      })
      value[index].checked = true
    })
    this.setState(prepareData(value), () => {
      onChange(value)
    })
  }

  render() {
    const { classes, disabled } = this.props
    const { leftBlock, rightBlock } = this.state

    return (
      <ContainerStyled>
        <CheckboxBox choose={leftBlock} disabled={disabled} onChange={(items) => this.handleOnChangeLeft(items)} />
        <CheckboxGroupControlerStyled>
          <Button
            disabled={disabled || !leftBlock.find((item) => item.checked)}
            className={classes.directionButton}
            onClick={() => this.handleOnChangeControlerToRight()}
            variant="contained"
            color="primary"
          >
            <ArrowFowardIcon />
          </Button>
          <Button
            disabled={disabled || !rightBlock.find((item) => item.checked)}
            className={classes.directionButton}
            onClick={() => this.handleOnChangeControlerToLeft()}
            variant="contained"
            color="primary"
          >
            <ArrowBackIcon />
          </Button>
        </CheckboxGroupControlerStyled>
        <CheckboxBox choose={rightBlock} disabled={disabled} onChange={(items) => this.handleOnChangeRight(items)} />
      </ContainerStyled>
    )
  }
}

export default withStyles(styles)(CheckboxGroup)
