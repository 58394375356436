import styled, { keyframes } from 'styled-components'
import { colors } from '../../config/theme'

export const keyFrameOpen = keyframes`
  0% {
    max-height: 0px;
  }
  100% {
    max-height: 600px;
}
`

export const ActionsStyled = styled.div`
  width: 100%;
  background-color: ${colors.grey[800]};
  padding: 6px 18px;
  padding-bottom: 0px;
  display: flex;
`

export const ActionsFiltersContainerStyled = styled.div`
  width: 100%;
  animation: ${keyFrameOpen} 0.5s;
  max-height: 600px;
`

export const ActionsFiltersStyled = styled.div`
  width: 100%;
  background-color: ${colors.primary.light};
  padding: 26px;
  display: flex;
  position: relative;
  & > div {
    width: 100%;
  }
  & .filter-field {
    display: inline-block;
    padding-right: 23px;
    width: 100%;
    &:last-child {
      padding-right: 0px;
    }
  }
`

export const SpaceStyled = styled.div`
  flex-grow: 1;
`

export const MenuRightStyled = styled.div`
  padding-bottom: 6px;
`

export const ToolbarStyled = styled.div`
  width: 100%;
`

export const ToolbarTitleStyled = styled.h2`
  padding: 20px;
  padding-bottom: 0px;
  margin: 0px;
`
