import styled from 'styled-components'
import { colors } from '../../../../config/theme'

export const AddModuleStyled = styled.div`
  min-height: 56px;
  border: dashed 1px ${colors.grey[400]};
  padding: 10px;
  margin-bottom: ${(props) => (props.empty ? '0px' : '5px')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background-color: ${colors.grey[400]};
  }
`

export const InfoStyled = styled.span`
  padding: 0px 10px;
  font-size: 14px;
  color: ${colors.states.error};
`
