import Button from '@material-ui/core/Button'
import React from 'react'
import { CREATE, fetchEnd, fetchStart, SaveButton, showNotification, SimpleForm, Toolbar, UPDATE } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, destroy, getFormValues, submit } from 'redux-form'

import dataProvider from '../../providers'
import { SeparatorStyled } from './style'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  form: {
    '& > div:first-child': {
      padding: '0px',
    },
    '& > div': {
      minWidth: '100%',
      boxSizing: 'border-box',
    },
  },
})

const DialogToolbar = ({ handleSubmitWithRedirect, redirect, submitOnEnter, onClose, ...rest }) => (
  <Toolbar {...rest}>
    <SaveButton handleSubmitWithRedirect={handleSubmitWithRedirect} redirect={redirect} submitOnEnter={submitOnEnter} />
    <SeparatorStyled />
    <Button onClick={() => onClose()} color="primary">
      Cancel
    </Button>
  </Toolbar>
)

class ImagesForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: false,
    }
  }
  componentDidMount() {
    //const {file, form} = this.props
    //if(file)this.props.change(FORM,'asset',file)
  }

  componentWillUnmount() {
    const { destroyForm, form } = this.props
    destroyForm(form)
  }

  handleSubmit({ ...data }) {
    const { onClickSave } = this.props
    if (onClickSave) onClickSave(data)
    else this.setImage(data)
  }

  setImage(imageProps) {
    const { fetchStart, fetchEnd, resource, type } = this.props
    if (resource) {
      fetchStart()
      this.setState({
        isFetching: true,
      })
      return dataProvider(imageProps.id ? UPDATE : CREATE, resource, {
        id: imageProps.id,
        data: imageProps,
      })
        .then((response) => {
          this.props.onSubmit(response.data)
          this.props.showNotification('Element updated', 'success')
        })
        .catch((error) => {
          this.props.showNotification(error.message, 'error')
        })
        .finally(() => {
          this.setState({
            isFetching: false,
          })
          fetchEnd()
        })
    } else {
      this.props.onSubmit(imageProps)
    }
  }

  render() {
    const { onClose, children, form, formData, isFetching, onClickSave, classes, toolbar, ...props } = this.props
    const formIsFetching = onClickSave ? isFetching : this.state.isFetching
    return (
      <SimpleForm
        {...props}
        saving={formIsFetching}
        form={form}
        save={(data) => this.handleSubmit(data)}
        toolbar={toolbar ? toolbar : <DialogToolbar isFetching={formIsFetching} onClose={() => onClose()} />}
        className={classes.form}
      >
        {React.cloneElement(children, {
          form: form,
          recordForm: formData(form),
        })}
      </SimpleForm>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      formData: (form) => getFormValues(form)(state),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      destroyForm: (form) => dispatch(destroy(form)),
      change: (form, field, value) => dispatch(change(form, field, value)),
      submit: () => dispatch(submit()),
    })
  ),
  withStyles(styles)
)

export default enhance(ImagesForm)
