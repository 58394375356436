import React, { Component } from 'react'
import { Field } from 'redux-form'
import DateTime from './DateTime'

class DateTimeInput extends Component {
  render() {
    if (this.props.source) {
      return <Field component={DateTime} {...this.props} name={this.props.name ? this.props.name : this.props.source} />
    } else {
      return <DateTime {...this.props} />
    }
  }
}

export default DateTimeInput
