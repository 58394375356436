import Grid from '@material-ui/core/Grid'
import React from 'react'
import _ from 'lodash'

import { DndBlockEditor } from '../../../components/inputs'
import { GalleryPlugin, NewPlugin, VideoPlugin } from '../../../components/inputs/dndBlockEditor/plugins'
import LanguagesNav from '../../../components/languagesNav'

const PageSubtitle = ({ record }) => <h3>Market: {_.get(record, 'market')}</h3>

export const PageForm = ({ numItems = 5, ...rest }) => (
  <LanguagesNav {...rest} removeDelete>
    <PageSubtitle {...rest} />
    <Grid container>
      <h3></h3>
      <DndBlockEditor
        label="Highlights"
        onChange={(value) => null}
        name="items"
        options={{
          limit: {
            numItems: numItems,
            type: 'fifo',
          },
        }}
        plugins={{
          gallery: {
            plugin: GalleryPlugin,
            options: {
              name: 'Gallery',
              removeClean: true,
              reference: true,
              readOnly: true,
            },
          },
          video: {
            plugin: VideoPlugin,
            options: {
              name: 'Video',
              removeClean: true,
              reference: true,
              readOnly: true,
              filters: {
                state: 'published',
              },
            },
          },
          news: {
            plugin: NewPlugin,
            options: {
              name: 'News',
              removeClean: true,
              reference: true,
              readOnly: true,
              filters: {
                state: 'published',
              },
            },
          },
        }}
      />
    </Grid>
  </LanguagesNav>
)

export default PageForm
