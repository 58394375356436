import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import classNames from 'classnames'
import React, { Component } from 'react'
import { showNotification } from 'react-admin'
import { arrayMove, List } from 'react-movable'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { BoolExternalLinkInput, ImageInput, LinkInput } from '../../../../inputs'
import { linkInputValidate } from '../../../../inputs/linkInput/validate'

const styles = (theme) => {
  return {
    root: {
      padding: '8px 0',
      width: '100%',
      boxSizing: 'border-box',
    },
    label: {
      marginBottom: '10px',
      display: 'flex',
      fontSize: '0.75rem',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    imageRow: {
      background: theme.palette.grey[50],
      width: '100%',
      padding: '8px 8px 8px 0',
      marginBottom: '8px',
      boxSizing: 'border-box',
      display: 'flex',
    },
    image: {
      '& > div': {
        display: 'flex',
      },
      '& label': {
        display: 'none',
      },
      '& .imageContainer': {
        minHeight: '0px',
        minWidth: '0px',
        height: '100px',
        width: '100px',
        overflow: 'hidden',
      },
    },
    imagePortrait: {
      '& .imageContainer': {
        width: '133px',
        height: '200px',
      },
    },
    imageLandscape: {
      '& .imageContainer': {
        width: '200px',
        height: '133px',
      },
    },
    moreInfo: {
      boxSizing: 'border-box',
      padding: '8px 16px',
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
    },
    drag: {
      flex: '0 0 35px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }
}

class ListImages extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
  }

  handleChange(position, key, value) {
    let newContent = [...this.props.content]
    if (key === 'image') {
      for (let i = 0; i < newContent.length; i++) {
        if (newContent[i].image === value && position !== i) {
          value = null
          this.props.showNotification('This image has been used.', 'warning')
          break
        }
      }
    }
    newContent[position][key] = value
    this.props.onChange(newContent)
  }

  handleRemove(position) {
    let newContent = [...this.props.content]
    newContent.splice(position, 1)
    this.props.onChange(newContent)
  }

  handleAdd() {
    let newContent = this.props.content ? [...this.props.content] : []
    newContent.push({
      image: null,
      target: 'internal',
      url: '',
    })
    this.props.onChange(newContent)
  }

  handleChangeOrder = ({ oldIndex, newIndex }) => {
    let newContent = [...this.props.content]
    newContent = arrayMove(newContent, oldIndex, newIndex)
    this.props.onChange(newContent)
  }

  render() {
    const { content, classes, version } = this.props
    return (
      <div className={classes.root}>
        <label className={classes.label}>
          <span>Images</span>
          <Button color="primary" size="small" variant="contained" onClick={this.handleAdd}>
            <AddIcon />
          </Button>
        </label>
        {content && (
          <List
            lockVertically
            values={content}
            onChange={this.handleChangeOrder}
            renderList={({ children, props }) => <div {...props}>{children}</div>}
            renderItem={({ index, props, isDragged }) => {
              const each = content[index]
              return (
                <div {...props} style={{ ...props.style, zIndex: 1 }}>
                  <div className={classes.imageRow} key={each.image || index}>
                    <div className={classes.drag} data-movable-handle style={{ cursor: isDragged ? 'grabbing' : 'grab' }}>
                      <DragHandleIcon />
                    </div>
                    <div
                      className={classNames(classes.image, {
                        [`${classes.imagePortrait}`]: version === 'portrait',
                        [`${classes.imageLandscape}`]: version === 'landscape',
                      })}
                    >
                      <ImageInput
                        value={each.image}
                        onChange={(value) => this.handleChange(index, 'image', value.id)}
                        meta={{
                          error: !each.image ? 'required' : false,
                          touched: true,
                        }}
                        options={{}}
                      />
                    </div>
                    <div className={classes.moreInfo}>
                      <BoolExternalLinkInput label="External link" onChange={(value) => this.handleChange(index, 'target', value)} value={each.target} />
                      <LinkInput
                        label="Url"
                        value={each.url}
                        onChange={(value) => this.handleChange(index, 'url', value)}
                        internalLink={each.target === 'internal'}
                        meta={{
                          error: linkInputValidate('target')(each.url, each),
                        }}
                        fullWidth
                      />
                    </div>
                    <Button variant="fab" color="secondary" aria-label="Remove" className={classes.button} onClick={() => this.handleRemove(index)} mini>
                      <DeleteIcon />
                    </Button>
                  </div>
                </div>
              )
            }}
          />
        )}
      </div>
    )
  }
}

const enhance = compose(
  connect(null, {
    showNotification,
  }),
  withStyles(styles)
)

export default enhance(ListImages)
