import React from 'react'
import { Create } from 'react-admin'

import MobileAppsForm from './form'

export const MobileAppsCreate = (props) => (
  <Create {...props}>
    <MobileAppsForm isEdit={false} {...props} />
  </Create>
)

export default MobileAppsCreate
