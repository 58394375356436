import React from 'react'
import {
  TextInput,
  required,
  maxLength,
  SelectInput,
  LongTextInput,
  GET_ONE,
  GET_LIST,
  fetchEnd,
  fetchStart,
  showNotification,
  FormDataConsumer,
} from 'react-admin'
import _get from 'lodash/get'
import LanguagesNav from '../../../../components/languagesNav'
import { Grid } from '@material-ui/core'
import { MatchInput } from '../../../../components/inputs'
import dataProvider from '../../../../providers'
import { getResource } from '../../../../config/resources'
import { change, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import MetricTeam from './MetricTeam'
import MetricPlayer from './MetricPlayer'

const FORM = 'record-form'
const selector = formValueSelector(FORM)

const styles = () => ({
  fullWidth: {
    width: '100%',
    '& > div': {
      '& > div': {
        width: '100%',
        marginTop: '6px',
        marginLeft: '-12px',
      },
    },
  },
})

class AdsSpaceForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // metricChoices: [
      //   { id: 'team', name: 'Team' },
      //   { id: 'player', name: 'Player' },
      //   { id: 'generic', name: 'Generic' },
      // ],
      // matchData: null,
      // matchId: null,
      // teamFilter: null,
      // isFetchingPlayers: false,
      seasonFilter: null,
      sponsors: [],
    }
  }

  componentDidMount() {
    // const { change, record } = this.props
    // if (record.matchId) {
    //   dataProvider(GET_ONE, getResource('competiciones', 'matches'), {
    //     id: record.matchId,
    //   }).then(({ data }) => {
    //     change(FORM, 'tempSeason', _get(data, 'season.id'))
    //     change(FORM, 'tempCompetition', _get(data, 'competition.id'))
    //     change(FORM, 'tempSubscription', _get(data, 'subscription.id'))
    //     change(FORM, 'tempRound', _get(data, 'gameweek.round.id'))
    //     change(FORM, 'tempGameweek', _get(data, 'gameweek.id'))
    //   })
    // }
    this.getSponsors()
  }

  getSponsors() {
    dataProvider(GET_LIST, getResource('digitalAssets', 'sponsors'), {
      sort: {
        field: 'createdAt',
        order: 'DESC',
      },
      filter: {
        active: true,
      },
      pagination: {
        page: 1,
        perPage: 50,
      },
    }).then(({ data }) => {
      this.setState({
        sponsors: data,
      })
    })
  }

  renderOptionText = (value) => {
    return `${_get(value, 'shirtNumber') || ''} ${_get(value, 'personRole.person.name')} (${_get(value, 'personRole.person.firstname')} ${_get(
      value,
      'personRole.person.lastname'
    )})`
  }

  render() {
    return (
      <LanguagesNav {...this.props}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <Grid container>
                {/* <Grid item xs={12}>
                  <MatchInput
                    validate={[required()]}
                    sourceSeason="tempSeason"
                    sourceCompetition="tempCompetition"
                    sourceSubscription="tempSubscription"
                    sourceRound="tempRound"
                    sourceGameweek="tempGameweek"
                    sourceMatch="matchId"
                  />
                </Grid> */}
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextInput source="title" label="Title" fullWidth validate={[required(), maxLength(255)]} />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectInput
                      source="space"
                      choices={[
                        { id: 'calendar', name: 'Calendar' },
                        { id: 'matchDetailpage', name: 'matchDetailpage' },
                      ]}
                      alwaysOn
                      fullWidth
                      defaultValue="calendar"
                    />
                  </Grid>
                  {/* 
                  <Grid item xs={12}>
                    <LongTextInput source="body" label="El Dato Description" fullWidth validate={[required(), maxLength(255)]} />
                  </Grid> 
                  {formData.matchId && (
                    <Grid item xs={12} lg={4}>
                      <SelectInput source="metric" label="Metric" choices={this.state.metricChoices} fullWidth />
                    </Grid>
                  )}
                  {formData.matchId && formData.metric === 'team' && <MetricTeam matchId={formData.matchId} />}
                  {formData.matchId && formData.metric === 'player' && <MetricPlayer matchId={formData.matchId} />} */}

                  <Grid item xs={12}>
                    <SelectInput label="Sponsor" alwaysOn fullWidth choices={this.state.sponsors} source="sponsor.id" optionValue="id" />
                  </Grid>
                </Grid>
              </Grid>
            )
          }}
        </FormDataConsumer>
      </LanguagesNav>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  )
)

export default withStyles(styles)(enhance(AdsSpaceForm))
