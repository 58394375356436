import { CRUD_CREATE_FAILURE, CRUD_UPDATE_FAILURE } from 'react-admin'
import { stopSubmit } from 'redux-form'
import { put, takeEvery } from 'redux-saga/effects'

const sendValidate = function* () {
  yield takeEvery([CRUD_CREATE_FAILURE, CRUD_UPDATE_FAILURE], crudCreateFailure)
}

export function* crudCreateFailure({ type, requestPayload, payload }) {
  yield put(stopSubmit('record-form', payload))
}

export default sendValidate
