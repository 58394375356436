import Grid from '@material-ui/core/Grid'
import React from 'react'
import { Datagrid, EditButton, Filter, List, Responsive, SimpleList, TextField, TextInput } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'
import { DateField } from '../../../components/field'
import { DateRangeInput } from '../../../components/inputs'

const ImagesFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput source="name" label="Name" alwaysOn />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <DateRangeInput source="createdAt" fullWidth />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<ImagesFilter />} />
}

export const BroadcastList = (props) => (
  <List bulkActions={false} actions={<Actions />} title="Layouts" {...props}>
    <Responsive
      small={<SimpleList primaryText={(record) => record.name} secondaryText={(record) => <DateField record={record} source="createdAt" />} />}
      medium={
        <Datagrid>
          <TextField source="name" label="Name" />
          <DateField source="createdAt" />
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default BroadcastList
