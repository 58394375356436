import React, { useState } from 'react'
import { BooleanInput } from 'react-admin'
import Typography from '@material-ui/core/Typography'

const Toggle = ({ toggle }) => {
  const [showHelper, setShowHelper] = useState(false)
  return (
    <div style={{ position: 'relative' }} onMouseOver={() => setShowHelper(true)} onMouseLeave={() => setShowHelper(false)}>
      <BooleanInput source={toggle.source} label={toggle.label} />
      {showHelper && (
        <Typography variant="caption" style={{ position: 'absolute', backgroundColor: '#EEEEEE' }}>
          *{toggle.helper}
        </Typography>
      )}
    </div>
  )
}

export default Toggle
