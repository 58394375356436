import styled from 'styled-components'

export const ContainerStyled = styled.div`
  position: relative;
`
export const PickerStyled = styled.div`
  position: absolute;
  bottom: 10px;
  right: -20px;
`
export const PickerContainerStyled = styled.div`
  position: relative;
`
export const ColorStyled = styled.div`
  border-radius: 3px;
  position: absolute;
  width: 24px;
  height: 19px;
  bottom: 20px;
  left: 5px;
  background-color: ${(props) => props.bgColor};
`
