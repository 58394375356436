import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { getResources, toggleSidebar as toggleSidebarAction, translate } from 'ra-core';
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import { Logo } from '../../../components';
import Responsive from '../responsive';
import DashboardMenuItem from './DashboardMenuItem';
import ListMenu from './menu';
import { UserBarStyled } from './styles';

const styleLogo = {
  padding: '14px',
  marginRight: '10px',
  height: '31px',
  width: 'auto',
}

const styles = (theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
  },
  header: {
    backgroundColor: theme.palette.common.black,
  },
})

const Menu = ({ classes, user, groups, className, dense, open, hasDashboard, onMenuClick, pathname, resources, translate, toggleSidebar, logout, ...rest }) => {
  return (
    <div className={classnames(classes.main, className)} {...rest}>
      <header className={classes.header}>
        <Logo mini={!open} style={styleLogo} type="light" />
        <UserBarStyled className="user-bar">
          <span> {user ? user.username : 'No logueado'}</span>
          {/* <p>{JSON.stringify(groups)}</p>*/}
        </UserBarStyled>
      </header>
      {hasDashboard && <DashboardMenuItem onClick={onMenuClick} />}
      <ListMenu resources={resources} translate={translate} dense={dense} onMenuClick={onMenuClick} open={open} />
      <Responsive xsmall={logout} medium={null} />
    </div>
  )
}

Menu.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  groups: PropTypes.array,
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  logout: PropTypes.element,
  onMenuClick: PropTypes.func,
  pathname: PropTypes.string,
  resources: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired,
}

Menu.defaultProps = {
  onMenuClick: () => null,
}

const mapStateToProps = (state) => ({
  resources: getResources(state),
  pathname: state.router.location.pathname, // used to force redraw on navigation
})

const enhance = compose(
  translate,
  connect(
    mapStateToProps,
    {}, // Avoid connect passing dispatch in props,
    null,
    {
      areStatePropsEqual: (prev, next) =>
        prev.resources.every(
          (value, index) => value === next.resources[index] // shallow compare resources
        ) && prev.pathname === next.pathname,
      toggleSidebar: toggleSidebarAction,
    }
  ),
  withStyles(styles)
)

export default enhance(Menu)
