import MuiAppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { toggleSidebar as toggleSidebarAction } from 'ra-core'
import React, { cloneElement } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import UrlShortener from './urlShortener'

import LoadingIndicator from './LoadingIndicator'

const styles = (theme) => ({
  appBar: {
    position: 'fixed',
    width: 'calc(100% - 240px)',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 1300,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      top: '0px',
      left: '0px',
    },
  },
  appBarClose: {
    width: 'calc(100% - 93px)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  grow: {
    flexGrow: 1,
  },
  pathname: {
    color: theme.palette.common.black,
  },
  toolbar: {
    paddingRight: 24,
  },
  menuButton: {
    marginLeft: '0.5em',
    marginRight: '0.5em',
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(0deg)',
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(180deg)',
  },
  title: {
    flex: 1,
  },
  loadingIndicator: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 1200,
    margin: 0,
  },
  logout: {
    color: theme.palette.secondary.contrastText,
  },
})

const AppBar = ({
  classes,
  className,
  locale,
  logout,
  open,
  title,
  state,
  posts,
  subheader,
  toggleSidebar,
  toggleSubheader,
  adminCustom,
  pathname,
  ...rest
}) => {
  return (
    <MuiAppBar className={classNames(classes.appBar, className, !open && classes.appBarClose)} color="secondary" position="absolute" {...rest}>
      <div>
        <Toolbar disableGutters className={classes.toolbar}>
          <IconButton aria-label="open drawer" onClick={toggleSidebar} className={classNames(classes.menuButton)}>
            <MenuIcon
              classes={{
                root: open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed,
              }}
            />
          </IconButton>
          <div className={classes.pathname}>{title}</div>
          <div className={classes.grow}></div>
          {/*
                <IconButton >
                    <Badge className={classes.margin} badgeContent={2} color="error">
                    <NotificationsIcon />
                    </Badge>
                </IconButton>
                */}
          <UrlShortener />
          {logout &&
            cloneElement(logout, {
              className: classes.logout,
            })}
        </Toolbar>
      </div>
      <LoadingIndicator className={classes.loadingIndicator} />
    </MuiAppBar>
  )
}

AppBar.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  toggleSidebar: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    (state) => ({
      locale: state.i18n.locale, // force redraw on locale change
      state: state,
      posts: state.admin.resources.posts,
      pathname: state.router.location.pathname,
      adminCustom: state.adminCustom,
    }),
    {
      toggleSidebar: toggleSidebarAction,
    }
  ),
  withStyles(styles)
)

export default enhance(AppBar)
