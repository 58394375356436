import styled from 'styled-components'
import { colors } from '../../../config/theme'

export const ContainerStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
`
export const ListStyled = styled.div`
  height: 300px;
  overflow-y: scroll;
`

export const CheckboxFormStyled = styled.div`
  border: solid 1px ${colors.grey[300]};
`

export const CheckboxGroupControlerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const ControlerStyled = styled.div``

export const SelectAllStyled = styled.div`
  background-color: ${colors.grey[300]};
`
