import React, { Component } from 'react'
import { Field } from 'redux-form'
import BoolExternalLink from './BoolExternalLink'

class BoolExternalLinkInput extends Component {
  render() {
    if (this.props.source) {
      return <Field {...this.props} label={this.props.label} component={BoolExternalLink} name={this.props.source} />
    } else {
      return <BoolExternalLink {...this.props} />
    }
  }
}

export default BoolExternalLinkInput
