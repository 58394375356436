import React, { Component } from 'react'
import { Field } from 'redux-form'
import Balls from './Balls'

class BallInput extends Component {
  render() {
    const { source, label, ...rest } = this.props
    return <Field component={Balls} name={this.props.source} label={this.props.label ? this.props.label : 'Balls'} {...rest} />
  }
}

export default BallInput
