import React, { Component } from 'react'
import Plugin from '../plugin'
import FaqsPlugin from './FaqsPlugin'

class TextPlugin extends Component {
  render() {
    return <Plugin name="faqs" {...this.props} component={FaqsPlugin} />
  }
}

export default TextPlugin
