import React from 'react'
import { Edit } from 'react-admin'

import BroadcastsForm from './form'

const BroadcastTitle = ({ record }) => {
  return <span>{`Difusión: ${record.nombre}`}</span>
}

export const BroadcastEdit = (props) => (
  <Edit undoable={false} title={<BroadcastTitle />} {...props}>
    <BroadcastsForm isEdit={true} {...props} />
  </Edit>
)

export default BroadcastEdit
