import _get from 'lodash/get'
import React, { Component } from 'react'
import { fetchEnd, fetchStart, GET_ONE, showNotification } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector } from 'redux-form'

import { MatchInput } from '../../../../components/inputs'
import { getResource } from '../../../../config/resources'
import * as Variables from '../../../../config/variables'
import dataProvider from '../../../../providers'

const FORM = 'record-form'
const selector = formValueSelector(FORM)
class GameweekSelector extends Component {
  componentDidMount() {
    const { field, change } = this.props
    const gameweek = field('gameweek')
    if (gameweek) {
      dataProvider(GET_ONE, getResource('competiciones', 'gameweeks'), {
        id: gameweek,
      }).then(({ data }) => {
        change(FORM, 'temp-season', _get(data, 'round.subscription.season.id'))
        change(FORM, 'temp-competition', _get(data, 'round.subscription.competition.id'))
        change(FORM, 'temp-subscription', _get(data, 'round.subscription.id'))
        change(FORM, 'temp-round', _get(data, 'round.id'))
      })
    }
  }

  render() {
    return (
      <MatchInput
        alwaysOn
        sourceSeason="temp-season"
        sourceCompetition="temp-competition"
        sourceSubscription="temp-subscription"
        sourceRound="temp-round"
        sourceGameweek="gameweek"
        sourceMatch={false}
        filterCompetition={{ id: [Variables.ID_PRIMERA_DIVISION] }}
      />
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  )
)

export default enhance(GameweekSelector)
