import { Grid, FormLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { Fragment } from 'react'
import { Edit, TextField, FormDataConsumer } from 'react-admin'

import LanguagesNav from '../../../components/languagesNav'
import ButtonValidate from './buttonValidate'
import MatchInfo from './matchInfo'
import ListRules from './listRules'
import ListBroadcast from './listBroadcast'
import stateImport from './state'

const ImportTitle = ({ record }) => <span>Importar:</span>

const styles = (theme) => ({
  rowState: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

export const ImportEdit = (props) => (
  <Edit title={<ImportTitle />} {...props}>
    <LanguagesNav disabledLanguages={true}>
      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) => {
          if (formData) {
            return (
              <Fragment>
                <Grid container>
                  <Grid item xs={9} className={props.classes.rowState}>
                    <div>
                      <FormLabel>State</FormLabel>
                      <TextField source="state" record={formData} />
                    </div>
                    {formData.state === stateImport.revised && <ButtonValidate {...props} record={formData} />}
                  </Grid>
                </Grid>
                <MatchInfo />
                {formData.state === stateImport.pending && <ListRules errorImports={formData.errorImports} />}
                {formData.state === stateImport.completed && (
                  <Grid container>
                    <Grid item xs={12}>
                      <ListBroadcast idMatch={formData.match} />
                    </Grid>
                  </Grid>
                )}
              </Fragment>
            )
          }
        }}
      </FormDataConsumer>
    </LanguagesNav>
  </Edit>
)

export default withStyles(styles)(ImportEdit)
