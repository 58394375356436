import React from 'react'
import { Button, Grid, TextField, Collapse, ListItem, ListItemText, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import DeleteIcon from '@material-ui/icons/Delete'
import DragHandleIcon from '@material-ui/icons/DragHandle'

import { ColorPicker, ImageInput, LinkInput, RichTextInput } from '../../../../../inputs'
import { linkInputValidate } from '../../../../linkInput/validate'

const styles = (theme) => {
  return {
    root: {
      padding: '8px 0',
      width: '100%',
      boxSizing: 'border-box',
    },
    label: {
      marginBottom: '10px',
      display: 'flex',
      fontSize: '0.75rem',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    cardRow: {
      background: theme.palette.grey[50],
      width: '100%',
      padding: '8px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
    },
    moreInfo: {
      boxSizing: 'border-box',
      padding: '0 16px',
      display: 'flex',
      flexDirection: 'column',
    },
    drag: {
      flex: '0 0 35px',
    },
    deleteButton: {
      marginRight: '16px',
    },
    title: {
      width: '100%',
      marginBottom: '8px',
    },
    relativeElement: {
      position: 'relative',
    },
    reset: {
      width: '35px',
      height: '35px',
      fontSize: 20,
      position: 'absolute',
      zIndex: 2,
      top: '40%',
      right: 0,
    },
  }
}

class Card extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      includesLink: false,
    }
  }

  handleClick = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  extractTextFromHTML = (htmlString) => {
    // Create a new DOMParser
    const parser = new DOMParser()

    // Parse the HTML string
    const doc = parser.parseFromString(htmlString, 'text/html')

    // Get the first element (whatever it is)
    const element = doc.body.firstChild

    // Get the text content of the element
    const textContent = element ? element.textContent : ''

    return textContent
  }

  render() {
    const { classes, each, index, isDragged, handleChange, handleRemove } = this.props
    return (
      <div className={classes.cardRow} key={each.image || index}>
        <ListItem button onClick={this.handleClick}>
          <div className={classes.drag} data-movable-handle style={{ cursor: isDragged ? 'grabbing' : 'grab' }}>
            <DragHandleIcon />
          </div>
          <ListItemText primary={this.extractTextFromHTML(each.title) || 'No title'} />
          <Button variant="fab" color="secondary" aria-label="Remove" className={classes.deleteButton} onClick={() => handleRemove(index)} mini>
            <DeleteIcon />
          </Button>
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse className={classes.moreInfo} in={this.state.open} timeout="auto">
          <div>
            <ImageInput
              value={each.image}
              onChange={(value) => handleChange(index, 'image', value.id)}
              meta={{
                error: !each.image ? 'required' : false,
                touched: true,
              }}
              options={{}}
            />
          </div>
          <Grid container>
            <Grid item xs={12} lg={8} className={classes.relativeElement}>
              <LinkInput label="Url (optional)" value={each.url} onChange={(value) => handleChange(index, 'url', value)} fullWidth />
              {each.url.length !== 0 &&
                (!linkInputValidate('external')(each.url) ? null : (
                  <div style={{ position: 'absolute', top: '9px', left: '82px' }}>
                    <Typography variant="caption" color="error">
                      Invalid Url *
                    </Typography>
                  </div>
                ))}
            </Grid>

            {each.url.length > 0 && !linkInputValidate('external')(each.url) && (
              <Grid item xs={12} lg={4} className={classes.relativeElement}>
                <TextField
                  label="Url Title"
                  value={each.urlTitle}
                  onChange={(value) => {
                    handleChange(index, 'urlTitle', value.target.value)
                  }}
                  fullWidth
                />
                {each.urlTitle.length === 0 && (
                  <div style={{ position: 'absolute', top: '9px', left: '56px' }}>
                    <Typography variant="caption" color="error">
                      required
                    </Typography>
                  </div>
                )}
              </Grid>
            )}
          </Grid>
          <div style={{ marginBottom: '12px' }} className={classes.relativeElement}>
            <RichTextInput
              label="Title"
              value={each.title}
              onChange={(value) => {
                handleChange(index, 'title', value)
              }}
            />
            {each.title.length === 0 && (
              <div style={{ position: 'absolute', top: '1px', left: '26px' }}>
                <Typography variant="caption" color="error">
                  required
                </Typography>
              </div>
            )}
          </div>
          <div style={{ marginBottom: '12px' }} className={classes.relativeElement}>
            <RichTextInput
              label="Description"
              value={each.excerpt}
              onChange={(value) => {
                handleChange(index, 'excerpt', value)
              }}
            />
            {each.excerpt.length === 0 && (
              <div style={{ position: 'absolute', top: '1px', left: '64px' }}>
                <Typography variant="caption" color="error">
                  required
                </Typography>
              </div>
            )}
          </div>
          <Grid container>
            <Grid item className={classes.relativeElement}>
              <ColorPicker
                label="Bg color text container (optional)"
                value={each.color}
                onChange={(value) => {
                  handleChange(index, 'color', value)
                }}
              />
              {each.color ? (
                <IconButton
                  className={classes.reset}
                  aria-label="Reset value"
                  onClick={() => {
                    handleChange(index, 'color', '')
                  }}
                >
                  <ClearIcon fontSize="inherit" />
                </IconButton>
              ) : null}
            </Grid>
          </Grid>
        </Collapse>
      </div>
    )
  }
}

export default withStyles(styles)(Card)
