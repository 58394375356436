import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import PropTypes from 'prop-types'

const styles = {
  divColor: {
    width: '20px',
    height: '20px',
    border: '1px solid #616161',
  },
}

const TextField = ({ source, record = {}, classes }) => {
  return <span>{_.get(record, source) && <div className={classes.divColor} style={{ background: _.get(record, source) }} />}</span>
}

TextField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

export default withStyles(styles)(TextField)
