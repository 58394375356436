import React, { Component } from 'react'
import NewPluginEdit from './newPluginEdit'
import Plugin from '../plugin'

class ImagePlugin extends Component {
  render() {
    return <Plugin {...this.props} name="imagePlugin" component={NewPluginEdit} />
  }
}

export default ImagePlugin
