import Grid from '@material-ui/core/Grid'
import React from 'react'
import { TextInput, ReferenceInput, required, maxLength } from 'react-admin'

import { DateSingleInput, AutocompleteInput, ImageInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'
import { getResource } from '../../../../config/resources'

export const ActForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <DateSingleInput source="startedAt" label="Started at" validate={[required()]} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextInput fullWidth source="name" label="Name" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <ReferenceInput label="Event" source="event.id" reference={getResource('assets', 'events')} fullWidth>
          <AutocompleteInput optionText="name" validate={[required()]} />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <ImageInput source="image" label="Image" options={{ remove: true }} getObject />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default ActForm
