import React from 'react'
import Grid from '@material-ui/core/Grid'
import { CloneButton, EditButton, SimpleList, Responsive, List, Filter, Datagrid, TextField, TextInput, ReferenceInput, SelectInput } from 'react-admin'
import { ListActions, TableRowList } from '../../../components'
import { DateRangeInput } from '../../../components/inputs'
import { getResource } from '../../../config/resources'
import { DateField } from '../../../components/field'

const ImagesFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput source="name" label="Name" alwaysOn />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <DateRangeInput label="Created at" source="createdAt" fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <ReferenceInput filter={{ parent: 'firstLevel' }} source="parent" label="Parent" reference={getResource('news', 'categories')} alwaysOn allowEmpty>
            <SelectInput />
          </ReferenceInput>
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<ImagesFilter />} />
}

export const BroadcastList = (props) => (
  <List bulkActions={false} actions={<Actions />} title="Categories" {...props}>
    <Responsive
      small={<SimpleList primaryText={(record) => record.name} secondaryText={(record) => (record.parent ? record.parent.name : '')} />}
      medium={
        <Datagrid>
          <TextField source="name" label="Name" />
          <TextField source="parent.name" label="Parent" sortable={false} />
          <DateField source="createdAt" />
          <TableRowList>
            <EditButton />
            <CloneButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default BroadcastList
