import Grid from '@material-ui/core/Grid'
import React from 'react'
import {
  ArrayField,
  ChipField,
  CloneButton,
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  Responsive,
  SelectInput,
  SimpleList,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin'

import { ImageField, ListActions, TableRowList } from '../../../components'
import { DateField } from '../../../components/field'
import { DateRangeInput, MetadataInput } from '../../../components/inputs'
import { getResource } from '../../../config/resources'
import PreviewButton from './previewbutton'

const NewsFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={6} md={3}>
          <ReferenceInput
            source="category"
            label="Category"
            reference={getResource('news', 'categories')}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={100}
            alwaysOn
            allowEmpty
            fullWidth
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SelectInput
            defaultValue="draft"
            fullWidth
            alwaysOn
            allowEmpty
            source="status"
            choices={[
              {
                id: 'published',
                name: 'published',
              },
              {
                id: 'draft',
                name: 'draft',
              },
              {
                id: 'hidden',
                name: 'hidden',
              },
              {
                id: 'disabled',
                name: 'disabled',
              },
              {
                id: 'programmed',
                name: 'programmed',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ReferenceInput
            source="market"
            label="Markets"
            filter={{ group: 'oneMarket' }}
            reference={getResource('transversal', 'markets')}
            alwaysOn
            allowEmpty
            fullWidth
          >
            <SelectInput optionText="id" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ReferenceInput source="clientUuid" label="Clients" reference={getResource('transversal', 'clientuuids')} alwaysOn allowEmpty fullWidth>
            <SelectInput optionText="name" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput source="title" label="Title" alwaysOn fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateRangeInput label="Created At" source="createdAt" fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateRangeInput label="Published At" source="publishedAt" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <MetadataInput source="metadatas" label="Metadatas" />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<NewsFilter />} />
}

export const MyImageField = ({ ...props }) => {
  const { record } = props
  if (record && record.image) {
    return (
      <ReferenceField {...props} linkType={false} reference={getResource('assets', 'images')} source="imageDefault" allowEmpty>
        <ImageField state={props.state} sizes={['small', 'medium', 'large', 'xlarge']} title="imagen operador" />
      </ReferenceField>
    )
  }
  return <ImageField record={record} state={props.state} source="providerThumbnails.small" title="imagen operador" />
}

export const Image = ({ ...props }) => {
  const { record, label } = props
  return (
    <ReferenceField {...props} linkType={false} reference={getResource('assets', 'images')} label={label} source="imageDefault" allowEmpty>
      <ImageField state={record.status} sizes={['small', 'medium', 'large', 'xlarge']} title="imagen operador" />
    </ReferenceField>
  )
}

export const NewsList = ({ actions, title, ...rest }) => {
  return (
    <List bulkActions={false} actions={<Actions />} title="Imágenes" {...rest}>
      <Responsive
        medium={
          <SimpleList
            leftAvatar={(record) => (
              <ReferenceField
                allowEmpty
                linkType={false}
                record={record}
                basePath={getResource('assets', 'images')}
                label="Image"
                source="imageDefault"
                resource={getResource('assets', 'images')}
                reference={getResource('assets', 'images')}
              >
                <ImageField mini source="asset.fileDir" title="imagen operador" />
              </ReferenceField>
            )}
            primaryText={(record) => record.title}
            secondaryText={(record) => record.status}
          />
        }
        large={
          <Datagrid>
            <Image label="state" />
            <TextField source="title" label="Title" />
            <TextField source="category.name" label="Category" sortable={false} />
            <DateField source="createdAt" />
            <DateField source="publishedAt" />
            <ArrayField source="newsLanguages" label="Available in" sortable={false}>
              <SingleFieldList linkType={false}>
                <ChipField source="language" />
              </SingleFieldList>
            </ArrayField>
            <ArrayField source="markets" label="Markets" sortable={false}>
              <SingleFieldList linkType={false}>
                <ChipField source="market" />
              </SingleFieldList>
            </ArrayField>
            <ArrayField source="clientUuids" label="Clients" sortable={false}>
              <SingleFieldList linkType={false}>
                <ChipField source="name" />
              </SingleFieldList>
            </ArrayField>
            <TableRowList>
              <EditButton />
              <CloneButton />
              <PreviewButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}

export default NewsList
