import { Grid, Paper, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { Component } from 'react'
import { BooleanInput, fetchEnd, fetchStart, GET_LIST, LongTextInput, maxLength, required, SelectInput, showNotification, UPDATE } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector, initialize, isSubmitting } from 'redux-form'

import { DateSingleInput, RefereesInput as MatchRefereesInput, TimeInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'
import PERMISSIONS from '../../../../config/permissions'
import { getResource } from '../../../../config/resources'
import dataProvider from '../../../../providers'
import { roleCollection } from '../../../../resources/competiciones/utils/constants'
import { competitions } from '../../utils/constants'
import ChannelsMatch from './ChannelsMatch'
import DownloadInfo from './DownloadInfo'
import { GrowStyled, OptionsBarStyled } from './styles'
import InfoGameweek from './InfoGameweek'

const FORM = 'record-timetable'
const selector = formValueSelector(FORM)

const styles = (theme) => ({})

const hideReferees = [competitions.laligaSantander.id, competitions.laliga123.id]

export class TimeTableForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lang: 'es',
      roleCollection: false,
      channels: {},
    }
  }

  componentDidMount() {
    const { id, initialize, record } = this.props
    const { lang } = this.state
    initialize('record-form', record)
    this.initializaTimetable(id, lang)
    if (!hideReferees.includes(record.round.subscription.competition.id)) {
      this.listRolesReferees()
    }
    this.setState({
      initialMetadata: [
        { type: 'season', value: record.round.subscription.season.id },
        { type: 'competition', value: record.round.subscription.competition.id },
        { type: 'subscription', value: record.round.subscription.id },
        { type: 'round', value: record.round.id },
        { type: 'gameweek', value: record.id },
      ],
    })
  }

  initializaTimetable(idGameweek, lang) {
    const { initialize, record } = this.props
    fetchStart()
    dataProvider(GET_LIST, getResource('competiciones', 'timetablesEdit'), {
      sort: {
        field: 'id',
        order: 'ASC',
      },
      pagination: { page: 1, perPage: 50 },
      filter: {
        gameweek: idGameweek,
      },
      data: {
        lang: lang,
      },
    })
      .then(({ data }) => {
        this.setState({ lang: lang })
        initialize(FORM, {
          gameweek: record,
          timetable: data,
        })

        const matches = data.map((item) => {
          return item.match.id
        })
        this.getChannelMatches(matches)
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  listRolesReferees() {
    const { fetchStart, fetchEnd } = this.props
    fetchStart()
    dataProvider(GET_LIST, getResource('competiciones', 'roles'), {
      sort: {
        field: 'name',
        order: 'ASC',
      },
      pagination: {},
      filter: {
        collection: roleCollection.referee.type,
      },
    })
      .then((response) => {
        this.setState({ roleCollection: response.data })
      })
      .finally(() => {
        fetchEnd()
      })
  }

  getChannelMatches(matches) {
    const { fetchStart, fetchEnd } = this.props
    let channels = {}
    matches.map((item) => {
      channels[item] = []
    })
    fetchStart()
    dataProvider(GET_LIST, getResource('operadores', 'channelmatches'), {
      sort: {
        field: 'id',
        order: 'ASC',
      },
      pagination: {},
      filter: {
        match: matches,
      },
    })
      .then(({ data }) => {
        data.map((item) => {
          channels[item.match].push(item)
        })

        this.setState({
          channels: channels,
        })
      })
      .finally(() => {
        fetchEnd()
      })
  }

  updateTimetables() {
    const { fetchStart, fetchEnd, showNotification, field } = this.props
    const { lang } = this.state

    let timetable = field('timetable')
    if (timetable) {
      fetchStart()

      const data = timetable.map((item, key) => {
        let newItem = { ...item, lang: lang }
        if (!this.canIPublish()) {
          newItem.state = 'pending'
          this.props.change(FORM, `timetable.${key}.state`, 'pending')
        }
        return newItem
      })

      Promise.all(
        data.map((item) => {
          return dataProvider(UPDATE, getResource('competiciones', 'timetablesEdit'), {
            id: item.id,
            data: item,
          })
            .then((response) => {
              return response
            })
            .catch((error) => {
              return false
            })
            .finally((resp) => {
              return resp
            })
        })
      )
        .then((responses) => {
          let error = false
          for (var i = 0; i < responses.length; i++) {
            if (responses[i] === false) error = true
          }

          if (!error) {
            showNotification('Elements updated', 'success')
          } else {
            showNotification('Error when saving data', 'error')
          }
        })
        .catch((error) => {
          showNotification(error.message, 'error')
        })
        .finally((responses) => {
          fetchEnd()
        })
    }
  }

  handleChangeLang(lang) {
    const { id } = this.props
    this.initializaTimetable(id, lang)
  }

  canIPublish() {
    const { permissions } = this.props

    const roles = [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor]
    return _.intersection(roles, permissions).length > 0
  }

  setAllPublished(timetable) {
    const { change, field } = this.props
    const newTimetable = _.map(timetable, (item) => {
      const newItem = { ...item }
      newItem.state = field('globalState')
      return newItem
    })
    change(FORM, 'timetable', newTimetable)
  }

  render() {
    const { isSubmitting, field, record } = this.props
    const timetable = field('timetable')
    const { channels, roleCollection } = this.state

    return (
      <LanguagesNav
        {...this.props}
        resource={getResource('competiciones', 'timetablesEdit')}
        form={FORM}
        saving={isSubmitting}
        save={() => this.updateTimetables()}
        onChangeLang={(lang) => {
          this.handleChangeLang(lang)
        }}
      >
        <InfoGameweek record={record} />
        <Grid container>
          {timetable && this.canIPublish() && (
            <Grid item xs={12}>
              <OptionsBarStyled>
                <GrowStyled />
                <SelectInput
                  label="State"
                  allowEmpty={false}
                  source={`globalState`}
                  disabled={!this.canIPublish()}
                  choices={[
                    {
                      id: 'published',
                      name: 'published',
                    },
                    {
                      id: 'pending',
                      name: 'pending',
                    },
                  ]}
                />
                <Button variant="contained" color="primary" disabled={!field('globalState')} onClick={() => this.setAllPublished(timetable)}>
                  Change All
                </Button>
              </OptionsBarStyled>
            </Grid>
          )}
          {timetable &&
            timetable.map((item, key) => {
              return (
                <Grid item xs={12} key={key}>
                  <Paper>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="title">
                          {item.match.homeTeam.selectorName} - {item.match.awayTeam.selectorName}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <DateSingleInput label="Date" source={`timetable.${key}.date`} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TimeInput label="Time" source={`timetable.${key}.time`} date={timetable[key].date} fullWidth />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <SelectInput
                          label="State"
                          allowEmpty={false}
                          validate={[required()]}
                          fullWidth
                          source={`timetable.${key}.state`}
                          disabled={!this.canIPublish()}
                          choices={[
                            {
                              id: 'published',
                              name: 'published',
                            },
                            {
                              id: 'pending',
                              name: 'pending',
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {!hideReferees.includes(record.round.subscription.competition.id) && roleCollection && (
                          <MatchRefereesInput source={`timetable.${key}.timetableReferees`} roles={roleCollection} />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <BooleanInput
                          source={`timetable.${key}.mark`}
                          label="Note"
                          onChange={(value) => {
                            this.props.change(FORM, `timetable.${key}.note`, null)
                          }}
                        />
                        {item.mark && <LongTextInput source={`timetable.${key}.note`} label="" validate={[maxLength(255)]} />}
                      </Grid>
                      <Grid item xs={12}>
                        {channels[item.match.id] && (
                          <ChannelsMatch channels={channels[item.match.id]} match={item.match.id} initialMetadata={this.state.initialMetadata} />
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )
            })}
        </Grid>
        <DownloadInfo lang={this.state.lang} />
      </LanguagesNav>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      isSubmitting: isSubmitting(FORM)(state),
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(TimeTableForm)
