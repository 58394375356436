import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'
import { DELETE_MANY, fetchEnd, fetchStart, GET_ONE, showNotification, SimpleForm } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector, initialize, isSubmitting } from 'redux-form'

import { getResource } from '../../../../../config/resources'
import dataProvider from '../../../../../providers'
import { MY_CREATE_MANY, MY_UPDATE_MANY } from '../../../../../providers/dataProvider/types'
import Lineup from './Lineup'

const FORM = 'lineups-form'
const selector = formValueSelector(FORM)

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
    marginRight: theme.spacing.unit,
  },
})

export class Lineups extends Component {
  constructor(props) {
    super(props)
    this.state = {
      idSeason: null,
    }
  }

  componentDidMount() {
    const { fetchStart, fetchEnd, showNotification, initialize, match } = this.props
    initialize({
      home: null,
      away: null,
    })
    fetchStart()
    dataProvider(GET_ONE, getResource('competiciones', 'rounds'), {
      id: match.gameweek.round.id,
    })
      .then(({ data }) => {
        this.setState({
          idSeason: data.subscription.season.id,
        })
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  saveLineups() {
    const { match } = this.props
    this.saveLineup('home', match.id, match.homeTeam.id)
    this.saveLineup('away', match.id, match.awayTeam.id)
  }

  saveLineup(source, idMatch, idTeam) {
    const { fetchStart, fetchEnd, showNotification, field } = this.props
    const lienupsCreate = []
    const lineupsUpdate = []
    const lineupsDelete = field(`${source}.lineup-deleted`)

    const lineupsStart = field(`${source}.lineup-start`)
    const lineupsSub = field(`${source}.lineup-sub`)
    const lineupsOther = field(`${source}.lineup-other`)

    let i = 0
    lineupsStart.map((item) => {
      let person = { ...item }
      person['status'] = 'start'
      person['position'] = i
      if (person.id === 'new') {
        delete person.id
        person['team'] = { id: idTeam }
        person['match'] = { id: idMatch }
        lienupsCreate.push(person)
      } else {
        lineupsUpdate.push(person)
      }
      i++
    })
    lineupsSub.map((item) => {
      let person = { ...item }
      person['status'] = 'sub'
      person['position'] = i
      if (person.id === 'new') {
        delete person.id
        person['team'] = { id: idTeam }
        person['match'] = { id: idMatch }
        lienupsCreate.push(person)
      } else {
        lineupsUpdate.push(person)
      }
      i++
    })
    lineupsOther.map((item) => {
      let person = { ...item }
      person['status'] = null
      person['position'] = i
      if (person.id === 'new') {
        delete person.id
        person['team'] = { id: idTeam }
        person['match'] = { id: idMatch }
        lienupsCreate.push(person)
      } else {
        lineupsUpdate.push(person)
      }
      i++
    })

    fetchStart()
    dataProvider(MY_CREATE_MANY, getResource('competiciones', 'lineups'), {
      data: lienupsCreate,
    })
      .then((response) => {
        fetchStart()
        dataProvider(MY_UPDATE_MANY, getResource('competiciones', 'lineups'), {
          data: lineupsUpdate,
        })
          .then(({ data }) => {
            fetchStart()
            dataProvider(DELETE_MANY, getResource('competiciones', 'lineups'), {
              ids: lineupsDelete,
            })
              .then((response) => {
                showNotification(`Lineups ${source} updated`, 'success')
              })
              .catch((error) => {
                showNotification(`Lineups ${source} updated`, 'success')
              })
              .finally(() => {
                fetchEnd()
              })
          })
          .catch((error) => {
            showNotification(error.message, 'error')
          })
          .finally(() => {
            fetchEnd()
          })
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  render() {
    const { match, classes } = this.props
    const { idSeason } = this.state
    if (!idSeason) return null
    return (
      <SimpleForm form={FORM} save={() => this.saveLineups()}>
        <Lineup source="home" idMatch={match.id} idSeason={idSeason} team={match.homeTeam} />
        <Lineup source="away" idMatch={match.id} idSeason={idSeason} team={match.awayTeam} />
      </SimpleForm>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      isSubmitting: isSubmitting(FORM)(state),
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(Lineups)
