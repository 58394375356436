import React from 'react'
import { Grid, FormControl, InputLabel, Select, MenuItem, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { required, ReferenceInput, AutocompleteInput, GET_ONE, GET_LIST } from 'react-admin'
import { getResource } from '../../../../../config/resources'
import _get from 'lodash/get'
import dataProvider from '../../../../../providers'

const styles = () => ({
  fullWidth: {
    width: '100%',
    '& > div': {
      '& > div': {
        width: '100%',
        marginTop: '6px',
        marginLeft: '-12px',
      },
    },
  },
})

class MetricPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      matchData: null,
      teamFilter: null,
      seasonFilter: null,
    }
  }

  componentDidMount() {
    this.getMatchData()
    this.getSeasons()
  }

  handleTeamFilter(event) {
    this.setState({
      teamFilter: event.target.value,
    })
  }
  handleSeasonFilter(event) {
    this.setState({
      seasonFilter: event.target.value,
    })
  }

  renderOptionText = (value) => {
    return `${_get(value, 'shirtNumber') || ''} ${_get(value, 'personRole.person.name')} (${_get(value, 'personRole.person.firstname')} ${_get(
      value,
      'personRole.person.lastname'
    )})`
  }

  getMatchData() {
    dataProvider(GET_ONE, getResource('competiciones', 'matches'), {
      id: this.props.matchId,
    }).then(({ data }) => {
      this.setState({
        matchData: data,
      })
    })
  }

  getSeasons = () => {
    dataProvider(GET_LIST, getResource('competiciones', 'seasons'), {
      sort: {
        field: 'year',
        order: 'DESC',
      },
      filter: { showInWeb: 1 },
      pagination: {},
    }).then(({ data }) => {
      this.setState({
        seasons: data,
      })
    })
  }

  render() {
    return (
      <Grid container xs={12} justify="space-between" alignItems="center">
        {this.state?.matchData ? (
          <Grid container xs={12} lg={6}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="filter-player-by-team">Filter players by team</InputLabel>
                <Select
                  onChange={(event) => this.handleTeamFilter(event)}
                  value={this.state.teamFilter}
                  inputProps={{
                    name: 'filter-player-by-team',
                    id: 'filter-player-by-team',
                  }}
                >
                  {[this.state?.matchData?.awayTeam, this.state?.matchData?.homeTeam]?.map((team) => {
                    return (
                      <MenuItem key={team.id} value={team.id}>
                        {team.nickname}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="filter-player-by-season">Filter players by Season</InputLabel>
                <Select
                  onChange={(event) => this.handleSeasonFilter(event)}
                  value={this.state.seasonFilter}
                  inputProps={{
                    name: 'filter-player-by-season',
                    id: 'filter-player-by-season',
                  }}
                >
                  {this.state.seasons?.map((season) => {
                    return (
                      <MenuItem key={season.id} value={season.id}>
                        {season.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={6}>
            <Typography>Loading player filters...</Typography>
          </Grid>
        )}
        <Grid xs={12} lg={5}>
          <FormControl className={this.props.classes.fullWidth}>
            <ReferenceInput
              fullWidth
              source="playerId"
              label="Player"
              reference={getResource('competiciones', 'squads')}
              validate={required()}
              filter={{ current: true, team: this.state.teamFilter, season: this.state.seasonFilter }}
              style={{ width: '100%' }}
            >
              <AutocompleteInput fullWidth optionText={this.renderOptionText} optionValue="personRole.id" />
            </ReferenceInput>
          </FormControl>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(MetricPlayer)
