import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import _ from 'lodash'
import React, { Component } from 'react'
import { GET_LIST, GET_ONE, ReferenceField, TextField } from 'react-admin'

import { AutocompleteInput } from '../'
import { getResource } from '../../../config/resources'
import { dataProvider } from '../../../providers'
import { roleCollection } from '../../../resources/competiciones/utils/constants'

const styles = (theme) => ({
  main: {
    width: '100%',
  },
  tableCell: {
    padding: '10px',
  },
  label: {
    paddingLeft: '10px',
    marginBottom: '10px',
    opacity: '0.8',
    fontSize: '0.8em',
  },
  trashIcon: {
    color: theme.palette.grey[500],
    '&:hover': {
      color: theme.palette.error.dark,
      cursor: 'pointer',
    },
  },
  cellButton: {
    textAlign: 'center',
    paddingRight: '12px !important',
    paddingLeft: '12px !important',
    width: '70px;',
  },
  sourceReferee: {
    lineHeight: '1.2',
    fontWeight: 'bold',
    fontSize: '0.8em',
  },
})

class MatchReferees extends Component {
  constructor(props) {
    super(props)
    this.state = {
      roles: null,
      roleSelected: null,
      value: null,
      newReferees: {
        isFetching: false,
        value: null,
        choices: [],
      },
    }
  }

  componentDidMount() {
    if (this.props.roles) {
      this.setState({ roles: this.props.roles })
    } else {
      this.listRolesReferees()
    }
    this.setState({
      value: this.props.input.value,
    })
  }

  listRolesReferees() {
    dataProvider(GET_LIST, getResource('competiciones', 'roles'), {
      sort: {
        field: 'name',
        order: 'ASC',
      },
      pagination: {},
      filter: {
        collection: roleCollection.referee.type,
      },
    }).then((response) => {
      this.setState({ roles: response.data })
    })
  }

  addReferee(idPersonRole) {
    let { value } = this.state
    dataProvider(GET_ONE, getResource('competiciones', 'people'), {
      id: idPersonRole,
    }).then((response) => {
      const personRoles = response.data.personRoles
      let index = _.findIndex(personRoles, ['role.id', this.state.roleSelected])

      let personAdd = personRoles[index]
      personAdd.person = {
        firstname: response.data.firstname,
        lastname: response.data.lastname,
      }
      value.push({ personRole: personAdd })
      this.setState({
        roleSelected: null,
        value: value,
      })
      this.props.input.onChange(value)
      this.resetReferees()
    })
  }

  deleteReferee(idPersonRole) {
    let { value } = this.state
    let index = _.findIndex(value, ['personRole.id', idPersonRole])
    value.splice(index, 1)
    this.setState({
      value: value,
    })
    this.props.input.onChange(value)
  }

  handleChangeReferees = (event) => {
    this.setState({ [event.target.name]: event.target.value })
    this.resetReferees()
  }

  handleNewRefereesFilter = _.debounce((value) => {
    let newReferees = { ...this.state.newReferees }
    if (value) {
      newReferees.isFetching = true
      this.setState({ newReferees: newReferees })
      dataProvider(GET_LIST, getResource('competiciones', 'people'), {
        sort: {
          field: 'name',
          order: 'ASC',
        },
        pagination: {},
        filter: {
          q: value,
          role: this.state.roleSelected,
        },
      })
        .then((response) => {
          newReferees.choices = response.data
          this.setState({ newReferees: newReferees })
        })
        .finally(() => {
          newReferees.isFetching = false
          this.setState({ newReferees: newReferees })
        })
    }
  }, 800)

  handleNewRefereesChange(id) {
    if (!id || id === '') return null
    let newReferees = { ...this.state.newReferees }
    newReferees.value = id
    this.setState({
      newReferees: newReferees,
    })
  }

  resetReferees() {
    this.setState({
      newReferees: {
        isFetching: false,
        value: null,
        choices: [],
      },
    })
  }

  render() {
    const { classes } = this.props
    const { value, newReferees } = this.state

    return (
      <div className={classes.main}>
        <Typography className={classes.label}>Match Referees</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>
                {this.state.roles && (
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel shrink={this.state.roleSelected} htmlFor="role-selected">
                      Type
                    </InputLabel>
                    <Select
                      value={this.state.roleSelected}
                      onChange={this.handleChangeReferees}
                      inputProps={{
                        name: 'roleSelected',
                        id: 'role-selected',
                      }}
                    >
                      <MenuItem value="" />
                      {this.state.roles.map((item, key) => {
                        return (
                          <MenuItem value={item.id} key={key}>
                            {item.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                )}
              </TableCell>
              <TableCell>
                {this.state.roleSelected && (
                  <AutocompleteInput
                    isFetching={newReferees.isFetching}
                    value={newReferees.value}
                    choices={newReferees.choices}
                    onChange={(value) => this.handleNewRefereesChange(value)}
                    setFilter={(value) => this.handleNewRefereesFilter(value)}
                    label="Referees"
                    optionText="name"
                    fullWidth
                  />
                )}
              </TableCell>
              <TableCell className={classes.cellButton}>
                <Button
                  onClick={() => this.addReferee(this.state.newReferees.value)}
                  disabled={this.state.newReferees.value === null ? true : false}
                  variant="contained"
                  color="primary"
                  size="small"
                  aria-label="Add"
                >
                  Add
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {value &&
              value.map((item, key) => {
                return (
                  <TableRow key={key}>
                    <TableCell>
                      <div>
                        <ReferenceField
                          source={`personRole.id`}
                          reference={getResource('competiciones', 'peopleroles')}
                          basePath={getResource('competiciones', 'peopleroles')}
                          linkType={false}
                          record={item}
                        >
                          <TextField source="role.name" fullWidth />
                        </ReferenceField>
                      </div>
                      {item.source && <div className={classes.sourceReferee}>{item.source}</div>}
                    </TableCell>
                    <TableCell>
                      <ReferenceField
                        source={`personRole.id`}
                        reference={getResource('competiciones', 'peopleroles')}
                        basePath={getResource('competiciones', 'peopleroles')}
                        linkType={false}
                        record={item}
                      >
                        <TextField source="person.name" fullWidth />
                      </ReferenceField>
                    </TableCell>
                    <TableCell className={classes.cellButton}>
                      <DeleteIcon className={classes.trashIcon} aria-label="Delete" onClick={() => this.deleteReferee(item.personRole.id)} />
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default withStyles(styles)(MatchReferees)
