import { Button, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline'
import CircularProgress from '@material-ui/core/CircularProgress'
import _get from 'lodash/get'
import React, { useState } from 'react'
import {
  ChipField,
  Create,
  FormDataConsumer,
  CREATE,
  fetchStart,
  fetchEnd,
  showNotification,
  GET_LIST,
  GET_ONE,
  ReferenceField,
  ReferenceInput,
  required,
} from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector } from 'redux-form'
import { push } from 'react-router-redux'

import { MatchField } from '../../../components'
import { AutocompleteInput, MatchInput } from '../../../components/inputs'
import LanguagesNav from '../../../components/languagesNav'
import { getResource } from '../../../config/resources'
import { dataProvider } from '../../../providers'

const FORM = 'record-form'
const selector = formValueSelector(FORM)

const styles = (theme) => ({
  rowRule: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: '8px',
    },
  },
  addCountry: {
    display: 'flex',
    maxWidth: '400px',
    alignItems: 'center',
    '& button': {
      marginLeft: '8px',
    },
  },
  rowAddMatches: {
    display: 'flex',
    width: '100%',
  },
  addMatches: {
    flex: '0 0 35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0,0,0,0.1)',
    top: '0px',
    left: '0px',
  },
})

export const BroadcastCreate = (props) => {
  const { classes, field, change, fetchStart, fetchEnd, showNotification } = props
  const [loadingAddMatch, setLoadingAddMatch] = useState(false)
  const [loadingAddRule, setLoadingAddRule] = useState(false)

  const handleAddCountry = (newCountry) => {
    let countries = field('data.countries')
    if (countries) {
      if (!countries.includes(newCountry)) {
        countries.push(newCountry)
      }
    } else {
      countries = [newCountry]
    }
    change(FORM, 'data.countries', countries)
    change(FORM, 'temp-add-country', null)
  }

  const handleRemoveCountry = (key) => {
    let countries = field('data.countries')
    countries.splice(key, 1)
    change(FORM, 'data.countries', countries)
  }

  const handleAddMatches = () => {
    const dataMatches = field('temp-matches')

    if (_get(dataMatches, 'match')) {
      let metadatas = field('data.metadatas') ? field('data.metadatas') : []
      let canIAdd = true
      metadatas.forEach((eachMetadata) => {
        const index = eachMetadata.findIndex((each) => each.value === dataMatches.match)
        if (index > -1) {
          canIAdd = false
        }
      })
      if (canIAdd) {
        metadatas.push(formatMetadatas(dataMatches))
      }

      change(FORM, 'data.metadatas', metadatas)
      change(FORM, 'temp-matches.match', null)
    } else if (_get(dataMatches, 'gameweek')) {
      const gameweek = _get(dataMatches, 'gameweek')

      fetchStart()
      setLoadingAddMatch(true)
      dataProvider(GET_LIST, getResource('competiciones', 'matches'), {
        sort: {
          field: 'id',
          order: 'ASC',
        },
        pagination: {
          page: 1,
          perPage: 50,
        },
        filter: {
          gameweek: gameweek,
        },
      })
        .then(({ data }) => {
          let newMetadatas = data.map(({ id }) => {
            let filledMetadatas = { ...dataMatches }
            filledMetadatas.match = id
            return formatMetadatas(filledMetadatas)
          })

          change(FORM, 'data.metadatas', newMetadatas)
          change(FORM, 'temp-matches.match', null)
        })
        .catch((error) => {
          showNotification(error.message, 'error')
        })
        .finally(() => {
          setLoadingAddMatch(false)
          fetchEnd()
        })
    }
  }

  const handleRemoveMetadata = (key) => {
    let metadatas = field('data.metadatas')
    metadatas.splice(key, 1)
    change(FORM, 'data.metadatas', metadatas)
  }

  const formatMetadatas = (metadata) => {
    return [
      { type: 'season', value: metadata.season },
      { type: 'competition', value: metadata.competition },
      { type: 'subscription', value: metadata.subscription },
      { type: 'round', value: metadata.round },
      { type: 'gameweek', value: metadata.gameweek },
      { type: 'match', value: metadata.match },
    ]
  }

  const handleLoadRule = (rule) => {
    if (rule) {
      fetchStart()
      setLoadingAddRule(true)

      dataProvider(GET_ONE, getResource('operadores', 'rules'), {
        id: rule,
      })
        .then(({ data: rule }) => {
          let data = field('data') ? field('data') : {}
          data.broadcaster = { id: rule.broadcaster.id }

          if (rule.ruleCountries) {
            data.countries = rule.ruleCountries.map((each) => each.country)
          }

          change(FORM, 'temp-load-rule', null)
          change(FORM, 'data', data)
        })
        .catch((error) => {
          showNotification(error.message, 'error')
        })
        .finally(() => {
          setLoadingAddRule(false)
          fetchEnd()
        })
    }
  }

  const handleSaveForm = (dataForm) => {
    const { data } = dataForm
    const countries = _get(data, 'countries')
    const metadatas = _get(data, 'metadatas')

    let error = false
    if (!countries || countries.length === 0) {
      error = true
      showNotification('Countries is required.', 'warning')
    }
    if (!metadatas || metadatas.length === 0) {
      error = true
      showNotification('Matches is required.', 'warning')
    }

    if (!error) {
      fetchStart()
      dataProvider(CREATE, getResource('operadores', 'broadcasts-matches'), {
        data: data,
      })
        .then((response) => {
          showNotification('Broadcasters succesfully added', 'success')
          change(FORM, 'temp-load-rule', null)
          change(FORM, 'temp-matches', null)
          change(FORM, 'data', null)
          props.redirectToList()
        })
        .catch((error) => {
          showNotification(error.message, 'error')
        })
        .finally(() => {
          fetchEnd()
        })
    }
  }

  return (
    <Create {...props}>
      <LanguagesNav {...props} customSave={handleSaveForm}>
        <FormDataConsumer>
          {({ formData }) => (
            <>
              <Grid container style={{ position: 'relative' }}>
                <Grid item xs={12} style={{ background: '#cdcdcd' }}>
                  <div className={classes.rowRule}>
                    <ReferenceInput label="Load rule" source="temp-load-rule" reference={getResource('operadores', 'rules')}>
                      <AutocompleteInput source="name" fullWidth />
                    </ReferenceInput>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={() => handleLoadRule(_get(formData, 'temp-load-rule'))}
                      disabled={!_get(formData, 'temp-load-rule')}
                    >
                      Load
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <ReferenceInput
                    label="Broadcaster"
                    source="data.broadcaster.id"
                    validate={[required()]}
                    reference={getResource('operadores', 'broadcasters')}
                  >
                    <AutocompleteInput source="name" fullWidth />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.addCountry}>
                    <ReferenceInput label="Countries" source={`temp-add-country`} reference={getResource('transversal', 'countries')} fullWidth>
                      <AutocompleteInput />
                    </ReferenceInput>
                    <Button size="small" onClick={() => handleAddCountry(_get(formData, 'temp-add-country'))} disabled={!_get(formData, 'temp-add-country')}>
                      <AddIcon className={classes.leftIcon} />
                      Add
                    </Button>
                  </div>
                  <div>
                    {_get(formData, 'data.countries') &&
                      formData.data.countries.map((item, key) => (
                        <ReferenceField
                          source={`data.countries[${key}]`}
                          reference={getResource('transversal', 'countries')}
                          basePath={getResource('transversal', 'countries')}
                          linkType={false}
                          record={formData}
                          key={key}
                        >
                          <ChipField source="name" onDelete={() => handleRemoveCountry(key)} />
                        </ReferenceField>
                      ))}
                  </div>
                </Grid>
                {loadingAddRule && (
                  <div className={classes.loading}>
                    <CircularProgress />
                  </div>
                )}
              </Grid>
              <div style={{ position: 'relative' }}>
                <div className={classes.rowAddMatches}>
                  <MatchInput
                    sourceSeason="temp-matches.season"
                    sourceCompetition="temp-matches.competition"
                    sourceSubscription="temp-matches.subscription"
                    sourceRound="temp-matches.round"
                    sourceGameweek="temp-matches.gameweek"
                    sourceMatch="temp-matches.match"
                  />
                  <div className={classes.addMatches}>
                    <Button size="small" onClick={handleAddMatches} disabled={!_get(formData, 'temp-matches.gameweek')}>
                      <AddIcon className={classes.leftIcon} />
                    </Button>
                  </div>
                </div>
                <div>
                  {_get(formData, 'data.metadatas') &&
                    formData.data.metadatas.map((item, key) => {
                      const index = item.findIndex((each) => each.type === 'match')
                      return (
                        <div style={{ display: 'flex' }} key={key}>
                          <ReferenceField
                            source={`data.metadatas[${key}][${index}].value`}
                            reference={getResource('competiciones', 'matches')}
                            basePath={getResource('competiciones', 'matches')}
                            linkType={false}
                            record={formData}
                          >
                            <MatchField />
                          </ReferenceField>
                          <Button size="small" onClick={() => handleRemoveMetadata(key)}>
                            <RemoveIcon className={classes.leftIcon} />
                          </Button>
                        </div>
                      )
                    })}
                </div>
                {loadingAddMatch && (
                  <div className={classes.loading}>
                    <CircularProgress />
                  </div>
                )}
              </div>
            </>
          )}
        </FormDataConsumer>
      </LanguagesNav>
    </Create>
  )
}

const enhance = compose(
  connect(
    (state) => ({
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      change: (form, field, value) => dispatch(change(form, field, value)),
      redirectToList: () => dispatch(push('/operadores/broadcasts')),
    })
  ),
  withStyles(styles)
)
export default enhance(BroadcastCreate)
