import React from 'react'
import { Edit } from 'react-admin'

import VenuesForm from './form'

export const VenuesEdit = (props) => (
  <Edit undoable={false} {...props}>
    <VenuesForm isEdit={true} {...props} />
  </Edit>
)

export default VenuesEdit
