import React from 'react'
import { maxLength, required, TextInput, BooleanInput } from 'react-admin'
import { Grid, List, ListItem, ListItemText, Collapse, Button, withStyles } from '@material-ui/core'
import { change, formValueSelector, initialize } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import DeleteIcon from '@material-ui/icons/Delete'
import { ImageInput, RichTextInput } from '../../../../../../../components/inputs'

const styles = (theme) => ({
  row: {
    position: 'relative',
    background: theme.palette.grey[50],
    marginBottom: theme.spacing.unit,
    display: 'flex',
  },
  deleteButton: {
    marginRight: '16px',
  },
  drag: {
    flex: '0 0 35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const FORM = 'record-form'
const selector = formValueSelector(FORM)

class CardItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
    this.handleClick.bind(this)
  }

  handleClick = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  handleRemove = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.handleRemove(this.props.index)
  }

  render() {
    const { source, classes, isDragged } = this.props

    return (
      <List>
        <ListItem button onClick={this.handleClick}>
          <div className={classes.drag} data-movable-handle style={{ cursor: isDragged ? 'grabbing' : 'grab' }}>
            <DragHandleIcon />
          </div>
          <ListItemText primary={this.props.field(`${source}.title`)} />
          <Button className={classes.deleteButton} variant="fab" color="secondary" aria-label="Remove" onClick={this.handleRemove} mini>
            <DeleteIcon fontSize={12} />
          </Button>
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <div className={classes.row}>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <TextInput fullWidth source={`${source}.title`} label="Card Title" validate={[required(), maxLength(255)]} />
              </Grid>
              <Grid item xs={12}>
                <RichTextInput fullWidth source={`${source}.content`} label="Card Content" validate={[required()]} />
              </Grid>
              <Grid item xs={6}>
                <ImageInput fullWidth label="Card Image" source={`${source}.image`} validate={[required()]} />
              </Grid>
              <Grid item xs={12}>
                <ImageInput
                  source={`${source}.videoId`}
                  label="Card Video"
                  options={{
                    type: 'videos',
                    remove: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput fullWidth source={`${source}.videoTag`} label="Card Button Text" validate={[required(), maxLength(255)]} />
              </Grid>
              <Grid item xs={12}>
                <BooleanInput label="Active" source={`${source}.active`} validate={[required()]} />
              </Grid>
            </Grid>
          </div>
        </Collapse>
      </List>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(CardItem)
