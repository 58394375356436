import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'
import { GET_ONE, required, SelectInput } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector } from 'redux-form'

import { getResource } from '../../../../config/resources'
import dataProvider from '../../../../providers'

const styles = () => {
  return {
    gridNoPadding: {
      padding: '0px',
    },
  }
}

const selector = formValueSelector('record-form')

export class SelectTeamsMatch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      subscriptionTeams: [],
      groupTeams: [],
      groups: false,
    }
  }

  componentDidMount() {
    this.getTeamsSubscription()
    this.getRound()
    this.getAggregates()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.subscription?.id !== this.props.subscription?.id) {
      this.getTeamsSubscription()
    }
    if (prevProps.idRound !== this.props.idRound) {
      this.getRound()
    }
    if (prevProps.aggregate?.id !== this.props.aggregate?.id) {
      this.getAggregates()
    }
  }

  getTeamsSubscription = () => {
    const { subscription } = this.props
    if (subscription?.id) {
      dataProvider(GET_ONE, getResource('competiciones', 'subscriptions'), {
        id: subscription.id,
      }).then(({ data }) => {
        this.setState({
          subscriptionTeams: data?.subscriptionTeams.map((each) => each.team) || [],
        })
      })
    } else {
      this.setState({
        teams: [],
      })
    }
  }

  getRound() {
    const { idRound } = this.props

    if (idRound) {
      dataProvider(GET_ONE, getResource('competiciones', 'rounds'), {
        id: idRound,
      }).then(({ data }) => {
        this.setState({
          groups: data?.aggregates || false,
        })
      })
    }
  }

  getAggregates() {
    const { aggregate } = this.props

    if (aggregate?.id) {
      dataProvider(GET_ONE, getResource('competiciones', 'aggregates'), {
        id: aggregate.id,
      }).then(({ data }) => {
        this.setState({
          groupTeams: data.aggregateTeams?.map((each) => each.team.id) || [],
        })
      })
    } else {
      this.setState({
        groupTeams: [],
      })
    }
  }

  getChoicesTeams() {
    const { aggregate } = this.props
    if (aggregate?.id && this.state.groups) {
      return this.state.subscriptionTeams?.filter((each) => this.state.groupTeams.includes(each.id))
    }

    return this.state.subscriptionTeams || []
  }

  render() {
    return (
      <Grid container>
        <Grid item xs={12} md={4}>
          <SelectInput label="Groups" source="aggregate.id" choices={this.state.groups || []} fullWidth disabled={!this.state.groups} />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectInput
            label="Home team"
            source="homeTeam.id"
            optionText="selectorName"
            validate={[required()]}
            disabled={this.props.idRound === null}
            choices={this.getChoicesTeams()}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectInput
            label="Away team"
            source="awayTeam.id"
            optionText="selectorName"
            validate={[required()]}
            disabled={this.props.idRound === null}
            choices={this.getChoicesTeams()}
            fullWidth
          />
        </Grid>
      </Grid>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      aggregate: selector(state, 'aggregate'),
      subscription: selector(state, 'subscription'),
    }),
    (dispatch) => ({
      changeField: (field, value) => dispatch(change('record-form', field, value)),
    })
  ),
  withStyles(styles)
)
export default enhance(SelectTeamsMatch)
