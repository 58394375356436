import React, { Component } from 'react'
import { Field } from 'redux-form'
import ExperienceInput from './experienceInputs'

class ExperienceInputMetadata extends Component {
  render() {
    const { source, ...rest } = this.props
    if (this.props.source) {
      return <Field {...rest} component={ExperienceInput} name={source} />
    } else {
      return <ExperienceInput {...this.props} />
    }
  }
}

export default ExperienceInputMetadata
