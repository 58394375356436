import InputAdornment from '@material-ui/core/InputAdornment'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import classNames from 'classnames'
import React from 'react'

import { withFormActiveLanguage } from '../../languagesNav/formActiveLanguageContext'

const styles = (theme) => {
  return {
    prefix: {
      marginRight: '0px',
      padding: '12px 0px',
      '& > p': {
        lineHeight: '21px',
      },
    },
    inputInternalLink: {
      '& input': {
        paddingLeft: '4px',
      },
    },
  }
}

const LinkInput = (props) => {
  const { internalLink = false, fullWidth = false, classes, meta = { error: false } } = props
  const { formActiveLanguage } = props.formLanguageData
  const { value, onChange } = props.input ? props.input : props

  const urlPrefix = formActiveLanguage === 'es' ? process.env.REACT_APP_PUBLIC_FRONT : `${process.env.REACT_APP_PUBLIC_FRONT}/${formActiveLanguage}-XX`
  return (
    <TextField
      label={props.label}
      disabled={props.disabled}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      InputProps={{
        startAdornment: internalLink ? (
          <InputAdornment className={classes.prefix} position="start">
            {urlPrefix}
          </InputAdornment>
        ) : (
          false
        ),
      }}
      fullWidth={fullWidth}
      className={classNames({ [classes.inputInternalLink]: internalLink })}
      error={meta.pristine === undefined ? !!meta.error : !meta.pristine && !!meta.error}
      helperText={meta.error}
    />
  )
}

export default withStyles(styles)(withFormActiveLanguage(LinkInput))
