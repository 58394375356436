import React from 'react'
import { Edit } from 'react-admin'

import AmbassadorsForm from './form'

export const AmbassadorsEdit = (props) => (
  <Edit undoable={false} {...props}>
    <AmbassadorsForm isEdit={true} {...props} />
  </Edit>
)

export default AmbassadorsEdit
