import React from 'react'
import { Create, required, SimpleForm } from 'react-admin'

import { AddAssetInput, MetadataInput } from '../../../components/inputs'

const ImportTitle = ({ record }) => <span>Importar:</span>

export const InputCreate = (props) => (
  <Create title={<ImportTitle />} {...props}>
    <SimpleForm>
      <AddAssetInput
        source="file"
        label="File"
        accept=".csv, .xls, .xlsx, text/html, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        fullWidth
        base64
        validate={[required()]}
      />
      <MetadataInput
        source="metadatas"
        label="Metadata"
        sources={{
          match: 'match',
        }}
      />
    </SimpleForm>
  </Create>
)

export default InputCreate
