import Popover from '@material-ui/core/Popover'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, { Component } from 'react'
import { ChromePicker } from 'react-color'

import { ColorStyled, PickerContainerStyled } from './style'

const styles = {
  input: {
    '& input': {
      paddingLeft: '35px',
      cursor: 'pointer',
    },
  },
  reset: {
    width: '35px',
    height: '35px',
    fontSize: 20,
  },
}

class Picker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
    }
  }

  handleClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
    })
  }

  handleClose() {
    this.setState({
      anchorEl: null,
    })
  }

  onColorChange(hex) {
    this.props.onChange(hex)
    this.handleClose()
  }

  handleClickClear = (e) => {
    const { input } = this.props

    e.stopPropagation()
    input.onChange('')
  }

  render() {
    const { input } = this.props
    const { classes, disabled, clearable, value, name } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <PickerContainerStyled>
        <ColorStyled bgColor={input?.value || value} />
        <TextField
          {...input}
          disabled={disabled}
          className={classes.input}
          label={this.props.label || value}
          onClick={(event) => {
            this.handleClick(event)
          }}
          value={input?.value || value}
          margin="normal"
          InputProps={{
            endAdornment: clearable ? (
              <InputAdornment position="end">
                <IconButton className={classes.reset} aria-label="Resset value" onClick={(e) => this.handleClickClear(e)}>
                  <ClearIcon fontSize="inherit" />
                </IconButton>
              </InputAdornment>
            ) : (
              false
            ),
          }}
          fullWidth
        />
        <Popover
          id="simple-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={() => this.handleClose()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <ChromePicker
            name={name}
            color={input?.value || value}
            onChangeComplete={(color) => {
              if (input) {
                input.onChange(color.hex)
              } else {
                this.props.onChange(color.hex)
              }
            }}
          />
        </Popover>
      </PickerContainerStyled>
    )
  }
}

export default withStyles(styles)(Picker)
