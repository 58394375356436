import { Card, CardContent, CardHeader } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import {
  BooleanInput,
  FormDataConsumer,
  LongTextInput,
  maxLength,
  NumberInput,
  RadioButtonGroupInput,
  REDUX_FORM_NAME,
  required,
  SelectInput,
  TextInput,
} from 'react-admin'
import { change } from 'redux-form'

import { ClipboardField } from '../../../../components/formFields'
import {
  CategoryInput,
  ClientUuidsInput,
  DateTimeInput,
  DndBlockEditor,
  ImageInput,
  LanguageInput,
  MarketsInput,
  MetadataInput,
} from '../../../../components/inputs'
import { checkboxRequired } from '../../../../components/inputs/clientUuidsInput/validate'
import {
  GalleryPlugin,
  ImagePlugin,
  ScriptPlugin,
  TablePlugin,
  TextPlugin,
  TwitterPlugin,
  VideoPlugin,
} from '../../../../components/inputs/dndBlockEditor/plugins'
import LanguagesNav from '../../../../components/languagesNav'
import { getResource } from '../../../../config/resources'
import { choicesStates, states } from '../states'
import { slugifyFiler } from './../../../../config/utils'
import OneFootball from './oneFootball'

const publishedOrProgrammed = (status) => (value) => {
  return !value && (status === states.programmed || status === states.published) ? 'Requiered' : undefined
}

const generateSlug = (value, dispatch) => {
  dispatch(change(REDUX_FORM_NAME, 'slug', slugifyFiler(value.title)))
}

const basePublicUrl = {
  es: `${process.env.REACT_APP_PUBLIC_FRONT}/noticias/`,
  en: `${process.env.REACT_APP_PUBLIC_FRONT}/en-ES/news/`,
}

class NewsForm extends React.Component {
  render() {
    return (
      <LanguagesNav remove useAdditionalLanguages {...this.props}>
        {({ dir }) => (
          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) =>
              formData && (
                <div>
                  <Card>
                    <CardHeader title="General" subheader="Common for all languages" />
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12} sm={6} md={4}>
                          <LanguageInput source="newsLanguages" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <MarketsInput label="Market" source="markets" select />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <ClientUuidsInput label="Clients" source="clientUuids" isEdit={this.props.isEdit} validate={[checkboxRequired()]} />
                        </Grid>
                      </Grid>
                      <Grid container alignItems="flex-end">
                        <Grid item xs={12} sm={6} md={4}>
                          <SelectInput
                            defaultValue={states.draft}
                            allowEmpty={false}
                            validate={[required()]}
                            fullWidth
                            source="status"
                            choices={choicesStates}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={8}>
                          <DateTimeInput
                            label="Published At"
                            source="publishedAt"
                            date
                            time
                            validate={formData.status === states.programmed ? [required()] : []}
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} sm={6} md={8}>
                          <CategoryInput label="Category" source="category" resource={getResource('news', 'categories')} validate={[required()]} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <NumberInput source="position" step={1} validate={[required()]} defaultValue={0} />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} sm={4}>
                          <RadioButtonGroupInput
                            source="assetType"
                            defaultValue="image"
                            choices={[
                              { id: 'image', name: 'Image' },
                              { id: 'video', name: 'Video' },
                            ]}
                            onChange={() => {
                              dispatch(change(REDUX_FORM_NAME, 'assetDefault', null))
                              dispatch(change(REDUX_FORM_NAME, 'imageDefault', null))
                              dispatch(change(REDUX_FORM_NAME, 'assetTranslatable', null))
                              dispatch(change(REDUX_FORM_NAME, 'imageTranslatable', null))
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Grid container>
                            {formData && formData.assetType === 'video' ? (
                              <>
                                <Grid item xs={12} sm={6}>
                                  <ImageInput
                                    fullWidth
                                    label="Video Default"
                                    source="assetDefault"
                                    validate={[publishedOrProgrammed(formData.status)]}
                                    options={{
                                      type: 'videos',
                                      filters: {
                                        state: 'published',
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <ImageInput fullWidth label="Image Default" source="imageDefault" validate={[publishedOrProgrammed(formData.status)]} />
                                </Grid>
                              </>
                            ) : (
                              <Grid item xs={12} sm={6}>
                                <ImageInput fullWidth label="Image Default" source="assetDefault" validate={[publishedOrProgrammed(formData.status)]} />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <MetadataInput source="metadatas" label="Metadata" />
                      </Grid>
                      {formData && formData.id && (
                        <Grid item xs={12}>
                          <ImageInput
                            fullWidth
                            label="Related news"
                            source="relatedNews"
                            multiselect
                            options={{
                              type: 'news',
                              resource: getResource('news', 'noticias'),
                              filters: {
                                recomendedRelatedNews: formData.id,
                              },
                            }}
                          />
                        </Grid>
                      )}
                    </CardContent>
                  </Card>
                  <Card>
                    <CardHeader title="Translatable" subheader="Independent for each language" />
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12} sm={6}>
                          <Grid container>
                            {formData && formData.assetType === 'video' ? (
                              <>
                                <Grid item xs={12} sm={6}>
                                  <ImageInput
                                    fullWidth
                                    label="Video Translatable"
                                    source="assetTranslatable"
                                    options={{
                                      type: 'videos',
                                      filters: {
                                        state: 'published',
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <ImageInput fullWidth label="Image Translatable" source="imageTranslatable" />
                                </Grid>
                              </>
                            ) : (
                              <Grid item xs={12} sm={6}>
                                <ImageInput fullWidth label="Image Translatable" source="assetTranslatable" />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <div dir={dir} style={{ width: '100%' }}>
                          <Grid item xs={12}>
                            <TextInput
                              fullWidth
                              label="Title"
                              source="title"
                              validate={[required(), maxLength(255)]}
                              onChange={(value) => {
                                if (formData.slugAutogenerate) {
                                  generateSlug(formData, dispatch)
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <BooleanInput
                              label="Autogenerate slug"
                              source="slugAutogenerate"
                              onChange={(value) => {
                                if (value.target.checked) {
                                  generateSlug(formData, dispatch)
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={9}>
                            <TextInput
                              disabled={formData && formData.slugAutogenerate}
                              fullWidth
                              label="Slug"
                              source="slug"
                              validate={[required(), maxLength(255)]}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ClipboardField
                              urlShortener
                              label="Url"
                              value={`${basePublicUrl[formData.lang ? formData.lang : 'es']}${formData.slug}`}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextInput
                              fullWidth
                              label="Title highlight"
                              source="titleHighlight"
                              validate={[publishedOrProgrammed(formData.status), maxLength(255)]}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <LongTextInput
                              fullWidth
                              label="Short description"
                              source="shortDescription"
                              validate={[publishedOrProgrammed(formData.status), maxLength(255)]}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <LongTextInput
                              fullWidth
                              label="SEO description"
                              source="seoDescription"
                              validate={[publishedOrProgrammed(formData.status), maxLength(255)]}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <DndBlockEditor
                              label="Content"
                              onChange={(value) => null}
                              name="contentBlocks"
                              options={{
                                reverse: true,
                              }}
                              plugins={{
                                image: {
                                  plugin: ImagePlugin,
                                  options: {
                                    name: 'Bloque imagen',
                                    removeClean: true,
                                    reference: true,
                                    readOlny: true,
                                  },
                                },
                                gallery: {
                                  plugin: GalleryPlugin,
                                  options: {
                                    name: 'Bloque galería',
                                    removeClean: true,
                                    reference: true,
                                    readOlny: true,
                                  },
                                },
                                text: {
                                  plugin: TextPlugin,
                                  options: {
                                    name: 'Bloque texto',
                                    removeClean: true,
                                  },
                                },
                                video: {
                                  plugin: VideoPlugin,
                                  options: {
                                    name: 'Bloque video',
                                    removeClean: true,
                                    reference: true,
                                    readOlny: true,
                                    filters: {
                                      state: 'published',
                                    },
                                  },
                                },
                                table: {
                                  plugin: TablePlugin,
                                  options: {
                                    name: 'Bloque tabla',
                                    removeClean: true,
                                  },
                                },
                                script: {
                                  plugin: ScriptPlugin,
                                  options: {
                                    name: 'Bloque script',
                                  },
                                },
                                twitter: {
                                  plugin: TwitterPlugin,
                                  options: {
                                    name: 'Bloque twitter',
                                  },
                                },
                              }}
                            />
                          </Grid>
                        </div>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Grid item xs={12}>
                    <OneFootball id={this.props.id} lang={formData.lang ? formData.lang : 'es'} />
                  </Grid>
                </div>
              )
            }
          </FormDataConsumer>
        )}
      </LanguagesNav>
    )
  }
}

export default NewsForm
