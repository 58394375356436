import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Button,
  LinearProgress,
  withStyles,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import _ from 'lodash'
import React, { Component } from 'react'
import { GET_ONE, GET_LIST } from 'react-admin'
import CountriesSelector from './countriesSelector'
import { AddMarketStyled, ContainerStyled, ContaierAddMarket, ContaierAddMarketLoading } from './style'

import { getResource } from '../../../config/resources'
import { dataProvider } from '../../../providers'

const styles = (theme) => ({})

const MARKET_ES = 'ES'
const MARKET_ALL = 'ALL'

class Markets extends Component {
  constructor(props) {
    super(props)
    this.state = {
      marketSelected: null,
      open: false,
      isFetching: false,
    }
  }

  handleOnChange() {
    if (this.props.input) {
      this.props.input.onChange(this.state.list)
    } else {
      this.props.onChange(this.state.list)
    }
  }

  handleClose() {
    this.setState({
      marketSelected: null,
      open: false,
    })
  }

  submit(market, index, func) {
    const { select } = this.props
    const { value, onChange } = this.props.input ? this.props.input : this.props
    if (select) {
      const newMarket = [market]
      onChange(newMarket)
      if (func) {
        func(newMarket)
      }
    } else {
      let newValue = [...value]
      if (index || index === 0) {
        const id = newValue[index].id
        let newMarket = market
        if (id) newMarket.id = id
        newValue[index] = newMarket
      } else {
        newValue[newValue.length] = market
      }
      onChange(newValue)
      if (func) {
        func(newValue)
      }
    }
  }

  hangleSubmit(market, index, empty) {
    this.submit(market, index, () => {
      this.setState({
        marketSelected: null,
        open: false,
        empty: empty,
      })
    })
  }

  handleDelete(index) {
    const { value, onChange } = this.props.input ? this.props.input : this.props
    let newValue = [...value]
    _.pullAt(newValue, index)
    onChange(newValue)
    this.setState({
      empty: false,
    })
  }

  handleClickShortCutMarket(slug) {
    this.setState({
      isFetching: true,
    })
    if (MARKET_ALL === slug) {
      dataProvider(GET_LIST, getResource('transversal', 'countries'), {
        sort: {
          field: 'name',
          order: 'ASC',
        },
        pagination: { perPage: 300 },
        filter: {
          group: 'oneMarket',
        },
      })
        .then(({ data }) => {
          this.hangleSubmit(this.formatValueMarket(slug, data))
        })
        .finally(() => {
          this.setState({ isFetching: false })
        })
    } else {
      dataProvider(GET_ONE, getResource('transversal', 'markets'), {
        id: slug,
      })
        .then(({ data }) => {
          this.hangleSubmit(this.formatValueMarket(slug, data.countries))
        })
        .finally(() => {
          this.setState({ isFetching: false })
        })
    }
  }

  formatValueMarket(slug, countries) {
    return {
      market: slug,
      countries: countries && countries.map((item) => ({ country: item.id })),
    }
  }

  render() {
    const { classes, meta } = this.props
    const { value } = this.props.input ? this.props.input : this.props
    const slicedValue = this.props.select ? _.slice(value, 0, 1) : value

    const list = _.map(slicedValue, (item, index) => {
      return (
        <ListItem
          key={item.market}
          button
          onClick={() =>
            this.setState({
              marketSelected: { value: item, index: index },
              open: true,
            })
          }
        >
          <ListItemText primary={item.market} secondary={`${item.countries ? item.countries.length : ''} countries`} />
          {!this.props.select && (
            <ListItemSecondaryAction>
              <IconButton aria-label="Delete" onClick={() => this.handleDelete(index)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      )
    })
    return (
      <ContainerStyled>
        <FormControl error={meta && meta.touched && meta.error}>
          <InputLabel htmlFor="age-simple">{this.props.label}</InputLabel>
          <List component="div" className={classes.root} style={{ paddingTop: 0 }}>
            {list}
          </List>
          {((this.props.allowAdd && !this.state.empty) || value.length <= 0) && (
            <ContaierAddMarket>
              {(!slicedValue || !slicedValue.find((item) => item.market === MARKET_ALL)) && (
                <Button variant="contained" size="small" color="primary" className={classes.button} onClick={() => this.handleClickShortCutMarket(MARKET_ALL)}>
                  All
                </Button>
              )}
              {(!slicedValue || !slicedValue.find((item) => item.market === MARKET_ES)) && (
                <Button variant="contained" size="small" color="primary" className={classes.button} onClick={() => this.handleClickShortCutMarket(MARKET_ES)}>
                  ES
                </Button>
              )}
              <AddMarketStyled
                onClick={() =>
                  this.setState({
                    open: true,
                  })
                }
              >
                Custom market +
              </AddMarketStyled>
              {this.state.isFetching && (
                <ContaierAddMarketLoading>
                  <LinearProgress />
                </ContaierAddMarketLoading>
              )}
            </ContaierAddMarket>
          )}
          {this.state.open && (
            <CountriesSelector
              open={this.state.open}
              marketList={slicedValue}
              market={this.state.marketSelected ? this.state.marketSelected.value : null}
              onClose={() => this.handleClose()}
              onSubmit={(market, empty) => this.hangleSubmit(market, this.state.marketSelected ? this.state.marketSelected.index : null, empty)}
            />
          )}
          {meta && meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
      </ContainerStyled>
    )
  }
}

export default withStyles(styles)(Markets)
