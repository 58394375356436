import React from 'react'
import moment from 'moment'
import { TextInput, LongTextInput, DisabledInput, NumberInput, ReferenceInput } from 'react-admin'
import { DateSingleInput, AutocompleteInput, RichTextInput } from '../../../../components/inputs'
import { required, maxLength } from 'react-admin'
import LanguagesNav from '../../../../components/languagesNav'
import TeamManagement from './TeamManagement'
import { getResource } from '../../../../config/resources'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { ImageInput } from '../../../../components/inputs'

export const ClubsForm = ({ isEdit, ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12}>
        <TextInput fullWidth source="name" label="Name" validate={[required(), maxLength(255)]} />
        <DisabledInput fullWidth source="slug" label="Slug" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput fullWidth source="nickname" label="Nickname" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput fullWidth source="selectorName" label="Selector Name" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput fullWidth source="boundname" label="Boundname" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput fullWidth source="shortname" label="Shortname" validate={[required(), maxLength(3)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput fullWidth source="associate" label="Associate" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateSingleInput label="Foundation" source="foundation" rangeStartDate={moment('1850-01-02')} />
      </Grid>
      <Grid item xs={12}>
        <TextInput fullWidth source="president" label="President" validate={[maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        {isEdit ? <TeamManagement idClub={props.record.id} /> : ''}
      </Grid>

      <Grid item xs={12}>
        <RichTextInput fullWidth source="description" label="Description" />
      </Grid>
      <Grid item xs={12}>
        <LongTextInput fullWidth source="address" label="Address" />
      </Grid>
      <Grid item xs={12}>
        <TextInput fullWidth source="web" label="Web" validate={[maxLength(255)]} />
        <TextInput fullWidth source="twitter" label="Twitter" validate={[maxLength(255)]} />
        <TextInput fullWidth source="facebook" label="Facebook" validate={[maxLength(255)]} />
        <TextInput fullWidth source="instagram" label="Instagram" validate={[maxLength(255)]} />
        <TextInput fullWidth source="mail" label="Email" validate={[maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput fullWidth source="phone" label="Phone" validate={[maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput fullWidth source="fax" label="Fax" validate={[maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <ReferenceInput label="Country" source="country" reference={getResource('transversal', 'countries')}>
          <AutocompleteInput allowEmpty fullWidth />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <ImageInput source="shield" label="Shield" accept="image/*" />
      </Grid>
      <Grid item xs={12}>
        <ImageInput source="tvShield" label="TV Shield" accept="image/*" />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default ClubsForm
