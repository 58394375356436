import React, { Component } from 'react'
import TablePluginEdit from './tablePluginEdit'
import Plugin from '../plugin'

class TextPlugin extends Component {
  render() {
    return <Plugin name="tablePlugin" {...this.props} component={TablePluginEdit} />
  }
}

export default TextPlugin
