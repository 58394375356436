import Grid from '@material-ui/core/Grid'
import React from 'react'
import { List, Datagrid, TextField, NumberField, EditButton, Filter, TextInput, SimpleList, Responsive, BooleanField } from 'react-admin'
import { TableRowList, ListActions } from '../../../components'
import { BooleanFilterInput } from '../../../components/inputs'

const VenuesFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} md={2}>
          <BooleanFilterInput source="active" label="Active" alwaysOn fullWidth />
        </Grid>
        <Grid item xs={12} md={10}>
          <TextInput className={props.classes.input} variant="filled" label="Name" source="name" alwaysOn fullWidth />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<VenuesFilter />} />
}

const VenuesList = (props) => (
  <List bulkActions={false} title="Venues" actions={<Actions />} {...props}>
    <Responsive
      small={<SimpleList primaryText={(record) => record.name} secondaryText={(record) => record.capacity} />}
      medium={
        <Datagrid>
          <TextField source="name" label="Name" />
          <BooleanField source="active" label="Active" />
          <NumberField source="capacity" label="Capacity" />
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default VenuesList
