import { Button, Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'
import { fetchEnd, fetchStart, SelectInput, showNotification } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector, initialize, isSubmitting } from 'redux-form'
import { OptionsBarStyled } from './styles'
import { GET_MIDDLEWARE } from '../../../../providers/dataProvider/types'
import dataProvider from '../../../../providers'
import { getResource } from '../../../../config/resources'
const FORM = 'record-timetable'
const selector = formValueSelector(FORM)

const styles = (theme) => ({
  button: {
    marginBottom: '20px !important',
  },
})

class DownloadInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lang: 'es',
      loading: false,
    }
  }

  canIDownload() {
    const { field } = this.props
    if (field('download.format') && field('download.status') && field('download.type') && !this.state.loading) {
      return true
    } else {
      return false
    }
  }

  onClickDownload() {
    const { field, showNotification, fetchEnd, fetchStart } = this.props
    const download = field('download')
    const gameweek = field('gameweek')

    const subscriptionSlug = gameweek.round.subscription.slug
    const week = gameweek.week

    fetchStart()
    this.setState({ loading: true })
    dataProvider(GET_MIDDLEWARE, getResource('middleware', 'middleware'), {
      query: `subscriptions/${subscriptionSlug}/gameweeks/${week}/asset`,
      filter: {
        format: download.format,
        status: download.status,
        type: download.type,
      },
      data: {
        lang: this.props.lang,
      },
    })
      .then(({ data }) => {
        window.open(data)
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        this.setState({ loading: false })
        fetchEnd()
      })
  }

  render() {
    const { classes } = this.props
    return (
      <OptionsBarStyled>
        <Grid container alignItems="flex-end">
          <Grid item xs={12}>
            <Typography variant="title">Download</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectInput
              label="Format"
              allowEmpty={false}
              fullWidth
              source="download.format"
              choices={[
                { id: 'pdf', name: 'pdf' },
                { id: 'jpg', name: 'jpg' },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectInput
              label="Status"
              allowEmpty={false}
              fullWidth
              source="download.status"
              choices={[
                { id: 'published', name: 'published' },
                { id: 'temp', name: 'temp' },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectInput
              label="Type"
              allowEmpty={false}
              fullWidth
              source="download.type"
              choices={[
                { id: 'broadcaster', name: 'broadcaster' },
                { id: 'referee', name: 'referee' },
                { id: 'forecast', name: 'forecast' },
              ]}
            />
          </Grid>
        </Grid>
        <Button variant="contained" color="primary" className={classes.button} disabled={!this.canIDownload()} onClick={() => this.onClickDownload()}>
          {' '}
          Download{' '}
        </Button>
      </OptionsBarStyled>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      isSubmitting: isSubmitting(FORM)(state),
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(DownloadInfo)
