import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ImageField,
  EditButton,
  Responsive,
  SimpleList,
  DeleteButton,
  Filter,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import { ListActions, TableRowList } from '../../../components'
import { getResource } from '../../../config/resources'
import { withStyles } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { Grid } from '@material-ui/core'

const styles = (theme) => ({
  deleteStyle: {
    color: fade(theme.palette.error.main, 0.9),
    padding: '2px 6px 2px 2px',
  },
  fullWidthAutoCompleteInput: {
    width: '100%',
  },
})

const AdsSpaceFilters = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid container xs={12}>
          <Grid item xs={12} sm={4}>
            <ReferenceInput source="sponsorId" label="Sponsors" reference={getResource('digitalAssets', 'sponsors')} alwaysOn allowEmpty fullWidth>
              <SelectInput />
            </ReferenceInput>
          </Grid>
        </Grid>
        <ReferenceInput source="matchId" label="Match" reference={getResource('competiciones', 'matches')} allowEmpty className={props.classes.input}>
          <AutocompleteInput optionText="name" fullWidth />
        </ReferenceInput>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<AdsSpaceFilters />} />
}

class AdsSpaceList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: false,
    }
  }

  render() {
    const { classes, ...rest } = this.props
    return (
      <List title="Ads Space" bulkActions={false} actions={<Actions />} {...rest}>
        <Responsive
          small={
            <SimpleList
              leftAvatar={(record) => (
                <ReferenceField
                  source="image"
                  reference={getResource('assets', 'images')}
                  basePath={getResource('assets', 'images')}
                  linkType={false}
                  record={record}
                  allowEmpty
                >
                  <ImageField mini source="asset.fileDir" title="Image Splash Screen" />
                </ReferenceField>
              )}
              primaryText={(record) => record.name}
            />
          }
          medium={
            <Datagrid>
              <ReferenceField label="Image" source="sponsor.image" reference={getResource('assets', 'images')} linkType={false} sortable={false} allowEmpty>
                <ImageField source="asset.fileDir" title="Sponsor image" />
              </ReferenceField>
              <TextField source="sponsor.name" label="Sponsor" />

              {/* <ReferenceField label="Match" source="matchId" reference={getResource('competiciones', 'matches')} linkType={false} sortable={true}>
                <TextField source="name" />
              </ReferenceField> */}

              <TextField source="sponsor.type" label="Sponsor Type" />
              <TextField source="space" label="Space" />
              <TextField source="title" />
              <TableRowList>
                <EditButton />
                <DeleteButton className={classes.deleteStyle} />
              </TableRowList>
            </Datagrid>
          }
        />
      </List>
    )
  }
}

export default withStyles(styles)(AdsSpaceList)
