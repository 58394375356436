import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'
import { GET_ONE } from 'react-admin'
import { dataProvider } from '../../../providers'
import LinearProgress from '@material-ui/core/LinearProgress'
import Tooltip from '@material-ui/core/Tooltip'

const styles = (theme) => ({
  fullWidth: {
    width: '100%',
  },
  chip: {
    minWidth: '150px',
  },
})

class ChipItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
  }

  componentDidMount() {
    const { resource, value } = this.props
    this.getChipData(resource, value, (data) => {
      this.setState({
        data: data,
      })
    })
  }

  getChipData(resource, id, func) {
    dataProvider(GET_ONE, resource, {
      id: id,
    }).then((response) => {
      func(response.data)
    })
  }

  render() {
    const { onDelete, type, classes } = this.props
    //if(type === 'subscription' )  return null
    if (!this.state.data) return <Chip label={<LinearProgress className={classes.chip} />} />
    const text = `${type}: ${type === 'subscription' ? this.state.data.competitionName : this.state.data.name}`
    return (
      <Tooltip title={text}>
        <Chip label={<span>{text}</span>} onDelete={() => onDelete()} />
      </Tooltip>
    )
  }
}

export default withStyles(styles)(ChipItem)
