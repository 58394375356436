import React from 'react'
import { Create } from 'react-admin'
import SponsoringsForm from './form'

const SponsoringsTitle = ({ record }) => <span>New sponsoring:</span>

export const SponsoringsCreate = (props) => (
  <Create title={<SponsoringsTitle />} {...props}>
    <SponsoringsForm isEdit={false} />
  </Create>
)

export default SponsoringsCreate
