import Grid from '@material-ui/core/Grid'
import React from 'react'
import { Filter, Datagrid, EditButton, List, Responsive, SimpleList, TextField, TextInput } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'

const ListFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} md={4} lg={2}>
          <TextInput fullWidth source="name" label="Name" />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<ListFilter />} />
}

export const BeyonStatsHeaderCategories = (props) => {
  return (
    <List bulkActions={false} actions={<Actions />} {...props}>
      <Responsive
        small={<SimpleList primaryText={(record) => record.name} />}
        medium={
          <Datagrid>
            <TextField source="name" label="Name" />
            <TableRowList>
              <EditButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}
export default BeyonStatsHeaderCategories
