import React from 'react'
import { Edit } from 'react-admin'

import CompetitionsForm from './form'

export const PeopleEdit = (props) => (
  <Edit undoable={false} {...props}>
    <CompetitionsForm {...props} idEdit={true} />
  </Edit>
)

export default PeopleEdit
