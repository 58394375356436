import React from 'react'
import {
  Datagrid,
  EditButton,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  FunctionField,
  Filter,
  List,
  Responsive,
  SimpleList,
  TextField,
  AutocompleteInput,
  TextInput,
} from 'react-admin'
import Grid from '@material-ui/core/Grid'

import { ListActions, TableRowList } from '../../../components'
import { DateField, ImageField } from '../../../components/field'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { getResource } from '../../../config/resources'

const SquadFilter = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput
        className={props.classes.input}
        variant="filled"
        label="Season"
        source="season"
        reference={getResource('competiciones', 'seasons')}
        filter={{ showInWeb: 1 }}
        alwaysOn
      >
        <SelectInput />
      </ReferenceInput>
      <ReferenceInput className={props.classes.input} label="Teams" source="team" reference={getResource('competiciones', 'teams')} alwaysOn>
        <AutocompleteInput optionText="selectorName" inputValueMatcher={() => null} fullWidth />
      </ReferenceInput>

      <TextInput className={props.classes.input} variant="filled" label="Name" source="personName" alwaysOn fullWidth />
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<SquadFilter />} />
}

const styles = {
  img: {
    width: '30px',
    'vertical-align': 'middle',
  },
}

const TeamShield = withStyles(styles)(({ classes, ...props }) => <ImageField {...props} className={classes.img} />)

const TeamField = (record) => {
  let team = record.team

  return (
    <React.Fragment>
      {team && (
        <ReferenceField
          source="shield"
          reference={getResource('assets', 'images')}
          basePath={getResource('assets', 'images')}
          linkType={false}
          record={team}
          allowEmpty
        >
          <TeamShield mini source="asset.fileDir" title="imagen competition" />
        </ReferenceField>
      )}
      <span style={{ display: 'inline' }}>{_.get(team, 'selectorName', '-')}</span>
    </React.Fragment>
  )
}

const SquadList = (props) => (
  <List bulkActions={false} title="Seasons" {...props} actions={<Actions />}>
    <Responsive
      small={
        <SimpleList
          primaryText={(record) => record.name}
          tertiaryText={(record) => (
            <div>
              <TextField source="personRole.person.name" label="Name" />
            </div>
          )}
        />
      }
      medium={
        <Datagrid>
          <TextField source="personRole.person.name" label="Name" />
          <TextField source="season.year" label="Season" />
          <TextField source="position.name" label="Position" />
          <TextField source="personRole.role.name" label="Role" />

          <FunctionField label="Team" render={TeamField} />
          <TextField source="shirtNumber" label="ShirtNumber" />
          <TextField source="current" label="Current" />

          <DateField showTime source="updatedAt" label="UpdatedAt" />
        </Datagrid>
      }
    />
  </List>
)

export default SquadList
