import React, { Component, Fragment } from 'react'
import { GET_LIST } from 'react-admin'
import dataProvider from '../../../../../providers'
import { getResource } from '../../../../../config/resources'
import { ListItemsStyled, RowItemStyled } from '../../../utils/styles'
import Squads from './Squads'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const styles = (theme) => ({
  cellButton: {
    textAlign: 'right',
    paddingRight: '12px !important',
    width: '75px;',
  },
  paper: {
    position: 'absolute',
    width: '90vw',
    maxWidth: theme.breakpoints.values.md,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
  },
  closeButton: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    '&:hover': {
      color: theme.palette.error.dark,
    },
  },
})

class SquadsInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      seasons: null,
      seasonOpen: null,
      modalLabel: null,
    }
  }

  componentDidMount() {
    this.getSeasons()
  }

  getSeasons() {
    const { showNotification } = this.props

    dataProvider(GET_LIST, getResource('competiciones', 'seasons'), {
      sort: {
        field: 'year',
        order: 'DESC',
      },
      pagination: { page: 1, perPage: 50 },
      filter: { showInWeb: 1 },
    })
      .then(({ data }) => {
        this.setState({
          seasons: data,
        })
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {})
  }

  handleOpen = (key) => {
    const { seasons } = this.state

    this.setState({
      open: true,
      seasonOpen: seasons[key].id,
      modalLabel: seasons[key].name,
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
      seasonOpen: null,
      modalLabel: null,
    })
  }

  render() {
    const { classes, idTeam } = this.props
    const { seasons, seasonOpen, modalLabel } = this.state
    return (
      <Fragment>
        <ListItemsStyled>
          <RowItemStyled className="sticky">
            <div className="head">Squads</div>
            <div className={classes.cellButton} />
          </RowItemStyled>
          {seasons &&
            seasons.map((item, key) => {
              return (
                <RowItemStyled key={key}>
                  <div className="full">
                    <Typography variant="body1">{item.name}</Typography>
                  </div>
                  <div className={classes.cellButton}>
                    <Button variant="contained" color="primary" onClick={() => this.handleOpen(key)}>
                      Edit
                    </Button>
                  </div>
                </RowItemStyled>
              )
            })}
        </ListItemsStyled>
        <Modal open={this.state.open} onClose={this.handleClose}>
          <Paper className={classes.paper}>
            <IconButton className={classes.closeButton} onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
            <Squads label={modalLabel} idTeam={idTeam} idSeason={seasonOpen} closeModal={() => this.handleClose()} />
          </Paper>
        </Modal>
      </Fragment>
    )
  }
}

export default withStyles(styles)(SquadsInput)
