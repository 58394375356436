import { Grid } from '@material-ui/core'
import React from 'react'
import { maxLength, required, TextInput } from 'react-admin'

import { LanguagesNav } from '../../../../components'
import { ImageInput, RichTextInput } from '../../../../components/inputs'

export const PageForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12}>
        <TextInput fullWidth source="title" label="Title" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <RichTextInput fullWidth source="description" label="Description" validate={[required()]} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ImageInput label="Image" source="image" validate={[required()]} />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default PageForm
