import React from 'react'
import { Create, GET_ONE } from 'react-admin'
import { withRouter } from 'react-router-dom'

import GalleryForm from './form'
import dataProvider from '../../../providers'
import { getResource } from '../../../config/resources'

const GalleryTitle = ({ record }) => <span>Nueva difusión:</span>

class GalleriesCreate extends React.Component {
  constructor(props) {
    super(props)

    const { location } = props
    const { search } = location
    const queryParams = new URLSearchParams(search)
    const cloneId = queryParams.get('cloneId')

    this.state = {
      formDefaultValue: null,
      cloneId: cloneId,
    }
  }

  componentDidMount() {
    this.initializeForm()
  }

  initializeForm = async () => {
    const { cloneId } = this.state

    if (cloneId) {
      await this.fetchGalleries(cloneId)
    }
  }

  fetchGalleries = async (cloneId) => {
    try {
      const { data } = await dataProvider(GET_ONE, getResource('assets', 'galleries'), {
        id: cloneId,
      })

      this.setState({
        formDefaultValue: {
          name: data.name,
          description: data.description,
          category: data.category,
          slug: `${data.slug}-1`,
          clientUuids: data.clientUuids,
          type: data.type,
          state: 'draft',
          markets: data.markets,
          galleryImages: data.galleryImages,
          metadatas: data.metadatas,
        },
      })
    } catch (error) {
      console.error('Error fetching galleries:', error)
    }
  }

  render() {
    const { formDefaultValue } = this.state

    return (
      <Create title={<GalleryTitle />} {...this.props}>
        <GalleryForm isEdit={false} {...this.props} defaultValue={formDefaultValue} />
      </Create>
    )
  }
}

export default withRouter(GalleriesCreate)
