import React from 'react'

import {
  // CloneButton,
  Datagrid,
  EditButton,
  Filter,
  List,
  Responsive,
  RichTextField,
  SelectInput,
  SimpleList,
  TextField,
  TextInput,
  ArrayField,
  ReferenceInput,
  SingleFieldList,
  ChipField,
  Pagination,
} from 'react-admin'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ContentCopy from '@material-ui/icons/ContentCopy'
import { Link } from 'react-router-dom'

import { ListActions, TableRowList } from '../../../components'
import { DateField, ImageField } from '../../../components/field'
import { MetadataInput } from '../../../components/inputs'
import { getResource } from '../../../config/resources'

const CustomCloneButton = (props) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: 'galleries/create',
        search: '?cloneId=' + props.record.id,
      }}
      startIcon={<ContentCopy />}
    >
      Clone
    </Button>
  )
}

const GalleriesFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={6} md={3}>
          <SelectInput
            fullWidth
            source="state"
            choices={[
              {
                id: 'published',
                name: 'published',
              },
              {
                id: 'draft',
                name: 'draft',
              },
              {
                id: 'hidden',
                name: 'hidden',
              },
            ]}
            alwaysOn
            allowEmpty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ReferenceInput
            source="market"
            label="Markets"
            filter={{ group: 'oneMarket' }}
            reference={getResource('transversal', 'markets')}
            alwaysOn
            allowEmpty
            fullWidth
          >
            <SelectInput optionText="id" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ReferenceInput source="clientUuid" label="Clients" reference={getResource('transversal', 'clientuuids')} alwaysOn allowEmpty fullWidth>
            <SelectInput optionText="name" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput variant="filled" label="Title" source="q" alwaysOn fullWidth />
        </Grid>
        <Grid item xs={12}>
          <MetadataInput source="metadatas" label="Metadatas" />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<GalleriesFilter />} />
}

const Image = ({ record }) => {
  return (
    <ImageField
      sourceImage="prominentImage.content"
      sizes={['small', 'medium', 'large', 'xlarge']}
      state={record.state}
      record={record}
      label="State"
      title="imagen"
    />
  )
}

const PostPagination = (props) => <Pagination rowsPerPageOptions={[25, 50]} {...props} />

export const FotogalleryList = (props) => (
  <List bulkActions={false} filter={{ type: 'image' }} title="Galleries" {...props} actions={<Actions />} perPage={25} pagination={<PostPagination />}>
    <Responsive
      medium={
        <SimpleList
          leftAvatar={(record) => <ImageField sourceImage="prominentImage.content" sourceState="state" record={record} label="State" title="imagen" />}
          primaryText={(record) => record.name}
          tertiaryText={(record) => (
            <span>
              <div>State: {record.state}</div>
            </span>
          )}
        />
      }
      large={
        <Datagrid>
          <Image />
          <TextField source="name" label="Title" />
          <RichTextField source="description" label="Description" />
          <TextField source="state" label="Publication" />
          <ArrayField source="galleryLanguages" label="Available in" sortable={false}>
            <SingleFieldList>
              <ChipField source="language" />
            </SingleFieldList>
          </ArrayField>
          <ArrayField source="markets" label="Markets" sortable={false}>
            <SingleFieldList linkType={false}>
              <ChipField source="market" />
            </SingleFieldList>
          </ArrayField>
          <DateField source="createdAt" label="Created at" />
          <DateField source="publishedAt" label="Published at" />
          <ArrayField source="clientUuids" label="Clients" sortable={false}>
            <SingleFieldList linkType={false}>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <TableRowList>
            <EditButton />
            <CustomCloneButton {...props} />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default FotogalleryList
