import Grid from '@material-ui/core/Grid'
import React from 'react'
import { BooleanField, Datagrid, EditButton, Filter, List, ReferenceField, Responsive, SimpleList, TextField, TextInput } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'
import { ImageField } from '../../../components/field'
import { BooleanFilterInput } from '../../../components/inputs'
import { getResource } from '../../../config/resources'

const CompetitionsFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} md={2}>
          <BooleanFilterInput source="showInPublic" label="Public" alwaysOn fullWidth />
        </Grid>
        <Grid item xs={12} md={10}>
          <TextInput className={props.classes.input} variant="filled" label="Competition" source="name" alwaysOn fullWidth />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<CompetitionsFilter />} />
}

const CompetitionsList = (props) => (
  <List bulkActions={false} title="Competitions" {...props} actions={<Actions />}>
    <Responsive
      small={
        <SimpleList
          leftAvatar={(record) => (
            <ReferenceField
              source="image"
              reference={getResource('assets', 'images')}
              basePath={getResource('assets', 'images')}
              linkType={false}
              record={record}
              allowEmpty
            >
              <ImageField mini source="asset.fileDir" title="imagen competition" />
            </ReferenceField>
          )}
          primaryText={(record) => record.name}
        />
      }
      medium={
        <Datagrid>
          <ReferenceField label="Image" source="image" reference={getResource('assets', 'images')} linkType={false} sortable={false} allowEmpty>
            <ImageField source="asset.fileDir" title="imagen competition" />
          </ReferenceField>
          <TextField source="name" label="Competition" />
          <BooleanField source="showInPublic" label="Public" />
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default CompetitionsList
