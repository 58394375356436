import React, { Component } from 'react'
import { ContainerPluginStyled } from './style'

class Plugin extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    //console.log(this.props)
    const MyComponent = this.props.component
    return (
      <ContainerPluginStyled>
        <MyComponent {...this.props} />
      </ContainerPluginStyled>
    )
  }
}

export default Plugin
