import React from 'react'

import LanguagesNav from '../../../../components/languagesNav'
import Form from './form'

export const VideoForm = ({ ...props }) => {
  return (
    <LanguagesNav useAdditionalLanguages {...props} defaultValue={{ isPrivate: false }}>
      {({ dir }) => <Form isEdit={props.isEdit} dir={dir} />}
    </LanguagesNav>
  )
}

export default VideoForm
