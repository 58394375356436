import { Button, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import CloseIcon from '@material-ui/icons/RemoveCircleOutline'
import React, { Component, Fragment } from 'react'
import { ArrayInput, RadioButtonGroupInput, ReferenceInput, required, SimpleFormIterator, FormDataConsumer } from 'react-admin'
import { AutocompleteInput, MetadataInput } from '../../../../components/inputs'
import { getResource } from '../../../../config/resources'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector } from 'redux-form'

export const states = {
  approved: 'approved',
  refused: 'refused',
  pending: 'pending',
}

const styles = (theme) => ({
  radioButtonGroupInput: {
    display: 'flex',
    '& > div': {
      flexDirection: 'row',
      width: '100%',
      flexWrap: 'nowrap',
    },
  },
  rowIndex: {
    padding: '10px',
    alignSelf: 'flex-start',
  },
  rowBroadcastCountries: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  rowContent: {
    flex: 'auto',
    padding: '0px 10px',
  },
})

export class FormChannelMatch extends Component {
  addRow(source, formData, form) {
    const { change } = this.props
    let items
    if (formData[source]) {
      items = [...formData[source]]
    } else {
      items = []
    }
    items.push({})
    change(form, source, items)
  }

  removeRow(source, formData, form, key) {
    const { change } = this.props
    let items = [...formData[source]]
    items.splice(key, 1)
    change(form, source, items)
  }

  ChannelMatchCountries = ({ source, recordForm, form }) => {
    const { classes } = this.props
    return (
      <FormDataConsumer form={form}>
        {({ formData, dispatch, ...rest }) => {
          const myFormData = recordForm || formData
          return (
            <Fragment>
              {myFormData &&
                myFormData[source] &&
                myFormData[source].map((item, key) => (
                  <div className={classes.rowBroadcastCountries} key={key}>
                    <div className={classes.rowIndex}>{key + 1}</div>
                    <div className={classes.rowContent}>
                      <ReferenceInput
                        label="Country"
                        source={`${source}[${key}].country`}
                        validate={[required()]}
                        reference={getResource('transversal', 'countries')}
                      >
                        <AutocompleteInput />
                      </ReferenceInput>
                      <RadioButtonGroupInput
                        className={classes.radioButtonGroupInput}
                        source={`${source}[${key}].state`}
                        label="State"
                        choices={[
                          { id: states.approved, name: 'Approved' },
                          { id: states.refused, name: 'Refused' },
                          { id: states.pending, name: 'Pending' },
                        ]}
                      />
                    </div>
                    <Button size="small" onClick={() => this.removeRow(source, myFormData, form, key)}>
                      <CloseIcon />
                      Remove
                    </Button>
                  </div>
                ))}
              <Button size="small" onClick={() => this.addRow(source, myFormData, form)}>
                <AddIcon className={classes.leftIcon} />
                Add
              </Button>
            </Fragment>
          )
        }}
      </FormDataConsumer>
    )
  }

  render() {
    const { ChannelMatchCountries } = this
    const { form, recordForm } = this.props
    return (
      <Grid container>
        <Grid item xs={12}>
          <ReferenceInput label="Channel" source="channel.id" validate={[required()]} reference={getResource('operadores', 'channels')}>
            <AutocompleteInput fullWidth source="name" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <ChannelMatchCountries source="channelMatchCountries" form={form} recordForm={recordForm} />
        </Grid>
        <Grid item xs={12}>
          <MetadataInput
            source="metadatas"
            label="Metadata"
            sources={{
              match: 'match',
            }}
          />
        </Grid>
      </Grid>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      // field: source => selector(state, source)
    }),
    (dispatch) => ({
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(FormChannelMatch)
