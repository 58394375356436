import React from 'react'
import { Edit } from 'react-admin'
import MatchForm from './form'

export const MatchEdit = (props) => (
  <Edit undoable={false} {...props}>
    <MatchForm {...props} />
  </Edit>
)

export default MatchEdit
