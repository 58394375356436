import React from 'react'
import { ImageField } from '../../../../components'

const ImageVideoField = ({ record }) => {
  if (record.imageVideo) {
    return <ImageField record={record.imageVideo} sizes={['small', 'medium', 'large', 'xlarge']} sourceImage="" />
  } else if (record.providerThumbnails) {
    return <ImageField record={record} source="providerThumbnails.small" title="imagen" />
  }
  return null
}

export default ImageVideoField
