import Grid from '@material-ui/core/Grid'
import React from 'react'
import {
  CloneButton,
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  TextField,
  List,
  ReferenceInput,
  Responsive,
  SelectInput,
  SimpleList,
  TextInput,
} from 'react-admin'
import { Link } from 'react-router-dom'

import { ListActions, TableRowList } from '../../../components'
import { DateField } from '../../../components/field'
import { DateRangeInput, AutocompleteInput } from '../../../components/inputs'
import { ImageField } from '../../../components/field'
import { getResource } from '../../../config/resources'

const ActsFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={12} md={12}>
          <DateRangeInput label="Started ad" source="startedAt" fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ReferenceInput source="event" label="Event" reference={getResource('assets', 'events')} alwaysOn allowEmpty fullWidth>
            <AutocompleteInput fullWidth />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextInput source="name" label="Name" alwaysOn fullWidth />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<ActsFilter />} />
}

export const ActsList = (props) => {
  return (
    <List bulkActions={false} actions={<Actions />} title="Imágenes" {...props}>
      <Responsive
        small={
          <SimpleList
            leftAvatar={(record) => <ImageField sourceImage="image" record={record} label="Image" title="Image" />}
            primaryText={(record) => record.name}
            secondaryText={(record) => (record.event ? record.event.name : '')}
          />
        }
        medium={
          <Datagrid>
            <ImageField sourceImage="image" label="Image" />
            <TextField source="name" label="Name" />
            <FunctionField
              label="Event"
              render={(record) => (
                <Link to={`/${record.event && getResource('assets', 'events')}/${record.event.id}`}>{record.event ? record.event.name : ''}</Link>
              )}
            />
            <DateField source="startedAt" />
            <TableRowList>
              <EditButton />
              <CloneButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}
export default ActsList
