import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'
import { fetchEnd, fetchStart, GET_LIST, LongTextInput, NumberInput, ReferenceField, showNotification, SimpleForm, TextField, number } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector, initialize, isSubmitting } from 'redux-form'

import { getResource } from '../../../../config/resources'
import dataProvider from '../../../../providers'
import { MY_UPDATE_MANY } from '../../../../providers/dataProvider/types'

const FORM = 'record-form'
const selector = formValueSelector(FORM)

const styles = (theme) => ({
  title: {
    marginBottom: '8px',
  },
})

class StandingFairplayForm extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { id } = this.props

    this.initializeFairplay(id)
  }

  initializeFairplay(idGameweek) {
    const { fetchStart, fetchEnd, showNotification } = this.props

    fetchStart()
    dataProvider(GET_LIST, getResource('competiciones', 'fairplays'), {
      sort: {
        field: 'id',
        order: 'ASC',
      },
      pagination: { page: 1, perPage: 50 },
      filter: {
        gameweek: idGameweek,
      },
    })
      .then(({ data }) => {
        this.props.initialize(FORM, {
          fairplay: data,
        })
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  updateFairplay() {
    const { fetchStart, fetchEnd, showNotification, field } = this.props
    const fairplay = field('fairplay')

    if (fairplay) {
      fetchStart()
      dataProvider(MY_UPDATE_MANY, getResource('competiciones', 'fairplays'), {
        data: fairplay,
      })
        .then((response) => {
          showNotification('Elements updated', 'success')
        })
        .catch((error) => {
          showNotification(error.message, 'error')
        })
        .finally(() => {
          fetchEnd()
        })
    }
  }

  render() {
    const { isSubmitting, field, classes, record } = this.props
    var fairplay = field('fairplay')

    return (
      <SimpleForm resource={getResource('competiciones', 'standings')} saving={isSubmitting} save={() => this.updateFairplay()}>
        <Grid container alignItems="flex-start">
          <Grid item xs={6} md={3}>
            <Typography variant="title" color="primary" className={classes.title}>
              Season
            </Typography>
            <ReferenceField
              source="round.id"
              reference={getResource('competiciones', 'rounds')}
              basePath={getResource('competiciones', 'rounds')}
              linkType={false}
              record={record}
            >
              <TextField source="subscription.season.name" fullWidth />
            </ReferenceField>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="title" color="primary" className={classes.title}>
              Competition
            </Typography>
            <ReferenceField
              source="round.id"
              reference={getResource('competiciones', 'rounds')}
              basePath={getResource('competiciones', 'rounds')}
              linkType={false}
              record={record}
            >
              <TextField source="subscription.competition.name" fullWidth />
            </ReferenceField>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="title" color="primary" className={classes.title}>
              Subscription
            </Typography>
            <ReferenceField
              source="round.id"
              reference={getResource('competiciones', 'rounds')}
              basePath={getResource('competiciones', 'rounds')}
              linkType={false}
              record={record}
            >
              <TextField source="subscription.competitionName" fullWidth />
            </ReferenceField>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="title" color="primary" className={classes.title}>
              Round
            </Typography>
            <ReferenceField
              source="round.id"
              reference={getResource('competiciones', 'rounds')}
              basePath={getResource('competiciones', 'rounds')}
              linkType={false}
              record={record}
            >
              <TextField source="name" fullWidth />
            </ReferenceField>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="title" color="primary" className={classes.title}>
              Gameweek
            </Typography>
            <TextField source="name" fullWidth record={record} />
          </Grid>

          {fairplay &&
            fairplay.map((item, key) => {
              return (
                <Grid item xs={12} key={key}>
                  <Paper key={key}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="subheading" color="textPrimary">
                          {item.team.selectorName}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <NumberInput label="Fairplay" source={`fairplay.${key}.fairplay`} validate={[number()]} fullWidth />
                      </Grid>
                      <Grid item xs={6}>
                        <NumberInput label="Fine" source={`fairplay.${key}.fine`} validate={[number()]} fullWidth />
                      </Grid>
                      <Grid item xs={12}>
                        <LongTextInput label="Remark" source={`fairplay.${key}.remark`} />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )
            })}
        </Grid>
      </SimpleForm>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      isSubmitting: isSubmitting(FORM)(state),
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(StandingFairplayForm)
