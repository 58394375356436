import React from 'react'

const Videos = ({ color = '#111111' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <path
          id="iconVideo_a"
          d="M19.348 14.385a.652.652 0 0 1 0 1.305h-4.25a1.953 1.953 0 0 1-1.837 1.304c-.85 0-1.566-.547-1.836-1.304H.652a.652.652 0 0 1 0-1.305h10.773a1.953 1.953 0 0 1 1.836-1.304c.849 0 1.566.547 1.836 1.304h4.25zM18.043.037C19.123.037 20 .915 20 1.994V9.82a1.959 1.959 0 0 1-1.957 1.957H1.957A1.959 1.959 0 0 1 0 9.82V1.994C0 .915.878.037 1.957.037h16.086zM12.97 6.45a.653.653 0 0 0 0-1.086L9.057 2.756a.653.653 0 0 0-1.014.542v5.218c0 .52.581.83 1.014.542L12.97 6.45z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g transform="translate(2 4)">
          <mask id="iconVideo_b" fill={color}>
            <use xlinkHref="#iconVideo_a" />
          </mask>
          <use fill={color} fillRule="nonzero" xlinkHref="#iconVideo_a" />
          <g fill={color} mask="url(#iconVideo_b)">
            <path d="M-36-39h99v99h-99z" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Videos
