import { Avatar, Card, CardHeader, Grid, IconButton, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { GET_LIST } from 'ra-core/lib/dataFetchActions'
import React, { Component, Fragment } from 'react'
import { DELETE, fetchEnd, fetchStart, Pagination, ReferenceField, ReferenceInput, SelectInput, showNotification, SimpleForm, TextField } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector, initialize, isSubmitting } from 'redux-form'

import { ConfirmationDialog } from '../../../../../components'
import { getResource } from '../../../../../config/resources'
import dataProvider from '../../../../../providers'
import { PATCH } from '../../../../../providers/dataProvider/types'
import EditEvent from './EditEvent'

const FORM = 'matchevents-form'
const selector = formValueSelector(FORM)

const styles = (theme) => {
  return {
    card: {
      width: '100%',
      background: theme.palette.background.paper,
    },
    cardHeader: {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
    avatar: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrasText,
      borderRadius: '100px',
    },
    cardHeaderActions: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '20px',
    },
    cardHeaderTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      '& .collection': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > span': {
          margin: '2px',
        },
      },
    },
    cardHeaderSubheader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    button: {
      margin: theme.spacing.unit,
    },
    rowMatchStatus: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      alignItems: 'flex-end',
    },
  }
}

class Events extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: null,
      perPage: null,
      deleteEvent: null,
      dialogOpen: false,
    }
  }

  componentDidMount() {
    const { initialize, match } = this.props
    initialize(FORM, {
      events: [],
      editEvent: {
        period: match.status,
      },
      matchStatus: match.status,
    })
    this.initializeEvents()
  }

  getMatchEvent(page, perPage, callback) {
    const { match, fetchStart, fetchEnd, showNotification } = this.props
    fetchStart()
    dataProvider(GET_LIST, getResource('competiciones', 'matchevents'), {
      sort: {
        field: 'time',
        order: 'DESC',
      },
      pagination: {
        page: page,
        perPage: perPage,
      },
      filter: {
        match: match.id,
      },
    })
      .then(({ data, total }) => {
        this.setState({
          page: page,
          perPage: perPage,
          total: total,
        })
        callback(data)
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  initializeEvents() {
    const { change } = this.props

    this.getMatchEvent(1, 5, function (data) {
      change(FORM, 'events', data)
    })
  }

  loadMatchEvent(key) {
    const { change, field } = this.props
    let event = field(`events.${key}`)
    if (event.lineup) {
      event.team = event.lineup.team.id
    }
    change(FORM, 'editEvent', event)
  }

  confirmDeleteMatchEvent(key) {
    this.setState({
      deleteEvent: key,
      dialogOpen: true,
    })
  }

  deleteMatchEvent() {
    const { field, fetchEnd, fetchStart, showNotification } = this.props
    const { deleteEvent } = this.state

    let events = field(`events`)
    const idDeleteEvent = events[deleteEvent].id
    fetchStart()
    dataProvider(DELETE, getResource('competiciones', 'matchevents'), {
      id: idDeleteEvent,
    })
      .then(({ data }) => {
        this.setState({
          deleteEvent: null,
          dialogOpen: false,
        })
        this.refreshListEvents()
        showNotification('Element deleted', 'success')
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  refreshListEvents() {
    const { change } = this.props
    const { page, perPage } = this.state

    this.getMatchEvent(page, perPage, function (data) {
      change(FORM, 'events', data)
    })
  }

  setListEvents(page, perPage) {
    const { change } = this.props

    this.getMatchEvent(page, perPage, function (data) {
      change(FORM, 'events', data)
    })
  }

  changeMatchStatus() {
    const { match, field, fetchStart, fetchEnd, showNotification } = this.props

    fetchStart()
    dataProvider(PATCH, getResource('competiciones', 'matches'), {
      id: match.id,
      data: {
        status: field('matchStatus'),
      },
    })
      .then(({ data }) => {
        showNotification('Match Status updated', 'success')
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  render() {
    const { classes, field, match } = this.props
    const events = field('events')
    const editEvent = field('editEvent')
    const matchStatus = field('matchStatus')

    return (
      <SimpleForm form={FORM} toolbar={false}>
        {matchStatus && (
          <div className={classes.rowMatchStatus}>
            <ReferenceInput label="Match Status" source="matchStatus" reference={getResource('competiciones', 'matchStatus')}>
              <SelectInput optionText="name" />
            </ReferenceInput>
            <Button onClick={() => this.changeMatchStatus()} variant="contained" className={classes.button} color="primary">
              Change
            </Button>
          </div>
        )}
        {editEvent && <EditEvent match={match} onChange={() => this.refreshListEvents()} />}
        <Grid container>
          {events &&
            events.map((item, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Card className={classes.card}>
                    <CardHeader
                      className={classes.cardHeader}
                      avatar={<Avatar className={classes.avatar}>{item.time}'</Avatar>}
                      action={
                        <span className={classes.cardHeaderActions}>
                          <IconButton onClick={() => this.loadMatchEvent(index)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton>
                            <DeleteIcon onClick={() => this.confirmDeleteMatchEvent(index)} />
                          </IconButton>
                        </span>
                      }
                      title={
                        <span className={classes.cardHeaderTitle}>
                          <span className="collection">
                            <ReferenceField
                              source={`events[${index}].collection`}
                              reference={getResource('competiciones', 'matchevent-collections')}
                              basePath={getResource('competiciones', 'matchevent-collections')}
                              linkType={false}
                              record={{
                                events: events,
                              }}
                            >
                              <TextField source="name" />
                            </ReferenceField>
                            <span> - </span>
                            <TextField
                              source={`events[${index}].matchEventKind.name`}
                              record={{
                                events: events,
                              }}
                            />
                            {field(`events[${index}].collection`) === 'shootout' && (
                              <Fragment>
                                <TextField
                                  source={`events[${index}].homeScore`}
                                  record={{
                                    events: events,
                                  }}
                                />
                                -
                                <TextField
                                  source={`events[${index}].awayScore`}
                                  record={{
                                    events: events,
                                  }}
                                />
                              </Fragment>
                            )}
                          </span>
                          <ReferenceField
                            source={`events[${index}].period`}
                            reference={getResource('competiciones', 'matchStatus')}
                            basePath={getResource('competiciones', 'matchStatus')}
                            linkType={false}
                            record={{
                              events: events,
                            }}
                          >
                            <TextField source="name" fullWidth />
                          </ReferenceField>
                        </span>
                      }
                      subheader={
                        <span className={classes.cardHeaderSubheader}>
                          <span>
                            {item.lineup.personRole.person.name}
                            {item.lineupOff && ` - ${item.lineupOff.personRole.person.name}`}
                            {item.position && ` - ${item.position}`}
                          </span>
                          <span>{item.lineup.team.selectorName}</span>
                        </span>
                      }
                    />
                  </Card>
                </Grid>
              )
            })}
          <Grid item xs={12}>
            {this.state.page && (
              <Pagination
                setPage={(page) => this.setListEvents(page, this.state.perPage)}
                setPerPage={(perPage) => this.setListEvents(this.state.page, perPage)}
                total={this.state.total}
                perPage={this.state.perPage}
                page={this.state.page}
              />
            )}
          </Grid>
        </Grid>
        <ConfirmationDialog
          open={this.state.dialogOpen}
          title="Are you sure?"
          onCancel={() => {
            this.setState({
              dialogOpen: false,
            })
          }}
          onSuccess={() => {
            this.deleteMatchEvent()
          }}
        />
      </SimpleForm>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      isSubmitting: isSubmitting(FORM)(state),
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(Events)
