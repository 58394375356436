import styled from 'styled-components'

export const ContainerTeam = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`

export const HeaderTeam = styled.div`
  padding: 10px;
  text-align: center;
`

export const ContainerPlayers = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > div {
    flex: 0 0 48%;
    display: flex;
    flex-direction: column;
  }
`

export const GroupPlayes = styled.div`
  width: 100%;
  margin: 10px 0;
  max-height: 60vh;
  overflow: auto;
  position: relative;
  border: 1px solid #ccc;
  padding: 0 0 5px 0;
  border-radius: 3px;
  background: #fff;
  & .title {
    position: sticky;
    top: 0;
    width: 100%;
    font-weight: bold;
    text-align: center;
    background: #fff;
    padding: 5px 0;
  }
  ul {
    list-style: none;
    padding: 0 10px;
    li {
      margin: 10px 0px;
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 3px;
      padding: 5px 10px;
    }
  }
`
