import React from 'react'
import Grid from '@material-ui/core/Grid'
import { List, Datagrid, TextField, EditButton, ReferenceField, Filter, SelectInput, Responsive, SimpleList, ReferenceInput } from 'react-admin'
import { DateField } from '../../../components/field'
import { ListActions, TableRowList } from '../../../components'
import { MatchField } from '../../../components/field'
import { MetadataInput } from '../../../components/inputs'
import { getResource } from '../../../config/resources'
import stateImport from './state'

const ImportFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12}>
          <SelectInput
            allowEmpty
            label="State"
            source="state"
            choices={[
              { id: stateImport.completed, name: 'Completado' },
              { id: stateImport.revised, name: 'Revisado' },
              { id: stateImport.pending, name: 'Pendiente' },
            ]}
            alwaysOn
          />
        </Grid>
        <Grid item xs={12}>
          <MetadataInput source="metadatas" label="Metadatas" types={[{ type: 'match', name: 'Match' }]} />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<ImportFilter />} />
}

export const ImportList = (props) => (
  <List bulkActions={false} title="Operadores" actions={<Actions />} {...props}>
    <Responsive
      small={
        <SimpleList
          primaryText={(record) => (
            <ReferenceField
              linkType={false}
              record={record}
              basePath={getResource('competiciones', 'matches')}
              label="Season"
              source="match"
              reference={getResource('competiciones', 'matches')}
            >
              <TextField source="gameweek.round.subscription.season.name" />
            </ReferenceField>
          )}
          secondaryText={(record) => (
            <div>
              <ReferenceField
                linkType={false}
                record={record}
                basePath={getResource('competiciones', 'matches')}
                label="Competition"
                source="match"
                reference={getResource('competiciones', 'matches')}
              >
                <TextField source="gameweek.round.subscription.competition.name" />
              </ReferenceField>
              <ReferenceField
                linkType={false}
                record={record}
                basePath={getResource('competiciones', 'matches')}
                label="Game week"
                source="match"
                reference={getResource('competiciones', 'matches')}
              >
                <TextField source="gameweek.name" />
              </ReferenceField>
              <DateField record={record} source="createdAt" label="Date" />
            </div>
          )}
          tertiaryText={(record) => record.state}
        />
      }
      medium={
        <Datagrid>
          <TextField source="state" label="State" />
          <ReferenceField label="Season" source="match" reference={getResource('competiciones', 'matches')}>
            <TextField source="season.name" />
          </ReferenceField>
          <ReferenceField label="Competition" source="match" reference={getResource('competiciones', 'matches')}>
            <TextField source="competition.name" />
          </ReferenceField>
          <ReferenceField linkType={false} label="Match" source="match" reference={getResource('competiciones', 'matches')}>
            <MatchField />
          </ReferenceField>
          <DateField source="createdAt" label="Date" />
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default ImportList
