import React from 'react'
import { Datagrid, EditButton, Filter, List, ReferenceField, Responsive, SimpleList, TextField, TextInput } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'
import { DateField, ImageField } from '../../../components/field'
import { getResource } from '../../../config/resources'

const CategoriesFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput source="q" label="Search" alwaysOn />
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<CategoriesFilter />} />
}

const Image = ({ record, label, ...props }) => {
  if (record) {
    if (record.imageVideo) {
      return <ImageField record={record.imageVideo} sizes={['small', 'medium', 'large', 'xlarge']} sourceImage="" />
    } else if (record.providerThumbnails) {
      return <ImageField record={record} source="providerThumbnails.small" title="imagen" />
    }
  }
  return null
}

export const CategoriesList = (props) => (
  <List bulkActions={false} actions={<Actions />} title="Imágenes" {...props}>
    <Responsive
      small={
        <SimpleList
          leftAvatar={(record) => (
            <ReferenceField
              source="asset"
              reference={getResource('assets', 'videos')}
              basePath={getResource('assets', 'videos')}
              linkType={false}
              record={record}
              allowEmpty
            >
              <Image label="Image" />
            </ReferenceField>
          )}
          primaryText={(record) => record.name}
          secondaryText={(record) => record.type}
        />
      }
      medium={
        <Datagrid>
          <ReferenceField label="Image" source="asset" reference={getResource('assets', 'videos')} linkType={false} sortable={false} allowEmpty>
            <Image label="Image" />
          </ReferenceField>
          <TextField label="Type" source="type" />
          <TextField label="Name" source="name" />
          <DateField label="Created At" source="createdAt" />
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default CategoriesList
