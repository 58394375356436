import React from 'react'
import { Create } from 'react-admin'

import CompetitionsForm from './form'

export const PositionsCreate = (props) => (
  <Create {...props}>
    <CompetitionsForm {...props} idEdit={false} />
  </Create>
)

export default PositionsCreate
