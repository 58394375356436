import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'
import React, { Component } from 'react'
import { DELETE, fetchEnd, fetchStart, GET_ONE, showNotification } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { ConfirmationDialog } from '../../../../../../components'
import { getResource } from '../../../../../../config/resources'
import { dataProvider } from '../../../../../../providers'
import FormRounds from './FormRound'
import Gameweeks from './Gameweeks'
import Groups from './Groups'

const styles = (theme) => {
  return {
    border: {
      border: '1px solid',
      borderColor: theme.palette.grey[600],
      position: 'relative',
    },
    loading: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0,0,0,0.1)',
    },
    buttonPanel: {
      paddingTop: '0px',
      paddingBottom: '0px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > button': {
        '& .icon': {
          fontSize: '20px',
        },
      },
    },
  }
}

class EditRound extends Component {
  constructor(props) {
    super(props)
    this.state = {
      round: null,
      dialogOpen: false,
    }
  }

  componentDidMount() {
    const { idRound } = this.props
    this.getRound(idRound)
  }

  getRound(idRound) {
    dataProvider(GET_ONE, getResource('competiciones', 'rounds'), {
      id: idRound,
    }).then((response) => {
      this.setState({
        round: response.data,
      })
    })
  }

  deleteRound = () => {
    const { idRound, closeEditRound, fetchEnd, fetchStart, showNotification } = this.props

    fetchStart()
    dataProvider(DELETE, getResource('competiciones', 'rounds'), {
      id: idRound,
    })
      .then((response) => {
        closeEditRound()
        showNotification('Elements deleted', 'success')
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  render() {
    const { classes } = this.props
    const { round } = this.state
    return (
      <Grid container>
        <Grid item xs={12} className={classes.buttonPanel}>
          <Button variant="contained" onClick={() => this.setState({ dialogOpen: true })} size="small">
            Delete
          </Button>
          <IconButton onClick={() => this.props.closeEditRound()} color="primary" size="small">
            <ClearIcon className="icon" />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <FormRounds round={round} />
        </Grid>
        <Grid item xs={12}>
          {round && round.groups && <Groups idRound={this.props.idRound} idSubscription={round.subscription.id} />}
        </Grid>
        <Grid item xs={12}>
          <Gameweeks idRound={this.props.idRound} />
        </Grid>
        <ConfirmationDialog
          open={this.state.dialogOpen}
          title="Are you sure?"
          onCancel={() => {
            this.setState({
              dialogOpen: false,
            })
          }}
          onSuccess={() => {
            this.deleteRound()
          }}
        />
      </Grid>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({}),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
    })
  ),
  withStyles(styles)
)
export default enhance(EditRound)
