import styled from 'styled-components'

import { colors } from '../../../../config/theme'

export const ContainerStanding = styled.div`
  padding: 10px 0;
  width: 100%;
  overflow: auto;
`

export const ItemTableStyled = styled.div`
  width: 100%;
  display: table;
  background-color: ${colors.grey[200]};
  margin-top: 5px;
  min-width: 600px;
  &.head {
    background-color: ${colors.grey[100]};
  }
`

export const ItemTdPosition = styled.div`
  display: table-cell;
  width: 25px;
  min-width: 50px;
  vertical-align: middle;
  padding: 0 5px;
  background-color: ${colors.grey[400]};
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  &.head {
    background-color: ${colors.grey[100]};
    text-align: center;
    padding: 5px 0;
    font-weight: bold;
    font-size: 14px;
  }
`

export const ItemTdName = styled.div`
  display: table-cell;
  width: 100%;
  padding: 0 5px;
  vertical-align: middle;
  font-size: 14px;
  &.head {
    background-color: ${colors.grey[100]};
    text-align: center;
    padding: 5px 0;
    font-weight: bold;
    font-size: 14px;
  }
`

export const ItemTdInput = styled.div`
  display: table-cell;
  width: 60px;
  max-width: 60px;
  min-width: 60px;
  label {
    display: none;
  }
  input {
    padding-right: 2px;
    padding-left: 2px;
    text-align: center;
  }
  &.head {
    text-align: center;
    padding: 5px 0;
    font-weight: bold;
    font-size: 14px;
  }
`
