import React from 'react'
import { Grid, withStyles } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { Datagrid, EditButton, DeleteButton, Filter, List, ReferenceInput, Responsive, TextField, TextInput, SimpleList } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'
import { DateField } from '../../../components/field'
import { AutocompleteInput, DateRangeInput } from '../../../components/inputs'
import { getResource } from '../../../config/resources'

const TransfersFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={4}>
          <DateRangeInput source="date" label="Date" alwaysOn fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInput source="teamFromString" label="Team From" alwaysOn fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInput source="teamToString" label="Team To" alwaysOn fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ReferenceInput source="procedure" label="Procedure" reference={getResource('competiciones', 'procedures')} alwaysOn allowEmpty>
            <AutocompleteInput fullWidth />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextInput source="name" label="Name" alwaysOn fullWidth />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<TransfersFilter />} />
}

const styles = (theme) => ({
  deleteStyle: {
    color: fade(theme.palette.error.main, 0.9),
    padding: '2px 6px 2px 2px',
  },
})

const TransfersList = (props) => (
  <List bulkActions={false} title="Transfers" {...props} actions={<Actions />}>
    <Responsive
      small={
        <SimpleList
          primaryText={(record) => record.competition.name}
          tertiaryText={(record) => <DateField source="date" label="Date" record={record} />}
          secondaryText={(record) => (
            <div>
              <TextField source="procedure.name" label="Procedure" record={record} />
              <TextField source="name" label="Player" record={record} />
              <span>
                <b>From:</b> <TextField source="teamFromString" label="Team From" record={record} />
              </span>
              <span>
                <b>To:</b> <TextField source="teamToString" label="Team To" record={record} />
              </span>
            </div>
          )}
        />
      }
      medium={
        <Datagrid>
          <TextField source="competition.name" label="Competition" />
          <DateField source="date" label="Date" />
          <TextField source="procedure.name" label="Procedure" />
          <TextField source="name" label="Player" />
          <TextField source="teamFromString" label="Team From" />
          <TextField source="teamToString" label="Team To" />
          <TableRowList>
            <EditButton />
            <DeleteButton className={props.classes.deleteStyle} />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default withStyles(styles)(TransfersList)
