import React from 'react'
import { TextInput, maxLength, required, BooleanInput, DisabledInput, NumberInput, SimpleForm } from 'react-admin'
import LanguagesNav from '../../../../components/languagesNav'
import { Grid } from '@material-ui/core'
import { ImageInput } from '../../../../components/inputs'

class SocialMediaProfilesForm extends React.Component {
  render() {
    const { isEdit, ...props } = this.props

    return (
      <LanguagesNav {...props}>
        <Grid container>
          <Grid item xs={12}>
            <TextInput fullWidth source="name" label="Name" validate={[required(), maxLength(255)]} />
          </Grid>
          <Grid item xs={12}>
            <TextInput fullWidth source="url" label="Url" validate={[required(), maxLength(255)]} />
          </Grid>
          <Grid item xs={12}>
            <ImageInput source="image" label="Logo RRSS" accept="image/*" />
          </Grid>
          <Grid item xs={12}>
            <BooleanInput source="active" label="Active" />
          </Grid>
          <Grid item xs={12}>
            {isEdit ? <DisabledInput source="positionApp" label="Position App" /> : <NumberInput source="positionApp" label="Position App" defaultValue={0} />}
          </Grid>
        </Grid>
      </LanguagesNav>
    )
  }
}

export default SocialMediaProfilesForm
