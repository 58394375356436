import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'
import _ from 'lodash'
import {
  ArrayInput,
  GET_ONE,
  maxLength,
  number,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  LongTextInput,
  BooleanInput,
} from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change } from 'redux-form'

import PERMISSIONS from '../../../../config/permissions'
import { StyleInlineSimpleFormIterator } from '../../../../app/style'
import { DateSingleInput, MatchInput, RichTextInput } from '../../../../components/inputs'
import { getResource } from '../../../../config/resources'
import { dataProvider } from '../../../../providers'

export class Form extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { record } = this.props
    if (record.round) {
      dataProvider(GET_ONE, getResource('competiciones', 'rounds'), {
        id: record.round.id,
      }).then((response) => {
        let round = response.data
        this.props.changeField('tempSeason', round.subscription.season.id)
        this.props.changeField('tempCompetition', round.subscription.competition.id)
        this.props.changeField('tempSubscription', round.subscription.id)
      })
    }
  }

  canIEdit() {
    const { permissions } = this.props

    const roles = [PERMISSIONS.admin]
    return _.intersection(roles, permissions).length > 0
  }

  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          <MatchInput
            validate={[required()]}
            sourceSeason="tempSeason"
            sourceCompetition="tempCompetition"
            sourceSubscription="tempSubscription"
            sourceRound="round.id"
            sourceGameweek={false}
            sourceMatch={false}
            disabledSeason={!this.canIEdit()}
            disabledCompetition={!this.canIEdit()}
            disabledSubscription={!this.canIEdit()}
            disabledRound={!this.canIEdit()}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <DateSingleInput source="date" label="Date" disabled={!this.canIEdit()} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <NumberInput source="week" label="Week number" validate={[required(), number()]} disabled={!this.canIEdit()} />
        </Grid>
        <Grid item xs={12}>
          <TextInput source="name" label="Name" validate={[required(), maxLength(255)]} disabled={!this.canIEdit()} fullWidth />
        </Grid>

        <Grid item xs={12}>
          <TextInput source="shortname" label="Shortname" validate={[required(), maxLength(255)]} disabled={!this.canIEdit()} fullWidth />
        </Grid>

        <Grid item xs={12} md={6}>
          <BooleanInput source="highlightNote" label="Results highlight note" fullWidth />
          <LongTextInput source="note" label="Results Note" fullWidth />
        </Grid>

        <Grid item xs={12} md={6}>
          <BooleanInput source="highlightNoteStanding" label="Standing highlight note" fullWidth />
          <LongTextInput source="noteStanding" label="Standing Note" fullWidth />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <RichTextInput label="Summary" source="summary" />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subheading">Dictionary:</Typography>
          <StyleInlineSimpleFormIterator>
            <ArrayInput source="dictionaries" label="">
              <SimpleFormIterator>
                <ReferenceInput className="selectKind" label="Tipo" source="kind.id" reference={getResource('competiciones', 'kinds')}>
                  <SelectInput />
                </ReferenceInput>
                <TextInput source="data" label="Dato" />
              </SimpleFormIterator>
            </ArrayInput>
          </StyleInlineSimpleFormIterator>
        </Grid>
      </Grid>
    )
  }
}

const enhance = compose(
  connect(
    (state) => {},
    (dispatch) => ({
      changeField: (field, value) => dispatch(change('record-form', field, value)),
    })
  )
)
export default enhance(Form)
