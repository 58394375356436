import React, { Component } from 'react'
import { Field } from 'redux-form'
import SelectMarkets from './SelectMarkets'

class SelectMarketsInput extends Component {
  render() {
    return <Field component={SelectMarkets} name={this.props.name ? this.props.name : this.props.source} {...this.props} />
  }
}

export default SelectMarketsInput
