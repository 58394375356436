import React from 'react'
import { Datagrid, EditButton, Filter, List, ReferenceField, ReferenceInput, Responsive, SimpleList, TextField, TextInput } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'
import { ImageField } from '../../../components/field'
import { AutocompleteInput } from '../../../components/inputs'
import { getResource } from '../../../config/resources'

const BroadcastersFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput className={props.classes.input} variant="filled" label="Search" source="q" alwaysOn fullWidth />
      <TextInput className={props.classes.input} variant="filled" label="Nombre" source="name" alwaysOn fullWidth />
      <ReferenceInput label="Country" source="country" reference={getResource('transversal', 'countries')} alwaysOn allowEmpty>
        <AutocompleteInput inputValueMatcher={() => null} />
      </ReferenceInput>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<BroadcastersFilter />} />
}

export const BroadcastersList = (props) => {
  return (
    <List bulkActions={false} title="Operadores" {...props} actions={<Actions />}>
      <Responsive
        small={
          <SimpleList
            leftAvatar={(record) => (
              <ReferenceField
                allowEmpty
                linkType={false}
                record={record}
                basePath={getResource('assets', 'images')}
                label="Image"
                source="image"
                reference={getResource('assets', 'images')}
              >
                <ImageField mini sourceImage="" title="image channel" />
              </ReferenceField>
            )}
            primaryText={(record) => record.name}
            secondaryText={(record) => record.priority}
          />
        }
        medium={
          <Datagrid>
            <ReferenceField allowEmpty linkType={false} reference={getResource('assets', 'images')} label="Image" source="image">
              <ImageField sourceImage="" title="image channel" />
            </ReferenceField>
            <TextField source="name" label="Name" />
            <TextField source="priority" />
            <TableRowList>
              <EditButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}

export default BroadcastersList
