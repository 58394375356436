import styled, { keyframes } from 'styled-components'
import { colors } from '../../../../config/theme'

const appear = keyframes`
  from {
    opacity: .3;
  }

  to {
    opacity: 1;
  }
`

const container = `
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
  animation: ${appear} .3s;
`

export const DropzoneStyled = styled.div`
  ${container}
  pointer-events: none;
  background-color: ${colors.grey[300]};
  border: solid 2px transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border: dashed 2px ${colors.grey[400]};
`

export const FormStyled = styled.div`
  background-color: ${colors.common.white};
  animation: ${appear} 0.3s;
`

export const SeparatorStyled = styled.div`
  flex-grow: 1;
`
