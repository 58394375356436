import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import ContentCreate from '@material-ui/icons/Create'
import _ from 'lodash'
import React, { Component, Fragment } from 'react'
import { fetchEnd, fetchStart, showNotification } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { ConfirmationDialog } from '../'
import { dataProvider } from '../../providers'
import { CLONE } from '../../providers/dataProvider/types'

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: '10px',
  },
  iconSmall: {
    fontSize: 20,
  },
})

class CloneButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      isFetching: false,
    }
  }

  clone(id, onChange) {
    const { resource, showNotification, fetchStart, fetchEnd } = this.props
    fetchStart()
    this.setState({
      isFetching: true,
    })
    dataProvider(CLONE, resource, {
      id: id,
    })
      .then((response) => {
        showNotification('clone success', 'success')
        if (onChange) onChange(response.data)
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
        this.setState({
          isFetching: false,
          open: false,
        })
      })
  }

  handleSuccess(id) {
    const { onClone } = this.props
    this.clone(id, (data) => {
      if (onClone) onClone(data)
    })
  }

  handleOpen() {
    this.setState({
      open: true,
    })
  }

  handleClose() {
    this.setState({
      open: false,
    })
  }

  render() {
    const { classes, record, source } = this.props
    return (
      <Fragment>
        {this.state.open && (
          <ConfirmationDialog
            title="Clone"
            isFetching={this.state.isFetching}
            open={this.state.open}
            onSuccess={() => this.handleSuccess(_.get(record, source))}
            onCancel={() => this.handleClose()}
          />
        )}
        <Button onClick={() => this.handleOpen()} color="secondary">
          <ContentCreate className={classes.leftIcon} />
          Clone
        </Button>
      </Fragment>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({}),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
    })
  ),
  withStyles(styles)
)

export default enhance(CloneButton)
