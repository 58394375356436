import React, { Component, createContext } from 'react'

const FormActiveLanguageContext = createContext()

class FormActiveLanguageProvider extends Component {
  render() {
    const { formActiveLanguage, useAdditionalLanguages, children } = this.props

    return <FormActiveLanguageContext.Provider value={{ formActiveLanguage, useAdditionalLanguages }}>{children}</FormActiveLanguageContext.Provider>
  }
}
function withFormActiveLanguage(Component) {
  class FormActiveLanguageContainer extends React.Component {
    render() {
      const { forwardRef, ...rest } = this.props

      return (
        <FormActiveLanguageContext.Consumer>
          {(formLanguageData) => <Component {...rest} ref={forwardRef} formLanguageData={formLanguageData} />}
        </FormActiveLanguageContext.Consumer>
      )
    }
  }

  return React.forwardRef((props, ref) => {
    return <FormActiveLanguageContainer {...props} forwardRef={ref} />
  })
}
export { FormActiveLanguageContext, FormActiveLanguageProvider, withFormActiveLanguage }
