import React, { Component } from 'react'

class Logo extends Component {
  render() {
    const logoColor = {
      color1: '#7F2F7C',
      color2: '#315192',
      color3: '#4578AF',
      color4: '#1BA850',
      color5: '#F9E43C',
      color6: '#E67734',
      color7: '#D9362F',
      text: '#1A1919',
    }
    const logoLight = {
      color1: '#FFFFFF',
      color2: '#FFFFFF',
      color3: '#FFFFFF',
      color4: '#FFFFFF',
      color5: '#FFFFFF',
      color6: '#FFFFFF',
      color7: '#FFFFFF',
      text: '#FFFFFF',
    }

    let colors = {}
    switch (this.props.type) {
      case 'light':
        colors = logoLight
        break
      default:
        colors = logoColor
    }
    return (
      <svg style={this.props.style} width="80" height="19" viewBox="0 0 80 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_440_22)">
          <path d="M0 14.7302V0.206055H3.99799V15.0747H11.0607V18.7924H2.31876L0.745938 16.8546C0.212796 16.1645 0 15.5275 0 14.7302Z" fill="white" />
          <path
            d="M13.2206 15.7659L17.5389 0.206055H22.1233C22.9768 0.206055 23.5088 0.604675 23.7493 1.45374L27.7207 15.7648C27.9069 16.4019 27.9335 16.827 27.9335 17.6496V18.7913H24.3888L22.9756 13.1357L17.2452 16.0562L16.5525 18.7913H13.0078V17.6496C13.0078 16.827 13.0344 16.4019 13.2206 15.7648V15.7659ZM22.7894 12.4203L20.6834 3.92383H20.2567L18.1507 12.4203H22.7894Z"
            fill="white"
          />
          <path
            d="M29.7461 14.7302V0.206055H33.7441V15.0747H40.8079V18.7924H32.066L30.4932 16.8546C29.9601 16.1645 29.7473 15.5275 29.7473 14.7302H29.7461Z"
            fill="white"
          />
          <path d="M43.0723 0.20752H47.0703V18.7939H43.0723V0.20752Z" fill="white" />
          <path
            d="M49.7891 12.8929V6.1071C49.7891 2.1509 51.9483 0 55.9197 0H57.6695C61.5877 0 63.8 1.9919 63.8 5.523H59.802V4.78C59.802 4.0899 59.4285 3.7177 58.7358 3.7177H54.8534C54.1607 3.7177 53.7871 4.0899 53.7871 4.78V14.2212C53.7871 14.9113 54.1607 15.2834 54.8534 15.2834H58.7358C59.4285 15.2834 59.802 14.9113 59.802 14.2212V11.8848H57.0299V8.167H61.4813L63.0541 10.1048C63.5872 10.7684 63.8 11.379 63.8 12.2293V12.8929C63.8 16.8491 61.6409 19 57.6695 19H55.9197C51.9483 19 49.7891 16.8491 49.7891 12.8929Z"
            fill="white"
          />
          <path
            d="M65.2186 15.7659L69.537 0.206055H74.1213C74.9748 0.206055 75.5068 0.604675 75.7473 1.45374L79.7187 15.7648C79.9049 16.4019 79.9315 16.827 79.9315 17.6496V18.7913H76.3869L74.9736 13.1357L69.2432 16.0562L68.5505 18.7913H65.0059V17.6496C65.0059 16.827 65.0325 16.4019 65.2186 15.7648V15.7659ZM74.7874 12.4203L72.6815 3.92383H72.2547L70.1488 12.4203H74.7874Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_440_22">
            <rect width="80" height="19" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  }
}

export default Logo
