import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import _ from 'lodash'
import React, { Component } from 'react'
import { fetchEnd, fetchStart, GET_ONE, showNotification } from 'react-admin'
import { connect } from 'react-redux'
import { change, getFormValues } from 'redux-form'
import compose from 'recompose/compose'

import { ConfirmationDialog, SubForm } from '../../../../../components'
import { ImageInput } from '../../../../../components/inputs'
import { getResource } from '../../../../../config/resources'
import dataProvider from '../../../../../providers'
import ImageForm from '../../../../../resources/assets/images/form/form'
import {
  FullContainerStyled,
  LeftContainerStyled,
  PluginContainerStyled,
  PrimaryTextStyled,
  RightContainerStyled,
  SecondaryTextStyled,
  TitleStyled,
} from './style'

const styles = (theme) => ({
  description: {
    backgroundColor: 'white',
  },
  chips: {
    margin: '4px',
    marginLeft: '0px',
  },
})

class ImagePlugin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pluginAnswere: null,
      prominent: false,
      zoom: false,
      modal: false,
    }
  }

  componentDidMount() {
    const { value, options } = this.props
    if (value && value.content) {
      const isReference = options && options.reference
      const asset = isReference ? value.content : value.content.id
      this.getImage(asset, (data) => {
        this.setState({
          pluginAnswere: data,
        })
      })
    }
    if (value && value.zoom) {
      this.setState({
        zoom: value.zoom,
      })
    }
    if (value && options.boolProminent) {
      this.setState({
        prominent: value.prominent,
      })
    }
  }

  handleChange(pluginAnswere, zoom, prominent) {
    const isReference = this.props.options && this.props.options.reference
    const boolProminent = this.props.options && this.props.options.boolProminent ? true : false

    this.setState({
      pluginAnswere: pluginAnswere,
      prominent: prominent,
      zoom: zoom,
    })
    let content = pluginAnswere
    if (isReference || this.props.input) {
      content = pluginAnswere.id
    }

    let valuesOnChange = {
      content: content,
      zoom: zoom,
    }
    if (boolProminent) {
      valuesOnChange['prominent'] = prominent
    }

    this.props.onChange(valuesOnChange)
  }

  getImage(value, func) {
    return dataProvider(GET_ONE, getResource('assets', 'images'), {
      id: value,
    }).then((response) => {
      func(response.data)
    })
  }

  handleChangeZoom(zoom) {
    const { pluginAnswere, prominent } = this.state
    this.handleChange(pluginAnswere, zoom, prominent)
  }

  handleChangeImage(content) {
    const { prominent, zoom } = this.state
    this.handleChange(content, zoom, prominent)
  }

  handleChangeProminent(checked) {
    const { pluginAnswere, zoom } = this.state

    this.handleChange(pluginAnswere, zoom, checked)
  }

  isDisabledProminent() {
    const { id, meta, field, source } = this.props
    const block = field(meta.form, source)
    let disabled = false
    for (const each of block) {
      if (each.plugin === 'imagePlugin' && each.prominent && each.id !== id) {
        disabled = true
        break
      }
    }
    return disabled
  }

  render() {
    const options = {
      type: 'images',
      ...this.props.options,
    }

    const valueName = this.state.pluginAnswere ? this.state.pluginAnswere.name : ''
    const valueDescription = this.state.pluginAnswere ? this.state.pluginAnswere.caption : ''

    return (
      <PluginContainerStyled>
        <LeftContainerStyled>
          <ImageInput label="Image" options={options} choose value={this.state.pluginAnswere} onChange={(Image) => this.handleChangeImage(Image)} fullWidth />
        </LeftContainerStyled>
        <ConfirmationDialog title="Edit image" open={this.state.modal} maxWidth="md" dialogActions={false} onCancel={() => this.setState({ modal: false })}>
          <SubForm
            submitOnEnter={false}
            resource={getResource('assets', 'images')}
            record={this.state.pluginAnswere}
            onClose={() => this.setState({ modal: false })}
            form="asset-form"
            onSubmit={(data) => {
              this.setState({
                modal: false,
                pluginAnswere: data,
              })
            }}
          >
            <ImageForm />
          </SubForm>
        </ConfirmationDialog>
        <RightContainerStyled>
          <FormGroup row>
            <FormControlLabel
              control={<Switch checked={this.state.zoom} onChange={(event) => this.handleChangeZoom(event.target.checked)} color="primary" value={true} />}
              label="Zoom"
            />
          </FormGroup>
          {options.boolProminent && (
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.prominent}
                    onChange={(event) => this.handleChangeProminent(event.target.checked)}
                    color="primary"
                    value={true}
                    disabled={this.isDisabledProminent()}
                  />
                }
                label="Prominent"
              />
            </FormGroup>
          )}
          <PrimaryTextStyled>
            <TitleStyled>{valueName}</TitleStyled>
            {this.state.pluginAnswere && (
              <IconButton onClick={() => this.setState({ modal: true })}>
                <EditIcon />
              </IconButton>
            )}
          </PrimaryTextStyled>
          <SecondaryTextStyled>{valueDescription}</SecondaryTextStyled>
        </RightContainerStyled>
      </PluginContainerStyled>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      formData: (form) => getFormValues(form)(state),
      field: (form, source) => {
        return _.get(getFormValues(form)(state), source)
      },
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(ImagePlugin)
