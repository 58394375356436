import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import React from 'react'
import _ from 'lodash'
import { GET_LIST } from 'react-admin'

import { getResource } from '../../../config/resources'
import { dataProvider } from '../../../providers'

import { ScriptInput } from '../'
import { ConfirmationDialog } from '../..'
import { SubForm } from './../../'
import { DndItemStyled } from './style'
import { BannerForm, HtmlForm, DigitalAssetForm, QueryParams } from './modulesConfig'

const validateModule = (values) => {
  const errors = {}
  _.map(_.get(values, 'config.queryParams'), (queryParam, index) => {
    if (_.get(queryParam, 'name') === 'matchSlug') {
      if (queryParam.active && !queryParam.season) _.set(errors, `config.queryParams[${index}].season`, 'season required')
      if (queryParam.active && !queryParam.competition) _.set(errors, `config.queryParams[${index}].competition`, 'competition required')
      if (queryParam.active && !queryParam.subscription) _.set(errors, `config.queryParams[${index}].subscription`, 'subscription required')
      if (queryParam.active && !queryParam.round) _.set(errors, `config.queryParams[${index}].round`, 'round required')
      if (queryParam.active && !queryParam.gameweek) _.set(errors, `config.queryParams[${index}].gameweek`, 'gameweek required')
      if (queryParam.active && !queryParam.value) _.set(errors, `config.queryParams[${index}].match`, 'match required')
    }
  })

  return errors
}

class ModuleItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dialog: false,
      anchorEl: null,
    }
  }

  onEdit = (data) => {
    const { onChange } = this.props
    const queryParams = _.map(_.get(data.config, 'queryParams'), (param) => {
      if (_.get(param, 'value')) return param
    })
    const newData = { ...data }
    _.set(newData, 'config.queryParams', queryParams)
    this.setState({
      dialog: false,
      data: newData,
    })
    onChange(newData)
  }

  isEditableModule(module) {
    return module.config && module.config.length
  }

  getModule() {
    const { value } = this.props
    dataProvider(GET_LIST, getResource('layouts', 'modules'), {
      sort: {
        field: 'name',
        order: 'ASC',
      },
      pagination: {},
      filter: { slug: value.module },
    }).then((response) => {
      this.setState({
        data: {
          ...value,
          queryParams: response.data[0].queryParams,
        },
      })
    })
  }

  render() {
    const { dnDprops, value, onRemove } = this.props
    const open = Boolean(this.state.anchorEl)

    const HtmlForm = () => {
      return (
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <ScriptInput source="config.html" label="Code" />
          </Grid>
        </Grid>
      )
    }

    return (
      <React.Fragment>
        <DndItemStyled>
          <ListItem>
            <div {...dnDprops.dragHandleProps}>
              <ListItemIcon>
                <DragHandleIcon />
              </ListItemIcon>
            </div>
            <ListItemText primary={value.name} secondary={value.module} />
            <ListItemSecondaryAction>
              <div>
                <IconButton aria-label="More" onClick={(event) => this.setState({ anchorEl: event.currentTarget })}>
                  <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={this.state.anchorEl} open={open} onClose={() => this.setState({ anchorEl: null })}>
                  <MenuItem
                    onClick={() => {
                      this.setState(
                        {
                          dialog: true,
                          anchorEl: null,
                          config: value.config,
                        },
                        () => {
                          this.getModule()
                        }
                      )
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem onClick={() => onRemove()}>Delete</MenuItem>
                </Menu>
              </div>
            </ListItemSecondaryAction>
          </ListItem>
        </DndItemStyled>
        <ConfirmationDialog
          open={this.state.dialog}
          onCancel={() => this.setState({ dialog: false })}
          title={`Edit ${value.name}`}
          dialogActions={false}
          maxWidth="md"
          isFetching={!this.state.data}
        >
          <SubForm
            validate={validateModule}
            submitOnEnter={false}
            record={this.state.data}
            onClose={() => this.setState({ dialog: false })}
            form="modalConfig-form"
            onSubmit={(data) => this.onEdit(data)}
          >
            <React.Fragment>
              {value.module === 'modulo-banner' && <BannerForm />}
              {value.module === 'modulo-html' && <HtmlForm />}
              {value.module === 'digital-asset' && <DigitalAssetForm />}
              <QueryParams record={value} />
            </React.Fragment>
          </SubForm>
        </ConfirmationDialog>
      </React.Fragment>
    )
  }
}

export default ModuleItem
