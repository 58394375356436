import Grid from '@material-ui/core/Grid'
import React from 'react'
import { DisabledInput, maxLength, NumberInput, required, TextInput, FormDataConsumer, BooleanInput } from 'react-admin'

import { ImageInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'

export const ChannelsForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12} sm={12}>
        <DisabledInput fullWidth label="Slug" source="slug" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ImageInput
          source="image"
          label="Image"
          accept="image/*"
          options={{
            remove: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ImageInput
          source="imageSecondary"
          label="Image Secondary"
          accept="image/*"
          options={{
            remove: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput fullWidth label="Nombre" source="name" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput fullWidth label="Priority" source="priority" validate={[maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <TextInput fullWidth label="Web" source="web" validate={[maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BooleanInput label="Local" source="local" />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (!formData) return null
            if (formData.local) {
              return <TextInput fullWidth label="Web" source="webLocal" validate={[maxLength(255)]} />
            }
          }}
        </FormDataConsumer>
      </Grid>
      <Grid item xs={12} sm={6}>
        <BooleanInput label="Residential LALIGA EA SPORTS" source="residential" />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (!formData) return null
            if (formData.residential) {
              return <TextInput fullWidth label="Web Residential LALIGA EA SPORTS" source="webResidential" validate={[maxLength(255)]} />
            }
          }}
        </FormDataConsumer>
      </Grid>
      <Grid item xs={12} sm={6}>
        <BooleanInput label="Residential LALIGA HYPERMOTION" source="residentialSecondary" />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (!formData) return null
            if (formData.residentialSecondary) {
              return <TextInput fullWidth label="Web Residential LALIGA HYPERMOTION" source="webResidentialSecondary" validate={[maxLength(255)]} />
            }
          }}
        </FormDataConsumer>
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default ChannelsForm
