import Button from '@material-ui/core/Button'
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import _ from 'lodash'
import React, { Component } from 'react'
import { DELETE, GET_LIST, ReferenceField, Pagination } from 'react-admin'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import { ConfirmationDialog } from '../../../../../components'
import { getResource } from '../../../../../config/resources'
import dataProvider from '../../../../../providers'
import AddAsset from './AddAsset'
import EditAsset from './editAsset'
import { PlaceholderStyled } from './styles'

const styles = (theme) => ({
  image: {
    maxWidth: '100%',
    width: 'auto',
  },
  cellButton: {
    textAlign: 'right',
  },
  button: {
    width: '100%',
    marginBottom: '5px',
  },
})

const Placeholder = () => (
  <PlaceholderStyled>
    <CircularProgress />
  </PlaceholderStyled>
)

class BMAssetInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bmAssets: [],
      bmAssetsPage: 1,
      bmAssetsPerPage: 10,
      bmAssetsTotal: 0,
      dialogOpen: false,
      idDeleteBMAsset: null,
      modeAdd: false,
      modeEdit: false,
      AssetsFetching: false,
    }
  }

  componentDidMount() {
    const { bmAssetsPerPage } = this.state
    const { idBrandMarket, slugBMAGKind } = this.props

    this.getBMAssets(idBrandMarket, slugBMAGKind, 1, bmAssetsPerPage)
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { bmAssetsPerPage } = this.state
    const { props } = this

    if (nextProps.idBrandMarket !== props.idBrandMarket) {
      this.getBMAssets(nextProps.idBrandMarket, nextProps.slugBMAGKind, 1, bmAssetsPerPage)
    }

    if (nextProps.slugBMAGKind !== props.slugBMAGKind) {
      this.getBMAssets(nextProps.idBrandMarket, nextProps.slugBMAGKind, 1, bmAssetsPerPage)
    }

    return true
  }

  getBMAssets(idBrandMarket, slugBMAGKind, page, perPage) {
    this.setState({
      AssetsFetching: true,
      bmAssets: null,
      modeAdd: false,
    })
    dataProvider(GET_LIST, getResource('digitalAssets', 'brand-market-assets'), {
      sort: {
        field: 'createdAt',
        order: 'DESC',
      },
      pagination: {
        page: page,
        perPage: perPage,
      },
      filter: {
        brandMarket: idBrandMarket,
        bMAGKind: slugBMAGKind,
      },
    })
      .then(({ data, total }) => {
        this.setState({
          bmAssets: data,
          bmAssetsPage: page,
          bmAssetsPerPage: perPage,
          bmAssetsTotal: total,
        })
      })
      .catch((error) => {})
      .finally(() => {
        this.setState({
          AssetsFetching: false,
        })
      })
  }

  ImageAsset = (props) => {
    const image = props.record

    return (
      <CardMedia
        component="img"
        alt={image.name ? image.name : ''}
        style={{
          width: 'auto',
          height: 'auto',
          maxWidth: '200px',
        }}
        image={image.asset.fileDir}
        title={image.name ? image.name : ''}
        className={props.className}
      />
    )
  }

  confirmDeleteBMAsset(idBMAsset) {
    this.setState({
      dialogOpen: true,
      idDeleteBMAsset: idBMAsset,
    })
  }

  editBMAsset(BMAsset) {
    this.setState({
      modeEdit: BMAsset,
    })
  }

  async DeleteBMAsset() {
    const { idDeleteBMAsset, bmAssets } = this.state
    this.setState({
      isDeleteBMAssetFetching: true,
    })

    return dataProvider(DELETE, getResource('digitalAssets', 'brand-market-assets'), {
      id: idDeleteBMAsset,
    })
      .then((response) => {
        let index = _.findIndex(bmAssets, ['id', idDeleteBMAsset])
        let newBMAssets = bmAssets.slice()

        newBMAssets.splice(index, 1)
        this.setState({
          bmAssets: newBMAssets,
          dialogOpen: false,
          idDeleteBMAsset: null,
        })
      })
      .finally(() => {
        this.setState({
          isDeleteBMAssetFetching: false,
        })
      })
  }

  onCreateBMAsset() {
    const { bmAssetsPerPage } = this.state
    const { idBrandMarket, slugBMAGKind } = this.props

    this.setState({
      modeAdd: false,
      modeEdit: false,
    })
    this.getBMAssets(idBrandMarket, slugBMAGKind, 1, bmAssetsPerPage)
  }

  translateLanguage(language) {
    let translations = {
      es: 'Spanish',
      en: 'English',
      nolanguage: 'No Language',
    }

    return language in translations ? translations[language] : '-'
  }

  render() {
    const { idBrandMarket, slugBMAGKind, classes } = this.props
    const { bmAssets, bmAssetsPage, bmAssetsPerPage, bmAssetsTotal, modeAdd, AssetsFetching } = this.state
    const { ImageAsset } = this

    if (AssetsFetching) return <Placeholder />
    return (
      <Grid container>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Banner</TableCell>
                <TableCell>Asset Group</TableCell>
                <TableCell>Language</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bmAssets &&
                bmAssets.map((item, key) => (
                  <TableRow key={key}>
                    <TableCell className={classes.tdImage}>
                      <ReferenceField
                        linkType={false}
                        label="Type"
                        source={`asset`}
                        reference={getResource('assets', 'images')}
                        record={item}
                        basePath={getResource('assets', 'images')}
                        className={classes.image}
                      >
                        <ImageAsset />
                      </ReferenceField>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        <strong>{_.lowerCase(item.bMAssetGroup.section)}</strong>
                      </Typography>
                      <Typography variant="body1">{item.bMAssetGroup.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{this.translateLanguage(item.language)}</Typography>
                    </TableCell>
                    <TableCell className={classes.cellButton}>
                      <Button
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        size="small"
                        aria-label="Edit"
                        onClick={() => this.editBMAsset(item)}
                      >
                        <EditIcon /> Edit
                      </Button>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        size="small"
                        aria-label="Delete"
                        onClick={() => this.confirmDeleteBMAsset(item.id)}
                      >
                        <DeleteIcon />
                        DELETE
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Pagination
            setPage={(page) => this.getBMAssets(idBrandMarket, slugBMAGKind, page, bmAssetsPerPage)}
            setPerPage={(perPage) => this.getBMAssets(idBrandMarket, slugBMAGKind, 1, perPage)}
            total={bmAssetsTotal}
            perPage={bmAssetsPerPage}
            page={bmAssetsPage}
          />
        </Grid>
        <Grid item xs={12} align="right">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.setState({ modeAdd: true })
            }}
          >
            Add
          </Button>
        </Grid>
        <ConfirmationDialog
          open={this.state.dialogOpen}
          title="Are you sure?"
          isFetching={this.state.isDeleteBMAssetFetching}
          onCancel={() => {
            this.setState({
              dialogOpen: false,
              idDeleteBMAsset: null,
            })
          }}
          onSuccess={() => {
            this.DeleteBMAsset()
          }}
        />
        <AddAsset
          idBrandMarket={idBrandMarket}
          slugBMAGKind={slugBMAGKind}
          open={modeAdd}
          onCancel={() => this.setState({ modeAdd: false })}
          onCreate={() => this.onCreateBMAsset()}
        />
        <EditAsset
          id={this.state.modeEdit.id}
          open={this.state.modeEdit}
          record={this.state.modeEdit}
          idBrandMarket={idBrandMarket}
          onCancel={() => this.setState({ modeEdit: false })}
          onCreate={() => this.onCreateBMAsset()}
        />
      </Grid>
    )
  }
}

export default withStyles(styles)(BMAssetInput)
