import React from 'react'
import ListFaqs from './ListFaqs'

class FaqsPlugin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pluginAnswere: {
        content: null,
      },
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const { value } = this.props
    if (value) {
      let pluginAnswere = {
        content: value.content ? value.content : this.state.pluginAnswere.content,
      }

      this.setState({
        pluginAnswere: pluginAnswere,
      })
    }
  }

  handleChange(key, value) {
    const { onChange } = this.props

    let pluginAnswere = { ...this.state.pluginAnswere }
    pluginAnswere[key] = value
    this.setState({ pluginAnswere })
    onChange(pluginAnswere)
  }

  render() {
    const { content } = this.state.pluginAnswere
    return <ListFaqs content={content} onChange={(value) => this.handleChange('content', value)} />
  }
}

export default FaqsPlugin
