import React from 'react'
import { required, TextInput, maxLength, BooleanInput } from 'react-admin'
import { Grid, List, ListItem, ListItemText, Collapse } from '@material-ui/core'
import { ImageInput } from '../../../../../../../components/inputs'
import { states } from '../../../states'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

const publishedOrProgrammed = (status) => (value) => {
  return !value && (status === states.programmed || status === states.published) ? 'Requiered' : undefined
}

class HeaderContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  handleClick = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    const { formData } = this.props

    return (
      <>
        <ListItem button onClick={this.handleClick}>
          <ListItemText primary="Header Content" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List>
            <ListItem>
              <Grid container>
                <Grid item xs={12}>
                  <TextInput fullWidth source="headerContent" label="Header Content" validate={[required(), maxLength(255)]} />
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container>
                <Grid item xs={6}>
                  <ImageInput fullWidth label="Image Header Desktop" source="headerImageDesktop" validate={[publishedOrProgrammed(formData.status)]} />
                </Grid>
                <Grid item xs={6}>
                  <ImageInput fullWidth label="Image Header Mobile" source="headerImageMobile" validate={[publishedOrProgrammed(formData.status)]} />
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container>
                <Grid item xs={12}>
                  <BooleanInput label="Dark Mode" source="darkMode" />
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Collapse>
      </>
    )
  }
}

export default HeaderContent
