export const isUrl = (value) =>
  value && value.match(RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi)) ? undefined : 'You must enter a url'
export const isAllowedDomain = (allowedDomains) => (value) => {
  let isValid = false
  if (allowedDomains && Array.isArray(allowedDomains)) {
    allowedDomains.forEach((domain) => {
      if (value.indexOf(domain.domain) !== -1) {
        isValid = true
      }
    })
  }
  return !isValid ? 'Is not allowed domain' : undefined
}
