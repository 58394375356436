import React from 'react'
import { Edit } from 'react-admin'

import { CountriesForm } from './form'

export const BroadcastersEdit = (props) => (
  <Edit undoable={false} {...props}>
    <CountriesForm {...props} />
  </Edit>
)

export default BroadcastersEdit
