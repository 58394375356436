const languages = [
  {
    title: 'Spanish',
    slug: 'es',
    dir: 'ltr',
  },
  {
    title: 'English',
    slug: 'en',
    dir: 'ltr',
  },
]

export const additionalLanguages = [
  ...languages,
  {
    title: 'French',
    slug: 'fr',
    dir: 'ltr',
  },
  {
    title: 'Arabic',
    slug: 'ar',
    dir: 'rtl',
  },
  {
    title: 'Japanese',
    slug: 'jp',
    dir: 'ltr',
  },
  {
    title: 'Hebrew',
    slug: 'he',
    dir: 'rtl',
  },
]

export default languages
