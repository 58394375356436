import React, { Component } from 'react'
import { connect } from 'react-redux'
import { userLogout as userLogoutAction } from 'react-admin'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import compose from 'recompose/compose'

class LogoutButton extends Component {
  constructor(props) {
    super(props)
    this.theme = createMuiTheme(props.theme)
  }

  render() {
    const { userLogout } = this.props

    return (
      <MuiThemeProvider theme={this.theme}>
        <Button onClick={userLogout}>Logout</Button>
      </MuiThemeProvider>
    )
  }
}

const mapDispatchToProps = (dispatch, { redirectTo }) => ({
  userLogout: () => dispatch(userLogoutAction(redirectTo)),
})

const enhance = compose(connect(undefined, mapDispatchToProps))

const EnhancedLogout = enhance(LogoutButton)

export default EnhancedLogout
