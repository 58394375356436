import React, { Component } from 'react'
import { Field } from 'redux-form'
import BooleanInput from './BooleanInput'

class BooleanFilterInput extends Component {
  render() {
    const { source, ...rest } = this.props
    return <Field component={BooleanInput} name={source} {...rest} />
  }
}

export default BooleanFilterInput
