import React from 'react'
import { Edit } from 'react-admin'
import InternationalTimetableForm from './form'

export const InternationalTimetableEdit = (props) => (
  <Edit undoable={false} {...props}>
    <InternationalTimetableForm {...props} />
  </Edit>
)

export default InternationalTimetableEdit
