import Grid from '@material-ui/core/Grid'
import React from 'react'
import { CloneButton, Datagrid, EditButton, Filter, FunctionField, List, Responsive, SimpleList, TextInput } from 'react-admin'
import { Link } from 'react-router-dom'

import { ListActions, TableRowList } from '../../../components'
import { ImageField, DateField } from '../../../components/field'

const EventFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={6}>
          <TextInput source="q" label="Search" alwaysOn fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput source="name" label="Name" alwaysOn fullWidth />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<EventFilter />} />
}

export const EventList = (props) => {
  return (
    <List bulkActions={false} actions={<Actions />} title="Images" {...props}>
      <Responsive
        small={
          <SimpleList
            leftAvatar={(record) => <ImageField sourceImage="image" record={record} label="Image" title="Image" />}
            primaryText={(record) => record.name}
            secondaryText={(record) => <DateField label="Date" source="publishedAt" showTime record={record} />}
          />
        }
        medium={
          <Datagrid>
            <ImageField sourceImage="image" label="Image" />
            <DateField label="Date" source="publishedAt" showTime />
            <FunctionField source="name" label="Name" render={(record) => <Link to={`${props.basePath}/${record.id}`}>{record.name}</Link>} />
            <DateField label="Date" source="publishedAt" showTime />
            <TableRowList>
              <EditButton />
              <CloneButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}
export default EventList
