import styled from 'styled-components'
import { colors } from '../../../config/theme'

export const ImageFieldStyled = styled.div`
  position: relative;
  display: inline-block;
  margin: 0px;
  line-height: 0px;
  padding-left: ${(props) => (props.mini || !props.stateBorder ? '0px' : '24px')};
  width: ${(props) => (props.mini ? '100%' : 'auto')};
  height: ${(props) => (props.height ? props.height : props.mini ? '100%' : 'auto')};
  & > img {
    width: ${(props) => (props.mini ? '100%' : '120px')};
    height: ${(props) => (props.mini ? '100%' : '88px')};
    object-fit: ${(props) => (props.fit ? props.fit : 'cover')};
  }
  &:before {
    display: block;
    content: '';
    left: 0px;
    top: 0px;
    position: absolute;
    border: solid 1px transparent;
    border-color: ${(props) => props.stateColorBorder};
    background-color: ${(props) => props.stateColor};
    width: 24px;
    height: 100%;
    box-sizing: border-box;
  }
`
export const InfoStyled = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: ${colors.common.black};
  color: ${colors.common.white};
  padding: 8px 10px;
  font-size: 11px;
  line-height: 13px;
`

export const PlaceholderImgStyled = styled.div`
  width: ${(props) => (props.mini ? '100%' : '120px')};
  height: ${(props) => (props.mini ? '100%' : '88px')};
  background-color: ${colors.grey[400]};
`
