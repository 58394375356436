import React from 'react'
import { FormDataConsumer } from 'react-admin'
import { LanguagesNav } from '../../../../../components'

class EditShortenedUrlsForm extends React.Component {
  render() {
    return (
      <LanguagesNav remove useAdditionalLanguages {...this.props}>
        {({ dir }) => <FormDataConsumer>{({ formData, dispatch, ...rest }) => formData && <div>edit shortned urls form</div>}</FormDataConsumer>}
      </LanguagesNav>
    )
  }
}

export default EditShortenedUrlsForm
