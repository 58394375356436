import React from 'react'
import { Edit } from 'react-admin'

import CategoriesForm from './form'

export const CategoriesEdit = (props) => (
  <Edit undoable={false} {...props}>
    <CategoriesForm isEdit={true} {...props} />
  </Edit>
)

export default CategoriesEdit
