import Grid from '@material-ui/core/Grid'
import React from 'react'
import { ReferenceInput, required, SelectInput } from 'react-admin'

import { DateTimeInput, AutocompleteInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'
import { getResource } from '../../../../config/resources'

export const SponsoringsForm = ({ ...props }) => (
  <LanguagesNav remove {...props}>
    <Grid container>
      <Grid item xs={12}>
        <ReferenceInput
          label="Brand"
          source="brand.id"
          reference={getResource('digitalAssets', 'brands')}
          validate={[required()]}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteInput />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <ReferenceInput
          label="Digital Asset"
          source="digitalAsset.id"
          reference={getResource('digitalAssets', 'digital-assets')}
          validate={[required()]}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} md={6}>
        <DateTimeInput label="Start" source="startedAt" date time />
      </Grid>
      <Grid item xs={12} md={6}>
        <DateTimeInput label="End" source="endAt" date time />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default SponsoringsForm
