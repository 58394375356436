import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import React, { Component } from 'react'
import {
  ArrayInput,
  BooleanInput,
  DisabledInput,
  maxLength,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin'

import { StyleInlineSimpleFormIterator } from '../../../../app/style'
import { AutocompleteInput, DateSingleInput, ImageInput, RichTextInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'
import { getResource } from '../../../../config/resources'
import CareersInput from './careersInput'
import ModalDictionaries from './modalDictionaries'

const styles = (theme) => {
  return {
    button: {
      padding: '5px 20px',
    },
  }
}

const ActionsPersonRole = ({ source, classes, ...rest }) => {
  return (
    <Grid container direction="column">
      <Grid item className={classes.button}>
        <ModalDictionaries baseSource={source} {...rest} />
      </Grid>
      <Grid item className={classes.button}>
        <CareersInput source={`${source}.id`} {...rest} />
      </Grid>
    </Grid>
  )
}

export const RolesForm = ({ isEdit, ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12}>
        <TextInput fullWidth source="name" label="Name" validate={[required(), maxLength(255)]} />
        <TextInput fullWidth source="firstname" label="Firstname" validate={[required(), maxLength(255)]} />
        <TextInput fullWidth source="lastname" label="Lastname" validate={[maxLength(255)]} />
        <DisabledInput fullWidth source="slug" label="Slug" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BooleanInput source="international" label="International" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectInput
          fullWidth
          source="gender"
          label="Gender"
          choices={[
            { id: 'male', name: 'male' },
            { id: 'female', name: 'female' },
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReferenceInput label="Nationality" source="country" reference={getResource('transversal', 'nationalities')}>
          <AutocompleteInput
            options={{
              fullWidth: true,
            }}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={4}>
        <DateSingleInput block source="dateOfBirth" label="Date Of Birth" rangeStartDate={moment('1920-01-01')} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput fullWidth source="placeOfBirth" label="Place Of Birth" validate={[maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput fullWidth source="height" label="Height" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput fullWidth source="weight" label="Weight" />
      </Grid>
      <Grid item xs={12}>
        <ImageInput source="image" label="Image" accept="image/*" />
      </Grid>
      <Grid item xs={12}>
        <RichTextInput fullWidth source="description" label="Description" />
      </Grid>
      <Grid item xs={12}>
        <TextInput fullWidth source="web" label="Web" validate={[maxLength(255)]} />
        <TextInput fullWidth source="twitter" label="Twitter" validate={[maxLength(255)]} />
        <TextInput fullWidth source="facebook" label="Facebook" validate={[maxLength(255)]} />
        <TextInput fullWidth source="instagram" label="Instagram" validate={[maxLength(255)]} />
        <TextInput fullWidth source="twitch" label="Twitch" validate={[maxLength(255)]} />
      </Grid>

      <Grid item xs={12}>
        <StyleInlineSimpleFormIterator>
          <ArrayInput source="personRoles" label="Person Roles">
            <SimpleFormIterator>
              <BooleanInput source="active" label="Active" />
              <ReferenceInput source="role.id" label="Role" reference={getResource('competiciones', 'roles')}>
                <SelectInput />
              </ReferenceInput>
              <ActionsPersonRole classes={props.classes} />
            </SimpleFormIterator>
          </ArrayInput>
        </StyleInlineSimpleFormIterator>
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default withStyles(styles)(RolesForm)
