import styled from 'styled-components'
import { colors } from '../../../../../config/theme'

export const VideoProviderContainerStyled = styled.div`
  width: 100%;
  padding: 20px;
  background-color: ${colors.common.white};
  box-sizing: border-box;
`

export const NotificationStyled = styled.div`
  width: 100%;
  padding: 20px;
  background-color: ${colors.states.warning};
  color: ${colors.common.white};
  box-sizing: border-box;
`
