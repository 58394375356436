import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import React, { Component } from 'react'

import { AddModuleStyled, InfoStyled } from './style'

class AddPlugin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
    }
  }

  handleClick(event) {
    if (Object.keys(this.props.plugins).length === 1) {
      this.handleClickMenuItem(Object.keys(this.props.plugins)[0])
    } else {
      this.setState({ anchorEl: event.currentTarget })
    }
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleClickMenuItem(item) {
    this.props.onClick(item)
    this.handleClose()
  }

  renderPluginList() {
    const { plugins, disabled } = this.props
    if (Object.keys(plugins).length > 1) {
      const pluginList = Object.keys(plugins).map((item) => {
        return (
          <MenuItem key={plugins[item].options.name} disabled={disabled} onClick={() => this.handleClickMenuItem(item)}>
            {plugins[item].options.name}
          </MenuItem>
        )
      })

      return (
        <div>
          <Menu id="simple-menu" anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)} onClose={this.handleClose}>
            {pluginList}
          </Menu>
        </div>
      )
    }
    return null
  }

  render() {
    const { info } = this.props
    return (
      <React.Fragment>
        <AddModuleStyled onClick={(value) => this.handleClick(value)}>
          <AddCircleIcon color="action" />
          {info && <InfoStyled>{info}</InfoStyled>}
        </AddModuleStyled>
        {this.renderPluginList()}
      </React.Fragment>
    )
  }
}

export default AddPlugin
