import React from 'react'
import { Edit } from 'react-admin'

import EditShortenedUrlsForm from './form'

const ShortenedUrlsTitle = ({ record }) => {
  return <span>{`URL: ${record.nombre}`}</span>
}

export const ShortenedUrlsEdit = (props) => (
  <Edit undoable={false} title={<ShortenedUrlsTitle />} {...props}>
    <EditShortenedUrlsForm isEdit={true} {...props} />
  </Edit>
)

export default ShortenedUrlsEdit
