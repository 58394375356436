import { Grid } from '@material-ui/core'
import React from 'react'
import { BooleanInput, maxLength, required, TextInput } from 'react-admin'

import { LanguagesNav } from '../../../../components'
import GameweekSelector from './GameweekSelector'

export const PageForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12}>
        <BooleanInput label="Active" source="active" />
      </Grid>
    </Grid>
    <GameweekSelector {...props} />
    <Grid container>
      <Grid item xs={12}>
        <TextInput fullWidth source="winner" label="Winner" validate={[required(), maxLength(255)]} />
      </Grid>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((position) => (
        <Grid item xs={12} md={6} key={position}>
          <TextInput fullWidth source={`secondaryWinners[${position}]`} label={`Secondary Winner (${position + 1})`} validate={[maxLength(255)]} />
        </Grid>
      ))}
    </Grid>
  </LanguagesNav>
)

export default PageForm
