import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import React, { Component } from 'react'
import { BooleanInput, DisabledInput, FormDataConsumer, LongTextInput, maxLength, REDUX_FORM_NAME, required, SelectInput, TextInput } from 'react-admin'
import { change } from 'redux-form'

import { ConfirmationDialog, SubForm } from '../../../../components'
import { ClipboardField } from '../../../../components/formFields'
import { CategoryInput, ClientUuidsInput, ImageInput, LanguageInput, MarketsInput, MetadataInput } from '../../../../components/inputs'
import { checkboxRequired } from '../../../../components/inputs/clientUuidsInput/validate'
import LanguagesNav from '../../../../components/languagesNav'
import { getResource } from '../../../../config/resources'
import ImageForm from '../../../assets/images/form/form'
import { slugifyFiler } from './../../../../config/utils'
import MagicGallery from './magicGallery'

const generateSlug = (value, dispatch) => {
  dispatch(change(REDUX_FORM_NAME, 'slug', slugifyFiler(value.name)))
}

const basePublicUrl = {
  es: `${process.env.REACT_APP_PUBLIC_FRONT}/multimedia/`,
  en: `${process.env.REACT_APP_PUBLIC_FRONT}/en-ES/multimedia/`,
}

class GalleryForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editImageDialog: null,
    }
  }

  editImage(image, index) {
    this.setState({
      editImageDialog: {
        index,
        image,
      },
    })
  }

  renderEditForm(formData, dispatch) {
    return (
      <ConfirmationDialog
        title="Edit image"
        open={this.state.editImageDialog}
        maxWidth="md"
        dialogActions={false}
        onCancel={() => this.setState({ editImageDialog: null })}
      >
        {this.state.editImageDialog && (
          <SubForm
            submitOnEnter={false}
            resource={getResource('assets', 'images')}
            record={this.state.editImageDialog.image}
            onClose={() => this.setState({ editImageDialog: null })}
            form="asset-form"
            onSubmit={(data) => {
              const images = _.get(formData, 'galleryImages')
              _.set(images, `[${this.state.editImageDialog.index}].content`, data)
              dispatch(change(REDUX_FORM_NAME, 'galleryImages', images))
              this.setState({
                editImageDialog: null,
              })
            }}
          >
            <ImageForm />
          </SubForm>
        )}
      </ConfirmationDialog>
    )
  }

  render() {
    return (
      <LanguagesNav useAdditionalLanguages {...this.props} defaultValue={this.props.defaultValue}>
        {({ dir }) => (
          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => {
              if (formData) {
                return (
                  <React.Fragment>
                    <Grid container>
                      {!this.props.id && (
                        <Grid item xs={12} md={8}>
                          <MagicGallery formData={formData} dispatch={dispatch} source="galleryImages" />
                        </Grid>
                      )}
                      <Grid item xs={12} md={4}>
                        <DisabledInput source="type" defaultValue="image" />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={6} md={4}>
                        <LanguageInput source="galleryLanguages" />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <ClientUuidsInput label="Clients" source="clientUuids" isEdit={this.props.isEdit} validate={[checkboxRequired()]} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <MarketsInput label="Market" source="markets" select />
                      </Grid>
                    </Grid>
                    <div dir={dir} style={{ width: '100%' }}>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextInput
                            fullWidth
                            label="Name"
                            source="name"
                            validate={[required(), maxLength(255)]}
                            onChange={(value) => {
                              if (formData.slugAutogenerate) {
                                generateSlug(formData, dispatch)
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                          <BooleanInput
                            label="Autogenerate slug"
                            source="slugAutogenerate"
                            onChange={(value) => {
                              if (value.target.checked) {
                                generateSlug(formData, dispatch)
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={9} sm={9} md={9}>
                          <TextInput disabled={formData.slugAutogenerate} fullWidth label="Slug" source="slug" validate={[required(), maxLength(255)]} />
                        </Grid>
                        <Grid item xs={12}>
                          <ClipboardField urlShortener label="Url" value={`${basePublicUrl[formData.lang ? formData.lang : 'es']}${formData.slug}`} fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <LongTextInput label="Description" source="description" validate={[required()]} />
                        </Grid>
                      </Grid>
                    </div>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={4}>
                        <SelectInput
                          allowEmpty={false}
                          validate={[required()]}
                          fullWidth
                          source="state"
                          choices={[
                            {
                              id: 'published',
                              name: 'published',
                            },
                            {
                              id: 'draft',
                              name: 'draft',
                            },
                            {
                              id: 'hidden',
                              name: 'hidden',
                            },
                          ]}
                        />
                      </Grid>
                      <CategoryInput label="Category" source="category" resource={getResource('assets', 'gallerycategories')} validate={[required()]} />
                    </Grid>
                    <ImageInput
                      fullWidth
                      label="Images"
                      source="galleryImages"
                      multiselect
                      content="content"
                      position="position"
                      canChangePosition
                      options={{
                        type: 'images',
                        resource: getResource('assets', 'images'),
                        menuOptions: [{ text: 'Edit', onClick: (image, index) => this.editImage(image, index) }],
                        checkboxActions: { prop: 'prominent', label: 'Prominent', excude: true },
                      }}
                    />
                    {this.renderEditForm(formData, dispatch)}
                    <Grid item xs={12} sm={12} md={12}>
                      <MetadataInput source="metadatas" label="Metadata" />
                    </Grid>
                  </React.Fragment>
                )
              }
            }}
          </FormDataConsumer>
        )}
      </LanguagesNav>
    )
  }
}

export default GalleryForm
