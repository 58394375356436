import React from 'react'
import { Edit } from 'react-admin'
import StandingFairplayForm from './form'

const StandingFairplayTitle = ({ record }) => {
  return <span>{`Operador: ${record.nombre}`}</span>
}

export const StandingFairplayEdit = (props) => (
  <Edit undoable={false} title={<StandingFairplayTitle />} {...props}>
    <StandingFairplayForm {...props} />
  </Edit>
)

export default StandingFairplayEdit
