import React from 'react'
import { Datagrid, EditButton, List, Responsive, SimpleList, TextField } from 'react-admin'
import { TableRowList } from '../../../../components'

export const PartidazosHomepage = (props) => {
  return (
    <List bulkActions={false} {...props}>
      <Responsive
        small={<SimpleList primaryText={(record) => record.title} />}
        medium={
          <Datagrid>
            <TextField source="id" label="Id" />
            <TableRowList>
              <EditButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}

export default PartidazosHomepage
