import { AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGOUT } from 'react-admin'

import { getTokenRedirect, myMSALObj, signIn, signOut } from '../config/msal'
import { tokenRequest } from '../config/msal/authConfig'
import { getMemberOfRedirect } from '../config/msal/graph'

export default async (type, params) => {
  const account = myMSALObj.getActiveAccount()
  if (account) {
    const response = await getTokenRedirect(tokenRequest, account).catch((error) => {
      console.error({ error })
      throw new Error(error.code)
    })
    localStorage.setItem('token', response.accessToken)
  } else {
    await signIn()
  }

  if (type === AUTH_CHECK) {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject()
  }

  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('token')
    sessionStorage.removeItem('groups')
    await signOut()
    return Promise.resolve({ redirectTo: '/' })
  }

  if (type === AUTH_ERROR) {
    const status = params.status
    if (status === 401 || status === 403) {
      localStorage.removeItem('token')
      sessionStorage.removeItem('groups')
      return Promise.reject()
    }
    return Promise.resolve()
  }

  if (type === AUTH_GET_PERMISSIONS) {
    let groups = sessionStorage.getItem('groups')
    if (!groups) {
      groups = await getMemberOfRedirect()
      if (groups) {
        sessionStorage.setItem('groups', groups)
      }
    } else {
      groups = groups.split(',')
    }

    return Promise.resolve(groups)
  }

  return Promise.resolve()
}
