import React from 'react'
import Grid from '@material-ui/core/Grid'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ChipField,
  ReferenceField,
  SingleFieldList,
  ArrayField,
  Responsive,
  SimpleList,
  Filter,
  TextInput,
  ReferenceInput,
} from 'react-admin'
import { TableRowList, ListActions } from '../../../components'
import { MatchField, ImageField } from '../../../components/field'
import { MetadataInput, AutocompleteInput } from '../../../components/inputs'
import { getResource } from '../../../config/resources'

const BroadcastersFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={6} md={4}>
          <ReferenceInput label="Broadcaster" source="broadcaster" reference={getResource('operadores', 'broadcasters')} alwaysOn allowEmpty fullWidth>
            <AutocompleteInput inputValueMatcher={() => null} />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ReferenceInput label="Country" source="country" reference={getResource('transversal', 'countries')} alwaysOn allowEmpty>
            <AutocompleteInput inputValueMatcher={() => null} />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <MetadataInput source="metadatas" label="Metadatas" types={[{ type: 'match', name: 'Match' }]} />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<BroadcastersFilter />} />
}

export const BroadcastList = (props) => (
  <List bulkActions={false} actions={<Actions />} title="Operadores" {...props}>
    <Responsive
      small={
        <SimpleList
          primaryText={(record) => record.broadcaster.name}
          secondaryText={(record) => (
            <ReferenceField
              linkType={false}
              basePath={getResource('competiciones', 'matches')}
              record={record}
              label="Temporada"
              source="match"
              reference={getResource('competiciones', 'matches')}
            >
              <TextField source="gameweek.round.subscription.season.name" />
            </ReferenceField>
          )}
          tertiaryText={(record) => (
            <ReferenceField
              linkType={false}
              basePath={getResource('competiciones', 'matches')}
              record={record}
              label="Competición"
              source="match"
              reference={getResource('competiciones', 'matches')}
            >
              <TextField source="gameweek.round.subscription.competition.name" />
            </ReferenceField>
          )}
        />
      }
      medium={
        <Datagrid>
          <ReferenceField allowEmpty linkType={false} reference={getResource('assets', 'images')} label="Image" source="broadcaster.image">
            <ImageField sourceImage="" title="image channel" />
          </ReferenceField>
          <TextField source="broadcaster.name" label="Broadcaster" sortable={false} />
          <ReferenceField label="Game week" source="match" reference={getResource('competiciones', 'matches')}>
            <TextField source="gameweek.name" />
          </ReferenceField>
          <ReferenceField label="Match" source="match" reference={getResource('competiciones', 'matches')}>
            <MatchField />
          </ReferenceField>
          <ArrayField source="broadcastCountries" label="Countries" sortable={false}>
            <SingleFieldList linkType={false}>
              <ChipField source="country" />
            </SingleFieldList>
          </ArrayField>
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default BroadcastList
