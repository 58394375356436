import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ArrayField,
  SingleFieldList,
  ChipField,
  Responsive,
  SimpleList,
  ReferenceField,
  Filter,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  FunctionField,
} from 'react-admin'
import { TableRowList, ListActions } from '../../../components'
import { ImageField } from '../../../components/field'
import { getResource } from '../../../config/resources'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

const PeopleFilters = (props) => {
  return (
    <Filter {...props}>
      <TextInput className={props.classes.input} variant="filled" label="Name" source="name" alwaysOn fullWidth />
      <ReferenceInput className={props.classes.input} variant="filled" label="Roles" source="role" reference={getResource('competiciones', 'roles')} alwaysOn>
        <SelectInput />
      </ReferenceInput>
      <ReferenceInput className={props.classes.input} label="Nationality" source="country" reference={getResource('transversal', 'nationalities')}>
        <AutocompleteInput allowEmpty fullWidth />
      </ReferenceInput>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<PeopleFilters />} />
}

const styles = {
  img: {
    width: '30px',
    'vertical-align': 'middle',
  },
}

const TeamShield = withStyles(styles)(({ classes, ...props }) => <ImageField {...props} className={classes.img} />)

const TeamField = (record) => {
  let personRole = _.first(_.filter(record.personRoles, (r) => r.active))

  return (
    <React.Fragment>
      {personRole && personRole.team && (
        <ReferenceField
          source="shield"
          reference={getResource('assets', 'images')}
          basePath={getResource('assets', 'images')}
          linkType={false}
          record={personRole.team}
          allowEmpty
        >
          <TeamShield mini source="asset.fileDir" title="imagen competition" />
        </ReferenceField>
      )}
      <span style={{ display: 'inline' }}>{_.get(personRole, 'team.selectorName', '-')}</span>
    </React.Fragment>
  )
}

const PositionField = (record) => {
  let personRole = _.first(_.filter(record.personRoles, (r) => r.active))
  return _.get(personRole, 'position.name', '-')
}

const ShirtNumber = (record) => {
  let personRole = _.first(_.filter(record.personRoles, (r) => r.active))
  return _.get(personRole, 'shirt_number', '-')
}

const PeopleList = (props) => (
  <List bulkActions={false} title="People" actions={<Actions />} {...props}>
    <Responsive
      small={
        <SimpleList
          leftAvatar={(record) => (
            <ReferenceField
              source="image"
              reference={getResource('assets', 'images')}
              basePath={getResource('assets', 'images')}
              linkType={false}
              record={record}
              allowEmpty
            >
              <ImageField mini source="asset.fileDir" title="imagen competition" />
            </ReferenceField>
          )}
          primaryText={(record) => record.name}
          secondaryText={(record) => (
            <ArrayField source="personRoles" label="Role" linkType={false} record={record}>
              <SingleFieldList>
                <ChipField source="role.name" />
              </SingleFieldList>
            </ArrayField>
          )}
        />
      }
      medium={
        <Datagrid>
          <ReferenceField label="Image" source="image" reference={getResource('assets', 'images')} linkType={false} sortable={false} allowEmpty>
            <ImageField source="asset.fileDir" title="imagen competition" />
          </ReferenceField>
          <TextField source="name" label="Name" />
          <TextField source="country" label="Country" />
          <FunctionField label="Team" render={TeamField} />
          <FunctionField label="Position" render={PositionField} />
          <FunctionField label="Shirt Number" render={ShirtNumber} />

          <ArrayField source="personRoles" label="Role" linkType={false} sortable={false}>
            <SingleFieldList>
              <ChipField source="role.name" />
            </SingleFieldList>
          </ArrayField>

          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default PeopleList
