import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import { TwitterTweetEmbed } from 'react-twitter-embed'
import { ContentStyled, FormStyled, PreviewStyled } from './style'

class textPluginEdit extends Component {
  onChange(value) {
    this.props.onChange({ content: '' + value.target.value })
  }
  render() {
    return (
      <ContentStyled>
        <FormStyled>
          <TextField
            label="Tweet Id"
            value={this.props.value && this.props.value.content ? this.props.value.content : ''}
            onChange={(value) => this.onChange(value)}
            margin="normal"
          />
        </FormStyled>
        <PreviewStyled>
          {this.props.value && this.props.value.content && (
            <div key={this.props.value && this.props.value.content ? this.props.value.content : ''}>
              <TwitterTweetEmbed tweetId={this.props.value && this.props.value.content ? this.props.value.content : ''} />
            </div>
          )}
        </PreviewStyled>
      </ContentStyled>
    )
  }
}

export default textPluginEdit
