import React from 'react'
import { TextInput, required, maxLength } from 'react-admin'
import LanguagesNav from '../../../../components/languagesNav'

import Grid from '@material-ui/core/Grid'

export const SponsoringsForm = ({ isEdit, ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12}>
        <TextInput fullWidth label="Name" source="name" validate={[required(), maxLength(255)]} />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default SponsoringsForm
