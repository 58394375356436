import React, { Component } from 'react'
import { ContainerStyled, TextareaStyled } from './style'
import TextField from '@material-ui/core/TextField'

class TextFormatInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: '',
    }
  }

  handleOnChange(e) {
    const { options } = this.props
    this.setState({
      text: e.target.value,
    })

    const newValue = options && options.format ? options.format(e.target.value) : e.target.value

    if (this.props.input) {
      this.props.input.onChange(newValue)
    }
    if (this.props.onChange) this.props.onChange(newValue)
  }

  render() {
    const { value } = this.props.input ? this.props.input : this.props
    return (
      <ContainerStyled>
        <TextareaStyled>
          <TextField label={this.props.label} disabled={this.props.disabled} onChange={(e) => this.handleOnChange(e)} value={value} />
        </TextareaStyled>
      </ContainerStyled>
    )
  }
}

export default TextFormatInput
