import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import React from 'react'
import {
  ArrayInput,
  BooleanInput,
  DisabledInput,
  LongTextInput,
  maxLength,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin'

import { StyleInlineSimpleFormIterator } from '../../../../app/style'
import { AutocompleteInput, ColorPicker, DateSingleInput, DictionaryInput, ImageInput, RichTextInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'
import { getResource } from '../../../../config/resources'
import { choicesTeamType } from '../../utils/constants'
import SquadsInput from './squadsInput'

const styles = (theme) => {
  return {
    boolVenue: {
      width: '100px',
    },
    marginRight: {
      marginRight: '20px',
    },
  }
}

export const TeamsForm = ({ classes, ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12}>
        <TextInput fullWidth source="name" label="Name" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisabledInput fullWidth source="slug" label="Slug" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ReferenceInput label="Club" source="club.id" reference={getResource('competiciones', 'clubs')}>
          <AutocompleteInput fullWidth />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput fullWidth source="nickname" label="Nickname" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput fullWidth source="selectorName" label="Selector Name" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput fullWidth source="boundname" label="Boundname" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput fullWidth source="shortname" label="Shortname" validate={[required(), maxLength(3)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ReferenceInput label="Team Parent" source="teamParent.id" reference={getResource('competiciones', 'teams')}>
          <AutocompleteInput allowEmpty fullWidth optionText="selectorName" />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectInput
          source="status"
          label="Status"
          validate={[required()]}
          choices={[
            { id: 'visible', name: 'Visible' },
            { id: 'hidden', name: 'Hidden' },
          ]}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectInput
          source="gender"
          label="Gender"
          validate={[required()]}
          choices={[
            { id: 'male', name: 'Male' },
            { id: 'female', name: 'Female' },
          ]}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectInput allowEmpty source="type" label="Type" choices={choicesTeamType()} validate={[required()]} fullWidth />
      </Grid>

      <Grid item xs={12}>
        <SquadsInput idTeam={props.id} />
      </Grid>

      <Grid item xs={12}>
        <RichTextInput source="description" label="Description" />
      </Grid>
      <Grid item xs={12}>
        <LongTextInput fullWidth source="address" label="Address" />
      </Grid>
      <Grid item xs={12}>
        <TextInput fullWidth source="web" label="Web" validate={[maxLength(255)]} />
        <TextInput fullWidth source="twitter" label="Twitter" validate={[maxLength(255)]} />
        <TextInput fullWidth source="facebook" label="Facebook" validate={[maxLength(255)]} />
        <TextInput fullWidth source="instagram" label="Instagram" validate={[maxLength(255)]} />
        <TextInput fullWidth source="appleStoreUrl" label="Apple Store Url" validate={[maxLength(255)]} />
        <TextInput fullWidth source="playStoreUrl" label="Play Store Url" validate={[maxLength(255)]} />
        <TextInput fullWidth source="mail" label="Email" validate={[maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput fullWidth source="phone" label="Phone" validate={[maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput fullWidth source="fax" label="Fax" validate={[maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput fullWidth source="subscriber" label="Subscriber" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateSingleInput label="Foundation" source="foundation" rangeStartDate={moment('1850-01-02')} />
      </Grid>
      <Grid item xs={12}>
        <ImageInput source="shield" label="Shield" accept="image/*" options={{ remove: true }} />
      </Grid>
      <Grid item xs={12}>
        <ImageInput source="monochromeShield" label="Monochrome Shield" accept="image/*" options={{ remove: true }} />
      </Grid>
      <Grid item xs={12}>
        <ImageInput source="whiteShield" label="White Shield" accept="image/*" options={{ remove: true }} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput fullWidth source="hashtag" label="Hashtag" validate={[maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput fullWidth source="squadNote" label="Squad Note" validate={[maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput fullWidth source="seasonsFirstDivision" label="Seasons First Division" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput fullWidth source="seasonsSecondDivision" label="Seasons Second Division" />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <ColorPicker fullWidth label="Color" name="color" source="color" validate={[required(), maxLength(7)]} />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <ColorPicker fullWidth label="Color Secondary" name="colorSecondary" source="colorSecondary" validate={[required(), maxLength(7)]} />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <ColorPicker fullWidth label="Color Text" name="colorText" source="colorText" validate={[required(), maxLength(7)]} />
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <SelectInput
          fullWidth
          label="Shirt Style"
          source="shirtStyle"
          choices={[
            { id: 'mono', name: 'Mono' },
            { id: 'striped', name: 'Striped' },
            { id: 'band', name: 'Band' },
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <ColorPicker fullWidth label="Color Shirt Number" name="colorShirtNumber" source="colorShirtNumber" validate={[required(), maxLength(7)]} />
      </Grid>
      <Grid item xs={12}>
        <StyleInlineSimpleFormIterator>
          <ArrayInput source="teamVenues">
            <SimpleFormIterator>
              <BooleanInput className={[classes.boolVenue, classes.marginRight]} source="current" label="Current" />
              <ReferenceInput
                className={classes.marginRight}
                label="Season"
                source="season.id"
                reference={getResource('competiciones', 'seasons')}
                filter={{ showInWeb: 1 }}
              >
                <SelectInput validate={[required()]} fullWidth />
              </ReferenceInput>
              <ReferenceInput label="Venue" source="venue.id" reference={getResource('competiciones', 'venues')}>
                <AutocompleteInput inputValueMatcher={() => null} />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </StyleInlineSimpleFormIterator>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <DictionaryInput
            label="Dictionary"
            sources={[
              'ldeId',
              'quinielaId',
              'sportiumId',
              'pointsbetId',
              'mbetId',
              'fenixId',
              'performId',
              'optaId',
              'managerId',
              'strendusId',
              'esportsId',
              'rushbetId',
            ]}
          />
        </Paper>
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default withStyles(styles)(TeamsForm)
