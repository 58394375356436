import React, { Component } from 'react'
import _ from 'lodash'
import { InputStyled } from './style'
import SearchIcon from '@material-ui/icons/Search'

class Search extends Component {
  handleChange = _.debounce((text) => {
    this.props.onChange(text)
  }, 500)

  render() {
    return (
      <InputStyled>
        <SearchIcon />
        <input onChange={(e) => this.handleChange(e.target.value)} placeholder="Search…" />
      </InputStyled>
    )
  }
}

export default Search
