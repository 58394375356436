import Grid from '@material-ui/core/Grid'
import React from 'react'
import { LongTextInput, required, TextInput } from 'react-admin'

import { AddAssetInput } from '../../../../components/inputs'

export const Form = ({ ...props }) => (
  <Grid container>
    <Grid item xs={12} sm={12} md={4}>
      <Grid item xs={12} sm={12} md={12}>
        <AddAssetInput
          source="asset"
          label="File"
          accept="application/pdf, text/csv, .csv, .xls, .xlsx, .pdf, .doc, .docx, .word, text/html, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          fullWidth
          validate={[required()]}
        />
      </Grid>
    </Grid>
    <Grid item xs={12} sm={12} md={8}>
      <Grid item xs={12} sm={12} md={12}>
        <TextInput fullWidth source="name" label="name" validate={[required()]} />
      </Grid>
    </Grid>
    <Grid item xs={12} sm={12} md={12}>
      <LongTextInput fullWidth source="description" label="Description" validate={[required()]} />
    </Grid>
  </Grid>
)

export default Form
