import CircularProgress from '@material-ui/core/CircularProgress'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { Component } from 'react'
import { GET_LIST } from 'react-admin'

import { getResource } from '../../../../../../config/resources'
import dataProvider from '../../../../../../providers'
import ListMatches from './ListMatches'

const styles = (theme) => {
  return {
    border: {
      border: '1px solid',
      borderColor: theme.palette.grey[600],
      position: 'relative',
      minHeight: '150px',
    },
    loading: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0,0,0,0.1)',
      top: '0px',
      left: '0px',
    },
    itemDefault: {
      width: '100%',
      minHeight: '200px',
    },
    expansionPanel: {
      border: '1px solid',
      borderColor: theme.palette.grey[600],
      boxSizing: 'border-box',
      marginBottom: '10px',
      background: theme.palette.background.default,
      overFlow: 'hidden',
      paddingBottom: '1px',
    },
    expansionPanelSummary: {
      boxSizing: 'border-box',
      minHeight: '48px !important',
      '& > div': {
        margin: '0px !important',
      },
    },
    expansionPanelDetails: {
      padding: '0px',
      borderTop: '1px solid',
      borderColor: theme.palette.grey[400],
      background: theme.palette.background.paper,
    },
  }
}

class Gameweeks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      gameweeks: null,
      loading: false,
    }
  }

  componentDidMount() {
    this.listGameweeks()
  }

  listGameweeks() {
    const { idRound } = this.props
    this.setState({
      loading: true,
    })

    dataProvider(GET_LIST, getResource('competiciones', 'gameweeks'), {
      sort: {
        field: 'date',
        order: 'ASC',
      },
      pagination: { page: 1, perPage: 100 },
      filter: {
        round: idRound,
      },
    }).then((response) => {
      this.setState({
        loading: false,
        gameweeks: response.data,
      })
    })
  }

  render() {
    const { classes } = this.props

    return (
      <Grid container className={classes.border}>
        <Grid item xs={12}>
          <Typography color="primary" variant="h2" headlineMapping="h2">
            Gameweek
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {this.state.gameweeks &&
            this.state.gameweeks.map((item, key) => {
              return (
                <ExpansionPanel className={classes.expansionPanel} key={key}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionPanelSummary}>
                    <Typography>{item.name}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                    <ListMatches idGameweek={item.id} />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )
            })}
        </Grid>
        {this.state.loading && (
          <div className={classes.loading}>
            <CircularProgress className={classes.progress} />
          </div>
        )}
      </Grid>
    )
  }
}

export default withStyles(styles)(Gameweeks)
