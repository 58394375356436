import React from 'react'

import LanguagesNav from '../../../../components/languagesNav'
import Form from './form'

export const ImagesForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Form />
  </LanguagesNav>
)

export default ImagesForm
