import { Grid } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { BooleanInput, LongTextInput, maxLength, ReferenceInput, required, TextInput } from 'react-admin'

import { AutocompleteInput, DateSingleInput, DateTimeInput, ImageInput, RichTextInput, TextArrayInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'
import { getResource } from '../../../../config/resources'

export const AmbassadorsForm = (props) => (
  <LanguagesNav {...props}>
    <Grid container alignItems="flex-end">
      <Grid item xs={12}>
        <BooleanInput label="Active" source="active" />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextInput label="Name" source="name" validate={[required(), maxLength(255)]} fullWidth />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextInput label="Position" source="position" fullWidth />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextInput label="Slug" source="slug" validate={[required(), maxLength(255)]} fullWidth />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextInput label="Twitter" source="twitter" fullWidth />
      </Grid>
      <Grid item xs={12} md={4}>
        <DateSingleInput label="Birth date" source="birthDate" rangeStartDate={moment('1900-01-02')} />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextInput label="Birth Place" source="birthPlace" validate={[required(), maxLength(255)]} fullWidth />
      </Grid>
      <Grid item xs={12} md={4}>
        <ReferenceInput label="Nationality" source="nationality" reference={getResource('transversal', 'nationalities')}>
          <AutocompleteInput
            options={{
              fullWidth: true,
            }}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <LongTextInput label="Title" source="descriptionTitle" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <RichTextInput label="Description" source="descriptionContent" fullWidth />
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={12} md={6}>
        <ImageInput source="photo" label="Photo" options={{ remove: true }} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ImageInput source="photoCover" label="Photo cover" options={{ remove: true }} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextArrayInput label="Seasons" source="seasons" />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextArrayInput label="Trajectory" source="trajectory" />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextArrayInput label="Games" source="games" />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextArrayInput label="Goals" source="goals" />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextArrayInput label="Wins" source="wins" />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default AmbassadorsForm
