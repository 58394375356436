import { Grid, Paper, withStyles, Switch, FormControlLabel } from '@material-ui/core'
import _ from 'lodash'
import React, { Component } from 'react'
import { GET_LIST, BooleanInput, fetchEnd, fetchStart, NumberInput, showNotification } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector, initialize, isSubmitting } from 'redux-form'
import { MatchField } from '../../../../components/field'
import LanguagesNav from '../../../../components/languagesNav'
import { getResource } from '../../../../config/resources'
import dataProvider from '../../../../providers'
import { POST_MANY } from '../../../../providers/dataProvider/types'
import InfoGameweek from './InfoGameweek'

const FORM = 'record-form'
const selector = formValueSelector(FORM)

const styles = (theme) => ({
  rowController: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    marginBottom: '10px',
  },
  boxMatch: {
    marginBottom: '20px',
  },
  boxForecast: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  itemForecast: {
    display: 'flex',
  },
})

export class MatchesForecastForm extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { id } = this.props
    this.initializeMatches(id)
  }

  initializeMatches(idGameweek) {
    const { change, field, showNotification, fetchStart, fetchEnd } = this.props
    const lang = field('lang')
    fetchStart()
    dataProvider(GET_LIST, getResource('competiciones', 'matches'), {
      data: {
        lang: lang ? lang : 'es',
      },
      sort: {
        field: 'date',
        order: 'ASC',
      },
      pagination: {},
      filter: {
        gameweek: idGameweek,
      },
    })
      .then((response) => {
        change(FORM, 'matches', response.data)
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  updateMatchesForecast() {
    const { field, showNotification, fetchStart, fetchEnd } = this.props
    const matches = field('matches')
    const lang = field('lang')

    if (matches) {
      const matchesData = matches.map((item) => {
        return {
          id: item.id,
          forecastMatch: item.forecastMatch,
        }
      })
      fetchStart()
      dataProvider(POST_MANY, getResource('competiciones', 'matchesForecastEdit'), {
        data: {
          lang: lang ? lang : 'es',
          arrayData: matchesData,
        },
      })
        .then((response) => {
          showNotification('Save success', 'success')
        })
        .catch((error) => {
          showNotification(error.message, 'error')
        })
        .finally(() => {
          fetchEnd()
        })
    }
  }

  getValueSwitch = (name) => {
    const { field } = this.props
    const matches = field('matches')

    let value = true
    if (matches) {
      for (let i = 0; i < matches.length; i++) {
        if (!_.get(matches[i], name)) {
          value = false
          break
        }
      }
    } else {
      value = false
    }
    return value
  }

  handleChangeSwitch = (name) => (event) => {
    const { field, change } = this.props
    let matches = field('matches')
    if (matches) {
      matches.map((match) => {
        _.set(match, name, event.target.checked)
      })
      change(FORM, 'matches', matches)
    }
  }

  render() {
    const { isSubmitting, record, field, classes } = this.props
    const matches = field('matches')

    return (
      <LanguagesNav {...this.props} saving={isSubmitting} form={FORM} save={() => this.updateMatchesForecast()}>
        <InfoGameweek record={record} />
        <div className={classes.rowController}>
          <FormControlLabel
            control={
              <Switch
                checked={this.getValueSwitch('forecastMatch.locked')}
                onChange={this.handleChangeSwitch('forecastMatch.locked')}
                value="locked"
                color="primary"
              />
            }
            label="Locked"
          />
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={this.getValueSwitch('forecastMatch.showTemperatureHistorical')}
                  onChange={this.handleChangeSwitch('forecastMatch.showTemperatureHistorical')}
                  value="historical"
                  color="primary"
                />
              }
              label="Historical"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={this.getValueSwitch('forecastMatch.showTemperatureForecast')}
                  onChange={this.handleChangeSwitch('forecastMatch.showTemperatureForecast')}
                  value="forecast"
                  color="primary"
                />
              }
              label="Forecast"
            />
          </div>
        </div>
        {matches &&
          matches.map((item, key) => {
            const baseSource = `matches[${key}].`
            return (
              <Paper className={classes.boxMatch} key={`match-${item.id}`}>
                <Grid container>
                  <Grid item xs={12} md={5}>
                    <div className={classes.boxForecast}>
                      <MatchField
                        baseSource={baseSource}
                        record={{
                          matches: matches,
                        }}
                        basePath={getResource('assets', 'images')}
                      />
                      <BooleanInput source={`${baseSource}forecastMatch.locked`} label="Locked" />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <div className={classes.boxForecast}>
                      <div className={classes.itemForecast}>
                        <NumberInput source={`${baseSource}forecastMatch.temperatureHistorical`} label="Historical" fullWidth />
                        <BooleanInput source={`${baseSource}forecastMatch.showTemperatureHistorical`} label="Show" />
                      </div>
                      <div className={classes.itemForecast}>
                        <NumberInput source={`${baseSource}forecastMatch.temperatureForecast`} label="Forecast" fullWidth />
                        <BooleanInput source={`${baseSource}forecastMatch.showTemperatureForecast`} label="Show" />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            )
          })}
      </LanguagesNav>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      isSubmitting: isSubmitting(FORM)(state),
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(MatchesForecastForm)
