import React from 'react'

import LanguagesNav from '../../../../components/languagesNav'
import Form from './form'

export const GameweekForm = ({ ...props }) => {
  return (
    <LanguagesNav {...props}>
      <Form {...props} />
    </LanguagesNav>
  )
}

export default GameweekForm
