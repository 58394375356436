import React from 'react'
import { Edit } from 'react-admin'

import TeamPromiseForm from './form'

export const TeamsPromiseEdit = (props) => (
  <Edit undoable={false} {...props}>
    <TeamPromiseForm isEdit={true} {...props} />
  </Edit>
)

export default TeamsPromiseEdit
