import { Grid, Paper, Chip, withStyles } from '@material-ui/core'
import React, { Component } from 'react'
import { GET_LIST, fetchEnd, fetchStart, showNotification, ReferenceInput } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector, initialize, isSubmitting } from 'redux-form'

import LanguagesNav from '../../../../components/languagesNav'
import { MatchField } from '../../../../components/field'
import { AutocompleteInput } from '../../../../components/inputs'
import { ConfirmationDialog } from '../../../../components'
import { getResource } from '../../../../config/resources'
import dataProvider from '../../../../providers'
import { PATCH } from '../../../../providers/dataProvider/types'

import InfoGameweek from './InfoGameweek'

const FORM = 'record-form'
const selector = formValueSelector(FORM)
const styles = (theme) => ({
  boxMatch: {
    marginBottom: '20px',
  },
  containerBroadcast: {
    flex: 'auto',
  },
  chip: {
    margin: theme.spacing.unit,
  },
})

export class InternationalTimetableForm extends Component {
  constructor(state) {
    super(state)

    this.state = {
      broadcastMatch: [],
      infoDeleteBroadcast: false,
    }
  }

  componentDidMount() {
    const { id } = this.props
    this.initializeMatches(id)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.country != this.props.country) {
      this.getBroadcast(this.props.country)
    }
  }

  getBroadcast(country) {
    const { id, showNotification, fetchStart, fetchEnd } = this.props

    fetchStart()
    dataProvider(GET_LIST, getResource('operadores', 'broadcasts'), {
      sort: {
        field: 'id',
        order: 'ASC',
      },
      pagination: { page: 1, perPage: 99 },
      filter: {
        country: country,
        state: 'approved',
        metadatas: [{ type: 'gameweek', value: id }],
      },
    })
      .then(({ data }) => {
        let broadcastMatch = {}

        data.forEach((element) => {
          if (!broadcastMatch[element.match]) {
            broadcastMatch[element.match] = []
          }
          broadcastMatch[element.match].push(element)
        })

        this.setState({ broadcastMatch: broadcastMatch })
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  initializeMatches(idGameweek) {
    const { change, field, showNotification, fetchStart, fetchEnd } = this.props
    const lang = field('lang')
    fetchStart()
    dataProvider(GET_LIST, getResource('competiciones', 'matches'), {
      sort: {
        field: 'date',
        order: 'ASC',
      },
      pagination: {},
      filter: {
        gameweek: idGameweek,
      },
    })
      .then((response) => {
        change(FORM, 'matches', response.data)
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  deleteBroadcast() {
    const { fetchStart, fetchEnd, showNotification } = this.props
    let { broadcastMatch } = this.state
    const { idMatch, indexBroadcast } = this.state.infoDeleteBroadcast
    const country = this.props.field('country')

    const broadcasterCountry = broadcastMatch[idMatch][indexBroadcast].broadcastCountries.find((value) => value.country === country)

    fetchStart()
    dataProvider(PATCH, getResource('operadores', 'broadcastcountries'), {
      id: broadcasterCountry.id,
      data: {
        state: 'refused',
      },
    })
      .then(({ data }) => {
        broadcastMatch[idMatch].splice(indexBroadcast, 1)
        this.setState({
          infoDeleteBroadcast: false,
          broadcastMatch: broadcastMatch,
        })
        showNotification('Broadcast updated', 'success')
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  confirmDeleteBroadcast(idMatch, indexBroadcast) {
    this.setState({
      infoDeleteBroadcast: {
        idMatch: idMatch,
        indexBroadcast: indexBroadcast,
      },
    })
  }

  render() {
    const { record, field, classes } = this.props
    const { broadcastMatch, infoDeleteBroadcast } = this.state
    const matches = field('matches')
    const country = field('country')

    return (
      <LanguagesNav disabledLanguages disabledToolbar {...this.props}>
        <Grid container>
          <InfoGameweek record={record} />
          <Grid item xs={6} md={9}>
            <ReferenceInput label="Country" source="country" reference={getResource('transversal', 'countries')}>
              <AutocompleteInput inputValueMatcher={() => null} fullWidth />
            </ReferenceInput>
          </Grid>
        </Grid>
        {country &&
          matches &&
          matches.map((match, key) => {
            const baseSource = `matches[${key}].`
            return (
              <Paper className={classes.boxMatch} key={`match-${match.id}`}>
                <Grid container>
                  <Grid item xs={12}>
                    <MatchField
                      baseSource={baseSource}
                      record={{
                        matches: matches,
                      }}
                      basePath={getResource('assets', 'images')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.containerBroadcast}>
                      {broadcastMatch[match.id] &&
                        broadcastMatch[match.id].map((each, index) => {
                          return (
                            <Chip
                              key={index}
                              label={each.broadcaster.name}
                              onDelete={() => this.confirmDeleteBroadcast(match.id, index)}
                              className={classes.chip}
                            />
                          )
                        })}
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            )
          })}
        <ConfirmationDialog
          open={infoDeleteBroadcast !== false}
          onSuccess={() => this.deleteBroadcast()}
          onCancel={() => this.setState({ infoDeleteBroadcast: false })}
          title="Delete broadcast"
          content="Are you sure?"
        />
      </LanguagesNav>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      isSubmitting: isSubmitting(FORM)(state),
      field: (source) => selector(state, source),
      country: selector(state, 'country'),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(InternationalTimetableForm)
