import React from 'react'
import { Create } from 'react-admin'

import TeamsPromisesForm from './form'

export const TeamsPromisesCreate = (props) => (
  <Create {...props}>
    <TeamsPromisesForm {...props} />
  </Create>
)

export default TeamsPromisesCreate
