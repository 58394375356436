import Grid from '@material-ui/core/Grid'
import React from 'react'
import { Datagrid, EditButton, Filter, List, Responsive, TextField } from 'react-admin'
import { ListActions, TableRowList } from '../../../../components'

const EditShortenedUrlsFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <div>editshorthenedurls</div>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<EditShortenedUrlsFilter />} />
}

export const EditShortenedUrlsList = ({ actions, title, ...rest }) => {
  return (
    <List bulkActions={false} actions={<Actions />} title="Imágenes" {...rest}>
      <Responsive
        large={
          <Datagrid>
            <TextField source="code" label="Code" />
            <TextField source="url" label="Url" />
            <TableRowList>
              <EditButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}

export default EditShortenedUrlsList
