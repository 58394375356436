import Grid from '@material-ui/core/Grid'
import React from 'react'
import {
  CloneButton,
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  List,
  Responsive,
  SimpleList,
  TextField,
  TextInput,
  ArrayField,
  SingleFieldList,
  ChipField,
  SelectInput,
  ReferenceInput,
  Pagination,
} from 'react-admin'
import { Link } from 'react-router-dom'

import { ListActions, TableRowList } from '../../../components'
import { DateField } from '../../../components/field'
import { MetadataInput } from '../../../components/inputs'
import { getResource } from '../../../config/resources'
import { withStyles } from '@material-ui/core/styles'

import ImageVideoField from './commons/ImageListField'

const styles = () => ({
  breakWord: {
    wordBreak: 'break-all',
    minWidth: '200px',
    display: 'inline-block',
  },
})

const VideosFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={3}>
          <SelectInput
            fullWidth
            alwaysOn
            allowEmpty
            source="state"
            choices={[
              {
                id: 'published',
                name: 'published',
              },
              {
                id: 'draft',
                name: 'draft',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextInput label="Search" source="q" alwaysOn fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ReferenceInput
            source="category"
            label="Category"
            reference={getResource('assets', 'videocategories')}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={99}
            alwaysOn
            allowEmpty
            fullWidth
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SelectInput
            source="orientation"
            choices={[
              { id: 'landscape', name: 'Landscape' },
              { id: 'portrait', name: 'Portrait' },
            ]}
            alwaysOn
            allowEmpty
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} alwaysOn>
          <ReferenceInput source="source" label="Source" reference={getResource('assets', 'sources')} alwaysOn allowEmpty>
            <SelectInput optionText="name" fullWidth />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ReferenceInput source="clientUuid" label="Clients" reference={getResource('transversal', 'clientuuids')} alwaysOn allowEmpty fullWidth>
            <SelectInput optionText="name" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} alwaysOn>
          <MetadataInput source="metadatas" label="Metadatas" alwaysOn />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<VideosFilter />} />
}

const PostPagination = (props) => <Pagination rowsPerPageOptions={[25, 50]} {...props} />

export const VideoList = (props) => (
  <List perPage={25} bulkActions={false} actions={<Actions />} title="Videos" filter={{ isPrivate: 0 }} {...props} pagination={<PostPagination />}>
    <Responsive
      medium={
        <SimpleList
          leftAvatar={(record) => <ImageVideoField record={record} {...props} />}
          primaryText={(record) => <span className={props.classes.breakWord}>{record.name}</span>}
          secondaryText={(record) => record.category && <span>Category: {record.category.name}</span>}
          tertiaryText={(record) => (
            <span>
              <div>State: {record.state}</div>
              <div>Source: {record.source.name}</div>
            </span>
          )}
        />
      }
      large={
        <Datagrid>
          <ImageVideoField label="Image" />
          <FunctionField
            source="name"
            label="Name"
            className={props.classes.breakWord}
            render={(record) => (
              <Link className={props.classes.breakWord} to={`${props.basePath}/${record.id}`}>
                {record.name}
              </Link>
            )}
          />
          <TextField source="category.name" label="Category" sortable={false} />
          <DateField source="createdAt" label="Created At" />
          <DateField source="publishedAt" label="Published At" />
          <TextField source="state" label="State" />
          <TextField source="source.name" label="Source" sortable={false} />
          <TextField source="orientation" label="Orientation" sortable={false} />
          <ArrayField source="videoLanguages" label="Available in" sortable={false}>
            <SingleFieldList linkType={false}>
              <ChipField source="language" />
            </SingleFieldList>
          </ArrayField>
          <ArrayField source="markets" label="Markets" sortable={false}>
            <SingleFieldList linkType={false}>
              <ChipField source="market" />
            </SingleFieldList>
          </ArrayField>
          <ArrayField source="clientUuids" label="Clients" sortable={false}>
            <SingleFieldList linkType={false}>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <TableRowList>
            <EditButton />
            <CloneButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default withStyles(styles)(VideoList)
