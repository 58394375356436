import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { Component } from 'react'
import { ReferenceField } from 'react-admin'

import { getResource } from '../../../config/resources'
import ItemListLayout from './itemListLayout'
import { ContentListStyled, FooterStyled, GrowStyled, ImageFieldStyled } from './style'

const VIDEOS = 'videos'
const GALLERIES = 'galleries'
const NEWS = 'news'

const styles = {
  pagination: {
    backgroundColor: 'transparent',
  },
}

class ImageListSelected extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { selectedItems } = this.props
    this.setState({
      selectedItems: selectedItems,
      selectedItemsBase: selectedItems,
    })
  }

  isSelected(item) {
    const { multiselect } = this.props
    if (!this.state.selectedItems) return false
    if (multiselect) {
      return _.find(this.state.selectedItems, { id: item.id })
    } else {
      return item.id === this.state.selectedItems.id
    }
  }

  //PUSH ITEM
  selectItem(item) {
    const { multiselect, onChange } = this.props
    if (multiselect) {
      const mySelectedItems = this.state.selectedItems ? [...this.state.selectedItems] : []
      const indexItem = _.findIndex(mySelectedItems, { id: item.id })
      if (indexItem > -1) {
        mySelectedItems.splice(indexItem, 1)
      } else {
        mySelectedItems.push(item)
      }
      this.setState({ selectedItems: mySelectedItems })
      onChange(mySelectedItems)
    } else {
      this.setState({ selectedItems: item })
      onChange(item)
    }
  }

  renderList(list) {
    //ERROR
    if (this.state.error) {
      return <ContentListStyled>Error</ContentListStyled>
    }
    //IS FETCHING
    if (this.state.isFetching) {
      let list = []
      for (var i = 0; i < this.state.perPage; i++) {
        list.push(<ItemListLayout isFetching />)
      }
      return list
    }
    //LIST
    if (list && list.length > 0) {
      let myList = []
      myList = list.map((item) => {
        if (this.props.type === VIDEOS) {
          const ImageVideo = () => {
            if (item.imageVideo) {
              return <ImageFieldStyled mini record={item.imageVideo} sizes={['small', 'medium', 'large', 'xlarge']} sourceImage="" />
            } else if (item.providerThumbnails) {
              return <ImageFieldStyled record={item} source="providerThumbnails.small" title="imagen" mini />
            }
          }
          return (
            <ItemListLayout
              key={item.id}
              image={<ImageVideo />}
              text={item.name}
              onClick={() => {
                this.selectItem(item)
              }}
              selected={this.isSelected(item)}
            />
          )
        }
        if (this.props.type === GALLERIES) {
          return (
            <ItemListLayout
              key={item.id}
              image={<ImageFieldStyled mini sourceImage="prominentImage.content" sizes={['small', 'medium', 'large', 'xlarge']} record={item} title="imagen" />}
              text={item.name}
              onClick={() => {
                this.selectItem(item)
              }}
              selected={this.isSelected(item)}
            />
          )
        }
        if (this.props.type === NEWS) {
          return (
            <ItemListLayout
              key={item.id}
              image={
                <ReferenceField
                  linkType={false}
                  record={item}
                  basePath={getResource('assets', 'images')}
                  source="asset"
                  reference={getResource('assets', 'images')}
                >
                  <ImageFieldStyled mini sizes={['small', 'medium', 'large', 'xlarge']} />
                </ReferenceField>
              }
              text={item.title}
              onClick={() => {
                this.selectItem(item)
              }}
              selected={this.isSelected(item)}
            />
          )
        }
        return (
          <ItemListLayout
            key={item.id}
            image={<ImageFieldStyled mini record={item} sizes={['small', 'medium', 'large', 'xlarge']} />}
            text={item.name}
            onClick={() => {
              this.selectItem(item)
            }}
            selected={this.isSelected(item)}
          />
        )
      })
      return myList
    } else {
      return <span>No Results</span>
    }
  }

  render() {
    return (
      <React.Fragment>
        <ContentListStyled>{this.renderList(this.state.selectedItemsBase)}</ContentListStyled>
        <FooterStyled>
          <GrowStyled />
          <Button
            onClick={() => this.props.onSubmit(this.state.selectedItems)}
            variant="contained"
            color="primary"
            disabled={!this.state.selectedItems || this.state.selectedItems <= 0}
          >
            ADD
          </Button>
        </FooterStyled>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(ImageListSelected)
