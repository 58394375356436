import React from 'react'
import { maxLength, required } from 'react-admin'
import { Grid, List, ListItem, ListItemText, Collapse } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { RichTextInput } from '../../../../../../components/inputs'

class AfterHeroText extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  handleClick = () => {
    this.setState({
      open: !this.state.open,
    })
  }
  render() {
    return (
      <>
        <ListItem button onClick={this.handleClick}>
          <ListItemText primary="Text Under Hero" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List>
            <ListItem>
              <Grid container>
                <Grid item xs={12}>
                  <RichTextInput fullWidth source="videoModule.content" label="Text" validate={[required(), maxLength()]} />
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Collapse>
      </>
    )
  }
}

export default AfterHeroText
