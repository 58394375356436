import React from 'react'
import { FormControlLabel, Switch } from '@material-ui/core'

const BoolExternalLink = (props) => {
  const { label } = props
  const { value, onChange } = props.input ? props.input : props

  const handleChange = (e) => {
    if (e.target.checked) {
      onChange('external')
    } else {
      onChange('internal')
    }
  }

  return <FormControlLabel control={<Switch checked={value === 'external'} onChange={handleChange} name="target" color="primary" />} label={label} />
}

export default BoolExternalLink
