import React from 'react'
import { arrayMove, List as MovableList } from 'react-movable'
import AddIcon from '@material-ui/icons/Add'
import { Grid, List, ListItem, Button, withStyles } from '@material-ui/core'
import FaqItem from './faqitem'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { showNotification } from 'react-admin'

const styles = (theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing.unit,
    '& button': {
      textTransform: 'capitalize',
      paddingLeft: theme.spacing.unit,
      '& svg': {
        fontSize: 20,
        marginRight: theme.spacing.unit / 2,
      },
    },
  },
})

class ListFaqs extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
  }

  handleAdd() {
    let newContent = this.props.content ? [...this.props.content] : []

    newContent.push({
      position: newContent.length,
      question: '',
      answer: '',
    })
    this.props.onChange(newContent)
    this.props.showNotification('You added a new Faq: question and answer cannot be empty', 'warning')
  }

  handleChange(position, key, value) {
    let newContent = [...this.props.content]
    if (key === 'image') {
      for (let i = 0; i < newContent.length; i++) {
        if (newContent[i].image === value && position !== i) {
          value = null
          this.props.showNotification('This image has been used.', 'warning')
          break
        }
      }
    }
    newContent[position][key] = value
    this.props.onChange(newContent)
  }

  handleChangeOrder = ({ oldIndex, newIndex }) => {
    let newContent = [...this.props.content]
    newContent = arrayMove(newContent, oldIndex, newIndex)
    this.props.onChange(newContent)
  }

  handleRemove(position) {
    let newContent = [...this.props.content]
    newContent.splice(position, 1)
    this.props.onChange(newContent)
  }

  render() {
    const { content, classes } = this.props

    return (
      <>
        <List component="div">
          <ListItem>
            <Grid container>
              {content && (
                <Grid item xs={12}>
                  <MovableList
                    lockVertically
                    values={content}
                    onChange={this.handleChangeOrder}
                    renderList={({ children, props }) => <div {...props}>{children}</div>}
                    renderItem={({ index, props, isDragged, value }) => {
                      const each = content[index]
                      return (
                        <div {...props} key={value.id || index} style={{ ...props.style, zIndex: 2 }}>
                          <FaqItem each={each} index={index} handleChange={this.handleChange} handleRemove={this.handleRemove} isDragged={isDragged} />
                        </div>
                      )
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </ListItem>
        </List>
        <div className={classes.toolbar}>
          <Button color="primary" variant="contained" size="small" onClick={this.handleAdd}>
            <AddIcon />
            Add
          </Button>
        </div>
      </>
    )
  }
}

const enhance = compose(
  connect(null, {
    showNotification,
  }),
  withStyles(styles)
)

export default enhance(ListFaqs)
