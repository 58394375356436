import React from 'react'
import { List, Card } from '@material-ui/core'
import { FormDataConsumer } from 'react-admin'
import LanguagesNav from '../../../../../components/languagesNav'
import Faqs from './modules/faqs'

const PageForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    {({ dir }) => (
      <FormDataConsumer>
        {({ formData }) =>
          formData && (
            <Card>
              <List>
                <Faqs source="homepageModule.items" {...props} />
              </List>
            </Card>
          )
        }
      </FormDataConsumer>
    )}
  </LanguagesNav>
)

export default PageForm
