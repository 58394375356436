import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'

import ConfirmationDialog from '../../confirmationDialog'
import AddModule from './addModule'
import ModuleItem from './moduleItem'
import { AreaStyled, DndDraggableItemStyled } from './style'

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'transparent',
})

const styles = (theme) => ({
  list: {
    padding: '5px',
    border: `solid 1px ${theme.palette.grey[400]}`,
    margin: '5px',
  },
})

class ServiceCommandUnit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dialog: false,
    }
  }

  handleDelete = () => {
    const { onDeleteItem, indexSection, indexModule } = this.props
    onDeleteItem(indexSection, indexModule, this.state.dialog)
    this.setState({ dialog: false })
  }

  handleAddModule = (area) => (modules) => {
    const { onChangeModules, indexSection, indexModule } = this.props
    onChangeModules(modules, indexSection, indexModule, area)
  }

  render() {
    const { area, type, subItems, classes, numArea, onChangeItemConfig, indexSection, indexModule } = this.props
    return (
      <AreaStyled className={classes.list} area={area}>
        <Droppable droppableId={type} type={`droppableSubItem-${type}`}>
          {(provided, snapshot) => (
            <div>
              <AddModule empty={!subItems.length} onChange={this.handleAddModule(area)} type={type} value={subItems} area={area} numArea={numArea} />
              <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {subItems.map((item, index) => {
                  return (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <DndDraggableItemStyled style={{ display: 'flex' }} innerRef={provided.innerRef} {...provided.draggableProps}>
                          <ModuleItem
                            isDragging={snapshot.isDragging}
                            isDraggingOver={snapshot.isDraggingOver}
                            dnDprops={provided}
                            value={item}
                            onRemove={() => this.setState({ dialog: index })}
                            onChange={(newItem) => onChangeItemConfig(indexSection, indexModule, index, newItem)}
                          />
                          {provided.placeholder}
                        </DndDraggableItemStyled>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
        <ConfirmationDialog
          open={this.state.dialog !== false}
          onSuccess={this.handleDelete}
          onCancel={() => this.setState({ dialog: false })}
          title="Delete element"
          content="Are you sure?"
        />
      </AreaStyled>
    )
  }
}

export default withStyles(styles)(ServiceCommandUnit)
