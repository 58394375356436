import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { Component } from 'react'
import { CREATE, fetchEnd, fetchStart, SelectInput, showNotification } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector, initialize, isSubmitting } from 'redux-form'

import { ConfirmationDialog, SubForm } from '../../../../../components'
import { getResource } from '../../../../../config/resources'
import dataProvider from '../../../../../providers'
import AssetForm from './assetForm'

const FORM = 'squads-form'
const selector = formValueSelector(FORM)

const styles = (theme) => {
  return {
    ToolbarBotton: {
      marginLeft: '10px',
    },
  }
}

class AddAsset extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFormFetching: false,
    }
  }

  componentDidMount() {
    const { initialize } = this.props
    initialize(FORM, {
      bMAssetGroup: null,
      asset: null,
    })
  }

  createBMAsset(imgData) {
    const { fetchStart, fetchEnd, showNotification, field, idBrandMarket, onCreate } = this.props
    let newImage = {
      name: 'Brand Market',
      caption: 'Brand Market',
      asset: imgData.asset,
      forceConvert: false,
    }

    fetchStart()
    this.setState({
      isFormFetching: true,
    })
    dataProvider(CREATE, getResource('assets', 'images'), {
      data: newImage,
    })
      .then(({ data }) => {
        const mbAsset = { ...imgData, asset: data.id, brandMarket: { id: idBrandMarket } }
        dataProvider(CREATE, getResource('digitalAssets', 'brand-market-assets'), {
          data: mbAsset,
        })
          .then(({ data }) => {
            onCreate()
          })
          .catch((error) => {
            showNotification(error.message, 'error')
          })
          .finally(() => {
            fetchEnd()
            this.setState({
              isFormFetching: false,
            })
          })
      })
      .catch((error) => {
        showNotification(error.message, 'error')
        fetchEnd()
        this.setState({
          isFormFetching: false,
        })
      })
  }

  render() {
    const { slugBMAGKind, isSubmitting, onCancel, open } = this.props
    return (
      <ConfirmationDialog
        title="Add"
        isFetching={isSubmitting || this.state.isFormFetchings}
        open={open}
        fullWidth={false}
        maxWidth="md"
        dialogActions={false}
        onCancel={() => onCancel()}
      >
        <SubForm
          submitOnEnter={false}
          isFetching={this.state.isFormFetching}
          onClose={() => onCancel()}
          form="asset-form"
          onClickSave={(data) => this.createBMAsset(data)}
        >
          <AssetForm slugBMAGKind={slugBMAGKind} />
        </SubForm>
      </ConfirmationDialog>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      isSubmitting: isSubmitting(FORM)(state),
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(AddAsset)
