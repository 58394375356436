import { Button, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import React, { Component } from 'react'
import { FormDataConsumer, maxLength, ReferenceInput, required, TextInput, ReferenceField, ChipField } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change } from 'redux-form'
import { AutocompleteInput } from '../../../../components/inputs'
import { getResource } from '../../../../config/resources'

const styles = (theme) => ({
  radioButtonGroupInput: {
    display: 'flex',
    '& > div': {
      flexDirection: 'row',
      width: '100%',
      flexWrap: 'nowrap',
    },
  },
  rowIndex: {
    padding: '10px',
    alignSelf: 'flex-start',
  },
  rowBroadcastCountries: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  rowContent: {
    flex: 'auto',
    padding: '0px 10px',
  },

  addCountry: {
    display: 'flex',
    maxWidth: '400px',
    alignItems: 'center',
    '& button': {
      marginLeft: '8px',
    },
  },
  chip: {
    margin: theme.spacing.unit,
  },
})

export class FormChannelMatch extends Component {
  addRow(source, formData, form) {
    const { change } = this.props
    let items
    if (formData[source]) {
      items = [...formData[source]]
    } else {
      items = []
    }
    items.push({
      country: formData['temp-add-country'],
    })
    change(form, source, items)
    change(form, 'temp-add-country', '')
  }

  removeRow(source, formData, form, key) {
    const { change } = this.props
    let items = [...formData[source]]
    items.splice(key, 1)
    change(form, source, items)
  }

  RuleCountries = ({ source, recordForm, form }) => {
    const { classes } = this.props
    return (
      <FormDataConsumer form={form}>
        {({ formData, dispatch, ...rest }) => {
          const myFormData = recordForm || formData
          if (!myFormData) return null
          return (
            <div>
              <div className={classes.addCountry}>
                <ReferenceInput label="Country" source={`temp-add-country`} reference={getResource('transversal', 'countries')} fullWidth>
                  <AutocompleteInput />
                </ReferenceInput>
                <Button size="small" onClick={() => this.addRow(source, myFormData, form)} disabled={!myFormData['temp-add-country']}>
                  <AddIcon className={classes.leftIcon} />
                  Add
                </Button>
              </div>
              <div>
                {myFormData[source] &&
                  myFormData[source].map((item, key) => (
                    <ReferenceField
                      source={`${source}[${key}].country`}
                      reference={getResource('transversal', 'countries')}
                      basePath={getResource('transversal', 'countries')}
                      linkType={false}
                      record={myFormData}
                      form={form}
                      key={key}
                    >
                      <ChipField source="name" onDelete={() => this.removeRow(source, myFormData, form, key)} />
                    </ReferenceField>
                  ))}
              </div>
            </div>
          )
        }}
      </FormDataConsumer>
    )
  }

  render() {
    const { RuleCountries } = this
    const { form, recordForm } = this.props

    return (
      <Grid container>
        <Grid item xs={12} sm={6}>
          <TextInput fullWidth label="Nombre" source="name" validate={[required(), maxLength(255)]} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReferenceInput label="Operador" source="broadcaster.id" validate={[required()]} reference={getResource('operadores', 'broadcasters')}>
            <AutocompleteInput fullWidth />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <RuleCountries source="ruleCountries" form={form} recordForm={recordForm} />
        </Grid>
      </Grid>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      // field: source => selector(state, source)
    }),
    (dispatch) => ({
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(FormChannelMatch)
