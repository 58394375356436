import React from 'react'
import { Edit } from 'react-admin'

import BrandForm from './form'

const BroadcasterTitle = ({ record }) => {
  return <span>{`Operador: ${record.nombre}`}</span>
}

export const BrandEdit = (props) => (
  <Edit undoable={false} title={<BroadcasterTitle />} {...props}>
    <BrandForm {...props} isEdit={true} />
  </Edit>
)

export default BrandEdit
