import React, { Component } from 'react'
import Plugin from '../plugin'
import CardsPlugin from './CardsPlugin'

class TextPlugin extends Component {
  render() {
    return <Plugin name="cards" {...this.props} component={CardsPlugin} />
  }
}

export default TextPlugin
