import Grid from '@material-ui/core/Grid'
import React from 'react'
import { DisabledInput, maxLength, required, TextInput, LongTextInput } from 'react-admin'

import LanguagesNav from '../../../../components/languagesNav'
import { DndBlockEditor } from '../../../../components/inputs'
import {
  GalleryPlugin,
  ImagePlugin,
  ScriptPlugin,
  TablePlugin,
  TextPlugin,
  TwitterPlugin,
  VideoPlugin,
  PersonPlugin,
  GridImageLink,
  CardsPlugin,
  FaqsPlugin,
} from '../../../../components/inputs/dndBlockEditor/plugins'

export const PageForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12}>
        <DisabledInput fullWidth label="Name" source="name" />
      </Grid>
      <Grid item xs={12}>
        <DisabledInput fullWidth label="Slug" source="slug" />
      </Grid>
      <Grid item xs={12}>
        <TextInput fullWidth label="SEO Title" source="title" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <LongTextInput fullWidth label="SEO Description" source="seoDescription" validate={[maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <TextInput source="url" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <DndBlockEditor
          label="Content"
          onChange={(value) => null}
          name="contentBlocks"
          options={{
            reverse: true,
          }}
          plugins={{
            image: {
              plugin: ImagePlugin,
              options: {
                name: 'Bloque imagen',
                removeClean: true,
                reference: true,
                readOlny: true,
              },
            },
            gallery: {
              plugin: GalleryPlugin,
              options: {
                name: 'Bloque galería',
                removeClean: true,
                reference: true,
                readOlny: true,
              },
            },
            text: {
              plugin: TextPlugin,
              options: {
                name: 'Bloque texto',
                removeClean: true,
              },
            },
            video: {
              plugin: VideoPlugin,
              options: {
                name: 'Bloque video',
                removeClean: true,
                reference: true,
                readOlny: true,
                filters: {
                  state: 'published',
                },
              },
            },
            table: {
              plugin: TablePlugin,
              options: {
                name: 'Bloque tabla',
                removeClean: true,
              },
            },
            script: {
              plugin: ScriptPlugin,
              options: {
                name: 'Bloque script',
              },
            },
            twitter: {
              plugin: TwitterPlugin,
              options: {
                name: 'Bloque twitter',
              },
            },
            person: {
              plugin: PersonPlugin,
              options: {
                name: 'Bloque persona',
              },
            },
            grid_image_link: {
              plugin: GridImageLink,
              options: {
                name: 'Grid de imágenes',
              },
            },
            cards: {
              plugin: CardsPlugin,
              options: {
                name: 'Bloque Cards',
              },
            },
            faqs: {
              plugin: FaqsPlugin,
              options: {
                name: 'Bloque Faqs',
              },
            },
          }}
        />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default PageForm
