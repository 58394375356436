import React from 'react'
import { Edit } from 'react-admin'
import SponsorsForm from './form'

const SponsorsEdit = (props) => (
  <Edit {...props}>
    <SponsorsForm isEdit={true} {...props} />
  </Edit>
)

export default SponsorsEdit
