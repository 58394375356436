import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'

class LongMenu extends React.Component {
  state = {
    anchorEl: null,
  }

  handleClickMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClick = (event) => {
    const { onChange, sections } = this.props
    this.handleClose()
    onChange(sections[event.target.value])
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { sections } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <div>
        <IconButton aria-label="More" aria-owns={open ? 'long-menu' : undefined} aria-haspopup="true" onClick={this.handleClickMenu}>
          <MoreVertIcon />
        </IconButton>
        <Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={this.handleClose}>
          {sections.map((option, index) => (
            <MenuItem value={index} key={option.id} onClick={this.handleClick}>
              {option.name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }
}

export default LongMenu
