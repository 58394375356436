import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, { Component } from 'react'
import { CREATE, fetchStart, fetchEnd, showNotification } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { getResource } from '../../../../../config/resources'
import { dataProvider } from '../../../../../providers'

const styles = (theme) => {
  return {
    border: {
      border: '1px solid',
      borderColor: theme.palette.grey[600],
    },
    newRound: {
      marginTop: '0px',
      marginBottom: '10px',
    },
    noPadding: {
      padding: '0px',
    },
  }
}

class AddRound extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newRound: '',
      newRoundType: 'table',
      newRoundGroup: false,
    }
  }

  addNewRound() {
    const { fetchStart, fetchEnd, showNotification } = this.props

    if (this.state.newRound !== '') {
      fetchStart()
      dataProvider(CREATE, getResource('competiciones', 'rounds'), {
        data: {
          name: this.state.newRound,
          type: this.state.newRoundType,
          groups: this.state.newRoundGroup,
          subscription: {
            id: this.props.idSubscription,
          },
        },
      })
        .then((response) => {
          showNotification('Round created', 'success')
          this.props.refresRounds()
        })
        .catch((error) => {
          showNotification(error.message, 'error')
        })
        .finally(() => {
          fetchEnd()
        })
    }
  }

  handleChangeInput = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })
    if (name === 'newRoundType' && event.target.value === 'cup') {
      this.setState({
        newRoundGroup: false,
      })
    }
  }

  handleChangeCheckbox = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
    })
  }

  render() {
    const { classes } = this.props

    return (
      <Grid container justify="space-between" alignItems="flex-end" className={classes.border}>
        <Grid item>
          <Grid container spacing={0}>
            <Grid item xs={12} className={classes.noPadding}>
              <TextField
                id="new-round"
                label="Round"
                value={this.state.newRound}
                onChange={this.handleChangeInput('newRound')}
                margin="normal"
                fullWidth
                className={classes.newRound}
              />
            </Grid>
            <Grid item xs={12} className={classes.noPadding}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Type</FormLabel>
                <RadioGroup value={this.state.newRoundType} onChange={this.handleChangeInput('newRoundType')} row>
                  <FormControlLabel value="cup" control={<Radio color="primary" />} label="Cup" labelPlacement="left" />
                  <FormControlLabel value="table" control={<Radio color="primary" />} label="League" labelPlacement="left" />
                </RadioGroup>
              </FormControl>
              {this.state.newRoundType === 'table' && (
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={<Checkbox checked={this.state.newRoundGroup} onChange={this.handleChangeCheckbox('newRoundGroup')} value={true} color="primary" />}
                    label="Groups"
                  />
                </FormControl>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => this.addNewRound()} disabled={this.state.newRound ? false : true}>
            Add
          </Button>
        </Grid>
      </Grid>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({}),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
    })
  ),
  withStyles(styles)
)

export default enhance(AddRound)
