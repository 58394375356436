import React, { Component } from 'react'
import _ from 'lodash'
import dataProvider from '../../../../providers'
import { getResource } from '../../../../config/resources'
import { SelectInput, GET_LIST } from 'react-admin'

export class SelectWinner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      choices: [],
    }
  }

  componentDidMount() {
    this.getTeams(this.props.idHomeTeam, this.props.idAwayTeam)
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { props } = this
    if (props.idHomeTeam !== nextProps.idHomeTeam || props.idAwayTeam !== nextProps.idAwayTeam) {
      this.getTeams(nextProps.idHomeTeam, nextProps.idAwayTeam)
    }
    return true
  }

  getTeams(idHomeTeam, idAwayTeam) {
    let search = []
    if (idHomeTeam) {
      search.push(idHomeTeam)
    }
    if (idAwayTeam) {
      search.push(idAwayTeam)
    }

    dataProvider(GET_LIST, getResource('competiciones', 'teams'), {
      sort: {
        field: 'id',
        order: 'ASC',
      },
      pagination: { page: 1, perPage: 2 },
      filter: {
        id: search,
      },
    }).then((response) => {
      const searchTeams = response.data
      let choices = []

      if (idHomeTeam) {
        choices.push(searchTeams[_.findIndex(searchTeams, ['id', idHomeTeam])])
      }
      if (idAwayTeam) {
        choices.push(searchTeams[_.findIndex(searchTeams, ['id', idAwayTeam])])
      }

      this.setState({
        choices: choices,
      })
    })
  }

  render() {
    return <SelectInput label={this.props.label} source={this.props.source} optionText="selectorName" choices={this.state.choices} allowEmpty fullWidth />
  }
}

export default SelectWinner
