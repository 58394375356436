import React, { Component } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'

class LongMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
    }
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    })
  }

  handleItemClick = ({ item, onClick, index }) => {
    onClick(item, index)
    this.setState({
      anchorEl: null,
    })
  }

  render() {
    const { options, item, index } = this.props
    const open = Boolean(this.state.anchorEl)
    if (!options || !options.menuOptions || options.menuOptions.length <= 0) return null
    return (
      <div>
        <IconButton onClick={this.handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={this.state.anchorEl} keepMounted open={open} onClose={() => this.setState({ anchorEl: null })}>
          {options.menuOptions.map((optionItem) => {
            const { onClick } = optionItem
            return (
              <MenuItem key={optionItem.text} onClick={() => this.handleItemClick({ item, onClick, index })}>
                {optionItem.text}
              </MenuItem>
            )
          })}
        </Menu>
      </div>
    )
  }
}

export default LongMenu
