const PERMISSIONS = {
  admin: 'app_webligacms_rol_admin',
  coordinator: 'app_webligacms_rol_coordinador',
  editor: 'app_webligacms_rol_redactor',
  competition: 'app_webligacms_rol_competiciones',
  foundation: 'app_webligacms_rol_fundación',
  photohrapher: 'app_webligacms_rol_fotografo',
  broadcasters: 'app_webligacms_rol_broadcasters',
  thirdLegal: 'app_webligacms_rol_legal_terceros',
  mondia: 'app_weblaligacms_rol_mondia',
  appjugador: 'app_webligacms_rol_appjugador',
  planetconnect: 'app_weblaligacms_rol_planetconnect',
}

export default PERMISSIONS
