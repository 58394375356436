import styled from 'styled-components'
import { colors } from '../../../../config/theme'

export const ContainerStyled = styled.div`
  margin-top: 10px;
`

export const NoContentStyled = styled.div`
  padding: 40px;
  text-align: center;
  background-color: white;
`

export const BlocksListStyled = styled.div`
  padding: 10px;
  padding-bottom: 1px;
  background-color: ${colors.grey[300]};
`

export const PluginContainerStyled = styled.div`
  display: flex;
  margin-bottom: 10px;
  background-color: ${colors.common.white};
  border: solid 1px ${(props) => (props.highlight ? colors.primary.main : colors.grey[300])};
`

export const PluginStyled = styled.div`
  flex-grow: 1;
`

export const PluginOptions = styled.div`
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
`

export const PluginDraggableAreaStyled = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.grey[700]};
`

export const PluginHeaderStyled = styled.div`
  padding: 11px 10px;
  display: flex;
  align-items: center;
  color: ${colors.grey[700]};
`

export const PluginContentStyled = styled.div`
  max-height: ${(props) => (props.open ? 'auto' : '0px')};
  overflow: ${(props) => (props.open ? 'visible' : 'hidden')};
  transition: all 0.3s;
`
