import Grid from '@material-ui/core/Grid'
import React from 'react'
import { Datagrid, DateField, Filter, List, Pagination, Responsive, SimpleList, TextField, TextInput } from 'react-admin'

import { ImageField, ListActions } from '../../../components'

const PhotoPlayerFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={12} md={12}>
          <TextInput source="club" label="Club" alwaysOn />
          <TextInput source="player" label="Player" alwaysOn />
          <TextInput source="season" label="Season" alwaysOn />
          <TextInput source="formatImage" label="Format" alwaysOn />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<PhotoPlayerFilter />} />
}

const PostPagination = (props) => <Pagination rowsPerPageOptions={[25, 50]} {...props} />

export const PhotoPlayerList = (props) => {
  return (
    <List perPage={25} bulkActions={false} actions={<Actions />} title="Imágenes" {...props} pagination={<PostPagination />}>
      <Responsive
        small={
          <SimpleList
            leftAvatar={(record) => <ImageField record={record} label="State" title="imagen" sizes={['128x128', '128x139', '256x256', '256x278']} />}
            primaryText={(record) => record.fileName}
          />
        }
        medium={
          <Datagrid>
            <ImageField label="Image" title="imagen" sizes={['128x128', '128x139', '256x256', '256x278']} />
            <TextField source="fileName" label="Filename" />
            <TextField source="playerId" label="Player" />
            <TextField source="teamId" label="Team" />
            <TextField source="seasonId" label="Season" />
            <TextField source="formatImage" label="formatImage" />
            <TextField source="version" label="Version" />
            <TextField source="isReal" label="Real" />
            <DateField showTime source="createdAt" label="Created At" />
          </Datagrid>
        }
      />
    </List>
  )
}
export default PhotoPlayerList
