import React, { Component } from 'react'
import { Field } from 'redux-form'
import Selectors from './matchInputs'

class MatchInput extends Component {
  render() {
    const { source, ...rest } = this.props
    if (this.props.source) {
      return <Field {...rest} component={Selectors} name={source} />
    } else {
      return <Selectors {...this.props} />
    }
  }
}

export default MatchInput
