import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import React, { Component } from 'react'

import { getResource } from '../../../../config/resources'
import MetadataInput from '../metadata'

const EVENT = 'event'
const ACT = 'act'

class EventInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      source: null,
      perPage: 50,
    }
  }

  onChange(metadatas, type) {
    const { onChange } = this.props.input ? this.props.input : this.props
    const newMetadata = this.resetSelects(metadatas, type)
    onChange(newMetadata)
  }

  resetSelects(metadatas, type) {
    let typeToRemove = []
    switch (type) {
      case EVENT:
        typeToRemove = [ACT]
        break
    }

    return _.differenceWith(metadatas, typeToRemove, (arrVal, othVal) => {
      return arrVal.type === othVal
    })
  }

  getFromMetadata(type) {
    const { value } = this.props.input ? this.props.input : this.props
    const selectedItem = _.find(value, (item) => {
      return item.type === type
    })
    return selectedItem ? selectedItem.value : null
  }

  render() {
    const { value } = this.props.input ? this.props.input : this.props
    const event = this.getFromMetadata(EVENT)

    return (
      <Grid container>
        <Grid item xs={12} sm={6} md={4}>
          <MetadataInput
            value={value}
            placeholder="Write event..."
            mode="autocomplete"
            onChange={(metadatas) => this.onChange(metadatas, EVENT)}
            label="Event"
            type={EVENT}
            reference={getResource('assets', 'events')}
            sort={{
              field: 'name',
              order: 'ASC',
            }}
          />
        </Grid>
        {event && (
          <Grid item xs={12} sm={6} md={4}>
            <MetadataInput
              value={value}
              onChange={(metadatas) => this.onChange(metadatas, ACT)}
              label="Act"
              type={ACT}
              reference={getResource('assets', 'acts')}
              sort={{
                field: 'name',
                order: 'ASC',
              }}
              filter={{
                event: event,
              }}
            />
          </Grid>
        )}
      </Grid>
    )
  }
}

export default EventInput
