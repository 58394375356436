import Grid from '@material-ui/core/Grid'
import React from 'react'
import { Filter, SelectInput, ReferenceInput, Datagrid, EditButton, List, Responsive, SimpleList, TextField, ReferenceField } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'
import { getResource } from '../../../config/resources'

const ListFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} md={4} lg={2}>
          <ReferenceInput label="Categoria" source="category" reference={getResource('layouts', 'beyond-stats-categories')} perPage={50} allowEmpty alwaysOn>
            <SelectInput />
          </ReferenceInput>
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<ListFilter />} />
}

export const BeyonStatsBlocksList = (props) => {
  return (
    <List bulkActions={false} actions={<Actions />} {...props}>
      <Responsive
        small={
          <SimpleList
            primaryText={(record) => (
              <ReferenceField
                label="Category"
                linkType={false}
                source="category.id"
                reference={getResource('layouts', 'beyond-stats-categories')}
                basePath={getResource('layouts', 'beyond-stats-categories')}
                record={record}
              >
                <TextField source="name" />
              </ReferenceField>
            )}
            secondaryText={(record) => record.title}
          />
        }
        medium={
          <Datagrid>
            <ReferenceField label="Category" source="category.id" reference={getResource('layouts', 'beyond-stats-categories')} linkType={false}>
              <TextField source="name" />
            </ReferenceField>
            <TextField source="title" label="Title" />
            <TableRowList>
              <EditButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}

export default BeyonStatsBlocksList
