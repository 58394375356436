import styled from 'styled-components'
import { colors } from '../../../config/theme'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import IconButton from '@material-ui/core/IconButton'
import ErrorIcon from '@material-ui/icons/Error'

export const ImageFieldStyled = styled.div`
  position: relative;
  padding-left: 24px;
  margin: 0px;
  line-height: 0px;
  &:before {
    display: block;
    content: '';
    left: 0px;
    top: 0px;
    position: absolute;
    border: solid 1px transparent;
    border-color: ${(props) => props.stateColorBorder};
    background-color: ${(props) => props.stateColor};
    width: 24px;
    height: 100%;
    box-sizing: border-box;
  }
`
export const InfoStyled = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: ${colors.common.black};
  color: ${colors.common.white};
  padding: 8px 10px;
  font-size: 11px;
  line-height: 13px;
`

export const PlaceholderImgStyled = styled.div`
  width: 100%;
  height: 200px;
  background-color: ${colors.grey[400]};
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CloudUploadIconStyled = styled(CloudUploadIcon)`
  padding: 10px;
  color: ${colors.grey[700]};
`
export const ErrorIconStyled = styled(ErrorIcon)`
  padding: 10px;
  color: ${colors.grey[700]};
`

export const RefreshButtonStyled = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`
