import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { ImageInput } from '../../../inputs'
import { TextInput } from 'react-admin'

const BannerForm = () => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <ImageInput source="config.image" label="Image" accept="image/*" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextInput label="Url" fullWidth source="config.url" />
      </Grid>
    </Grid>
  )
}

export default BannerForm
