export const states = {
  published: 'published',
  draft: 'draft',
  hidden: 'hidden',
  disabled: 'disabled',
  programmed: 'programmed',
}

export const choicesStates = [
  {
    id: states.published,
    name: 'Published',
  },
  {
    id: states.draft,
    name: 'Draft',
  },
  {
    id: states.hidden,
    name: 'Hidden',
  },
  {
    id: states.disabled,
    name: 'Disabled',
  },
  {
    id: states.programmed,
    name: 'Programmed',
  },
]
