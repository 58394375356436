import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import _get from 'lodash/get'
import React, { Component } from 'react'
import { GET_LIST } from 'react-admin'

import { getResource } from '../../../../config/resources'
import { dataProvider } from '../../../../providers'
import MetadataInput from '../metadata'

const styles = () => ({
  fullWidth: {
    width: '100%',
  },
})

class PlayerInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      seasons: [],
      teams: [],
      subscriptions: [],
      idSubscription: false,
      isFetchingTeams: false,
      filters: {
        season: null,
        team: null,
      },
    }
  }

  componentDidMount() {
    this.getSeasons()
  }

  getSeasons = () => {
    dataProvider(GET_LIST, getResource('competiciones', 'seasons'), {
      sort: {
        field: 'year',
        order: 'DESC',
      },
      filter: { showInWeb: 1 },
      pagination: {},
    }).then(({ data }) => {
      this.setState({
        seasons: data,
      })
    })
  }

  getSubscriptions = (seasonId) => {
    this.setState({
      subscriptions: [],
    })
    dataProvider(GET_LIST, getResource('competiciones', 'subscriptions'), {
      sort: {
        field: 'id',
        order: 'ASC',
      },
      filter: { season: seasonId },
      pagination: {},
    }).then(({ data }) => {
      this.setState({
        subscriptions: data,
      })
    })
  }

  getTeams = (subscriptionId) => {
    this.setState({ teams: [] })
    dataProvider(GET_LIST, getResource('competiciones', 'teams'), {
      sort: {},
      filter: { subscription: subscriptionId },
      pagination: {},
    }).then(({ data }) => {
      this.setState({
        teams: data,
      })
    })
  }

  handleChangeSeason = (event) => {
    const value = event?.target?.value

    let filters = this.state.filters
    filters.season = null
    if (value) {
      filters.season = value
      this.getSubscriptions(value)
    }
    this.setState({
      filters,
      idSubscription: false,
    })
  }

  handleChangeSubscription = (event) => {
    const value = event?.target?.value
    if (value) {
      this.setState({
        idSubscription: value,
      })
      this.getTeams(value)
    } else {
      this.setState({
        idSubscription: null,
      })
    }
  }

  handleChangeTeam = (event) => {
    const value = event?.target?.value

    let filters = this.state.filters
    filters.team = null
    if (value) {
      filters.team = value
    }
    this.setState({
      filters,
    })
  }

  renderOptionText = (value) => {
    return `${_get(value, 'shirtNumber') || ''} ${_get(value, 'personRole.person.name')} (${_get(value, 'personRole.person.firstname')} ${_get(
      value,
      'personRole.person.lastname'
    )})`
  }

  render() {
    const { value, onChange, classes } = this.props

    return (
      <Grid container>
        <Grid item xs={12} md={4}>
          <FormControl className={classes.fullWidth}>
            <InputLabel htmlFor="input-season">Season</InputLabel>
            <Select
              value={this.state.filters.season}
              onChange={this.handleChangeSeason}
              inputProps={{
                id: 'input-season',
              }}
              displayEmpty
              fullWidth
            >
              <MenuItem></MenuItem>
              {this.state.seasons.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl className={classes.fullWidth}>
            <InputLabel htmlFor="input-subscription">Subscriptions</InputLabel>
            <Select
              value={this.state.idSubscription}
              onChange={this.handleChangeSubscription}
              inputProps={{
                id: 'input-subscription',
              }}
              disabled={this.state.filters.season === null}
              displayEmpty
              fullWidth
            >
              <MenuItem></MenuItem>
              {this.state.subscriptions.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.competitionName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl className={classes.fullWidth}>
            <InputLabel htmlFor="input-team">Teams</InputLabel>
            <Select
              value={this.state.filters.team}
              onChange={this.handleChangeTeam}
              inputProps={{
                id: 'input-team',
              }}
              disabled={this.state.idSubscription === null}
              displayEmpty
              fullWidth
            >
              <MenuItem></MenuItem>
              {this.state.teams.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.selectorName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <MetadataInput
            value={value}
            mode="autocomplete"
            type={'person'}
            label={'Player'}
            onChange={(metadatas) => onChange(metadatas)}
            reference={getResource('competiciones', 'squads')}
            optionText={this.renderOptionText}
            optionValue="personRole.person.id"
            filter={{
              current: 1,
              ...this.state.filters,
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(PlayerInput)
