import { Button, Table, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import DeleteIcon from '@material-ui/icons/Delete'
import DoneIcon from '@material-ui/icons/Done'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { Fragment } from 'react'
import { change, formValueSelector } from 'redux-form'
import { DELETE, fetchEnd, fetchStart, showNotification, Pagination } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { ConfirmationDialog, SubForm } from '../../../../components'
import { getResource } from '../../../../config/resources'
import { dataProvider } from '../../../../providers'
import { colors } from '../../../../config/theme'
import RuleForm from '../../rules/form/ruleForm'

const FORM = 'record-form'
const selector = formValueSelector(FORM)

const styles = (theme) => ({
  row: {
    backgroundColor: colors.states.warningLight,
  },
  cell: {
    textAlign: 'right',
  },
  leftIcon: {
    marginRight: '10px',
  },
  btnError: {
    color: fade(theme.palette.error.main, 0.9),
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
})

class ListRules extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      form: null,
      page: 1,
      perPage: 5,
      total: 0,
      idDeleteErrorImport: false,
    }
  }

  componentDidMount() {
    this.getList(1, 5)
  }

  getList(page, perPage) {
    const { field } = this.props
    const errorImports = field('errorImports')
    this.setState({
      page: page,
      perPage: perPage,
      total: errorImports.length,
    })
  }

  onSaveRule(data) {
    const { change, field } = this.props
    const index = _.findIndex(field('errorImports'), ['rule.id', data.id])

    change(FORM, `errorImports[${index}].rule`, data)
    this.setState({
      form: null,
    })
  }

  deleteErrorImport(idErrorImport) {
    const { fetchStart, fetchEnd, showNotification, change, field } = this.props

    fetchStart()
    dataProvider(DELETE, getResource('operadores', 'error-imports'), {
      id: idErrorImport,
    })
      .then((response) => {
        let errorImports = field('errorImports')
        const index = _.findIndex(errorImports, ['id', idErrorImport])
        errorImports.splice(index, 1)
        change(FORM, 'errorImports', errorImports)
        showNotification('Elements deleted', 'success')
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        this.setState({
          idDeleteErrorImport: false,
        })
        fetchEnd()
      })
  }

  ListRules = ({ classes, errorImports, page, perPage }) => {
    const indexIni = (page - 1) * perPage
    const list = errorImports.slice(indexIni, indexIni + perPage)
    return list.map((item, key) => {
      return (
        <TableRow key={key}>
          <TableCell>
            <Typography variant="body1" gutterBottom>
              {item.rule.name}
            </Typography>
          </TableCell>
          {item.rule.state === 'revise' && (
            <TableCell className={classes.cell}>
              <Button size="small" color="primary" onClick={() => this.setState({ form: item.rule.id })}>
                <AddIcon className={classes.leftIcon} />
                Edit
              </Button>
              {/* Descomentar cuando se puedan hacer DELETES de error-imports 
              <Button size="small" className={classes.btnError} onClick={()=>this.setState({idDeleteErrorImport: item.id})}>
                <DeleteIcon className={classes.leftIcon} />Delete
              </Button> */}
              <ConfirmationDialog
                title="Edit rule"
                open={this.state.form === item.rule.id}
                maxWidth="md"
                dialogActions={false}
                onCancel={() => this.setState({ form: null })}
              >
                <SubForm
                  record={item.rule}
                  resource={getResource('operadores', 'rules')}
                  onClose={() => this.setState({ form: null })}
                  form="rule-form"
                  onSubmit={(data) => this.onSaveRule(data)}
                >
                  <RuleForm />
                </SubForm>
              </ConfirmationDialog>
            </TableCell>
          )}
          {item.rule.state === 'valid' && (
            <TableCell className={classes.cell}>
              <DoneIcon />
            </TableCell>
          )}
        </TableRow>
      )
    })
  }

  render() {
    const { ListRules } = this
    const { classes, field } = this.props
    const errorImports = field('errorImports')

    return (
      <Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>List of undefined rules</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {errorImports && <ListRules classes={classes} errorImports={errorImports} page={this.state.page} perPage={this.state.perPage} />}
        </Table>
        {errorImports && (
          <Pagination
            setPage={(page) => this.getList(page, this.state.perPage)}
            setPerPage={(perPage) => this.getList(1, perPage)}
            total={this.state.total}
            perPage={this.state.perPage}
            page={this.state.page}
          />
        )}
        <ConfirmationDialog
          open={this.state.idDeleteErrorImport}
          title="Are you sure?"
          onCancel={() => {
            this.setState({
              idDeleteErrorImport: false,
            })
          }}
          onSuccess={() => {
            this.deleteErrorImport(this.state.idDeleteErrorImport)
          }}
        />
      </Fragment>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      change: (form, field, value) => dispatch(change(form, field, value)),
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
    })
  ),
  withStyles(styles)
)

export default enhance(ListRules)
