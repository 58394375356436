import React, { Component } from 'react'
import { Field } from 'redux-form'
import TextArray from './textArray'

class TextArrayInput extends Component {
  render() {
    if (this.props.source) {
      return <Field {...this.props} label={this.props.label} component={TextArray} name={this.props.source} />
    } else {
      return <TextArray {...this.props} />
    }
  }
}

export default TextArrayInput
