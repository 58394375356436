import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import CloudUpload from '@material-ui/icons/CloudUpload'
import React from 'react'
import Dropzone from 'react-dropzone'
import _ from 'lodash'

import { getResource } from '../../../../config/resources'
import dataProvider from '../../../../providers'
import { CREATE_IMAGE } from '../../../../providers/dataProvider/types'
import ImageForm from './imageForm'
import { DropzoneStyled, FormStyled } from './style'

const DRAGGING = 'dragging'
const FORM = 'form'
const LIST = 'list'

const styles = (theme) => ({
  dropzone: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    backgroundColor: theme.palette.grey[300],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all .3s',
    fontSize: '16px',
    zIndex: '100',
    border: `dashed 2px ${theme.palette.grey[400]}`,
  },
  dropzoneReject: {
    borderColor: theme.palette.states.error,
  },
  dropzoneAccept: {
    borderColor: theme.palette.primary.main,
  },
  disableEvents: {
    pointerEvents: 'none',
  },
  upload: {
    width: '70px',
    height: '70px',
    fill: theme.palette.grey[600],
  },
  continerDropzone: {
    position: 'relative',
  },
})

class DropzoneWithPreview extends React.Component {
  constructor() {
    super()
    this.state = {
      fetching: false,
      file: null,
      status: LIST,
      isDragging: false,
    }
  }

  onDrop(file) {
    const numFiles = file.length
    let files = []
    this.setState({
      fetching: true,
    })
    _.map(file, (myFile) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        this.setAsset(reader.result).then((response) => {
          files.push({ ...response.data, filename: myFile.name })
          if (files.length >= numFiles) {
            this.setState({
              error: null,
              file: files,
              status: FORM,
              fetching: false,
            })
          }
        })
      }
      reader.readAsArrayBuffer(myFile)
      return {
        ...myFile,
        preview: URL.createObjectURL(myFile),
      }
    })
  }

  onDragStart() {
    this.setState({
      state: 'drag',
    })
  }

  onSubmitForm(data) {
    if (this.props.onSubmit) this.props.onSubmit()
    this.setState({
      status: LIST,
    })
  }

  onDragLeave() {
    this.setState({
      state: null,
    })
  }

  setAsset(asset) {
    return dataProvider(CREATE_IMAGE, getResource('assets', 'assets'), {
      data: asset,
    }).catch((error) => {
      this.setState({
        error: error,
        status: LIST,
      })
    })
  }

  render() {
    return (
      <section>
        <div>
          <Dropzone
            accept="image/*"
            disableClick
            multiple
            onDropAccepted={this.onDrop.bind(this)}
            onDropRejected={() => this.setState({ status: LIST })}
            onDragEnter={() => {
              this.setState({ status: DRAGGING })
            }}
            onDragLeave={() => this.setState({ status: LIST })}
            className={this.props.classes.continerDropzone}
            acceptClassName={this.props.classes.dropzoneAccept}
            rejectClassName={this.props.classes.dropzoneReject}
          >
            <div>
              {this.state.status === DRAGGING && (
                <DropzoneStyled>
                  {!this.state.fetching && <CloudUpload className={this.props.classes.upload} color="secondary" />}
                  {this.state.fetching && <CircularProgress color="primary" />}
                </DropzoneStyled>
              )}
              {this.state.status === FORM && (
                <FormStyled>
                  <ImageForm onClose={() => this.setState({ status: LIST })} onSubmit={(data) => this.onSubmitForm(data)} file={this.state.file} />
                </FormStyled>
              )}
              {this.state.status !== FORM && <div className={this.state.status !== LIST ? this.props.classes.disableEvents : ''}>{this.props.children}</div>}
            </div>
          </Dropzone>
        </div>
      </section>
    )
  }
}

export default withStyles(styles)(DropzoneWithPreview)
