import React from 'react'
import { connect } from 'react-redux'
import { GET_LIST, showNotification } from 'react-admin'
import { Typography, CircularProgress, Button, Grid, Collapse } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { arrayMove, List as MovableList } from 'react-movable'
import MovableItem from '../../movableItem'
import dataProvider from '../../../../../providers'
import { getResource } from '../../../../../config/resources'
import { PATCH } from '../../../../../providers/dataProvider/types'

class RRSSList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openList: false,
      rrss: [],
      loading: false,
      fetching: false,
    }
  }

  componentDidMount() {
    this.getRRSS()
  }

  getRRSS = () => {
    this.setState({ loading: true })
    dataProvider(GET_LIST, getResource('layouts', 'social-media-profiles'), {
      sort: {
        field: 'positionApp',
        order: 'ASC',
      },
      pagination: { page: 1, perPage: 20 },
    }).then((res) => this.setState({ rrss: res?.data, loading: false }))
  }

  handleChangeOrder = ({ oldIndex, newIndex }) => {
    let newContent = this.state.rrss
    newContent = arrayMove(newContent, oldIndex, newIndex)
    newContent = newContent.map((item, index) => ({
      ...item,
      positionApp: index,
    }))
    this.setState({ rrss: newContent })
  }

  handleSaveData = () => {
    this.setState({ fetching: true })
    Promise.all(
      this.state.rrss.map((socialMedia) => {
        return dataProvider(PATCH, getResource('layouts', 'social-media-profiles'), {
          id: socialMedia.id,
          data: socialMedia,
        })
      })
    )
      .then(({ data }) => {
        this.setState({ fetching: false })
        this.props.showNotification('RRSS order updated', 'success')
      })
      .catch((error) => {
        this.setState({ error: true, fetching: false })
        this.props.showNotification(error.message, 'error')
      })
  }

  handleClick = () => {
    this.setState({
      openList: !this.state.openList,
    })
  }

  render() {
    return (
      <>
        <div style={{ margin: '29px' }}>
          {this.state.loading ? (
            <div style={{ display: 'flex' }}>
              <Typography component="h2" variant="h2" style={{ marginBottom: '16px', marginRight: '16px' }}>
                Loading RRSS
              </Typography>
              <CircularProgress size={24} />
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'flex-start' }} onClick={() => this.handleClick()}>
              <Typography component="h2" variant="h2" style={{ marginBottom: '16px' }}>
                RRSS
              </Typography>
              <div style={{ marginLeft: '16px' }}>{this.state.openList ? <ExpandLess /> : <ExpandMore />}</div>
            </div>
          )}

          {this.state.rrss && this.state.openList && (
            <Collapse in={this.state.openList} timeout={350}>
              <Grid item xs={12} lg={8}>
                <div style={{ opacity: this.state.loading ? 0 : 1, transition: 'opacity 350ms' }}>
                  <MovableList
                    lockVertically
                    values={this.state.rrss}
                    onChange={this.handleChangeOrder}
                    renderList={({ children, props }) => <div {...props}>{children}</div>}
                    renderItem={({ index, props, isDragged, value }) => {
                      return (
                        <div {...props} key={value.id || index} index={index} style={{ ...props.style, zIndex: 1 }}>
                          <MovableItem isDragged={isDragged} index={index} item={value} />
                        </div>
                      )
                    }}
                  />
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={this.state.loading || this.state.fetching}
                      onClick={() => this.handleSaveData()}
                    >
                      {this.state.fetching ? 'Saving...' : 'Save'}
                    </Button>
                  </Grid>
                </div>
              </Grid>
            </Collapse>
          )}
        </div>
      </>
    )
  }
}

const enhance = connect(null, {
  showNotification,
})

export default enhance(RRSSList)
