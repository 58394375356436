import React, { Component } from 'react'
import { Field } from 'redux-form'
import Picker from './picker'

class ColorPicker extends Component {
  render() {
    if (this.props.source) {
      return (
        <Field
          component={Picker}
          name={this.props.source}
          label={this.props.label ? this.props.label : 'Color'}
          disabled={this.props.disabled || false}
          clearable={this.props.clearable || false}
        />
      )
    } else {
      return <Picker {...this.props} />
    }
  }
}

export default ColorPicker
