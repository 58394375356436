import React from 'react'
import { Create } from 'react-admin'
import AdsSpaceForm from './form'

const AdsSpaceCreate = (props) => (
  <Create {...props}>
    <AdsSpaceForm isEdit={false} {...props} />
  </Create>
)

export default AdsSpaceCreate
