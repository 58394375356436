import 'moment/locale/es'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import moment from 'moment-timezone'
import React, { Component } from 'react'
import { SingleDatePicker } from 'react-dates'

import { DateGlobalStyled, SelectsGlobalStyled } from '../style'

class DateSingle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      focusedInput: false,
      dateInput: null,
    }
  }

  componentDidMount() {
    const { value } = this.props.input ? this.props.input : this.props
    const initialDate = value ? moment(value) : null
    this.setState({ dateInput: initialDate })
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { value } = this.props.input ? this.props.input : this.props
    const nextValue = nextProps.input ? nextProps.input.value : nextProps.value

    if (value !== nextValue) {
      const initialDate = nextValue ? moment(nextValue) : null
      this.setState({ dateInput: initialDate })
    }

    return true
  }

  render() {
    const {
      input,
      rangeStartDate = moment('2010-01-01'),
      rangeEndDate = moment().add(10, 'year'),
      label = false,
      meta,
      source,
      fullWidth,
      filterForm,
      changeField,
      isRequired,
      hideReset = false,
      ...otherProps
    } = this.props
    const classLabel = label ? 'hasLabel' : ''
    return (
      <FormControl error={(meta && meta.error && meta.touched) || this.state.error}>
        <InputLabel>
          {label} {isRequired ? '*' : ''}
        </InputLabel>
        <DateGlobalStyled className={classLabel}>
          <SingleDatePicker
            id={`singleDate_${this.props.name}`}
            date={this.state.dateInput}
            onDateChange={(date) => {
              if (date) {
                date.tz('UTC')
                date.hour(0).minute(0).second(0)
              }
              this.setState({ dateInput: date })
              const timestamp = date !== null ? date.toISOString(true) : date
              if (input) {
                input.onChange(timestamp)
              } else {
                this.props.onChange(timestamp)
              }
            }}
            focused={this.state.focusedInput}
            onFocusChange={(focused) => {
              this.setState({ focusedInput: focused.focused })
            }}
            numberOfMonths={1}
            placeholder="Fecha"
            isOutsideRange={(day) => {
              return parseInt(day.format('X')) < parseInt(rangeStartDate.format('X')) || parseInt(day.format('X')) > parseInt(rangeEndDate.format('X'))
            }}
            showClearDate={hideReset ? false : true}
            hideKeyboardShortcutsPanel
            renderMonthElement={({ month, onMonthSelect, onYearSelect }) => {
              const arrayYears = []
              for (var i = rangeStartDate.year(); i <= rangeEndDate.year(); i++) arrayYears.push(i)

              return (
                <SelectsGlobalStyled>
                  <div>
                    <Select
                      native
                      value={month.month()}
                      onChange={(e) => {
                        onMonthSelect(month, e.target.value)
                      }}
                    >
                      {moment.months().map((label, value) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <Select
                      native
                      value={month.year()}
                      onChange={(e) => {
                        onYearSelect(month, e.target.value)
                      }}
                    >
                      {arrayYears.map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </Select>
                  </div>
                </SelectsGlobalStyled>
              )
            }}
            {...otherProps}
          />
        </DateGlobalStyled>
        {((meta && meta.error && meta.touched) || this.state.error) && (
          <FormHelperText>
            {meta.error}
            <div>{this.state.error}</div>
          </FormHelperText>
        )}
      </FormControl>
    )
  }
}

export default DateSingle
