import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import RichTextInput from 'ra-input-rich-text'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import _ from 'lodash'
import {
  ArrayInput,
  FormDataConsumer,
  maxLength,
  NumberInput,
  ReferenceInput,
  required,
  SimpleFormIterator,
  TextInput,
  SelectInput,
  LongTextInput,
  REDUX_FORM_NAME,
} from 'react-admin'
import { AutocompleteInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'
import { getResource } from '../../../../config/resources'
import { change } from 'redux-form'

const styles = {
  simpleFormIteratorStyle: {
    '& ul': {
      '& li': {
        '& > section': {
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          '& > div': {
            flex: '1 0 auto',
            maxWidth: '220px',
            padding: '10px',
          },
        },
      },
    },
  },
  iteratorInput: {
    marginTop: '10px',
  },
}

const SelectQueryParam = ({ ...props }) => {
  const { choices, input, source, dispatch, change, ...rest } = props
  const { value, onChange } = props.input

  const newOnChange = (inputValue) => {
    const nameSource = _.replace(source, '.id', '')
    dispatch(change(REDUX_FORM_NAME, `${nameSource}.name`, _.find(choices, { id: inputValue }).name))
    onChange(inputValue)
  }

  const newInput = { ...input }
  newInput.onChange = newOnChange

  const newProps = { ...props }

  newProps.input = newInput

  return <SelectInput {...newProps} />
}

const AssistanceForm = ({ classes }) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={6} md={6}>
        <NumberInput validate={[required()]} label="Assistance" source="config.attempt_current" step={1} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <NumberInput validate={[required()]} label="Previous assistance" source="config.attempt_previous" step={1} fullWidth />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <LongTextInput label="Note ES" source="config.attempt_season_es" fullWidth toolbar={[[]]} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <LongTextInput label="Note EN" source="config.attempt_season_en" fullWidth toolbar={[[]]} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <LongTextInput label="Note ES" source="config.attempt_note_es" fullWidth toolbar={[[]]} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <LongTextInput label="Note EN" source="config.attempt_note_en" fullWidth toolbar={[[]]} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <div className={classes.simpleFormIteratorStyle}>
          <ArrayInput source="config.attempt_previous_season">
            <SimpleFormIterator className={classes.SimpleFormIteratorStyle} classes={classes} fullWidth>
              <TextInput validate={[required()]} label="Season" source="attempt_season" fullWidth />
              <NumberInput validate={[required()]} label="Percentage" source="attempt_percentage" fullWidth />
              <NumberInput validate={[required()]} label="Number" source="attempt_number" step={1} fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </div>
      </Grid>
    </Grid>
  )
}

export const ModuleForm = ({ classes, ...props }) => (
  <LanguagesNav {...props} removeDelete disabledLanguages>
    <FormDataConsumer>
      {({ formData, dispatch, ...rest }) => (
        <Grid container>
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <TextInput fullWidth label="Name" source="name" validate={[required(), maxLength(255)]} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <ReferenceInput label="Digital asset" source="digitalAssetId" reference={getResource('digitalAssets', 'digital-assets')}>
                <AutocompleteInput fullWidth />
              </ReferenceInput>
            </Grid>
          </Grid>
          <ArrayInput source="queryParams">
            <SimpleFormIterator>
              <ReferenceInput className={classes.iteratorInput} label="Name" source="id" reference={getResource('layouts', 'queryParams')}>
                <SelectQueryParam dispatch={dispatch} change={change} />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
          {formData && formData.slug === 'modulo-asistencia' && (
            <Paper>
              <AssistanceForm classes={classes} />
            </Paper>
          )}
        </Grid>
      )}
    </FormDataConsumer>
  </LanguagesNav>
)

const enhance = compose(
  connect(
    (state) => ({
      //field: source => selector(state, source)
    }),
    (dispatch) => ({
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(ModuleForm)
