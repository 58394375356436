import React from 'react'
import Grid from '@material-ui/core/Grid'
import { List, SimpleList, Filter, TextField, Responsive, TextInput, EditButton, Datagrid, ReferenceField, DeleteButton } from 'react-admin'
import { ListActions, TableRowList } from '../../../components'
import { DateField } from '../../../components/field'
import { getResource } from '../../../config/resources'

const EventsFilter = (props) => (
  <Filter {...props}>
    <Grid container alwaysOn>
      <Grid item xs={12}>
        <TextInput source="q" label="Search" alwaysOn fullWidth />
      </Grid>
    </Grid>
  </Filter>
)

const Actions = (props) => {
  return <ListActions {...props} filters={<EventsFilter />} />
}

export const EventsList = (props) => (
  <List bulkActions={false} actions={<Actions />} title="Events" {...props}>
    <Datagrid>
      <TextField source="name" label="Name" />
      <ReferenceField label="Category" source="category.id" reference={getResource('layouts', 'event-categories')} allowEmpty linkType={false}>
        <TextField source="name" />
      </ReferenceField>
      <DateField source="startedAt" label="Start" />
      <DateField source="endAt" label="End" />
      <TableRowList>
        <EditButton />
        <DeleteButton />
      </TableRowList>
    </Datagrid>
  </List>
)

export default EventsList
