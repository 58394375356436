import React from 'react'
import Grid from '@material-ui/core/Grid'
import {
  ArrayField,
  ChipField,
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceInput,
  Responsive,
  SimpleList,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin'

import { ListActions, TableRowList } from '../../../components'
import { AutocompleteInput } from '../../../components/inputs'
import { getResource } from '../../../config/resources'

const RulesFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} md={4}>
          <TextInput className={props.classes.input} variant="filled" label="Search" source="q" alwaysOn fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReferenceInput label="Broadcaster" source="broadcaster" reference={getResource('operadores', 'broadcasters')} alwaysOn allowEmpty fullWidth>
            <AutocompleteInput inputValueMatcher={() => null} />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={4}>
          <ReferenceInput label="Country" source="country" reference={getResource('transversal', 'countries')} alwaysOn allowEmpty fullWidth>
            <AutocompleteInput inputValueMatcher={() => null} />
          </ReferenceInput>
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<RulesFilter />} />
}

export const RulestList = (props) => (
  <List bulkActions={false} actions={<Actions />} title="Operadores" {...props}>
    <Responsive
      small={<SimpleList primaryText={(record) => record.name} secondaryText={(record) => (record.broadcaster ? record.broadcaster.name : '')} />}
      medium={
        <Datagrid>
          <TextField source="name" label="Name" />
          <TextField source="broadcaster.name" label="Broadcaster" sortable={false} />
          <ArrayField source="ruleCountries" label="Countries" sortable={false}>
            <SingleFieldList linkType={false}>
              <ChipField source="country" />
            </SingleFieldList>
          </ArrayField>
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default RulestList
