import React, { Component } from 'react'
import 'react-image-crop/dist/ReactCrop.css'
import Button from '@material-ui/core/Button'
import { Toolbar } from '@material-ui/core'
import { GrowStyled, CropperContentStyled, ReactCropStyled } from './style'

class Cropper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cropOptions: props.cropperOptions,
    }
  }

  static defaultProps = {
    type: 'image/jpeg',
  }

  onChange = (crop) => {
    if (this.state.image) {
      const { cropperOptions } = this.props
      let newCropperOptions = { ...cropperOptions }
      newCropperOptions.crop = crop
      this.setState({
        cropOptions: newCropperOptions,
      })
    }
  }

  onComplete = (crop) => {
    if (this.state.image) {
      const { cropperOptions } = this.props
      let newCropperOptions = { ...cropperOptions }

      if (crop.width <= 0 || crop.height <= 0) {
        newCropperOptions = this.getFullSizeCrop(cropperOptions, this.state.image)
      } else {
        newCropperOptions.crop = crop
      }
      this.setState({
        cropOptions: newCropperOptions,
      })
    }
  }

  async onSubmit(value, cropOptions) {
    const { onSubmit, type } = this.props

    const croppedImg = await this.getCroppedImg(value, cropOptions, 'img', type)
    onSubmit(croppedImg)
  }

  getCroppedImg = (image, cropOptions, fileName, type) => {
    const myCrop = cropOptions.crop
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const width = cropOptions.width ? cropOptions.width : myCrop.width * scaleX
    const height = cropOptions.height ? cropOptions.height : myCrop.height * scaleY
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(image, myCrop.x * scaleX, myCrop.y * scaleY, myCrop.width * scaleX, myCrop.height * scaleY, 0, 0, width, height)

    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        blob.name = fileName
        resolve(blob)
      }, type)
    })
  }

  getFullSizeCrop(cropperOptions, image) {
    const newCropOptions = { ...cropperOptions }

    newCropOptions.crop = {
      ...newCropOptions.crop,
      x: 0,
      y: 0,
      width: image.width,
      height: image.height,
    }

    return newCropOptions
  }

  render() {
    const { value, onImageLoaded, cropperOptions } = this.props
    const { minWidth, minHeight, ...cropOptions } = this.state.cropOptions
    const ratio = this.state.image ? this.state.image.clientWidth / this.state.image.naturalWidth : 1

    return (
      <CropperContentStyled>
        <ReactCropStyled
          style={{
            minWidth: minWidth * ratio,
            minHeight: minHeight * ratio,
          }}
          onComplete={this.onComplete}
          src={value}
          onImageLoaded={(image) => {
            if (onImageLoaded) onImageLoaded(image)

            let newCropOptions = { ...cropperOptions }

            if (!cropperOptions.crop || (!cropperOptions.crop.height && !cropperOptions.crop.width && !cropperOptions.crop.x && !cropperOptions.crop.y)) {
              newCropOptions = this.getFullSizeCrop(cropperOptions, image)
            }
            this.setState({
              image: image,
              cropOptions: newCropOptions,
            })
          }}
          {...cropOptions}
          onChange={this.onChange}
          minWidth={minWidth ? minWidth * ratio : null}
          minHeight={minHeight ? minHeight * ratio : null}
        />
        <Toolbar>
          <GrowStyled />
          <Button onClick={() => this.onSubmit(this.state.image, this.state.cropOptions)} color="primary">
            Submit
          </Button>
        </Toolbar>
      </CropperContentStyled>
    )
  }
}

export default Cropper
