import { Grid } from '@material-ui/core'
import React from 'react'
import { maxLength, required, TextInput } from 'react-admin'

import { LanguagesNav } from '../../../../components'

export const PageForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12}>
        <TextInput fullWidth source="name" label="Name" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <TextInput fullWidth source="position" label="Position" validate={[required(), maxLength(255)]} />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default PageForm
