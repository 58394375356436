import { fetchUtils } from 'react-admin'

import { getTokenRedirect, myMSALObj } from '../../config/msal'
import { tokenRequest } from '../../config/msal/authConfig'

const httpClientAction = (options, token, url, i) => {
  let myOptions = { ...options }
  myOptions.headers = new Headers({
    Accept: 'application/json',
    Authorization: 'Bearer ' + token,
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_BACKEND_SUBSCRIPTION_KEY,
    'Content-Language': options.contentLanguage,
    ...options.headers,
  })
  return fetchUtils
    .fetchJson(url, myOptions)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((error) => {
      if (error.name === 'Invalid JWT Token' || error.status === 403) {
        localStorage.clear()
        if (i >= 9) return Promise.reject(error)
        return handleTokenExpired(url, options, ++i)
      }
      return Promise.reject(error)
    })
}

const httpClient = (url, options = {}) => {
  const i = 0
  const token = localStorage.getItem('adal.access.token.keyhttps://graph.microsoft.com')
  if (token) {
    return httpClientAction(options, token, url, i)
  } else {
    return handleTokenExpired(url, options, i)
  }
}

const handleTokenExpired = (url, options = {}, i) => {
  const account = myMSALObj.getActiveAccount()
  return getTokenRedirect(tokenRequest, account)
    .then((response) => {
      localStorage.setItem('token', response.accessToken)
      return httpClientAction(options, response.accessToken, url, i)
    })
    .catch((error) => {
      console.error(error)
    })
}

export default httpClient
