import _ from 'lodash'
import { stringify } from 'query-string'
import { fetchUtils, GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE, CREATE, UPDATE, UPDATE_MANY, DELETE, DELETE_MANY } from 'react-admin'

import resources from '../../config/resources'
import {
  CLONE,
  CREATE_IMAGE,
  MY_CREATE_MANY,
  MY_UPDATE_MANY,
  PATCH,
  GET,
  GET_MIDDLEWARE,
  POST_MANY,
  RESIZE,
  CREATE_SH,
  GET_ONE_FOOTBALL,
  POST_ONE_FOOTBALL,
  DELETE_ONE_FOOTBALL,
  POST_ONE_WSC_VIDEO,
  GET_ONE_WSC_VIDEO,
} from './types'

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    const apiUrl = resources[resource].api.url
    const endpoint = resources[resource].endpoint
    let url = ''
    const options = {}
    switch (type) {
      case GET_LIST: {
        const page = params.pagination.page ? params.pagination.page : 1
        const perPage = params.pagination.perPage ? params.pagination.perPage : 50
        const { field, order } = params.sort
        const query = {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
          filter: JSON.stringify(params.filter),
        }
        url = `${apiUrl}/${endpoint}?${stringify(query)}`
        break
      }
      case GET:
        url = `${apiUrl}/${endpoint}`
        break
      case GET_ONE:
        url = `${apiUrl}/${endpoint}/${params.id}`
        break
      case GET_MANY: {
        const query = {
          filter: JSON.stringify({ id: params.ids }),
        }
        url = `${apiUrl}/${endpoint}?${stringify(query)}`
        break
      }
      case GET_MANY_REFERENCE: {
        const { page = 1, perPage = 50 } = params.pagination
        const { field, order } = params.sort
        const query = {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
          filter: JSON.stringify({
            ...params.filter,
            [params.target]: params.id,
          }),
        }
        url = `${apiUrl}/${endpoint}?${stringify(query)}`
        break
      }
      case UPDATE:
        url = `${apiUrl}/${endpoint}/${params.id}`
        options.method = 'PUT'
        options.body = JSON.stringify(params.data)
        break
      case CREATE:
        url = `${apiUrl}/${endpoint}`
        options.method = 'POST'
        options.body = JSON.stringify(params.data)
        break
      case CREATE_IMAGE:
        url = `${apiUrl}/${endpoint}`
        options.method = 'POST'
        options.body = params.data
        options.headers = { ...options.headers, 'Content-Type': 'image/jpeg', 'Process-Data': false }
        break
      case DELETE:
        url = `${apiUrl}/${endpoint}/${params.id}`
        options.method = 'DELETE'
        break
      case CLONE:
        url = `${apiUrl}/${endpoint}/${params.id}/clone`
        options.method = 'POST'
        break
      case RESIZE:
        url = `${apiUrl}/${endpoint}/${params.id}/resize`
        options.method = 'PUT'
        if (params?.data) {
          options.body = JSON.stringify(params.data)
        }
        break
      case PATCH:
        url = `${apiUrl}/${endpoint}/${params.id}`
        options.method = 'PATCH'
        options.body = JSON.stringify(params.data)
        break

      case GET_MIDDLEWARE:
        url = `${apiUrl}/${params.query}?${stringify(params.filter)}`
        options.method = 'GET'
        break

      case GET_ONE_FOOTBALL:
        url = `${apiUrl}/${endpoint}/${params.id}/language/${params.language}?${stringify(params.filter)}`
        options.method = 'GET'
        break
      case POST_ONE_FOOTBALL:
        url = `${apiUrl}/${endpoint}/${params.id}/language/${params.language}?${stringify(params.filter)}`
        options.method = 'POST'
        break
      case DELETE_ONE_FOOTBALL:
        url = `${apiUrl}/${endpoint}/${params.id}/language/${params.language}?${stringify(params.filter)}`
        options.method = 'DELETE'
        break
      case GET_ONE_WSC_VIDEO:
        url = `${apiUrl}/${endpoint}/${params.id}`
        options.method = 'GET'
        break
      case POST_ONE_WSC_VIDEO:
        url = `${apiUrl}/${endpoint}/${params.id}/process`
        options.method = 'PATCH'
        if (params?.data) {
          options.body = JSON.stringify(params.data)
        }
        break
      case POST_MANY:
        url = `${apiUrl}/${endpoint}`
        options.method = 'POST'
        options.body = JSON.stringify(params.data.arrayData)
        break
      case CREATE_SH:
        url = `${apiUrl}/${endpoint}`
        options.method = params.method || 'POST'
        options.body = JSON.stringify(params.data)
        options.headers = {
          'Ocp-Apim-Subscription-Key': null,
        }
        break

      default:
        throw new Error(`Unsupported fetch action type ${type}`)
    }

    return { url, options }
  }

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { headers, json } = response
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if (!headers.has('content-range')) {
          throw new Error(
            'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
          )
        }
        return {
          data: json,
          total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }
      case CREATE:
        return { data: { ...params.data, id: json.id } }
      default:
        return { data: json }
    }
  }

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    const apiUrl = resources[resource].api.url
    const endpoint = resources[resource].endpoint
    let contentLanguage = params.data && params.data.lang ? params.data.lang : 'es'

    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${endpoint}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
            contentLanguage: contentLanguage,
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }))
    }
    if (type === MY_UPDATE_MANY) {
      return Promise.all(
        params.data.map((item) => {
          contentLanguage = item && item.lang ? item.lang : 'es'
          httpClient(`${apiUrl}/${endpoint}/${item.id}`, {
            method: 'PUT',
            body: JSON.stringify(item),
            contentLanguage: contentLanguage,
          })
        })
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }))
    }
    if (type === MY_CREATE_MANY) {
      return Promise.all(
        params.data.map((item) => {
          contentLanguage = item && item.lang ? item.lang : 'es'
          httpClient(`${apiUrl}/${endpoint}`, {
            method: 'POST',
            body: JSON.stringify(item),
            contentLanguage: contentLanguage,
          })
        })
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }))
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map((id) => {
          httpClient(`${apiUrl}/${endpoint}/${id}`, {
            method: 'DELETE',
          })
        })
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }))
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params)
    options.contentLanguage = contentLanguage

    return httpClient(url, options).then((response) => {
      return convertHTTPResponse(response, type, resource, params)
    })
  }
}
