import { Grid, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { Component, Fragmet } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { initialize, isSubmitting, change, formValueSelector } from 'redux-form'
import { ReferenceInput, SelectInput, fetchEnd, fetchStart, showNotification } from 'react-admin'
import { AutocompleteInput } from '../../../../../components/inputs'
import { personsRoles, roleCollection } from '../../../utils/constants'
import { getResource } from '../../../../../config/resources'

const FORM = 'lineups-form'
const selector = formValueSelector(FORM)

const styles = (theme) => {
  return {
    backgroundGrey: {
      background: theme.palette.background.default,
    },
    button: {
      margin: theme.spacing.unit,
    },
  }
}

class PersonRoleInput extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  handelClickAdd() {
    const { field, change, sourcePerson, sourceRole, onClickAdd } = this.props
    const idPersonRole = field(sourcePerson)
    change(FORM, sourceRole, null)
    change(FORM, sourcePerson, null)

    onClickAdd(idPersonRole)
  }

  render() {
    const { sourceRole, sourcePerson, field, classes } = this.props
    return (
      <Grid container className={classes.backgroundGrey}>
        <Grid item xs={12}>
          <ReferenceInput label="Rol" source={sourceRole} reference={getResource('competiciones', 'roles')} filter={{ collection: roleCollection.squad.type }}>
            <SelectInput allowEmpty fullWidth />
          </ReferenceInput>
          {field(sourceRole) && (
            <ReferenceInput
              label="Person"
              source={sourcePerson}
              reference={getResource('competiciones', 'peopleroles')}
              filter={{ role: field(sourceRole) }}
              fullWidth
              allowEmpty
            >
              <AutocompleteInput allowEmpty optionText="person.name" fullWidth />
            </ReferenceInput>
          )}
          {field(sourceRole) && field(sourcePerson) && (
            <Button onClick={() => this.handelClickAdd()} variant="contained" size="small" className={classes.button} color="primary">
              Add
            </Button>
          )}
        </Grid>
      </Grid>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      isSubmitting: isSubmitting(FORM)(state),
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(PersonRoleInput)
