import React from 'react'
import { maxLength, required, TextInput } from 'react-admin'
import { Grid, List, ListItem, ListItemText, Collapse } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { ImageInput, RichTextInput } from '../../../../../../components/inputs'

class HeaderContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }
  handleClick = () => {
    this.setState({
      open: !this.state.open,
    })
  }
  render() {
    return (
      <>
        <ListItem button onClick={this.handleClick}>
          <ListItemText primary="Header Content" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List>
            <ListItem>
              <Grid container>
                <Grid item xs={12}>
                  <TextInput fullWidth source="title" label="Hero Title" validate={[required(), maxLength(255)]} />
                </Grid>
                <Grid item xs={12}>
                  <RichTextInput fullWidth source="headerContent" label="Hero Description" validate={[required()]} />
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container>
                <Grid item xs={6}>
                  <ImageInput
                    source="headerVideoLoopDesktop"
                    label="Video Background Desktop"
                    options={{
                      type: 'videos',
                      remove: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ImageInput
                    source="headerVideoLoopMobile"
                    label="Video Background Mobile"
                    options={{
                      type: 'videos',
                      remove: true,
                    }}
                  />
                </Grid>
              </Grid>
            </ListItem>

            <ListItem>
              <Grid item xs={12}>
                <TextInput fullWidth source="videoTag" label="Video Button Text" validate={[required(), maxLength(255)]} />
              </Grid>
            </ListItem>
          </List>
        </Collapse>
      </>
    )
  }
}

export default HeaderContent
