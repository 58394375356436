import React, { Component } from 'react'
import { GET_LIST } from 'react-admin'
import dataProvider from '../../../../../../providers'
import { getResource } from '../../../../../../config/resources'
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const styles = (theme) => {
  return {
    listMatches: {
      width: '100%',
      '& > div': {
        paddingRight: '10px',
        paddingLeft: '10px',
        '&:hover': {
          background: theme.palette.grey[50],
        },
      },
    },
  }
}

class ListMatches extends Component {
  constructor(props) {
    super(props)
    this.state = {
      matches: false,
    }
  }

  componentDidMount() {
    this.getMatchesGameweek(this.props.idGameweek)
  }

  getMatchesGameweek(idGameweek) {
    dataProvider(GET_LIST, getResource('competiciones', 'matches'), {
      sort: {
        field: 'date',
        order: 'ASC',
      },
      pagination: { page: 1, perPage: 100 },
      filter: {
        gameweek: idGameweek,
      },
    }).then((response) => {
      this.setState({
        matches: response.data,
      })
    })
  }

  render() {
    const { matches } = this.state
    const { classes } = this.props
    return (
      <div className={classes.listMatches}>
        {matches &&
          matches.map((match, index) => {
            return (
              <Grid container justify="space-between" alignItems="center" key={index}>
                <Grid item>
                  <Typography className={classes.match}>
                    {match.homeTeam.selectorName} vs {match.awayTeam.selectorName}
                  </Typography>
                </Grid>
                <Grid item>
                  <Link to={`/${getResource('competiciones', 'matches')}/${match.id}`} target="_blank">
                    <Button variant="contained" color="primary" size="small" aria-label="Edit">
                      Edit
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            )
          })}
      </div>
    )
  }
}

export default withStyles(styles)(ListMatches)
