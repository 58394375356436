import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import {
  Filter,
  List,
  Responsive,
  SimpleList,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  CloneButton,
  TextInput,
  BooleanField,
  SelectInput,
  GET_LIST,
} from 'react-admin'
import { ListActions, TableRowList } from '../../../components'
import { getResource } from '../../../config/resources'
import dataProvider from '../../../providers'

const styles = () => ({
  breakWord: {
    wordBreak: 'break-all',
    minWidth: '200px',
    display: 'inline-block',
  },
})

class WSCFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      seasons: [],
      seasonFilter: '',
    }
  }

  componentDidMount() {
    dataProvider(GET_LIST, getResource('competiciones', 'seasons'), {
      sort: {
        field: 'start',
        order: 'DESC',
      },
      pagination: { page: 1, perPage: 50 },
      filter: { showInWeb: 1 },
    }).then(({ data }) => {
      this.setState({
        seasons: data,
      })
    })
  }

  render() {
    const { seasons } = this.state

    return (
      <Filter {...this.props}>
        <Grid container alwaysOn>
          <Grid item xs={12} sm={8}>
            <TextInput source="title" label="Title" alwaysOn fullWidth />
          </Grid>
          <Grid container xs={12} direction="row">
            <Grid item xs={12} sm={6} md={4}>
              <SelectInput
                source="orientation"
                choices={[
                  { id: 'landscape', name: 'Landscape' },
                  { id: 'portrait', name: 'Portrait' },
                ]}
                alwaysOn
                allowEmpty
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <SelectInput label="Season" source="optaSeasonId" choices={this.state.seasons} optionValue="optaId" alwaysOn allowEmpty fullWidth />
            </Grid>
          </Grid>
        </Grid>
      </Filter>
    )
  }
}

const Actions = (props) => {
  return <ListActions {...props} filters={<WSCFilter />} />
}

const WSCList = ({ actions, title, ...rest }) => {
  return (
    <List perPage={25} bulkActions={false} title="WSC" actions={<Actions />} {...rest}>
      <Responsive
        medium={
          <SimpleList
            primaryText={(record) => <span className={rest.classes.breakWord}>{record.title}</span>}
            secondaryText={(record) => <span>Type: {record.type}</span>}
          />
        }
        large={
          <Datagrid>
            <TextField source="title" label="Title" />
            <TextField source="duration" label="Duration" />
            <TextField source="type" label="Type" />
            <TextField source="orientation" label="Orientation" />
            <BooleanField source="isVideoPublic" label="Is video public" />
            <BooleanField source="isVideoPremium" label="Is video premium" />
            <DateField source="createdAt" label="Created At" />
            <DateField source="updatedAt" label="Updated At" />
            <TableRowList>
              <EditButton />
              <CloneButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}

export default withStyles(styles)(WSCList)
