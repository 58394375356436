import {
  broadcasters as broadcastersOperadores,
  broadcasts as broadcastsOperadores,
  rules as rulesOperadores,
  imports as importsOperadores,
  channels as channelsOperadores,
  channelMatches as channelMatchesOperadores,
  errorImports as errorImportsOperadores,
  broadcastCountries as broadcastCountriesOperadores,
} from '../resources/operadores'

import {
  competitions as competitionsCompeticiones,
  seasons as seasonsCompeticiones,
  venues as venuesCompeticiones,
  clubs as clubsCompeticiones,
  teams as teamsCompeticiones,
  roles as rolesCompeticiones,
  positions as positionsCompeticiones,
  schools as schoolsCompeticiones,
  people as peopleCompeticiones,
  kinds as kindsCompeticiones,
  procedures as proceduresCompeticiones,
  transfers as transfersCompeticiones,
  matches as matchesCompeticiones,
  matchcenter as matchcenterCompeticiones,
  timeTable as timeTableCompeticiones,
  timetableEdit as timetableEditCompeticiones,
  internationalTimetable as internationalTimetableCompeticiones,
  subscriptions as subscriptionsCompeticiones,
  rounds as roundsCompeticiones,
  gameweeks as gameweeksCompeticiones,
  aggregates as aggregatesCompeticiones,
  matchStatus as matchStatusCompeticiones,
  careers as careersCompeticiones,
  squads as squadsCompeticiones,
  peopleroles as peoplerolesCompeticiones,
  lineups as lineupsCompeticiones,
  matchevents as matcheventsCompeticiones,
  matcheventCollections as matcheventCollectionsCompeticiones,
  matcheventTypes as matcheventTypesCompeticiones,
  attempts as attemptsCompeticiones,
  matchesUpdate as matchesUpdateCompeticiones,
  photoPlayers as photoPlayersCompeticiones,
  matchesForecast as matchesForecastCompeticiones,
  matchesForecastEdit as matchesForecastEditCompeticiones,
  balls as ballsCompetiones,
} from '../resources/competiciones'

import middleware from '../resources/middleware'

import {
  genuine as standindsGenuine,
  promises as standindsPromises,
  standingOthers,
  standingsFairplay,
  fairplays,
  otherstandingsMultiples,
} from '../resources/standings'

import {
  assets,
  images as imagesAssets,
  videos as videosAssets,
  videosPremium as videosPremiumAssets,
  files as filesAssets,
  categories as categoriesAssets,
  galleries as galleriesAssets,
  sources as sourcesAssets,
  tags as tagsAssets,
  acts as actsAssets,
  events as eventsAssets,
  metadataOthers as metadataOthersAssets,
  wsc as wscAssets,
} from '../resources/assets'

import { news, categories as newsCategories, externalNews } from '../resources/news'

import {
  brands as brandsDigitalAssets,
  digitalAssets,
  sponsorings as sponsoringsDigitalAssets,
  brandGroups as brandGroupsDigitalAssets,
  brandSectors as brandSectorsDigitalAssets,
  bmAssets as bmassetsDigitalAssets,
  bmAssetGroups as bmAssetsGroupsDigitalAssets,
  bmAssetGroupKinds as bmAssetGroupKindsDigitalAssets,
  sponsors as sponsorsAppOficial,
} from '../resources/digitalAssets'

import {
  layouts,
  modules as layoutModules,
  sections as layoutSections,
  pages as layoutPages,
  queryParams as layoutQueryParams,
  quickAccess as layoutQuickAccess,
  // microsoftMatches,
  // microsoftHeaders,
  // microsoftFooters,
  // microsoftGameweekDescription,
  sportPlayWinners,
  beyondStatsHeader,
  beyondStatsBlock,
  beyondStatsCategories,
  beyondStats2024,
} from '../resources/layouts'

import {
  pagesApps,
  pagesCampus,
  pagesGenuine,
  pagesLaLigaVS,
  pagesInstitutional,
  pagesOthers,
  pagesLegal,
  pagesThirdLegal,
  pagesLaLigaGroup,
} from '../resources/pages'

import domains from '../resources/domains'
import {
  sections as highlightSections,
  homepage as highlightHomepage,
  subhomePrimeraDivisionFemenina as highlightSubhomePrimeraDivisionFemenina,
  subhomeSegundaDivision as highlightSubhomeSegundaDivision,
  subhomePrimeraDivision as highlightSubhomePrimeraDivision,
  subhomeLigaGenuine as highlightSubhomeLigaGenuine,
  appHomepage,
} from '../resources/highlights'

import {
  clientUuids as clientUuidsTransversal,
  countries as countriesTransversal,
  nationalities as nationalitiesTransversal,
  markets as marketsTransversal,
  globaldatas as globaldatasTransversal,
} from '../resources/transversal'

import { categories as categoriesExperience, experiences } from '../resources/experience'

import { promises, pages as pagesPromises, teamsPromises } from '../resources/promises'

import { events as eventsInternalEvents, categories as categoriesInternalEvents } from '../resources/internalEvents'

import { ambassadors } from '../resources/ambassadors'

import shortUrls from '../resources/shortUrls'

import API from './api'
import GROUPS from './groups'
import PERMISSIONS from './permissions'

import oneFootball from '../resources/oneFootball'
import { partidazoshomepage, partidazosmatches } from '../resources/layouts/partidazos'

import { appmanagement, mobileapps, socialmediaprofiles, dynamicsplashscreen, sponsors, adsSpace } from '../resources/app'

import { shortenedurls } from '../resources/pages/shortenedUrls'

const resources = {
  //OPERADORES
  [`${API.operadores.prefix}/broadcasters`]: {
    resource: broadcastersOperadores,
    endpoint: 'broadcasters',
    api: API.operadores,
    group: GROUPS.broadcasters,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.operadores.prefix}/broadcasts`]: {
    resource: broadcastsOperadores,
    endpoint: 'broadcasts',
    api: API.operadores,
    group: GROUPS.broadcasters,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.operadores.prefix}/rules`]: {
    resource: rulesOperadores,
    endpoint: 'rules',
    api: API.operadores,
    group: GROUPS.broadcasters,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.operadores.prefix}/imports`]: {
    resource: importsOperadores,
    endpoint: 'imports',
    api: API.operadores,
    group: GROUPS.broadcasters,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.operadores.prefix}/channels`]: {
    resource: channelsOperadores,
    endpoint: 'channels',
    api: API.operadores,
    group: GROUPS.broadcasters,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.operadores.prefix}/channelmatches`]: {
    resource: channelMatchesOperadores,
    endpoint: 'channelmatches',
    api: API.operadores,
    group: GROUPS.broadcasters,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.operadores.prefix}/error-imports`]: {
    resource: errorImportsOperadores,
    endpoint: 'error-imports',
    api: API.operadores,
    group: GROUPS.broadcasters,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition, PERMISSIONS.coordinator],
    visible: false,
  },
  [`${API.operadores.prefix}/broadcastcountries`]: {
    resource: broadcastCountriesOperadores,
    endpoint: 'broadcastcountries',
    api: API.operadores,
    group: GROUPS.broadcasters,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition, PERMISSIONS.coordinator, PERMISSIONS.broadcasters],
    visible: false,
  },
  [`${API.operadores.prefix}/broadcasts-matches`]: {
    resource: { options: { label: 'Broadcast Matches' } },
    endpoint: 'broadcasts-matches',
    api: API.operadores,
    group: GROUPS.broadcasters,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition, PERMISSIONS.coordinator, PERMISSIONS.broadcasters],
    visible: false,
  },

  //COMPETICIONES
  [`${API.competiciones.prefix}/seasons`]: {
    resource: seasonsCompeticiones,
    endpoint: 'seasons',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/competitions`]: {
    resource: competitionsCompeticiones,
    endpoint: 'competitions',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/venues`]: {
    resource: venuesCompeticiones,
    endpoint: 'venues',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/clubs`]: {
    resource: clubsCompeticiones,
    endpoint: 'clubs',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/teams`]: {
    resource: teamsCompeticiones,
    endpoint: 'teams',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/roles`]: {
    resource: rolesCompeticiones,
    endpoint: 'roles',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/positions`]: {
    resource: positionsCompeticiones,
    endpoint: 'positions',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/schools`]: {
    resource: schoolsCompeticiones,
    endpoint: 'schools',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/people`]: {
    resource: peopleCompeticiones,
    endpoint: 'people',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/kinds`]: {
    resource: kindsCompeticiones,
    endpoint: 'kinds',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: false,
  },
  [`${API.competiciones.prefix}/matches`]: {
    resource: matchesCompeticiones,
    endpoint: 'matches',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/timeTables`]: {
    resource: timeTableCompeticiones,
    endpoint: 'gameweeks',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/timetablesEdit`]: {
    resource: timetableEditCompeticiones,
    endpoint: 'timetables',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor, PERMISSIONS.competition],
    visible: false,
  },
  [`${API.operadores.prefix}/international-timetable`]: {
    resource: internationalTimetableCompeticiones,
    endpoint: 'gameweeks',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition, PERMISSIONS.coordinator, PERMISSIONS.broadcasters],
    visible: true,
  },
  [`${API.competiciones.prefix}/procedures`]: {
    resource: proceduresCompeticiones,
    endpoint: 'procedures',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/transfers`]: {
    resource: transfersCompeticiones,
    endpoint: 'transfers',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/subscriptions`]: {
    resource: subscriptionsCompeticiones,
    endpoint: 'subscriptions',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: false,
  },
  [`${API.competiciones.prefix}/rounds`]: {
    resource: roundsCompeticiones,
    endpoint: 'rounds',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: false,
  },
  [`${API.competiciones.prefix}/gameweeks`]: {
    resource: gameweeksCompeticiones,
    endpoint: 'gameweeks',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/aggregates`]: {
    resource: aggregatesCompeticiones,
    endpoint: 'aggregates',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/matchescenter`]: {
    resource: matchcenterCompeticiones,
    endpoint: 'matches',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/matchStatus`]: {
    resource: matchStatusCompeticiones,
    endpoint: 'matchStatus',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: false,
  },
  [`${API.competiciones.prefix}/careers`]: {
    resource: careersCompeticiones,
    endpoint: 'careers',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: false,
  },
  [`${API.competiciones.prefix}/squads`]: {
    resource: squadsCompeticiones,
    endpoint: 'squads',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/peopleroles`]: {
    resource: peoplerolesCompeticiones,
    endpoint: 'peopleroles',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: false,
  },
  [`${API.competiciones.prefix}/lineups`]: {
    resource: lineupsCompeticiones,
    endpoint: 'lineups',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: false,
  },
  [`${API.competiciones.prefix}/matchevents`]: {
    resource: matcheventsCompeticiones,
    endpoint: 'matchevents',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: false,
  },
  [`${API.competiciones.prefix}/matchevent-collections`]: {
    resource: matcheventCollectionsCompeticiones,
    endpoint: 'matchevent-collections',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: false,
  },
  [`${API.competiciones.prefix}/matchevent-types`]: {
    resource: matcheventTypesCompeticiones,
    endpoint: 'matchevent-types',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: false,
  },
  [`${API.competiciones.prefix}/attempts`]: {
    resource: attemptsCompeticiones,
    endpoint: 'gameweeks',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/matches-update`]: {
    resource: matchesUpdateCompeticiones,
    endpoint: 'matches-update',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: false,
  },
  [`${API.competiciones.prefix}/photoplayers`]: {
    resource: photoPlayersCompeticiones,
    endpoint: 'photoplayers',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.competiciones.prefix}/matchesForecast`]: {
    resource: matchesForecastCompeticiones,
    endpoint: 'gameweeks',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/matchesForecastEdit`]: {
    resource: matchesForecastEditCompeticiones,
    endpoint: 'matches-forecast',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: false,
  },
  [`${API.competiciones.prefix}/balls`]: {
    resource: ballsCompetiones,
    endpoint: 'balls',
    api: API.competiciones,
    group: GROUPS.football,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: false,
  },
  //STANDINGS
  [`${API.competiciones.prefix}/standingGenuine`]: {
    resource: standindsGenuine,
    endpoint: 'rounds',
    api: API.competiciones,
    group: GROUPS.standings,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition, PERMISSIONS.foundation],
    visible: true,
  },
  [`${API.competiciones.prefix}/standingPromises`]: {
    resource: standindsPromises,
    endpoint: 'rounds',
    api: API.competiciones,
    group: GROUPS.standings,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition, PERMISSIONS.foundation],
    visible: true,
  },
  [`${API.competiciones.prefix}/standingOthers`]: {
    resource: standingOthers,
    endpoint: 'otherstandings',
    api: API.competiciones,
    group: GROUPS.standings,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: false,
  },
  [`${API.competiciones.prefix}/otherstandings/multiples`]: {
    resource: otherstandingsMultiples,
    endpoint: 'otherstandings/multiples',
    api: API.competiciones,
    group: GROUPS.standings,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: false,
  },
  [`${API.competiciones.prefix}/standingsFairplay`]: {
    resource: standingsFairplay,
    endpoint: 'gameweeks',
    api: API.competiciones,
    group: GROUPS.standings,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: true,
  },
  [`${API.competiciones.prefix}/fairplays`]: {
    resource: fairplays,
    endpoint: 'fairplays',
    api: API.competiciones,
    group: GROUPS.standings,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition],
    visible: false,
  },

  //MULTIMEDIA
  [`${API.assets.prefix}/assets`]: {
    resource: assets,
    endpoint: 'assets',
    api: API.assets,
    group: GROUPS.assets,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: false,
  },
  [`${API.assets.prefix}/images`]: {
    resource: imagesAssets,
    endpoint: 'images',
    api: API.assets,
    group: GROUPS.assets,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },
  [`${API.assets.prefix}/videos`]: {
    resource: videosAssets,
    endpoint: 'videos',
    api: API.assets,
    group: GROUPS.assets,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor, PERMISSIONS.mondia, PERMISSIONS.appjugador, PERMISSIONS.planetconnect],
    visible: true,
  },
  [`${API.assets.prefix}/videos-premium`]: {
    resource: videosPremiumAssets,
    endpoint: 'videos',
    api: API.assets,
    group: GROUPS.assets,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor, PERMISSIONS.mondia, PERMISSIONS.appjugador],
    visible: true,
  },
  [`${API.assets.prefix}/wsc`]: {
    resource: wscAssets,
    endpoint: 'wscs',
    api: API.assets,
    group: GROUPS.assets,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor, PERMISSIONS.mondia, PERMISSIONS.appjugador, PERMISSIONS.planetconnect],
    visible: true,
  },
  [`${API.assets.prefix}/files`]: {
    resource: filesAssets,
    endpoint: 'files',
    api: API.assets,
    group: GROUPS.assets,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },
  [`${API.assets.prefix}/galleries`]: {
    resource: galleriesAssets,
    endpoint: 'galleries',
    api: API.assets,
    group: GROUPS.assets,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor, PERMISSIONS.mondia, PERMISSIONS.planetconnect],
    visible: true,
  },
  /*
  [`${API.assets.prefix}/galleriesVideos`]: {
    resource: galleriesVideoAssets,
    endpoint: 'galleries',
    api: API.assets,
    group: GROUPS.assets,
    permissions: [PERMISSIONS.admin],
    visible: true
  },
  */
  [`${API.assets.prefix}/categories`]: {
    resource: categoriesAssets,
    endpoint: 'categories',
    api: API.assets,
    group: null,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: false,
  },
  [`${API.assets.prefix}/gallerycategories`]: {
    resource: categoriesAssets,
    endpoint: 'gallerycategories',
    api: API.assets,
    group: null,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: false,
  },
  [`${API.assets.prefix}/videocategories`]: {
    resource: categoriesAssets,
    endpoint: 'videocategories',
    api: API.assets,
    group: null,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: false,
  },
  [`${API.assets.prefix}/sources`]: {
    resource: sourcesAssets,
    endpoint: 'sources',
    api: API.assets,
    group: null,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: false,
  },
  [`${API.assets.prefix}/tags`]: {
    resource: tagsAssets,
    endpoint: 'tags',
    api: API.assets,
    group: null,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: false,
  },
  [`${API.assets.prefix}/acts`]: {
    resource: actsAssets,
    endpoint: 'acts',
    api: API.assets,
    group: GROUPS.assets,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },
  [`${API.assets.prefix}/events`]: {
    resource: eventsAssets,
    endpoint: 'events',
    api: API.assets,
    group: GROUPS.assets,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },
  [`${API.assets.prefix}/metadataothers`]: {
    resource: metadataOthersAssets,
    endpoint: 'metadataothers',
    api: API.assets,
    group: GROUPS.assets,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },
  //NOTICIAS
  [`${API.news.prefix}/noticias`]: {
    resource: news,
    endpoint: 'news',
    api: API.news,
    group: GROUPS.news,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor, PERMISSIONS.mondia, PERMISSIONS.planetconnect],
    visible: true,
  },

  [`${API.news.prefix}/categories`]: {
    resource: newsCategories,
    endpoint: 'categories',
    api: API.news,
    group: GROUPS.news,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },
  [`${API.news.prefix}/externalNews`]: {
    resource: externalNews,
    endpoint: 'external-news',
    api: API.news,
    group: GROUPS.news,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },
  [`${API.news.prefix}/onefootball`]: {
    resource: oneFootball,
    endpoint: 'onefootball/news',
    api: API.news,
    group: GROUPS.news,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor, PERMISSIONS.mondia],
    visible: false,
  },
  //ACTIVOS DIGITALES
  [`${API.digitalAssets.prefix}/brands`]: {
    resource: brandsDigitalAssets,
    endpoint: 'brands',
    api: API.digitalAssets,
    group: GROUPS.digitalAssets,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.digitalAssets.prefix}/digital-assets`]: {
    resource: digitalAssets,
    endpoint: 'digital-assets',
    api: API.digitalAssets,
    group: GROUPS.digitalAssets,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.digitalAssets.prefix}/sponsorings`]: {
    resource: sponsoringsDigitalAssets,
    endpoint: 'sponsorings',
    api: API.digitalAssets,
    group: GROUPS.digitalAssets,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.digitalAssets.prefix}/brand-groups`]: {
    resource: brandGroupsDigitalAssets,
    endpoint: 'brand-groups',
    api: API.digitalAssets,
    group: GROUPS.digitalAssets,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: false,
  },
  [`${API.digitalAssets.prefix}/brand-sectors`]: {
    resource: brandSectorsDigitalAssets,
    endpoint: 'brand-sectors',
    api: API.digitalAssets,
    group: GROUPS.digitalAssets,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.digitalAssets.prefix}/brand-market-assets`]: {
    resource: bmassetsDigitalAssets,
    endpoint: 'brand-market-assets',
    api: API.digitalAssets,
    group: GROUPS.digitalAssets,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: false,
  },
  [`${API.digitalAssets.prefix}/brand-market-asset-groups`]: {
    resource: bmAssetsGroupsDigitalAssets,
    endpoint: 'brand-market-asset-groups',
    api: API.digitalAssets,
    group: GROUPS.digitalAssets,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: false,
  },
  [`${API.digitalAssets.prefix}/brand-market-asset-group-kinds`]: {
    resource: bmAssetGroupKindsDigitalAssets,
    endpoint: 'brand-market-asset-group-kinds',
    api: API.digitalAssets,
    group: GROUPS.digitalAssets,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: false,
  },
  [`${API.digitalAssets.prefix}/sponsors`]: {
    resource: sponsorsAppOficial,
    endpoint: 'sponsors',
    api: API.digitalAssets,
    group: GROUPS.digitalAssets,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: false,
  },

  //LAYOUTS
  [`${API.layouts.prefix}/layouts`]: {
    resource: layouts,
    endpoint: 'layouts',
    api: API.layouts,
    group: GROUPS.layouts,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.layouts.prefix}/modules`]: {
    resource: layoutModules,
    endpoint: 'modules',
    api: API.layouts,
    group: GROUPS.layouts,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.layouts.prefix}/queryParams`]: {
    resource: layoutQueryParams,
    endpoint: 'queryparams',
    api: API.queryParams,
    group: GROUPS.pages,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition, PERMISSIONS.coordinator, PERMISSIONS.editor, PERMISSIONS.foundation],
    visible: false,
  },
  [`${API.layouts.prefix}/quickAccess`]: {
    resource: layoutQuickAccess,
    endpoint: 'quick-accesses',
    api: API.layouts,
    group: GROUPS.layouts,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.layouts.prefix}/sections`]: {
    resource: layoutSections,
    endpoint: 'sections',
    api: API.layouts,
    group: GROUPS.layouts,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: false,
  },
  [`${API.layouts.prefix}/pages`]: {
    resource: layoutPages,
    endpoint: 'pages',
    api: API.layouts,
    group: GROUPS.layouts,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: false,
  },
  //HIGHLIGHTS
  [`${API.highlights.prefix}/sections`]: {
    resource: highlightSections,
    endpoint: 'sections',
    api: API.highlights,
    group: GROUPS.highlights,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.highlights.prefix}/Homepage`]: {
    resource: highlightHomepage,
    endpoint: 'highlights',
    api: API.highlights,
    group: GROUPS.highlights,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.highlights.prefix}/SubhomePrimeraDivision`]: {
    resource: highlightSubhomePrimeraDivision,
    endpoint: 'highlights',
    api: API.highlights,
    group: GROUPS.highlights,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.highlights.prefix}/SubhomeSegundaDivision`]: {
    resource: highlightSubhomeSegundaDivision,
    endpoint: 'highlights',
    api: API.highlights,
    group: GROUPS.highlights,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.highlights.prefix}/SubhomePrimeraDivisionFemenina`]: {
    resource: highlightSubhomePrimeraDivisionFemenina,
    endpoint: 'highlights',
    api: API.highlights,
    group: GROUPS.highlights,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.highlights.prefix}/subhomeLaLigaGenuine`]: {
    resource: highlightSubhomeLigaGenuine,
    endpoint: 'highlights',
    api: API.highlights,
    group: GROUPS.highlights,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.highlights.prefix}/app-homepage`]: {
    resource: appHomepage,
    endpoint: 'highlights',
    api: API.highlights,
    group: GROUPS.highlights,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  //PAGES
  [`${API.layouts.prefix}/pagesApps`]: {
    resource: pagesApps,
    endpoint: 'pages',
    api: API.layouts,
    group: GROUPS.pages,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.layouts.prefix}/pagesCampus`]: {
    resource: pagesCampus,
    endpoint: 'pages',
    api: API.layouts,
    group: GROUPS.pages,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.layouts.prefix}/pagesGenuine`]: {
    resource: pagesGenuine,
    endpoint: 'pages',
    api: API.layouts,
    group: GROUPS.pages,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.layouts.prefix}/pagesLaLigaVS`]: {
    resource: pagesLaLigaVS,
    endpoint: 'pages',
    api: API.layouts,
    group: GROUPS.pages,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.layouts.prefix}/pagesInstitutional`]: {
    resource: pagesInstitutional,
    endpoint: 'pages',
    api: API.layouts,
    group: GROUPS.pages,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.layouts.prefix}/pagesLaLigaGroup`]: {
    resource: pagesLaLigaGroup,
    endpoint: 'pages',
    api: API.layouts,
    group: GROUPS.pages,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.layouts.prefix}/pagesOthers`]: {
    resource: pagesOthers,
    endpoint: 'pages',
    api: API.layouts,
    group: GROUPS.pages,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.layouts.prefix}/pagesLegal`]: {
    resource: pagesLegal,
    endpoint: 'pages',
    api: API.layouts,
    group: GROUPS.pages,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  [`${API.layouts.prefix}/pagesThirdLegal`]: {
    resource: pagesThirdLegal,
    endpoint: 'pages',
    api: API.layouts,
    group: GROUPS.pages,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.thirdLegal],
    visible: true,
  },
  //TRANSVERSAL
  [`${API.transversal.prefix}/countries`]: {
    resource: countriesTransversal,
    endpoint: 'countries',
    api: API.transversal,
    group: GROUPS.transversal,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition, PERMISSIONS.coordinator, PERMISSIONS.editor, PERMISSIONS.foundation],
    visible: true,
  },
  [`${API.transversal.prefix}/nationalities`]: {
    resource: nationalitiesTransversal,
    endpoint: 'nationalities',
    api: API.transversal,
    group: GROUPS.transversal,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition, PERMISSIONS.coordinator, PERMISSIONS.editor, PERMISSIONS.foundation],
    visible: true,
  },
  [`${API.transversal.prefix}/markets`]: {
    resource: marketsTransversal,
    endpoint: 'markets',
    api: API.transversal,
    group: GROUPS.transversal,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition, PERMISSIONS.coordinator, PERMISSIONS.editor, PERMISSIONS.foundation],
    visible: true,
  },
  [`${API.transversal.prefix}/globaldatas`]: {
    resource: globaldatasTransversal,
    endpoint: 'globaldatas',
    api: API.transversal,
    group: GROUPS.transversal,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition, PERMISSIONS.coordinator, PERMISSIONS.editor, PERMISSIONS.foundation],
    visible: true,
  },
  //Middleware
  [`${API.middleware.prefix}/middleware`]: {
    resource: middleware,
    endpoint: '',
    api: API.middleware,
    group: GROUPS.transversal,
    permissions: [PERMISSIONS.admin, PERMISSIONS.competition, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: false,
  },
  //Experience
  [`${API.layouts.prefix}/experiences`]: {
    resource: experiences,
    endpoint: 'experiences',
    api: API.layouts,
    group: GROUPS.experience,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },
  [`${API.layouts.prefix}/experience-categories`]: {
    resource: categoriesExperience,
    endpoint: 'experience-categories',
    api: API.layouts,
    group: GROUPS.experience,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },
  //PROMISES
  [`${API.layouts.prefix}/promises`]: {
    resource: promises,
    endpoint: 'promises',
    api: API.layouts,
    group: GROUPS.promises,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },
  [`${API.layouts.prefix}/pagesPromises`]: {
    resource: pagesPromises,
    endpoint: 'pages',
    api: API.layouts,
    group: GROUPS.promises,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: false,
  },
  [`${API.layouts.prefix}/teamsPromises`]: {
    resource: teamsPromises,
    endpoint: 'promise-teams',
    api: API.layouts,
    group: GROUPS.promises,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator],
    visible: true,
  },
  // Internal events
  [`${API.layouts.prefix}/events`]: {
    resource: eventsInternalEvents,
    endpoint: 'events',
    api: API.layouts,
    group: GROUPS.internalEvents,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },
  [`${API.layouts.prefix}/event-categories`]: {
    resource: categoriesInternalEvents,
    endpoint: 'event-categories',
    api: API.layouts,
    group: GROUPS.internalEvents,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },
  [`${API.shortUrls.prefix}/domains`]: {
    resource: domains,
    endpoint: 'domains',
    api: API.shortUrls,
    group: {},
    permissions: Object.values(PERMISSIONS),
    visible: false,
  },
  [`${API.transversal.prefix}/clientuuids`]: {
    resource: clientUuidsTransversal,
    endpoint: 'clientuuids',
    api: API.transversal,
    group: GROUPS.news,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: false,
  },
  [`${API.shortUrls.prefix}/short-urls`]: {
    resource: shortUrls,
    endpoint: 'short-urls',
    api: API.shortUrls,
    group: {},
    permissions: Object.values(PERMISSIONS),
    visible: false,
  },
  [`${API.shortUrls.prefix}/edit-shortened-urls`]: {
    resource: shortenedurls,
    endpoint: 'short-urls',
    api: API.shortUrls,
    group: GROUPS.shortURLS,
    permissions: Object.values(PERMISSIONS),
    visible: true,
  },

  // Ambassadors
  [`${API.layouts.prefix}/ambassadors`]: {
    resource: ambassadors,
    endpoint: 'ambassadors',
    api: API.layouts,
    group: GROUPS.ambassadors,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },
  // Microsoft
  // [`${API.layouts.prefix}/microsoft-matches`]: {
  //   resource: microsoftMatches,
  //   endpoint: 'microsoft-matches',
  //   api: API.layouts,
  //   group: GROUPS.microsoft,
  //   permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
  //   visible: false,
  // },
  // [`${API.layouts.prefix}/microsoft-headers`]: {
  //   resource: microsoftHeaders,
  //   endpoint: 'microsoft-headers',
  //   api: API.layouts,
  //   group: GROUPS.microsoft,
  //   permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
  //   visible: false,
  // },
  // [`${API.layouts.prefix}/microsoft-footers`]: {
  //   resource: microsoftFooters,
  //   endpoint: 'microsoft-footers',
  //   api: API.layouts,
  //   group: GROUPS.microsoft,
  //   permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
  //   visible: false,
  // },
  // [`${API.layouts.prefix}/microsoft-gameweek-descriptions`]: {
  //   resource: microsoftGameweekDescription,
  //   endpoint: 'microsoft-gameweek-descriptions',
  //   api: API.layouts,
  //   group: GROUPS.microsoft,
  //   permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
  //   visible: false,
  // },
  //Sportium
  [`${API.layouts.prefix}/sportplay-winners`]: {
    resource: sportPlayWinners,
    endpoint: 'sportplay-winners',
    api: API.layouts,
    group: GROUPS.sportium,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },
  //BEYOND STATS
  [`${API.layouts.prefix}/beyond-stats-headers`]: {
    resource: beyondStatsHeader,
    endpoint: 'beyond-stats-headers',
    api: API.layouts,
    group: GROUPS.beyondStats,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: false,
  },
  [`${API.layouts.prefix}/beyond-stats-blocks`]: {
    resource: beyondStatsBlock,
    endpoint: 'beyond-stats-blocks',
    api: API.layouts,
    group: GROUPS.beyondStats,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: false,
  },
  [`${API.layouts.prefix}/beyond-stats-categories`]: {
    resource: beyondStatsCategories,
    endpoint: 'beyond-stats-categories',
    api: API.layouts,
    group: GROUPS.beyondStats,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: false,
  },
  [`${API.layouts.prefix}/beyond-stats`]: {
    resource: beyondStats2024,
    endpoint: 'beyond-stats',
    api: API.layouts,
    group: GROUPS.beyondStats,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },
  // PARTIDAZOS
  [`${API.layouts.prefix}/partidazos-homepage`]: {
    resource: partidazoshomepage,
    endpoint: 'partidazos-homepage',
    api: API.layouts,
    group: GROUPS.partidazos,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },
  [`${API.layouts.prefix}/partidazos`]: {
    resource: partidazosmatches,
    endpoint: 'partidazos',
    api: API.layouts,
    group: GROUPS.partidazos,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },
  // APP OFICIAL
  [`${API.layouts.prefix}/app-management`]: {
    resource: appmanagement,
    endpoint: 'competitions',
    api: API.competiciones,
    group: GROUPS.appOficial,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },

  [`${API.layouts.prefix}/social-media-profiles`]: {
    resource: socialmediaprofiles,
    endpoint: 'social-media-profiles',
    api: API.layouts,
    group: GROUPS.appOficial,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },

  [`${API.layouts.prefix}/mobile-apps`]: {
    resource: mobileapps,
    endpoint: 'mobile-apps',
    api: API.layouts,
    group: GROUPS.appOficial,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },

  [`${API.layouts.prefix}/splash-screens`]: {
    resource: dynamicsplashscreen,
    endpoint: 'splash-screens',
    api: API.layouts,
    group: GROUPS.appOficial,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },

  [`${API.layouts.prefix}/sponsors`]: {
    resource: sponsors,
    endpoint: 'sponsors',
    api: API.digitalAssets,
    group: GROUPS.appOficial,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: true,
  },

  [`${API.digitalAssets.prefix}/ads-spaces`]: {
    resource: adsSpace,
    endpoint: 'ads-spaces',
    api: API.digitalAssets,
    group: GROUPS.appOficial,
    permissions: [PERMISSIONS.admin, PERMISSIONS.coordinator, PERMISSIONS.editor],
    visible: false,
  },
}

export const getResource = (api, key) => {
  return `${API[api].prefix}/${key}`
}

export const getLink = (api, key, options = {}) => {
  let url = `${API[api].prefix}/${key}`
  if (options.id) {
    url += `/${options.id}`
  }
  if (options.query) {
    url += '?'
    if (options.query.filter) {
      url += `filter=${JSON.stringify(options.query.filter)}&`
    }
    if (options.query.page) {
      url += `page=${options.query.page}&`
    }
    if (options.query.perPage) {
      url += `perPage=${options.query.perPage}&`
    }
    if (options.query.order) {
      url += `order=${options.query.order}&`
    }
    if (options.query.sort) {
      url += `sort=${options.query.sort}&`
    }
    url = url.substring(0, url.length - 1)
  }
  return url
}

export default resources
