import { Card, CardHeader, Grid, List } from '@material-ui/core'
import React from 'react'
import { maxLength, required, TextInput, LongTextInput } from 'react-admin'
import { LanguagesNav } from '../../../../components'
import HeaderContent from './modules/headerContent'
import AfterHeroText from './modules/AfterHeroText'
import CardsSection from './modules/CardsSection'
import BeyondStatsNewsSection from './modules/BeyondStatsNewsSection'

export const PageForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12}>
        <TextInput fullWidth source="seoTitle" label="Title SEO" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <LongTextInput fullWidth label="SEO description" source="seoDescription" validate={[maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Modules" />
          <List>
            <HeaderContent />
            <AfterHeroText />
            <CardsSection source="videoModule.items" {...props} />
            <BeyondStatsNewsSection />
          </List>
        </Card>
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default PageForm
