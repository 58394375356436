import React from 'react'
import { TextInput } from 'react-admin'
import { required, maxLength, minLength } from 'react-admin'
import { LanguagesNav } from '../../../../components'
import Grid from '@material-ui/core/Grid'

export const CountriesForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12} sm={12}>
        <TextInput fullWidth label="Name" source="name" validate={[required(), maxLength(255)]} />
        <TextInput fullWidth label="ISO" source="id" validate={[required(), maxLength(2), minLength(2)]} />
        <TextInput fullWidth label="Area" source="area" validate={[maxLength(255)]} />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default CountriesForm
