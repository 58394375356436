import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import React, { cloneElement, Component } from 'react'
import { CardActions, CreateButton, RefreshButton } from 'react-admin'

import resources from '../../config/resources'
import { ActionsFiltersStyled, ActionsStyled, MenuRightStyled, SpaceStyled, ToolbarStyled, ToolbarTitleStyled } from './styles'

const styles = (theme) => ({
  container: {
    width: '100%',
    flexWrap: 'wrap',
  },
  input: {
    color: theme.palette.common.black,
    '& label': {
      color: theme.palette.common.black,
    },
    '& > div': {
      '&:before': {
        borderBottom: `solid 1px ${theme.palette.grey[700]}`,
      },
    },
  },
  button: {
    color: theme.palette.common.white,
    marginLeft: '5px',
  },
  createButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  filterButton: {
    borderRadius: '0px',
    fontSize: '12px',
    width: '176px',
    display: 'flex',
    textAlign: 'left',
    textTransform: 'capitalize',
    '& .text': {
      flexGrow: 1,
    },
  },
  filterButtonClosed: {
    borderRadius: '0px',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: '12px',
    width: '176px',
    display: 'flex',
    textAlign: 'left',
    textTransform: 'capitalize',
    '& .text': {
      flexGrow: 1,
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
  },
  resetFiltersButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
})

class ListActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openFilters: true,
    }
  }
  componentDidMount() {
    this.setState({
      openFilters: Object.keys(this.props.filterValues).length > 0,
    })
  }

  handleFilters = () => {
    this.setState({ openFilters: !this.state.openFilters })
  }

  FilterButton = () => {
    return (
      <Button
        className={this.state.openFilters ? this.props.classes.filterButton : this.props.classes.filterButtonClosed}
        variant="contained"
        color="primary"
        onClick={this.handleFilters}
      >
        <span className="text">
          <span>Filters </span>
          {Object.keys(this.props.filterValues).length > 0 && <span>[{Object.keys(this.props.filterValues).length}]</span>}
        </span>
        {this.state.openFilters ? <ExpandLess /> : <ExpandMore />}
      </Button>
    )
  }

  resetFilters() {
    this.props.setFilters({})
  }

  render() {
    const {
      bulkActions,
      basePath,
      displayedFilters,
      filters,
      filterValues,
      onUnselectItems,
      resource,
      selectedIds,
      showFilter,
      setFilters,
      classes,
    } = this.props
    const FilterButton = this.FilterButton
    const openFilters = filters && this.state.openFilters
    return (
      <ToolbarStyled>
        <CardActions className={classes.container}>
          {bulkActions &&
            cloneElement(bulkActions, {
              basePath,
              filterValues,
              resource,
              selectedIds,
              onUnselectItems,
            })}
          <ActionsStyled>
            {filters && <FilterButton />}
            <SpaceStyled />
            <MenuRightStyled>
              {/* <ExportButton className={classes.button} resource={resource} sort={currentSort} filter={filterValues} exporter={exporter} /> */}
              <RefreshButton className={classes.button} />
              {resources[resource].resource.create && <CreateButton basePath={basePath} className={classes.createButton + ' ' + classes.button} />}
            </MenuRightStyled>
          </ActionsStyled>
          {openFilters && (
            <ActionsFiltersStyled>
              <IconButton className={classes.resetFiltersButton} onClick={() => this.resetFilters()}>
                <DeleteIcon fontSize="small" />
              </IconButton>
              {filters &&
                cloneElement(filters, {
                  resource,
                  showFilter,
                  displayedFilters,
                  filterValues,
                  setFilters,
                  context: 'form',
                  classes,
                  hideFilter: () => null,
                  source: 'filter',
                })}
            </ActionsFiltersStyled>
          )}
        </CardActions>
        <ToolbarTitleStyled>{`${resources[resource].resource.options.label} list`}</ToolbarTitleStyled>
      </ToolbarStyled>
    )
  }
}

export default withStyles(styles)(ListActions)
