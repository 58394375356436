import React from 'react'
import { Edit } from 'react-admin'

import FilesForm from './form'

const FileTitle = ({ record }) => {
  return <span>{`Difusión: ${record.nombre}`}</span>
}

export const FileEdit = (props) => (
  <Edit undoable={false} title={<FileTitle />} {...props}>
    <FilesForm {...props} />
  </Edit>
)

export default FileEdit
