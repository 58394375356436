import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import { Grid, Button } from '@material-ui/core'
import React, { Component, Fragment } from 'react'
import { fetchEnd, fetchStart, showNotification, ReferenceInput, SelectInput, NumberInput, UPDATE, CREATE } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector, initialize, isSubmitting } from 'redux-form'

import { getResource } from '../../../../../config/resources'
import dataProvider from '../../../../../providers'

const FORM = 'matchevents-form'
const selector = formValueSelector(FORM)

const styles = (theme) => ({
  container: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  gridTimeMatch: {
    display: 'flex',
  },
  periodSelect: {
    minWidth: 'auto',
    maxWidth: 'calc(100% - 65px)',
  },
  timeInput: {
    minWidth: '65px',
    textAlign: 'center',
    '& input': {
      textAlign: 'center',
      paddingLeft: '5px',
      paddingRight: '5px',
    },
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '0px',
  },
  button: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
})

class EditEvent extends Component {
  componentDidMount() {
    const { change } = this.props
    change(FORM, 'editEvent', {})
  }

  optionSelectTeam = (choices) => {
    return choices.selectorName
  }

  resetMatchEvent() {
    const { change, match } = this.props
    change(FORM, 'editEvent', {
      period: match.status,
    })
  }

  addMatchEvent() {
    const { field, onChange, fetchStart, fetchEnd, showNotification } = this.props
    const editEvent = field('editEvent')

    fetchStart()
    dataProvider(CREATE, getResource('competiciones', 'matchevents'), {
      data: editEvent,
    })
      .then(({ data }) => {
        onChange()
        this.resetMatchEvent()
        showNotification('Elements updated', 'success')
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  editMatchEvent() {
    const { field, onChange, fetchStart, fetchEnd, showNotification } = this.props
    const editEvent = field('editEvent')
    const index = _.findIndex(field('events'), ['id', editEvent.id])

    if (index !== -1) {
      fetchStart()
      dataProvider(UPDATE, getResource('competiciones', 'matchevents'), {
        id: editEvent.id,
        data: editEvent,
      })
        .then(({ data }) => {
          onChange()
          this.resetMatchEvent()
          showNotification('Elements updated', 'success')
        })
        .catch((error) => {
          showNotification(error.message, 'error')
        })
        .finally(() => {
          fetchEnd()
        })
    }
  }

  render() {
    const { classes, field, change, match } = this.props
    const event = field('editEvent')

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12} md={4} className={classes.gridTimeMatch}>
          <ReferenceInput label="Period" source="editEvent.period" reference={getResource('competiciones', 'matchStatus')} className={classes.periodSelect}>
            <SelectInput optionText="name" fullWidth />
          </ReferenceInput>
          <NumberInput label="Time" source="editEvent.time" className={classes.timeInput} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReferenceInput
            label="Collection"
            source="editEvent.collection"
            reference={getResource('competiciones', 'matchevent-collections')}
            onChange={() => {
              change(FORM, 'editEvent.matchEventKind', {})
              change(FORM, 'editEvent.lineupOff', {})
              change(FORM, 'editEvent.position', null)
              change(FORM, 'editEvent.homeShootOutScore', null)
              change(FORM, 'editEvent.awayShootOutScore', null)
            }}
          >
            <SelectInput optionText="name" fullWidth />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={4}>
          <ReferenceInput
            label="Type"
            source="editEvent.matchEventKind.id"
            reference={getResource('competiciones', 'matchevent-types')}
            disabled={!event.collection}
            filter={{ collection: event.collection }}
          >
            <SelectInput optionText="name" fullWidth />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectInput
            label="Team"
            source="editEvent.team"
            choices={[{ ...match.homeTeam }, { ...match.awayTeam }]}
            optionText={this.optionSelectTeam}
            onChange={() => {
              change(FORM, 'editEvent.lineup', {})
              change(FORM, 'editEvent.lineupOff', {})
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReferenceInput
            label="Player"
            source="editEvent.lineup.id"
            reference={getResource('competiciones', 'lineups')}
            sort={{ field: 'shirtNumber', order: 'ASC' }}
            filter={{ match: match.id, team: event.team }}
          >
            <SelectInput
              optionText={(choice) => `${choice.shirtNumber ? `${choice.shirtNumber} - ` : ''}${choice.personRole.person.name}`}
              disabled={!event.team}
              fullWidth
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={4}>
          {event.collection === 'substitution' && (
            <ReferenceInput
              label="Player Off"
              source="editEvent.lineupOff.id"
              reference={getResource('competiciones', 'lineups')}
              sort={{ field: 'shirtNumber', order: 'ASC' }}
              filter={{ match: match.id, team: event.team }}
            >
              <SelectInput
                optionText={(choice) => `${choice.shirtNumber ? `${choice.shirtNumber} - ` : ''}${choice.personRole.person.name}`}
                disabled={!event.team}
                fullWidth
              />
            </ReferenceInput>
          )}
          {event.collection === 'shootout' && <NumberInput label="Position" source="editEvent.position" fullWidth />}
        </Grid>
        {event.collection === 'shootout' && (
          <Fragment>
            <Grid item xs={12} md={4}></Grid>
            <Grid item xs={12} md={4}>
              <NumberInput label="Home Shoot Out Score" source="editEvent.homeScore" fullWidth />
            </Grid>
            <Grid item xs={12} md={4}>
              <NumberInput label="Away Shoot Out Score" source="editEvent.awayScore" fullWidth />
            </Grid>
          </Fragment>
        )}
        <Grid item xs={12} className={classes.buttonRow}>
          {!event.id && (
            <Button onClick={() => this.addMatchEvent()} variant="contained" size="small" className={classes.button} color="primary">
              Add
            </Button>
          )}
          {event.id && (
            <Fragment>
              <Button onClick={() => this.resetMatchEvent()} variant="contained" size="small" className={classes.button}>
                Cancel
              </Button>
              <Button onClick={() => this.editMatchEvent()} variant="contained" size="small" className={classes.button} color="primary">
                Save
              </Button>
            </Fragment>
          )}
        </Grid>
      </Grid>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      isSubmitting: isSubmitting(FORM)(state),
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(EditEvent)
