import Grid from '@material-ui/core/Grid'
import React from 'react'
import { TextInput, required, maxLength, SelectInput, FormDataConsumer } from 'react-admin'
import { DateTimeInput, SelectMarketsInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'
import { BRAND_DAY_SLUG } from '../variables'
import BrandDayMatches from './BrandDayMatches'

export const DigitalAssetsForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        if (formData) {
          return (
            <Grid container>
              <Grid item xs={12}>
                <SelectMarketsInput source="markets" />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextInput label="Name" source="name" validate={[required(), maxLength(255)]} fullWidth />
              </Grid>
              <Grid item xs={12} md={4}>
                <SelectInput
                  allowEmpty={false}
                  validate={[required()]}
                  fullWidth
                  source="price"
                  label="Label"
                  choices={[
                    {
                      id: 'premium',
                      name: 'Premium',
                    },
                    {
                      id: 'gold',
                      name: 'Gold',
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DateTimeInput label="Start" source="startedAt" date time />
              </Grid>
              <Grid item xs={12} md={6}>
                <DateTimeInput label="End" source="endAt" date time />
              </Grid>
              {formData.slug === BRAND_DAY_SLUG && <BrandDayMatches />}
            </Grid>
          )
        }
      }}
    </FormDataConsumer>
  </LanguagesNav>
)

export default DigitalAssetsForm
