import 'brace/mode/html'
import 'brace/theme/kuroir'

import React, { Component } from 'react'

import { ScriptInput } from '../../../'
import { ContentStyled } from './style'

class scriptPluginEdit extends Component {
  render() {
    return (
      <ContentStyled>
        <ScriptInput
          onChange={(value) => this.props.onChange({ content: value })}
          value={this.props.value && this.props.value.content ? this.props.value.content : ''}
        />
      </ContentStyled>
    )
  }
}

export default scriptPluginEdit
