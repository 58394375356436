import React from 'react'
import { List, Datagrid, TextField, EditButton, Responsive, SimpleList, ReferenceField, Filter, TextInput } from 'react-admin'
import { TableRowList, ListActions } from '../../../components'
import { ImageField } from '../../../components/field'
import { getResource } from '../../../config/resources'

const ClubsFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput className={props.classes.input} variant="filled" label="Name" source="name" alwaysOn fullWidth />
      <TextInput className={props.classes.input} variant="filled" label="Shortname" source="shortname" alwaysOn fullWidth />
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<ClubsFilter />} />
}

const ClubsList = (props) => (
  <List bulkActions={false} title="Clubs" actions={<Actions />} {...props}>
    <Responsive
      small={
        <SimpleList
          leftAvatar={(record) => (
            <ReferenceField
              source="shield"
              reference={getResource('assets', 'images')}
              basePath={getResource('assets', 'images')}
              linkType={false}
              record={record}
              allowEmpty
            >
              <ImageField mini source="asset.fileDir" title="imagen competition" />
            </ReferenceField>
          )}
          primaryText={(record) => record.name}
        />
      }
      medium={
        <Datagrid>
          <ReferenceField label="Image" source="shield" reference={getResource('assets', 'images')} linkType={false} sortable={false} allowEmpty>
            <ImageField source="asset.fileDir" title="imagen club" />
          </ReferenceField>
          <TextField source="name" label="Name" />
          <TextField source="shortname" label="Shortname" />
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default ClubsList
