import styled from 'styled-components'

export const OptionsBarStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  button {
    margin-bottom: 10px;
    margin-left: 10px;
    min-height: 43px;
  }
`
export const GrowStyled = styled.div`
  flex-grow: 1;
`
