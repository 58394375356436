import React from 'react'
import { Create } from 'react-admin'

import CompetitionsForm from './form'

export const RolesCreate = (props) => (
  <Create {...props}>
    <CompetitionsForm {...props} />
  </Create>
)

export default RolesCreate
