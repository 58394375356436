import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import React, { Component } from 'react'
import { DisabledInput, GET_ONE, SimpleForm } from 'react-admin'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import compose from 'recompose/compose'
import { initialize, reset } from 'redux-form'
import CircularProgress from '@material-ui/core/CircularProgress'
import equal from 'fast-deep-equal/es6'

import languages, { additionalLanguages } from '../../config/languages'
import dataProvider from '../../providers'
import ConfirmationDialog from '../confirmationDialog'
import PostCreateToolbar from './postCreateToolbar'
import { ContainerStyled, LanguagesNavStyled, MainFormStyled, TitleFormStyled, FormContainerStyled, SpinnerContainerStyled } from './styles'
import { FormActiveLanguageProvider } from './formActiveLanguageContext'

const styles = (theme) => ({
  hidden: {
    display: 'none',
  },
})

class LanguagesNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      record: props.record,
      isFetchingForm: false,
      slug: 'es',
      dialog: false,
    }
  }

  static defaultProps = {
    buttonBack: true,
    remove: false,
  }

  handleChangeLang(lang) {
    const { onChangeLang } = this.props
    this.setState({
      isFetchingForm: true,
    })

    if (onChangeLang) {
      this.setState({
        slug: lang,
        isFetchingForm: false,
        dialog: null,
      })

      onChangeLang(lang)
    } else {
      if (this.props.id) {
        return dataProvider(GET_ONE, this.props.resource, {
          id: this.props.id,
          data: { lang: lang },
        })
          .then((response) => {
            this.setState(
              {
                slug: lang,
                record: { ...response.data, lang: lang },
              },
              () => this.props.initialize({ ...response.data, lang: lang })
            )
          })
          .catch((error) => {
            console.log('ERROR')
          })
          .finally(() => {
            this.setState({
              isFetchingForm: false,
              dialog: null,
            })
          })
      } else {
        this.setState(
          {
            isFetchingForm: false,
            dialog: null,
            slug: lang,
            record: { lang: lang },
          },
          () => this.props.initialize({ lang: lang })
        )
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { record } = this.props
    if (!equal(record, prevProps.record)) {
      this.setState({ record: record })
    }
  }

  render() {
    let {
      hasList,
      hasEdit,
      hasShow,
      buttonBack,
      hasCreate,
      remove,
      disabledLanguages = false,
      disabledToolbar = false,
      customSave = false,
      save,
      useAdditionalLanguages,
      record: propRecord,
      ...myProps
    } = this.props
    const { basePath } = this.props

    if (customSave) {
      save = customSave
    }

    const languagesToUse = useAdditionalLanguages ? additionalLanguages : languages
    const dir = languagesToUse.find((lang) => lang.slug === this.state.slug).dir || 'ltr'

    return (
      <div>
        <ContainerStyled>
          {buttonBack && (
            <IconButton className="button-back" component={Link} to={basePath}>
              <NavigateBeforeIcon />
            </IconButton>
          )}
          {!disabledLanguages && (
            <LanguagesNavStyled>
              {languagesToUse &&
                languagesToUse.map((item, key) => {
                  return (
                    <React.Fragment key={item.slug}>
                      <ConfirmationDialog
                        open={this.state.dialog === item.slug}
                        onSuccess={() => this.handleChangeLang(item.slug)}
                        onCancel={() => this.setState({ dialog: null })}
                        title="Change languaje"
                        isFetching={this.state.isFetchingForm}
                        content={
                          <div>
                            <p>Si continuas los datos no guardados se perderán</p>
                            <p>¿Estás seguro?</p>
                          </div>
                        }
                      />
                      <span
                        key={key}
                        onClick={() => {
                          if (item.slug !== this.state.slug) {
                            this.setState({ dialog: item.slug })
                          }
                        }}
                        className={this.state.slug === item.slug ? 'active' : ''}
                      >
                        {item.title}
                      </span>
                    </React.Fragment>
                  )
                })}
            </LanguagesNavStyled>
          )}
        </ContainerStyled>
        <MainFormStyled>
          <SimpleForm
            {...myProps}
            record={this.state.record}
            save={save}
            toolbar={disabledToolbar || this.state.isFetchingForm ? null : <PostCreateToolbar {...myProps} isEdit={this.props.id} remove={remove} />}
            submitOnEnter={false}
            redirect="list"
            defaultValue={this.props.defaultValue}
          >
            {!this.state.isFetchingForm ? (
              <React.Fragment>
                {this.props.options && this.props.options.label && (
                  <TitleFormStyled>{`${this.props.options.label} ${this.props.id ? 'edit' : 'create'}`}</TitleFormStyled>
                )}
                <FormContainerStyled fullWidth>
                  <FormActiveLanguageProvider formActiveLanguage={this.state.slug} useAdditionalLanguages={useAdditionalLanguages}>
                    <DisabledInput className={this.props.classes.hidden} source="lang" />
                    {typeof this.props.children === 'function' ? this.props.children({ dir }) : this.props.children}
                  </FormActiveLanguageProvider>
                </FormContainerStyled>
              </React.Fragment>
            ) : (
              <SpinnerContainerStyled>
                <CircularProgress />
              </SpinnerContainerStyled>
            )}
          </SimpleForm>
        </MainFormStyled>
      </div>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({}),
    (dispatch) => ({
      reset: () => dispatch(reset('record-form')),
      initialize: (data) => dispatch(initialize('record-form', data)),
    })
  ),
  withStyles(styles)
)

export default enhance(LanguagesNav)
