import React from 'react'
import { Edit } from 'react-admin'

import PromiseForm from '../utils/form'

const PromiseTitle = ({ record }) => {
  return <span>{`Operador: ${record.nombre}`}</span>
}

export const PromiseEdit = (props) => (
  <Edit undoable={false} title={<PromiseTitle />} {...props}>
    <PromiseForm {...props} />
  </Edit>
)

export default PromiseEdit
