import { Button, Chip, InputLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector, initialize, isSubmitting } from 'redux-form'

import { DELETE, GET_ONE, fetchEnd, fetchStart, showNotification } from 'react-admin'
import dataProvider from '../../../../providers'
import { getResource } from '../../../../config/resources'
import { ConfirmationDialog, SubForm } from '../../../../components'
import ChannelsMatchForm, { states as statesChannels } from '../../../operadores/channelMatches/form/form.js'

const FORM = 'channelsMatch-form'
const selector = formValueSelector(FORM)

const styles = (theme) => ({
  main: {
    width: '100%',
    display: 'flex',
  },
  containerChannels: {
    flex: 'auto',
  },
  containerButtons: {
    padding: `${theme.spacing.unit}px`,
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  addIcon: {
    fontSize: 20,
    marginLeft: theme.spacing.unit,
  },
  chip: {
    margin: theme.spacing.unit / 2,
    '&:first-child': {
      marginLeft: 0,
    },
  },
})

class ChannelsMatch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      channels: false,
      modalChannel: false,
      recordChannel: {},
      indexDeleteChannel: false,
    }
  }

  componentDidMount() {
    const { channels } = this.props
    this.setState({
      channels: channels,
    })
  }

  openChannelMatch(index) {
    const { channels } = this.state

    if (index !== false) {
      const id = channels[index].id
      dataProvider(GET_ONE, getResource('operadores', 'channelmatches'), {
        id: id,
      })
        .then(({ data }) => {
          this.setState({
            modalChannel: true,
            recordChannel: data,
            indexChannelOpen: index,
          })
        })
        .finally(() => {
          fetchEnd()
        })
    } else {
      this.setState({
        modalChannel: true,
        recordChannel: {
          match: this.props.match,
          metadatas: this.props.initialMetadata.concat([{ type: 'match', value: this.props.match }]),
          channelMatchCountries: [{ country: 'ES', state: statesChannels.approved }],
        },
        indexChannelOpen: false,
      })
    }
  }

  closeChannelMatch() {
    this.setState({
      modalChannel: false,
      recordChannel: {},
      indexChannelOpen: false,
    })
  }

  onSaveChannelMatch(data) {
    const { indexChannelOpen, channels } = this.state

    let channelsUpdate = [...channels]
    if (indexChannelOpen !== false) {
      channelsUpdate[indexChannelOpen] = data
      this.setState({
        modalChannel: false,
        recordChannel: {},
        channels: channelsUpdate,
        indexChannelOpen: false,
      })
    } else {
      const id = data.id
      dataProvider(GET_ONE, getResource('operadores', 'channelmatches'), {
        id: id,
      })
        .then(({ data }) => {
          channelsUpdate.push(data)
          this.setState({
            modalChannel: false,
            recordChannel: {},
            channels: channelsUpdate,
            indexChannelOpen: false,
          })
        })
        .finally(() => {
          fetchEnd()
        })
    }

    this.setState({
      modalChannel: false,
      recordChannel: {},
      channels: channelsUpdate,
      indexChannelOpen: false,
    })
  }

  confirmDeleteChannel(index) {
    this.setState({
      indexDeleteChannel: index,
    })
  }

  deleteChannel() {
    const { fetchStart, fetchEnd, showNotification } = this.props
    const { channels, indexDeleteChannel } = this.state
    const idChannelMatch = channels[indexDeleteChannel].id

    fetchStart()
    dataProvider(DELETE, getResource('operadores', 'channelmatches'), {
      id: idChannelMatch,
    })
      .then((response) => {
        let newChannels = [...channels]
        newChannels.splice(indexDeleteChannel, 1)
        this.setState({
          channels: newChannels,
          indexDeleteChannel: false,
        })
        showNotification('Channel deleted', 'success')
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  render() {
    const { label, classes, isSubmitting } = this.props
    const { channels, modalChannel, recordChannel, indexDeleteChannel } = this.state

    return (
      <div>
        <InputLabel disableAnimation>{label ? label : 'Channels'}</InputLabel>
        <div className={classes.main}>
          <div className={classes.containerChannels}>
            {channels &&
              channels.map((item, key) => {
                return (
                  <Chip
                    key={key}
                    label={item.channel.name}
                    onClick={() => this.openChannelMatch(key)}
                    onDelete={() => this.confirmDeleteChannel(key)}
                    className={classes.chip}
                  />
                )
              })}
          </div>
          <div className={classes.containerButtons}>
            <Button variant="contained" size="small" color="primary" className={classes.button} onClick={() => this.openChannelMatch(false)}>
              Add
              <AddIcon className={classes.addIcon} />
            </Button>
          </div>
        </div>
        <ConfirmationDialog
          title={recordChannel.id ? `Edit: ${recordChannel.channel.name}` : 'Add channel'}
          isSubmitting={isSubmitting}
          open={modalChannel}
          fullWidth={false}
          maxWidth="md"
          onCancel={() => {
            this.closeChannelMatch()
          }}
          dialogActions={false}
        >
          <SubForm
            submitOnEnter={false}
            form={FORM}
            record={recordChannel}
            resource={getResource('operadores', 'channelmatches')}
            onClose={() => this.closeChannelMatch()}
            onSubmit={(data) => this.onSaveChannelMatch(data)}
          >
            <ChannelsMatchForm form={FORM} />
          </SubForm>
        </ConfirmationDialog>
        <ConfirmationDialog
          open={indexDeleteChannel !== false}
          onSuccess={() => this.deleteChannel()}
          onCancel={() => this.setState({ indexDeleteChannel: false })}
          title="Delete channel"
          content="Are you sure?"
        />
      </div>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      isSubmitting: isSubmitting(FORM)(state),
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(ChannelsMatch)
