import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import _ from 'lodash'
import React, { Component } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'

import AddSection from './addSection'
import OptionBar from './optionBar'
import OptionsSection from './optionsSection'
import {
  ContainerListStyled,
  ContainerStyled,
  GrowSpaceStyled,
  HeaderListStyled,
  LabelStyled,
  ListDndStyled,
  ModuleGridStyled,
  HeaderStyled,
  FormControlStyled,
  LabelSectionStyled,
} from './style'
import SubList from './subList'

const styles = (theme) => ({
  formControl: {
    borderLeft: `solid 1px ${theme.palette.grey[400]}`,
    padding: '0px 10px',
    '&:first-child': {
      border: 'none',
    },
  },
  radioLight: {
    '& svg': {
      color: theme.palette.grey[700],
    },
  },
})

const SECTIONS_DEFAULT = [
  { id: '1', name: 'Content', areas: [100] },
  { id: '31', name: 'Content + Sidebar', areas: [75, 25] },
  { id: '13', name: 'Sidebar + Content', areas: [25, 75] },
]

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  result[startIndex].position = startIndex
  result[endIndex].position = endIndex

  return result
}

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: null,
    }
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  componentDidMount() {
    const { value } = this.props.input ? this.props.input : this.props
    this.setState(
      {
        items: this.applyIds(value),
      },
      () => {
        this.onChange()
      }
    )
  }

  onChange() {
    const { onChange } = this.props.input ? this.props.input : this.props
    onChange(this.prepareLayout())
  }

  prepareLayout() {
    let list = _.cloneDeep(this.state.items)
    _.map(list, (item, index) => {
      let modules = []
      _.map(item.modules, (module) => {
        modules = _.concat(modules, module)
        return null
      })
      list[index].modules = modules
      return null
    })
    return list
  }

  applyIds(list) {
    const myList = _.orderBy([...list], ['position'], ['asc'])
    _.map(myList, (item, index) => {
      const myId = index
      myList[index].id = myId
      if (item.modules) {
        let orderModules = _.orderBy(item.modules, ['position'], ['asc'])
        myList[index].modules = _.map(orderModules, (subItem, subIndex) => {
          const moduleItem = { ...myList[index].modules[subIndex] }
          moduleItem.id = `${myId}/${subItem.area}/${subIndex}`
          return moduleItem
        })
        myList[index].modules = _.groupBy(myList[index].modules, 'area')
      }
    })
    return myList
  }

  applyIndexToPosition = (array) => {
    return _.map(array, (item, index) => {
      const newItem = { ...item }
      newItem.position = index
      return newItem
    })
  }

  onDragEnd(result) {
    if (!result.destination) {
      return
    }
    if (result.type === 'droppableItem') {
      const items = reorder(this.state.items, result.source.index, result.destination.index)

      this.setState(
        {
          items: items,
        },
        () => this.onChange()
      )
    } else {
      const parentId = parseInt(result.type.split('-')[1])
      const listIndex = parseInt(result.source.droppableId.split('/')[1])
      const subItemsForCorrespondingParent = this.state.items[parentId].modules

      const reorderedSubItems = reorder(subItemsForCorrespondingParent[listIndex], result.source.index, result.destination.index)
      let newItems = [...this.state.items]
      newItems[parentId].modules[listIndex] = reorderedSubItems
      this.setState(
        {
          items: newItems,
        },
        () => this.onChange()
      )
    }
  }

  handleAddSection = (section) => {
    let mySections = [...this.state.items]
    const sectionId = section.id
    const typeSections = sectionId.toString().split('')
    let modules = {}
    _.map(typeSections, (item) => {
      modules[item] = []
      return null
    })
    mySections.push({
      name: 'Contenedor 1',
      section: sectionId,
      type: sectionId,
      theme: 'dark',
      modules: modules,
    })
    this.setState(
      {
        items: this.applyIndexToPosition(mySections),
      },
      () => this.onChange()
    )
  }

  handleDelete = (index) => {
    let newItems = [...this.state.items]
    newItems.splice(index, 1)
    this.setState(
      {
        items: this.applyIndexToPosition(newItems),
      },
      () => this.onChange()
    )
  }

  onChangeItemConfig = (indexSection, indexModule, index, item) => {
    const list = [...this.state.items]
    list[indexSection].modules[indexModule][index] = item
    this.setState(
      {
        items: list,
      },
      () => this.onChange()
    )
  }

  handleDeleteItem = (indexSection, indexModule, index) => {
    const list = [...this.state.items]
    let moduleList = _.get(list[indexSection].modules, indexModule)
    moduleList.splice(index, 1)
    moduleList = this.applyIndexToPosition(moduleList)
    list[indexSection].modules[indexModule] = moduleList
    this.setState(
      {
        items: list,
      },
      () => this.onChange()
    )
  }

  handleAddModules = (modules, indexSection, indexModule) => {
    const list = [...this.state.items]
    list[indexSection].modules[indexModule] = this.applyIndexToPosition(
      list[indexSection].modules[indexModule] ? _.concat(list[indexSection].modules[indexModule], modules) : modules
    )
    this.setState(
      {
        items: list,
      },
      () => this.onChange()
    )
  }

  changeTheme = (event, sectionIndex) => {
    let items = [...this.state.items]
    items[sectionIndex].theme = event.target.value

    this.setState(
      {
        items: items,
      },
      () => this.onChange()
    )
  }

  changePadding = (event, sectionIndex) => {
    let items = [...this.state.items]
    items[sectionIndex].padding = !items[sectionIndex].padding

    this.setState(
      {
        items: items,
      },
      () => this.onChange()
    )
  }

  ThemeSelector = ({ sectionIndex }) => {
    const { classes } = this.props
    let items = [...this.state.items]
    const theme = items[sectionIndex].theme
    const padding = items[sectionIndex].padding

    return (
      <HeaderStyled>
        <FormControlStyled component="fieldset" className={classes.formControl}>
          <RadioGroup aria-label="position" name="position" value={theme} onChange={(event) => this.changeTheme(event, sectionIndex)} row>
            <FormControlLabel value="dark" control={<Radio color="primary" />} label="Dark" labelPlacement="end" />
            <FormControlLabel value="light" control={<Radio className={classes.radioLight} />} label="Light" labelPlacement="end" />
          </RadioGroup>
        </FormControlStyled>
        <FormControlStyled component="fieldset" className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox label="Padding" checked={padding} onChange={(event) => this.changePadding(event, sectionIndex)} value={padding} color="primary" />
            }
            label="Padding"
          />
        </FormControlStyled>
      </HeaderStyled>
    )
  }

  render() {
    const { label, options } = this.props
    const { ThemeSelector } = this
    const sections = options && options.sections ? options.sections : SECTIONS_DEFAULT

    if (!this.state.items) return null
    return (
      <ContainerStyled>
        <OptionBar>
          {label && <LabelStyled>{label}</LabelStyled>}
          <GrowSpaceStyled />
          <AddSection onChange={this.handleAddSection} sections={sections} />
        </OptionBar>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable" type="droppableItem">
            {(provided, snapshot) => (
              <ListDndStyled innerRef={provided.innerRef} isDraggingOver={snapshot.isDraggingOver}>
                {_.map(this.state.items, (item, index) => (
                  <Draggable key={item.id} draggableId={index} index={index}>
                    {(provided, snapshot) => (
                      <ContainerListStyled>
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <HeaderListStyled>
                            <div {...provided.dragHandleProps}>
                              <DragHandleIcon />
                            </div>
                            <LabelSectionStyled>{_.find(sections, { id: item.type }).name}</LabelSectionStyled>
                            <GrowSpaceStyled />
                            <ThemeSelector sectionIndex={index} />
                            <OptionsSection onDelete={() => this.handleDelete(index)} />
                          </HeaderListStyled>
                          <ModuleGridStyled>
                            <SubList
                              onChangeItemConfig={this.onChangeItemConfig}
                              onDeleteItem={this.handleDeleteItem}
                              subItems={item.modules}
                              type={item.type}
                              id={item.id}
                              areas={item.areas}
                              indexSection={index}
                              modules={this.state.modules}
                              onChangeModules={this.handleAddModules}
                              sections={sections}
                            />
                          </ModuleGridStyled>
                        </div>
                        {provided.placeholder}
                      </ContainerListStyled>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ListDndStyled>
            )}
          </Droppable>
        </DragDropContext>
      </ContainerStyled>
    )
  }
}

export default withStyles(styles)(Layout)
