import React from 'react'
import { FormLabel, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { FormDataConsumer, ReferenceField, TextField } from 'react-admin'

import { getResource } from '../../../../config/resources'
import { MatchField } from '../../../../components/field'

const styles = (theme) => ({})

const MatchInfo = ({ classes, record, ...rest }) => {
  return (
    <FormDataConsumer>
      {({ formData, dispatch, ...rest }) => {
        if (formData) {
          return (
            <Grid container>
              <Grid item xs={12} sm={4}>
                <FormLabel>Season</FormLabel>
                <ReferenceField
                  source="match"
                  reference={getResource('competiciones', 'matches')}
                  basePath={getResource('competiciones', 'matches')}
                  linkType={false}
                  record={formData}
                >
                  <TextField source="season.name" fullWidth />
                </ReferenceField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormLabel>Competition</FormLabel>
                <ReferenceField
                  source="match"
                  reference={getResource('competiciones', 'matches')}
                  basePath={getResource('competiciones', 'matches')}
                  linkType={false}
                  record={formData}
                >
                  <TextField source="competition.name" fullWidth />
                </ReferenceField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormLabel>Subscription</FormLabel>
                <ReferenceField
                  source="match"
                  reference={getResource('competiciones', 'matches')}
                  basePath={getResource('competiciones', 'matches')}
                  linkType={false}
                  record={formData}
                >
                  <TextField source="subscription.competitionName" fullWidth />
                </ReferenceField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormLabel>Round</FormLabel>
                <ReferenceField
                  source="match"
                  reference={getResource('competiciones', 'matches')}
                  basePath={getResource('competiciones', 'matches')}
                  linkType={false}
                  record={formData}
                >
                  <TextField source="gameweek.round.name" fullWidth />
                </ReferenceField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormLabel>Gameweek</FormLabel>
                <ReferenceField
                  source="match"
                  reference={getResource('competiciones', 'matches')}
                  basePath={getResource('competiciones', 'matches')}
                  linkType={false}
                  record={formData}
                >
                  <TextField source="gameweek.name" fullWidth />
                </ReferenceField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormLabel>Gameweek</FormLabel>
                <ReferenceField
                  source="match"
                  reference={getResource('competiciones', 'matches')}
                  basePath={getResource('competiciones', 'matches')}
                  linkType={false}
                  record={formData}
                >
                  <MatchField />
                </ReferenceField>
              </Grid>
            </Grid>
          )
        }
      }}
    </FormDataConsumer>
  )
}

export default withStyles(styles)(MatchInfo)
