import React from 'react'
import { Create } from 'react-admin'

import DynamicSplashScreenForm from './form'

export const DynamicSplashScreenCreate = (props) => (
  <Create {...props}>
    <DynamicSplashScreenForm isEdit={false} {...props} />
  </Create>
)

export default DynamicSplashScreenCreate
