import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import LinearProgress from '@material-ui/core/LinearProgress'
import compose from 'recompose/compose'

const styles = (theme) => ({
  loader: {
    margin: 16,
  },
})

export const LoadingIndicator = ({ classes, className, isLoading, width, ...rest }) =>
  isLoading ? <LinearProgress className={classNames('app-loader', classes.loader, className)} color="primary" {...rest} /> : null

LoadingIndicator.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  width: PropTypes.string,
}

const mapStateToProps = (state) => ({
  isLoading: state.admin.loading > 0,
})

export default compose(
  connect(
    mapStateToProps,
    {} // Avoid connect passing dispatch in props
  ),
  withStyles(styles),
  withWidth()
)(LoadingIndicator)
