import React from 'react'
import { Show } from 'react-admin'
import WSCForm from './form'

export const WSCEdit = (props) => (
  <Show {...props} hasEdit={false}>
    <WSCForm {...props} />
  </Show>
)

export default WSCEdit
