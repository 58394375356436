import React, { Component } from 'react'
import { showNotification } from 'react-admin'
import { arrayMove, List } from 'react-movable'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Card from './card'
import { Button, withStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const styles = (theme) => {
  return {
    root: {
      padding: '8px 0',
      width: '100%',
      boxSizing: 'border-box',
    },
    label: {
      marginBottom: '10px',
      display: 'flex',
      fontSize: '0.75rem',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    imageRow: {
      background: theme.palette.grey[50],
      width: '100%',
      padding: '8px 8px 8px 0',
      marginBottom: '8px',
      boxSizing: 'border-box',
      display: 'flex',
    },
    image: {
      '& > div': {
        display: 'flex',
      },
      '& label': {
        display: 'none',
      },
      '& .imageContainer': {
        minHeight: '0px',
        minWidth: '0px',
        height: '100px',
        width: '100px',
        overflow: 'hidden',
      },
    },
    imagePortrait: {
      '& .imageContainer': {
        width: '133px',
        height: '200px',
      },
    },
    imageLandscape: {
      '& .imageContainer': {
        width: '200px',
        height: '133px',
      },
    },
    moreInfo: {
      boxSizing: 'border-box',
      padding: '8px 16px',
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
    },
    drag: {
      flex: '0 0 35px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }
}

class ListImages extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
  }

  handleChange(position, key, value) {
    let newContent = [...this.props.content]
    newContent[position][key] = value
    this.props.onChange(newContent)
  }

  handleRemove(position) {
    let newContent = [...this.props.content]
    newContent.splice(position, 1)
    this.props.onChange(newContent)
  }

  handleAdd() {
    let newContent = this.props.content ? [...this.props.content] : []
    newContent.push({
      image: null,
      target: 'external',
      url: '',
      urlTitle: '',
      title: '',
      excerpt: '',
    })
    this.props.onChange(newContent)
    this.props.showNotification('You added a new card: Image Title and description cannot be empty', 'warning')
  }

  handleChangeOrder = ({ oldIndex, newIndex }) => {
    let newContent = [...this.props.content]
    newContent = arrayMove(newContent, oldIndex, newIndex)
    this.props.onChange(newContent)
  }

  render() {
    const { content, classes, version } = this.props
    return (
      <div className={classes.root}>
        <label className={classes.label}>
          <span>Cards</span>
          <Button color="primary" size="small" variant="contained" onClick={this.handleAdd}>
            <AddIcon />
          </Button>
        </label>
        {content && (
          <List
            lockVertically
            values={content}
            onChange={this.handleChangeOrder}
            renderList={({ children, props }) => <div {...props}>{children}</div>}
            renderItem={({ index, props, isDragged }) => {
              const each = content[index]
              return (
                <div {...props} style={{ ...props.style, zIndex: 1 }}>
                  <Card each={each} isDragged={isDragged} version={version} index={index} handleChange={this.handleChange} handleRemove={this.handleRemove} />
                </div>
              )
            }}
          />
        )}
      </div>
    )
  }
}

const enhance = compose(
  connect(null, {
    showNotification,
  }),
  withStyles(styles)
)

export default enhance(ListImages)
