import React from 'react'
import { List, Datagrid, TextField, EditButton, Filter, TextInput, SimpleList, Responsive } from 'react-admin'
import { TableRowList, ListActions, DateField } from '../../../components'

const GenuineFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput className={props.classes.input} variant="filled" label="Name" source="competitionName" alwaysOn fullWidth />
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<GenuineFilter />} />
}

export const GenuineList = (props) => {
  return (
    <List bulkActions={false} filter={{ competition: 22, type: 'table' }} title="Operadores" {...props} actions={<Actions />}>
      <Responsive
        small={
          <SimpleList
            primaryText={(record) => record.subscription.competitionName}
            tertiaryText={(record) => (
              <div>
                <DateField record={record} source="subscription.dateEnd" sortable={false} label="Date end" />
                <DateField record={record} source="subscription.dateIni" sortable={false} label="Date start" />
              </div>
            )}
          />
        }
        medium={
          <Datagrid>
            <TextField source="subscription.competitionName" sortable={false} label="Competition" />
            <DateField source="subscription.dateIni" sortable={false} label="Date start" />
            <DateField source="subscription.dateEnd" sortable={false} label="Date end" />
            <TableRowList>
              <EditButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}

export default GenuineList
