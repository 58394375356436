import React from 'react'
import { Grid, List, ListItem, ListItemText, Collapse } from '@material-ui/core'
import { TextInput, required, maxLength } from 'react-admin'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

class Events extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  handleClick = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    return (
      <>
        <ListItem button onClick={this.handleClick}>
          <ListItemText primary="Events Module" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div">
            <ListItem>
              <Grid container>
                <Grid item xs={12}>
                  <TextInput fullWidth source="eventModule.label" label="Events Module Label" validate={[required(), maxLength(255)]} />
                </Grid>
                <Grid item xs={12}>
                  <TextInput fullWidth source="eventModule.title" label="Events Module Title" validate={[required(), maxLength(255)]} />
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Collapse>
      </>
    )
  }
}

export default Events
