import React from 'react'
import { GET_LIST, showNotification } from 'react-admin'
import { connect } from 'react-redux'
import dataProvider from '../../../../../providers'
import { getResource } from '../../../../../config/resources'
import { Typography, CircularProgress, Button, Grid, Collapse, Select, MenuItem } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import _ from 'lodash'
import { PATCH } from '../../../../../providers/dataProvider/types'

class AdsSpacesList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openList: false,
      loading: false,
      fetching: false,
      calendar: null,
      matchDetailpage: null,
      sponsors: [],
      adsspaces: [],
    }
  }
  componentDidMount() {
    this.getSponsors()
    this.getAdsSpaces()
  }

  getSponsors() {
    this.setState({ loading: true })
    dataProvider(GET_LIST, getResource('digitalAssets', 'sponsors'), {
      sort: {
        field: 'createdAt',
        order: 'DESC',
      },
      filter: {
        active: true,
      },
      pagination: {
        page: 1,
        perPage: 50,
      },
    }).then(({ data }) => {
      this.setState({
        sponsors: data,
        loading: false,
      })
    })
  }

  getAdsSpaces() {
    this.setState({ loadingAdsSpaces: true })
    dataProvider(GET_LIST, getResource('digitalAssets', 'ads-spaces'), {
      sort: {
        field: 'createdAt',
        order: 'DESC',
      },
      filter: {
        active: true,
      },
      pagination: {
        page: 1,
        perPage: 50,
      },
    }).then(({ data }) => {
      this.setState({
        calendar: data[1],
        matchDetailpage: data[0],
        loadingAdsSpaces: false,
      })
    })
  }

  handleClick = () => {
    this.setState({
      openList: !this.state.openList,
    })
  }

  handleAdsSpaces = (event) => {
    const name = event.target.name
    const selectedSponsorId = event.target.value
    const newSponsor = selectedSponsorId ? this.state.sponsors.find((sponsor) => sponsor.id === selectedSponsorId) : null

    const oldState = this.state[name]

    const updatedState = {
      ...oldState,
      sponsor: newSponsor,
    }

    this.setState({
      [name]: updatedState,
    })
  }

  handleSaveData() {
    this.setState({ fetching: true })
    Promise.all(
      [this.state.calendar, this.state.matchDetailpage].map((adsSpace) => {
        return dataProvider(PATCH, getResource('digitalAssets', 'ads-spaces'), {
          id: adsSpace.id,
          data: adsSpace,
        })
      })
    )
      .then(({ data }) => {
        this.setState({ fetching: false })
        this.props.showNotification('Ads Spaces updated', 'success')
      })
      .catch((error) => {
        this.setState({ error: true, fetching: false })
        this.props.showNotification(error.message, 'error')
      })
  }

  render() {
    return (
      <>
        <div style={{ margin: '29px' }}>
          {this.state.loading ? (
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Typography component="h2" variant="h2" style={{ marginBottom: '16px', marginRight: '16px' }}>
                Loading ads spaces
              </Typography>
              <CircularProgress size={24} />
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'flex-start' }} onClick={() => this.handleClick()}>
              <Typography component="h2" variant="h2" style={{ marginBottom: '16px' }}>
                Ads Spaces
              </Typography>
              <div style={{ marginLeft: '16px' }}>{this.state.openList ? <ExpandLess /> : <ExpandMore />}</div>
            </div>
          )}
          {this.state.sponsors && this.state.openList && (
            <Collapse in={this.state.openList} timeout={600}>
              <Grid item xs={12} lg={8}>
                <Grid item xs={12} lg={8}>
                  <Typography>COMPETITIONS – CALENDAR:</Typography>
                  <Select
                    value={this.state.calendar.sponsor ? this.state.calendar.sponsor.id : ''}
                    onChange={(event) => this.handleAdsSpaces(event)}
                    fullWidth
                    inputProps={{ name: 'calendar', id: 'calendar' }}
                  >
                    <MenuItem value={null}>-</MenuItem>
                    {_.map(this.state.sponsors, (item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Typography>MATCH DETAIL PAGE:</Typography>
                  <Select
                    value={this.state.matchDetailpage?.sponsor?.id || ''}
                    onChange={(event) => this.handleAdsSpaces(event)}
                    fullWidth
                    inputProps={{ name: 'matchDetailpage', id: 'matchDetailpage' }}
                  >
                    <MenuItem value={null}>-</MenuItem>
                    {_.map(this.state.sponsors, (item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={this.state.loading || this.state.fetching}
                  onClick={() => this.handleSaveData()}
                >
                  {this.state.fetching ? 'Saving...' : 'Save'}
                </Button>
              </Grid>
            </Collapse>
          )}
        </div>
      </>
    )
  }
}

const enhance = connect(null, {
  showNotification,
})

export default enhance(AdsSpacesList)
