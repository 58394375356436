import React from 'react'
import { Create } from 'react-admin'

import MetadataOthersForm from './form'

export const MetadataOthersCreate = (props) => (
  <Create {...props}>
    <MetadataOthersForm {...props} />
  </Create>
)

export default MetadataOthersCreate
