import React, { Component } from 'react'
import { Field } from 'redux-form'
import DateRange from './DateRange'

class DateRangeInput extends Component {
  render() {
    return <Field component={DateRange} {...this.props} name={this.props.name ? this.props.name : this.props.source} />
  }
}

export default DateRangeInput
