import { createMuiTheme } from '@material-ui/core/styles'
import { relative } from 'path'

export const colors = {
  primary: {
    light: '#cceae7',
    main: '#009688',
    dark: '#009688',
    contrastText: '#fff',
  },
  secondary: {
    light: '#0077C8',
    main: '#e0e0e0',
    dark: '#0077C8',
    contrastText: '#fff',
  },
  common: {
    black: '#25282A',
    white: '#ffffff',
  },
  grey: {
    800: '#616161',
    700: '#7F7F7F',
    600: '#8F8F8F',
    500: '#959899',
    400: '#BBBBBB',
    300: '#DCDCDC',
    200: '#E4E4E4',
    100: '#EEEEEE',
    50: '#F5F5F5',
  },
  error: {
    dark: '#C8102E',
    main: '#C8102E',
    light: '#E38796',
    contrastText: '#ffffff',
  },
  background: {
    default: '#EEEEEE',
    paper: '#ffffff',
  },
  states: {
    success: '#7ED321',
    successLight: '#B2DF81',
    warning: '#FCBB01',
    warningLight: '#F1CA8A',
    error: '#C8102E',
    errorLight: '#E38796',
  },
}

export const size = {
  xl: '1200px',
  lg: '899px',
  sm: '600px',
}

const theme = createMuiTheme({
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: colors,
  status: colors.states,
  overrides: {
    simple: {
      form: {
        minHeight: '100vh',
      },
    },
    // Name of the component ⚛️ / style sheet
    MuiPaper: {
      // Name of the rule
      root: {
        // Some CSS
        borderRadius: 0,
        border: 'none',
        boxShadow: '0 3px 5px 2px transparent',
      },
      elevation1: {
        backgroundColor: 'transparent',
        borderRadius: 0,
        border: 'none',
        boxShadow: '0 3px 5px 2px transparent',
      },
      elevation2: {
        padding: '10px',
      },
    },
    MuiGrid: {
      item: {
        padding: '10px',
      },
    },
    MuiTable: {
      root: {
        borderCollapse: 'separate',
        borderSpacing: '0px 9px',
        '& a': {
          color: colors.secondary.dark,
          textDecoration: 'none',
          fontWeight: '500',
        },
      },
    },
    MuiTab: {
      root: {
        minWidth: '40px !important',
        flexGrow: '1',
      },
    },
    MuiTableSortLabel: {
      root: {
        '& span': {
          color: colors.common.black,
          fontSize: '12px',
        },
      },
    },
    MuiTableBody: {
      root: {
        '& tr': {
          backgroundColor: 'white',
          marginBottom: '9px',
          border: `solid 1px ${colors.grey[300]}`,
          '& td': {
            verticalAlign: 'top',
            lineHeight: '0px',
            '&:last-child': {
              padding: '0px 23px',
            },
            '& > span, & > a': {
              display: 'inline-block',
              padding: '8px 0px',
              lineHeight: '1.46429em',
            },
          },
        },
      },
    },
    MuiTableRow: {
      root: {
        position: 'relative',
      },
      hover: {
        '& td': {
          borderTop: `solid 1px ${colors.secondary.main}`,
          borderBottom: `solid 1px ${colors.secondary.main}`,
          backgroundColor: colors.common.white,
        },
        '& td:last-child': {
          borderRight: `solid 1px ${colors.secondary.main}`,
          borderTopRightRadius: '3px',
          borderBottomRightRadius: '3px',
        },
        '& td:first-child': {
          borderLeft: `solid 1px ${colors.secondary.main}`,
          borderTopLeftRadius: '3px',
          borderBottomLeftRadius: '3px',
        },
        '&:hover td': {
          borderTop: `solid 1px ${colors.primary.main}`,
          borderBottom: `solid 1px ${colors.primary.main}`,
        },
        '&:hover td:last-child': {
          borderRight: `solid 1px ${colors.primary.main}`,
        },
        '&:hover td:first-child': {
          borderLeft: `solid 1px ${colors.primary.main}`,
        },
      },
    },
    MuiTableCell: {
      root: {
        position: 'relative',
      },
    },
    MuiButton: {
      contained: {
        borderRadius: '3px',
        boxShadow: 'none',
      },
      containedSecondary: {
        backgroundColor: colors.grey[700],
      },
    },
    MuiInput: {
      root: {
        //backgroundColor: colors.common.white
        marginTop: '0px !important',
      },
      input: {
        padding: '12px 17px',
        border: 'none',
      },
      multiline: {
        padding: '12px 17px',
        backgroundColor: colors.grey[300],
        boxSizing: 'border-box',
        '& > div': {
          padding: '12px',
          backgroundColor: colors.grey[50],
        },
      },
      underline: {
        '&:before': {
          borderBottom: `solid 1px ${colors.grey[300]}`,
        },
      },
    },
    MuiCardContent: {
      root: {
        maxWidth: size.lg,
        paddingBottom: '24px',
        marginBottom: '20px',
      },
    },
    MuiCard: {
      root: {
        overflow: 'visible',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: colors.grey[100],
      },
      paperWidthMd: {
        maxWidth: '1103px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '10px 25px 25px 25px',
      },
    },
    MuiChip: {
      root: {
        border: `solid 1px ${colors.primary.main}`,
        backgroundColor: colors.primary.light,
        borderRadius: '3px',
      },
      deleteIcon: {
        fill: `${colors.primary.main}`,
      },
      clickable: {
        '&:hover': {
          backgroundColor: colors.primary.main,
          color: colors.primary.contrastText,
          '& svg': {
            fill: colors.primary.light,
          },
        },
      },
      label: {
        fontWeight: 'normal',
        maxWidth: '300px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        lineHeight: '1.5',
        whiteSpace: 'nowrap',
        display: 'inlineBlock',
      },
    },
    MuiToolbar: {
      root: {
        minHeight: 'auto !important',
      },
      gutters: {
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
      },
    },
    MuiAppBar: {
      root: {
        minHeight: '65px',
        padding: '8px 0px',
      },
    },
    MuiTablePagination: {
      toolbar: {
        height: 'auto',
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: colors.common.white,
      },
      select: {
        borderBottom: `solid ${colors.grey[300]} 1px`,
        '&:focus': {
          backgroundColor: colors.common.white,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: '60px',
        height: '60px',
        borderRadius: '0px',
      },
      colorDefault: {
        backgroundColor: 'transparent',
      },
    },
    MuiListItem: {
      button: {
        backgroundColor: colors.common.white,
        marginBottom: '5px',
      },
    },
    MuiInputLabel: {
      formControl: {
        top: '0px',
        left: '10px',
        marginBottom: '5px',
        zIndex: '1',
        pointerEvents: 'none',
        position: relative,
      },
      shrink: {
        transform: 'translate(0, 0px) scale(0.75)',
      },
      animated: {
        //Eliminación label dentro del input
        transform: 'translate(0, 0px) scale(0.75)',
      },
    },
    MuiFormLabel: {
      root: {
        zIndex: 10,
      },
    },
    MuiFormControl: {
      root: {
        marginTop: '0px',
      },
      marginNormal: {
        marginTop: 'inherit',
      },
    },
  },
})

export default theme
