import React, { Component } from 'react'
import { PluginContainerStyled, PluginStyled, PluginOptions, PluginDraggableAreaStyled, PluginHeaderStyled, PluginContentStyled } from './style'
import { Draggable } from 'react-beautiful-dnd'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DragHandle from '@material-ui/icons/DragHandle'
import { GrowStyled } from '../plugins/imagePlugin/style'

import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'

const ITEM_HEIGHT = 48

class DndItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDragDisabled: false,
      open: true,
      anchorEl: false,
    }
  }

  _handleDelete() {
    this.props.deteleBlock()
  }

  _handleHighlight() {
    this.props.highlightBlock()
  }

  _setDisabled(isDragDisabled) {
    this.setState({
      isDragDisabled: isDragDisabled,
    })
  }

  render() {
    const { children } = this.props
    const childrenWithProps = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        setDisabled: (isDragDisabled) => this._setDisabled(isDragDisabled),
        deteleBlock: () => this._handleDelete(),
      })
    )
    return (
      <Draggable draggableId={this.props.id} index={this.props.index} isDragDisabled={this.state.isDragDisabled}>
        {(provided) => (
          <PluginContainerStyled {...provided.draggableProps} innerRef={provided.innerRef}>
            <PluginStyled highlight={this.props.prominent}>
              <PluginHeaderStyled>
                <PluginDraggableAreaStyled {...provided.dragHandleProps}>
                  <DragHandle />
                </PluginDraggableAreaStyled>
                <span>{this.props.name}</span>
                <GrowStyled />
                <IconButton onClick={() => this.setState({ open: !this.state.open })}>
                  {this.state.open && <ExpandLessIcon />}
                  {!this.state.open && <ExpandMoreIcon />}
                </IconButton>
                <PluginOptions>
                  <IconButton
                    aria-label="More"
                    aria-owns={this.state.anchorEl ? 'long-menu' : undefined}
                    aria-haspopup="true"
                    onClick={(event) => this.setState({ anchorEl: event.target })}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={this.state.anchorEl}
                    open={this.state.anchorEl}
                    onClose={() => this.setState({ anchorEl: null })}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 200,
                      },
                    }}
                  >
                    <MenuItem
                      onClick={(event) => {
                        this._handleDelete(event)
                      }}
                    >
                      Delete
                    </MenuItem>
                    {this.props.highlight && (
                      <MenuItem
                        onClick={(event) => {
                          this._handleHighlight(event)
                        }}
                      >
                        Highlight
                      </MenuItem>
                    )}
                  </Menu>
                </PluginOptions>
              </PluginHeaderStyled>

              <PluginContentStyled open={this.state.open}>{childrenWithProps}</PluginContentStyled>
            </PluginStyled>
          </PluginContainerStyled>
        )}
      </Draggable>
    )
  }
}

export default DndItem
