import { Button, LinearProgress, AppBar, Grid, TextField, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'
import { showNotification, TextInput } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { ConfirmationDialog, SubForm } from '../../../../components'
import { ClipboardField } from '../../../../components/formFields'
import { getResource } from '../../../../config/resources'
import { dataProvider } from '../../../../providers'
import { CREATE_SH, GET } from '../../../../providers/dataProvider/types'
import { isUrl, isAllowedDomain } from './utils'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

const styles = (theme) => {
  return {
    tabs: {
      boxShadow: 'none',
    },
  }
}

class UrlShortener extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalOpen: false,
      clipboardText: null,
      allowedDomains: null,
      ready: false,
      tabActive: 0,
      code: null,
      updatedUrl: null,
    }

    this.handleModalOpen = this.handleModalOpen.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
    this.handleShortenerSuccess = this.handleShortenerSuccess.bind(this)
  }

  componentDidUpdate() {
    const { isModalOpen, allowedDomains } = this.state
    if (isModalOpen && !allowedDomains) {
      dataProvider(GET, getResource('shortUrls', 'domains'), {}).then(({ data }) => {
        this.setState({
          allowedDomains: data,
          ready: true,
        })
      })
    }
  }

  handleModalOpen() {
    this.setState({
      isModalOpen: true,
    })
  }

  handleModalClose() {
    this.setState({
      isModalOpen: false,
      clipboardText: null,
    })
  }

  handleShortenerSuccess({ url }) {
    const { showNotification } = this.props

    this.setState({
      ready: false,
    })

    dataProvider(CREATE_SH, getResource('shortUrls', 'short-urls'), {
      data: {
        url,
      },
    })
      .then(({ data }) => {
        this.setState({
          clipboardText: data,
          ready: true,
        })
      })
      .catch((e) => {
        console.log('error: ', e.message)
        showNotification((e.body && e.body.detail) || 'Error when shortening the url', 'warning')
      })
  }

  handleChangeTab = (event, value) => {
    this.setState({ tabActive: value })
  }

  handleChangeUpdateUrl = (event, name) => {
    this.setState({ [name]: event.target.value })
  }

  handleUpdateUrl = () => {
    const { showNotification } = this.props

    this.setState({
      ready: false,
    })

    let allowedDomainsArray = []

    this.state.allowedDomains.map((domain) => {
      allowedDomainsArray.push(Object.values(domain)[0])
    })

    const updatedUrlMatchAllowedDomains = new RegExp(allowedDomainsArray.join('|')).test(this.state.updatedUrl)

    dataProvider(CREATE_SH, getResource('shortUrls', 'short-urls'), {
      data: {
        code: this.state.code,
        url: this.state.updatedUrl,
      },
      method: 'PURGE',
    })
      .then(({ data }) => {
        this.setState({
          clipboardText: data,
          ready: true,
        })
      })
      .catch((e) => {
        this.setState({
          ready: true,
        })
        if (!updatedUrlMatchAllowedDomains) {
          showNotification('Url does not include any allowed domain.', 'warning')
        }
        if (this.state.code === null || this.state.code.length < 3) {
          showNotification('Introduce a valid code', 'warning')
        }

        showNotification(e.body ? e.body.detail : 'Error when updating the url. Check if the code is valid.', 'warning')
      })
  }

  handleClearData = () => {
    this.setState({
      clipboardText: null,
      code: null,
      updatedUrl: null,
    })
  }

  render() {
    const { isModalOpen, clipboardText, allowedDomains, ready } = this.state
    const { classes } = this.props

    return (
      <>
        <Button onClick={this.handleModalOpen}>Url Shortener</Button>
        <ConfirmationDialog title="Url Shortener" open={isModalOpen} maxWidth="md" dialogActions={false} onCancel={this.handleModalClose}>
          <AppBar className={classes.tabs} position="static" color="default">
            <Tabs value={this.state.tabActive} onChange={this.handleChangeTab} indicatorColor="primary" textColor="primary" fullWidth>
              <Tab label="New Url" />
              <Tab label="Update Url" />
            </Tabs>
          </AppBar>
          {clipboardText ? (
            <>
              <ClipboardField label="Short url" value={clipboardText.shortUrl} fullWidth />
              <TextField readOnly label="Views" value={clipboardText.views} fullWidth style={{ marginTop: '1rem' }} />
              <div style={{ marginTop: '1rem' }}>
                <Button variant="contained" onClick={this.handleClearData}>
                  Clear data
                </Button>
              </div>
            </>
          ) : (
            <>
              <SubForm
                form="url_shortener"
                isFetching={!ready}
                onClickSave={this.state.tabActive === 0 ? this.handleShortenerSuccess : this.handleUpdateUrl}
                onClose={this.handleModalClose}
              >
                {this.state.tabActive === 0 ? (
                  <TextInput validate={[isUrl, isAllowedDomain(allowedDomains)]} fullWidth source="url" label="Url" />
                ) : (
                  <Grid container>
                    <Grid item lg={3} style={{ position: 'relative' }}>
                      <TextField
                        error={this.state.code === null || this.state.code.length < 3}
                        value={this.state.code}
                        onChange={(e) => this.handleChangeUpdateUrl(e, 'code')}
                        fullWidth
                        label="Code"
                      />
                      <Typography color="error" variant="caption" style={{ position: 'absolute', bottom: '-8px' }}>
                        {this.state.code === null || this.state.code.length < 3 ? 'You must enter a valid code' : null}
                      </Typography>
                    </Grid>
                    <Grid item lg={8} style={{ position: 'relative' }}>
                      <TextField
                        value={this.state.updatedUrl}
                        error={isUrl(this.state.updatedUrl) || isAllowedDomain(allowedDomains)(this.state.updatedUrl)}
                        onChange={(e) => this.handleChangeUpdateUrl(e, 'updatedUrl')}
                        fullWidth
                        label="Url"
                      />

                      <Typography color="error" variant="caption" style={{ position: 'absolute', bottom: '-8px' }}>
                        {isUrl(this.state.updatedUrl)
                          ? 'You must enter a url'
                          : isAllowedDomain(allowedDomains)(this.state.updatedUrl)
                          ? 'Is not allowed domain'
                          : null}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </SubForm>

              <>
                <p>Allowed Domains:</p>
                {allowedDomains && Array.isArray(allowedDomains) ? (
                  <ul>
                    {allowedDomains.map((domain, key) => (
                      <li key={key}>{domain.domain}</li>
                    ))}
                  </ul>
                ) : (
                  <div style={{ marginTop: '1rem', width: '200px' }}>
                    <LinearProgress />
                  </div>
                )}
              </>
            </>
          )}
        </ConfirmationDialog>
      </>
    )
  }
}

const enhance = compose(
  connect(null, {
    showNotification,
  }),
  withStyles(styles)
)

export default enhance(UrlShortener)
