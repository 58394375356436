import DoneIcon from '@material-ui/icons/Done'
import React, { Component } from 'react'
import { Button, crudUpdate } from 'react-admin'
import { connect } from 'react-redux'
import stateImport from '../state'

class ButtonValidate extends Component {
  handleClick = () => {
    const { basePath, crudUpdate, resource, record } = this.props
    let newRecord = { ...record }
    newRecord['state'] = stateImport.completed
    crudUpdate(resource, record.id, newRecord, record, basePath, 'list')
  }

  ContentBtn = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span>Validar</span>
      <DoneIcon style={{ fontSize: '1rem', marginLeft: '8px' }} />
    </div>
  )

  render() {
    const { ContentBtn } = this
    return <Button label={<ContentBtn />} onClick={this.handleClick} variant="contained" color="primary" size="small" />
  }
}

export default connect(undefined, { crudUpdate })(ButtonValidate)
