import React from 'react'
import { List, Datagrid, TextField, BooleanField, ReferenceField, ImageField, EditButton, Responsive, SimpleList, DeleteButton } from 'react-admin'
import { ListActions, TableRowList } from '../../../components'
import { getResource } from '../../../config/resources'
import { withStyles } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'

const Actions = (props) => {
  return <ListActions {...props} />
}

const styles = (theme) => ({
  deleteStyle: {
    color: fade(theme.palette.error.main, 0.9),
    padding: '2px 6px 2px 2px',
  },
})

const DynamicSplashScreen = (props) => {
  return (
    <List title="Dynamic Splash Screen" bulkActions={false} {...props} actions={<Actions />}>
      <Responsive
        small={
          <SimpleList
            leftAvatar={(record) => (
              <ReferenceField
                source="image"
                reference={getResource('assets', 'images')}
                basePath={getResource('assets', 'images')}
                linkType={false}
                record={record}
                allowEmpty
              >
                <ImageField mini source="asset.fileDir" title="Image Splash Screen" />
              </ReferenceField>
            )}
            primaryText={(record) => record.name}
          />
        }
        medium={
          <Datagrid>
            <ReferenceField label="Image" source="image" reference={getResource('assets', 'images')} linkType={false} sortable={false} allowEmpty>
              <ImageField source="asset.fileDir" title="Image Splash Screen" />
            </ReferenceField>
            <TextField source="name" />
            <TextField source="country" />
            <BooleanField source="active" label="Active" />
            <TableRowList>
              <EditButton />
              <DeleteButton className={props.classes.deleteStyle} />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}

export default withStyles(styles)(DynamicSplashScreen)
