import React from 'react'
import { Datagrid, EditButton, Responsive, SimpleList, TextField } from 'react-admin'

import { TableRowList } from '../../../components'

export const List = (props) => (
  <Responsive
    {...props}
    small={<SimpleList primaryText={(record) => record.name} secondaryText={(record) => record.slug} />}
    medium={
      <Datagrid>
        <TextField source="market" label="Market" />
        <TextField source="section" label="Section" />
        <TextField source="totalItems" label="Highlights" />
        <TableRowList>
          <EditButton />
        </TableRowList>
      </Datagrid>
    }
  />
)

export default List
