import React from 'react'
import { Grid, List } from '@material-ui/core'
import { LongTextInput, BooleanInput, TextInput, required, maxLength, REDUX_FORM_NAME } from 'react-admin'
import { slugifyFiler } from '../../../../../../../config/utils'
import { change } from 'redux-form'
import { states } from '../../../states'

const generateSlug = (value, dispatch) => {
  dispatch(change(REDUX_FORM_NAME, 'slug', slugifyFiler(value.title)))
}

const publishedOrProgrammed = (status) => (value) => {
  return !value && (status === states.programmed || status === states.published) ? 'Required' : undefined
}

class SeoInfo extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { formData, dispatch } = this.props
    return (
      <List component="div">
        <Grid container>
          <Grid item xs={12}>
            <TextInput
              fullWidth
              label="Title"
              source="title"
              validate={[required(), maxLength(255)]}
              onChange={(value) => {
                if (formData.slugAutogenerate) {
                  generateSlug(formData, dispatch)
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <BooleanInput
              label="Autogenerate slug"
              source="slugAutogenerate"
              onChange={(value) => {
                if (value.target.checked) {
                  generateSlug(formData, dispatch)
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput disabled={formData && formData.slugAutogenerate} fullWidth label="Slug" source="slug" validate={[required(), maxLength(255)]} />
          </Grid>
          <Grid item xs={12}>
            <TextInput fullWidth source="seoTitle" label="Seo Title" validate={[required(), maxLength(255)]} />
          </Grid>
          <Grid item xs={12}>
            <LongTextInput fullWidth label="SEO description" source="seoDescription" validate={[publishedOrProgrammed(formData.status), maxLength(255)]} />
          </Grid>
        </Grid>
      </List>
    )
  }
}

export default SeoInfo
