import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { Component } from 'react'
import { GET_ONE, UPDATE } from 'react-admin'

import { ImageInput } from '../../../../../components/inputs'
import { getResource } from '../../../../../config/resources'
import dataProvider from '../../../../../providers'
import { LeftContainerStyled, PluginContainerStyled, PrimaryTextStyled, RightContainerStyled, SecondaryTextStyled } from './style'

const styles = (theme) => ({})

class textPluginEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pluginAnswere: null,
    }
  }

  componentDidMount() {
    const { value, options } = this.props
    if (value && value.content) {
      const isReference = options && options.reference
      const asset = isReference ? value.content : value.content.id
      this.getGellery(asset, (data) => {
        this.setState({
          pluginAnswere: { content: data },
        })
      })
    }
  }

  handleChange(pluginAnswere) {
    this.setState({
      pluginAnswere: pluginAnswere,
    })
  }

  getGellery(value, func) {
    return dataProvider(GET_ONE, getResource('news', 'noticias'), {
      id: value.content ? value.content : value,
      //filter: filters
    }).then((response) => {
      func(response.data)
    })
  }

  handleChangeVideo(content) {
    const { options } = this.props
    const isReference = options && options.reference
    let pluginAnswere = { ...this.state.pluginAnswere }
    let pluginAnswereReference = { ...this.state.pluginAnswere }
    pluginAnswere.content = content
    pluginAnswereReference.content = content.id
    this.handleChange(pluginAnswere)
    if (isReference || this.props.input) {
      this.props.onChange(pluginAnswereReference)
    } else {
      this.props.onChange(pluginAnswere)
    }
  }

  handleChangeDescription(value) {
    let pluginAnswere = { ...this.state.pluginAnswere }
    pluginAnswere.content.description = value
    this.handleChange(pluginAnswere)
  }

  handleChangeTags(tags) {
    let pluginAnswere = { ...this.state.pluginAnswere }
    pluginAnswere.content.tags = tags
    this.handleChange(pluginAnswere)
  }

  saveImage() {
    this.setState({ list: null })
    return dataProvider(UPDATE, getResource('news', 'noticias'), {
      id: this.state.pluginAnswere.content.id,
      data: this.state.pluginAnswere.content,
      //filter: filters
    })
      .then((response) => {
        this.props.onChange(_.cloneDeep(this.state.pluginAnswere))
      })
      .catch((error) => {
        this.setState({
          error: error,
        })
      })
  }

  imageIsEqual() {
    const isEqual = _.isEqual(this.state.pluginAnswere, this.props.value)
    return isEqual
  }

  render() {
    const options = {
      type: 'news',
      ...this.props.options,
      resource: getResource('news', 'noticias'),
    }
    let valueDescription = null
    let valueName = null
    if (this.state.pluginAnswere) {
      valueDescription = this.state.pluginAnswere && this.state.pluginAnswere.content ? this.state.pluginAnswere.content.description : ''
      valueName = this.state.pluginAnswere && this.state.pluginAnswere.content ? this.state.pluginAnswere.content.title : ''
    }
    return (
      <PluginContainerStyled>
        <LeftContainerStyled>
          <ImageInput
            fullWidth
            options={options}
            choose
            value={this.state.pluginAnswere && this.state.pluginAnswere.content ? this.state.pluginAnswere.content : null}
            onChange={(image) => this.handleChangeVideo(image)}
          />
        </LeftContainerStyled>
        <RightContainerStyled>
          <PrimaryTextStyled>{valueName}</PrimaryTextStyled>
          <SecondaryTextStyled>{valueDescription}</SecondaryTextStyled>
        </RightContainerStyled>
      </PluginContainerStyled>
    )
  }
}

export default withStyles(styles)(textPluginEdit)
