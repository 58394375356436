import './config/msal'
import './index.css'

import * as OfflinePluginRuntime from 'offline-plugin/runtime'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './app'

OfflinePluginRuntime.install({
  onUpdateReady: () => {
    OfflinePluginRuntime.applyUpdate()
  },
  onUpdated: () => {
    window.location.reload()
  },
})

ReactDOM.render(<App />, document.getElementById('root'))
