import React, { Component } from 'react'
import { Field } from 'redux-form'
import MatchReferees from './MatchReferees'

class matchRefereesInput extends Component {
  render() {
    return <Field component={MatchReferees} name={this.props.name ? this.props.name : this.props.source} {...this.props} />
  }
}

export default matchRefereesInput
