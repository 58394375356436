import React from 'react'
import { Create } from 'react-admin'

import ChannelsForm from './form'

const ChannelTitle = ({ record }) => <span>New channel:</span>

export const ChannelCreate = (props) => (
  <Create title={<ChannelTitle />} {...props}>
    <ChannelsForm />
  </Create>
)

export default ChannelCreate
