import styled from 'styled-components'
import { colors } from '../../../config/theme'
import DialogContent from '@material-ui/core/DialogContent'
import List from '@material-ui/core/List'
import FormControl from '@material-ui/core/FormControl'

export const ContainerStyled = styled.div`
  display: block;
`

export const OptionBarContainerStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  position: sticky;
  top: 65px;
  z-index: 1;
  border: solid 1px #d0d0d0;
  margin-bottom: 10px;
  background-color: ${colors.common.white};
  border: solid 1px ${colors.grey[400]};
`

export const ContainerListStyled = styled.div`
  & > div {
    border: solid 1px lightgrey;
    background-color: lightgrey;
    padding: 10px;
    margin-bottom: 10px;
  }
`

export const ModuleGridStyled = styled.div`
  display: flex;
  border: solid 1px lightgrey;
`

export const ModuleGridItemStyled = styled.div`
  flex-grow: 1;
  display: flex;
  & * {
    box-sizing: border-box;
  }
`

export const AreaStyled = styled(List)`
  width: ${(props) => props.area}%;
  min-width: 276px;
`

export const AddModuleStyled = styled.div`
  min-height: 56px;
  border: dashed 1px ${colors.grey[400]};
  padding: 10px;
  margin-bottom: ${(props) => (props.empty ? '0px' : '5px')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background-color: ${colors.grey[400]};
  }
`

export const ListDndStyled = styled.div`
  max-width: 100%;
  background: ${(props) => (props.isDraggingOver ? 'lightblue' : 'transparent')};
`

export const DndButonStyled = styled.div`
  display: block;
  margin: 0 10px;
`

export const DndItemStyled = styled.div`
  transition: transform 0.2s;
  width: 100%;
  background: white;
  border: 1px solid lightgrey;
  transform-origin: 10px auto;
  transform: ${(props) => (props.isDragging ? 'rotateZ(2deg)' : 'rotateZ(0deg)')};
  box-shadow: ${(props) => (props.isDragging ? `2px 2px 6px #0006;` : `0px 0px 0px transparent`)};
`

export const DndDraggableItemStyled = styled.div`
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0px;
  }
`

export const HeaderListStyled = styled.div`
  display: flex;
  align-items: center;
`

export const GrowSpaceStyled = styled.div`
  flex-grow: 1;
`

export const LabelStyled = styled.div``

export const DialogContentStyled = styled(DialogContent)`
  padding: 10px;
`

export const HeaderStyled = styled.div`
  padding: 10px;
`

export const FormControlStyled = styled(FormControl)``

export const LabelSectionStyled = styled.span`
  padding: 0px 10px;
  color: ${colors.grey[800]};
`
