import styled from 'styled-components'
import { colors } from '../../../config/theme'

export const StateStyled = styled.div`
  position: absolute;
  height: 100%;
  width: 24px;
  top: 0px;
  left: 0px;
  ${({ status }) => {
    switch (status) {
      case 'FirstHalf':
      case 'HalfTime':
      case 'SecondHalf':
      case 'ExtraFirstHalf':
      case 'ExtraSecondHalf':
      case 'ExtraHalfTime':
      case 'ShootOut':
        return `boder: 1px ${colors.states.warning}; background: ${colors.states.warningLight};`
      case 'FullTime':
        return `boder: 1px ${colors.primary.main}; background: ${colors.primary.main};`
      case 'Abandoned':
      case 'Canceled':
        return `boder: 1px ${colors.states.error}; background: ${colors.states.errorLight};`
      case 'PreMatch':
      default:
        return `boder: 1px ${colors.grey[300]}; background: ${colors.grey[200]};`
    }
  }}
`
