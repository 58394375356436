import Grid from '@material-ui/core/Grid'
import React from 'react'
import { Filter, SelectInput, ReferenceInput, Datagrid, EditButton, List, Responsive, SimpleList, TextField, ReferenceField, BooleanField } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'
import { getResource } from '../../../config/resources'

const ListFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} md={4} lg={2}>
          <ReferenceInput
            label="Gameweek"
            source="gameweek"
            reference={getResource('competiciones', 'gameweeks')}
            filter={{ subscription: 116 }}
            perPage={50}
            allowEmpty
            alwaysOn
          >
            <SelectInput />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <SelectInput
            source="active"
            allowEmpty
            choices={[
              { id: true, name: 'Yes' },
              { id: false, name: 'No' },
            ]}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<ListFilter />} />
}

export const SportPlayWinnersList = (props) => (
  <List bulkActions={false} actions={<Actions />} title="Sportium Winners" {...props}>
    <Responsive
      small={
        <SimpleList
          primaryText={(record) => record.videoTitle}
          secondaryText={(record) => (
            <ReferenceField
              label="Gameweek"
              linkType={false}
              source="gameweek"
              reference={getResource('competiciones', 'gameweeks')}
              basePath={getResource('competiciones', 'gameweeks')}
              record={record}
            >
              <TextField source="winner" />
            </ReferenceField>
          )}
          tertiaryText={(record) => record.gameweek && `Active: ${record.active ? 'Yes' : 'No'}`}
        />
      }
      medium={
        <Datagrid>
          <BooleanField source="active" />
          <ReferenceField label="Gameweek" source="gameweek" reference={getResource('competiciones', 'gameweeks')} linkType={false}>
            <TextField source="name" />
          </ReferenceField>
          <TextField source="winner" label="Winner" />
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default SportPlayWinnersList
