import React from 'react'
import { Datagrid, EditButton, Filter, List, Responsive, SimpleList, TextField, TextInput, BooleanInput } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'
import { DateField } from '../../../components/field'
import { BooleanFilterInput } from '../../../components/inputs'

const SeasonsFilter = (props) => {
  return (
    <Filter {...props}>
      <BooleanFilterInput label="Show In Web" source="showInWeb" alwaysOn fullWidth />
      <TextInput className={props.classes.input} variant="filled" label="Season" source="name" alwaysOn fullWidth />
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<SeasonsFilter />} />
}

const SeasonsList = (props) => (
  <List bulkActions={false} title="Seasons" {...props} actions={<Actions />}>
    <Responsive
      small={
        <SimpleList
          primaryText={(record) => record.name}
          tertiaryText={(record) => (
            <div>
              <DateField source="start" label="Start" record={record} />
              <DateField source="end" label="End" record={record} />
            </div>
          )}
        />
      }
      medium={
        <Datagrid>
          <TextField source="name" label="Name" />
          <TextField source="season" label="Season" />
          <TextField source="year" label="Year" />
          <DateField source="start" label="Start" />
          <DateField source="end" label="End" />
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default SeasonsList
