import Grid from '@material-ui/core/Grid'
import React from 'react'
import { Datagrid, EditButton, Filter, List, Responsive, SelectInput, SimpleList, TextField, TextInput } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'
import { DateField } from '../../../components/field'
import { DateRangeInput } from '../../../components/inputs'

const DigitalAssetsFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item sm={12} md={6}>
          <TextInput className={props.classes.input} variant="filled" label="Nombre" source="name" alwaysOn fullWidth />
        </Grid>
        <Grid item sm={12} md={6}>
          <SelectInput
            allowEmpty
            fullWidth
            source="price"
            label="Price"
            choices={[
              {
                id: 'premium',
                name: 'Premium',
              },
              {
                id: 'gold',
                name: 'Gold',
              },
            ]}
          />
        </Grid>
      </Grid>
      <Grid container alwaysOn>
        <Grid item sm={12} md={6}>
          <DateRangeInput source="startedAt" label="Start at" fullWidth alwaysOn />
        </Grid>
        <Grid item sm={12} md={6}>
          <DateRangeInput source="endAt" label="End at" fullWidth alwaysOn />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<DigitalAssetsFilter />} />
}

export const DigitalAssetsList = (props) => {
  return (
    <List bulkActions={false} title="Digital Assets" {...props} actions={<Actions />}>
      <Responsive
        small={
          <SimpleList
            primaryText={(record) => record.name}
            tertiaryText={(record) => record.price}
            secondaryText={(record) => `${new Date(record.startedAt).toLocaleDateString()} - ${new Date(record.endAt).toLocaleDateString()}`}
          />
        }
        medium={
          <Datagrid>
            <TextField source="name" label="Name" />
            <TextField source="price" label="Price" />
            <DateField source="startedAt" label="Start" />
            <DateField source="endAt" label="End" />
            <TableRowList>
              <EditButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}

export default DigitalAssetsList
