import React, { cloneElement, Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { NavLink } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const styles = (theme) => ({
  root: {
    color: theme.palette.grey[800],
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    fontSize: '13px',
    backgroundColor: 'transparent',
    '& svg': {
      fill: theme.palette.grey[800],
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.grey[800],
      '& svg': {
        fill: theme.palette.grey[800],
      },
    },
  },
  active: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.grey[800],
    '& svg': {
      fill: theme.palette.grey[800],
    },
    '& p': {
      color: theme.palette.grey[800],
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.grey[800],
      '& p': {
        color: theme.palette.grey[800],
      },
      '& svg': {
        fill: theme.palette.grey[800],
      },
    },
  },
  icon: {
    paddingRight: '1.2em',
    display: 'inline-block',
    width: '24px',
    height: 'auto',
  },
})

export class MenuItemLink extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    leftIcon: PropTypes.node,
    onClick: PropTypes.func,
    primaryText: PropTypes.string,
    staticContext: PropTypes.object,
    to: PropTypes.string.isRequired,
  }

  handleMenuTap = () => {
    this.props.onClick && this.props.onClick()
  }

  render() {
    const { classes, className, primaryText, leftIcon, staticContext, ...props } = this.props

    return (
      <MenuItem className={classnames(classes.root, className)} activeClassName={classes.active} component={NavLink} {...props} onClick={this.handleMenuTap}>
        {leftIcon && <span className={classes.icon}>{cloneElement(leftIcon, { titleAccess: primaryText })}</span>}
        <Typography variant="body1" gutterBottom>
          {primaryText}
        </Typography>
      </MenuItem>
    )
  }
}

export default withStyles(styles)(MenuItemLink)
