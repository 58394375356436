import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core/styles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import React, { Component } from 'react'
import Search from './search'

import { MetadataInput, TagSelectorInput } from '../../inputs'
import { FilterBarStyled, FilterOptionsBarStyled, FiltersStyled, FilterBarContainerStyled } from './style'

const styles = (theme) => ({
  chipFilter: {
    margin: '5px',
  },
  filterButton: {
    borderRadius: '0px',
    fontSize: '12px',
    width: '176px',
    display: 'flex',
    textAlign: 'left',
    textTransform: 'capitalize',
    border: `solid 1px ${theme.palette.primary.main}`,
    borderBottom: 'none',
    marginBottom: '-2px',
    backgroundColor: theme.palette.common.white,
    '& .text': {
      flexGrow: 1,
    },
  },
  filterButtonClosed: {
    marginBottom: '-2px',
    border: `solid 1px ${theme.palette.grey[400]}`,
    borderRadius: '0px',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: '12px',
    width: '176px',
    display: 'flex',
    textAlign: 'left',
    textTransform: 'capitalize',
    '& .text': {
      flexGrow: 1,
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
  },
})

class FilterBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openFilters: false,
    }
  }

  handleFilters = () => {
    this.setState({
      openFilters: !this.state.openFilters,
    })
  }

  handleChange = (filter) => (newValue) => {
    const { value, onChange } = this.props
    let filters = { ...value }
    filters[filter] = newValue
    onChange(filters)
  }

  FilterButton = () => {
    return (
      <Button
        className={this.state.openFilters ? this.props.classes.filterButton : this.props.classes.filterButtonClosed}
        variant="contained"
        onClick={this.handleFilters}
      >
        <span className="text">
          <span>Filters </span>
        </span>
        {this.state.openFilters ? <ExpandLess /> : <ExpandMore />}
      </Button>
    )
  }

  NumFilters = () => {
    const { value, classes } = this.props

    return (
      <div>
        {value.q.length > 0 && <Chip className={classes.chipFilter} label={`Text / ${value.q}`} />}
        {value.metadatas.length > 0 && <Chip className={classes.chipFilter} label={`Metadatas / ${value.metadatas.length}`} />}
        {value.tags.length > 0 && <Chip className={classes.chipFilter} label={`Tags / ${value.tags.length}`} />}
      </div>
    )
  }

  render() {
    const { FilterButton, NumFilters } = this
    const { onChange, value, ...rest } = this.props
    return (
      <FilterBarContainerStyled>
        <FilterBarStyled>
          <FilterOptionsBarStyled>
            <FilterButton />
          </FilterOptionsBarStyled>
          <Search onChange={this.handleChange('q')} />
          <NumFilters />
        </FilterBarStyled>
        {this.state.openFilters && (
          <FiltersStyled>
            <MetadataInput label="Metadata" value={value.metadatas} onChange={this.handleChange('metadatas')} />
            <TagSelectorInput label="Tags" value={value.tags} onChange={this.handleChange('tags')} />
          </FiltersStyled>
        )}
      </FilterBarContainerStyled>
    )
  }
}

export default withStyles(styles)(FilterBar)
