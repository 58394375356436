import React, { Component } from 'react'
import AceEditor from 'react-ace'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'

import { ContentStyled } from './style'

import 'brace/mode/html'
import 'brace/theme/kuroir'

class ScriptInput extends Component {
  static defaultProps = {
    options: {
      mode: 'html',
      theme: 'kuroir',
      name: 'blah2',
      fontSize: 14,
      showPrintMargin: true,
      showGutter: true,
      highlightActiveLine: true,
      width: '100%',
      height: '50vh',
      setOptions: {
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showLineNumbers: true,
        tabSize: 2,
      },
    },
    meta: {},
  }

  onChange(item) {
    const { onChange } = this.props.input ? this.props.input : this.props
    this.setState({ value: item })
    if (item) {
      if (this.props.input) onChange(item)
      else onChange(item)
    } else {
      onChange('')
    }
  }

  render() {
    const { options, label } = this.props
    const { error, helperText = false } = this.props.meta
    const { value } = this.props.input ? this.props.input : this.props
    return (
      <ContentStyled>
        <InputLabel>{label}</InputLabel>
        <AceEditor {...options} onChange={(inputValue) => this.onChange(inputValue)} value={value} />
        {error && <FormHelperText error>{error}</FormHelperText>}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </ContentStyled>
    )
  }
}

export default ScriptInput
