import React from 'react'
import { Create } from 'react-admin'

import FileForm from './form'

const FileTitle = ({ record }) => <span>Nueva difusión:</span>

export const FileCreate = (props) => (
  <Create title={<FileTitle />} {...props}>
    <FileForm {...props} />
  </Create>
)

export default FileCreate
