import React from 'react'
import { Create } from 'react-admin'

import VenuesForm from './form'

export const VenuesCreate = (props) => (
  <Create {...props}>
    <VenuesForm isEdit={false} {...props} />
  </Create>
)

export default VenuesCreate
