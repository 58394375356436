import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import { withStyles } from '@material-ui/core/styles'
import { PermMedia } from '@material-ui/icons'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import classNames from 'classnames'
import _ from 'lodash'
import React, { Component } from 'react'
import { ReferenceField } from 'react-admin'

import { getResource } from '../../../config/resources'
import { colors } from '../../../config/theme'
import { ImageField } from '../../field'
import { CameraIcon, VideoIcon } from '../../icons'
import DropZone from './dropZone'
import FilterBar from './filterBar'
import ImageList from './imageList'
import ImageListSelected from './imageListSelected'
import Preview from './preview'
import { ContentStyled, GrowStyled, ImageContainerStyled, ModalStyled, OptionsBarStyled } from './style'

const IMAGES = 'images'
const VIDEOS = 'videos'
const GALLERIES = 'galleries'
const NEWS = 'news'

const LIST_VIEW_LIST = 'list'
const LIST_VIEW_SELECTED = 'listSelected'

const styles = (theme) => ({
  button: {
    padding: '10px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[400],
    width: '187px',
    maxWidth: '30%',
    fontSize: '13px',
    textAlign: 'left',
    borderRadius: '0px',
    margiRight: '11px',
    justifyContent: 'flex-start',
    textTransform: 'capitalize',
    marginRight: '11px',
    '& svg': {
      marginRight: '12px',
    },
  },
  closeButton: {
    width: '30px',
    height: '30px',
    '& svg': {
      fontSize: '18px',
    },
  },
  buttonSelected: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
    },
  },
  editButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    zIndex: '2',
  },
  removeButton: {
    position: 'absolute',
    left: '10px',
    top: '10px',
    zIndex: '2',
  },
})

class GalleryEditor extends Component {
  constructor(props) {
    super(props)
    const { options } = props
    const filters = options ? options.filters : {}
    this.state = {
      selectedView: LIST_VIEW_LIST,
      list: null,
      open: false,
      selectedItems: [],
      filters: {
        q: '',
        metadatas: [],
        tags: [],
        ...filters,
      },
    }
  }

  componentDidUpdate(prevProps) {
    const { options } = this.props
    const newFilters = options && options.filters ? options.filters : {}

    if(JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options)) {
      this.setState((state) => ({
        filters: {...state.filters, ...newFilters}
      }))
    }
  }

  getContent(value, content) {
    const { position } = this.props

    return _.map(position ? _.orderBy(value, [position], ['asc']) : value, (item) => {
      return _.get(item, content)
    })
  }

  setContent(value, content) {
    const { position } = this.props

    return _.map(value, (item, index) => {
      return {
        [position]: index,
        [content]: item,
      }
    })
  }

  handleEdit() {
    const { options, value, content } = this.props
    this.setState(
      {
        open: true,
        list: options && options.type ? options.type : IMAGES,
        selectedView: LIST_VIEW_LIST,
        selectedItems: content ? this.getContent(value, content) : value,
      },
      this.props.setDisabled ? this.props.setDisabled(true) : null
    )
  }

  handleClose() {
    this.setState(
      {
        list: null,
        open: false,
        selectedView: LIST_VIEW_LIST,
      },
      this.props.setDisabled ? this.props.setDisabled(true) : null
    )
  }

  handleSubmit(items) {
    const { content } = this.props
    this.props.onChange(content ? this.setContent(items, content) : items)
    this.handleClose()
  }

  handleDelete() {
    this.props.onChange(null)
  }

  getOptionsBar(type) {
    const { classes, multiselect } = this.props

    const GalleriesButton = () => (
      <Button
        onClick={() => this.setState({ selectedView: LIST_VIEW_LIST })}
        className={classNames(this.state.selectedView === LIST_VIEW_LIST ? classes.buttonSelected : null, classes.button)}
        variant="contained"
        color="primary"
      >
        <PermMedia style={this.state.selectedView === LIST_VIEW_LIST ? { fill: colors.common.black } : { fill: colors.common.white }} />
        Galleries
      </Button>
    )

    const VideosButton = () => (
      <Button
        onClick={() => this.setState({ selectedView: LIST_VIEW_LIST })}
        className={classNames(this.state.selectedView === LIST_VIEW_LIST ? classes.buttonSelected : null, classes.button)}
        variant="contained"
        color="primary"
      >
        <VideoIcon color={this.state.selectedView === LIST_VIEW_LIST ? colors.common.black : colors.common.white} />
        Videos
      </Button>
    )

    const ImagesButton = () => (
      <Button
        onClick={() => this.setState({ selectedView: LIST_VIEW_LIST })}
        className={classNames(this.state.selectedView === LIST_VIEW_LIST ? this.props.classes.buttonSelected : null, this.props.classes.button)}
        variant="contained"
        color="primary"
      >
        <CameraIcon color={this.state.selectedView === LIST_VIEW_LIST ? colors.common.black : colors.common.white} />
        Images
      </Button>
    )

    const NewsButton = () => (
      <Button
        onClick={() => this.setState({ selectedView: LIST_VIEW_LIST })}
        className={classNames(this.state.selectedView === LIST_VIEW_LIST ? this.props.classes.buttonSelected : null, this.props.classes.button)}
        variant="contained"
        color="primary"
      >
        <CameraIcon color={this.state.selectedView === LIST_VIEW_LIST ? colors.common.black : colors.common.white} />
        News
      </Button>
    )

    return (
      <OptionsBarStyled>
        {(!type || type === IMAGES) && <ImagesButton />}
        {type === VIDEOS && <VideosButton />}
        {type === GALLERIES && <GalleriesButton />}
        {type === NEWS && <NewsButton />}
        {multiselect && (
          <Button
            onClick={() => this.setState({ selectedView: LIST_VIEW_SELECTED })}
            className={classNames(this.state.selectedView === LIST_VIEW_SELECTED ? this.props.classes.buttonSelected : null, this.props.classes.button)}
            variant="contained"
            color="primary"
          >
            <CheckBoxIcon color={type === NEWS ? colors.common.black : colors.common.white} />
            Selected
          </Button>
        )}
        <GrowStyled />
        <IconButton className={classes.closeButton} onClick={() => this.handleClose()}>
          <CloseIcon />
        </IconButton>
      </OptionsBarStyled>
    )
  }

  getImageList() {
    const { options, onChange, value, filters, ...rest } = this.props
    const type = options && options.type ? options.type : IMAGES
    return (
      <Dialog open={this.state.open} onClose={() => this.handleClose()} scroll="body" maxWidth="md">
        <ModalStyled>
          <DropZone>
            <React.Fragment>
              {this.getOptionsBar(options && options.type ? options.type : IMAGES)}
              {this.state.selectedView === LIST_VIEW_LIST && (
                <FilterBar value={this.state.filters} onChange={(filters) => this.setState({ filters: filters })} />
              )}
              <ContentStyled>
                {this.state.selectedView === LIST_VIEW_LIST && (
                  <ImageList
                    type={type}
                    filters={this.state.filters}
                    selectedImg={this.state.selectedItems}
                    onSubmit={(item) => this.handleSubmit(item)}
                    onChange={(items) => {
                      this.setState({ selectedItems: items })
                    }}
                    resource={options ? options.resource : null}
                    {...rest}
                  />
                )}
                {this.state.selectedView === LIST_VIEW_SELECTED && (
                  <ImageListSelected
                    type={type}
                    filters={this.state.filters}
                    selectedItems={this.state.selectedItems}
                    onSubmit={(item) => this.handleSubmit(item)}
                    onChange={(items) => {
                      this.setState({ selectedItems: items })
                    }}
                    {...rest}
                  />
                )}
              </ContentStyled>
            </React.Fragment>
          </DropZone>
        </ModalStyled>
      </Dialog>
    )
  }

  renderGallerieImage() {
    const { options, value } = this.props
    if (options && options.type === GALLERIES && value) {
      const images = _.find(value.galleryImages, (o) => {
        return o.prominent === true
      })
      const image = images ? images : value.galleryImages[0]
      return <img src={image.content.asset.fileDir} alt="" />
    }
    return null
  }

  renderImage() {
    const { options, value } = this.props

    if (!value) return null
    if (options && options.type === VIDEOS) {
      if (value.imageVideo) {
        return <ImageField mini record={value.imageVideo} sizes={['small', 'medium', 'large', 'xlarge']} sourceImage="" />
      } else if (value.providerThumbnails) {
        return <ImageField record={value} source="providerThumbnails.small" title="imagen" mini />
      }
      return null
    }
    if (options && options.type === GALLERIES && value) {
      return <ImageField record={value} sourceImage="prominentImage.content" sizes={['small', 'medium', 'large', 'xlarge']} title="imagen" mini />
    }
    if (options && options.type === NEWS && value) {
      return (
        <ReferenceField linkType={false} record={value} basePath={getResource('assets', 'images')} source="asset" reference={getResource('assets', 'images')}>
          <ImageField mini sourceImage="" sizes={['small', 'medium', 'large', 'xlarge']} />
        </ReferenceField>
      )
    }
    if (value.asset) {
      return <ImageField record={value} sizes={['small', 'medium', 'large', 'xlarge']} title="imagen" mini />
    }
  }

  render() {
    const { classes, options, meta, label } = this.props
    return (
      <FormControl error={meta && meta.touched && meta.error}>
        {label && <InputLabel>{label}</InputLabel>}
        <ImageContainerStyled className="imageContainer">
          <Button className={classes.editButton} variant="fab" mini color="secondary" aria-label="Add" onClick={() => this.handleEdit()}>
            <EditIcon />
          </Button>
          {options && options.remove && (
            <Button className={classes.removeButton} variant="fab" mini color="secondary" aria-label="Add" onClick={() => this.handleDelete()}>
              <DeleteIcon />
            </Button>
          )}
          <Preview {...this.props} handleSubmit={this.handleSubmit} />
          {this.getImageList()}
        </ImageContainerStyled>
      </FormControl>
    )
  }
}

export default withStyles(styles)(GalleryEditor)
