import React, { Component } from 'react'
import { ContainerStyled, LabelStyled, TextareaStyled } from './style'

class Textarea extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: '',
    }
  }

  handleOnChange(e) {
    this.setState({
      text: e.target.value,
    })
    if (this.props.input) {
      this.props.input.onChange(e.target.value)
    } else {
      this.props.onChange(e.target.value)
    }
  }

  render() {
    const { value } = this.props.input ? this.props.input : this.props
    return (
      <ContainerStyled>
        <LabelStyled>{this.props.label}</LabelStyled>
        <TextareaStyled>
          <textarea disabled={this.props.disabled} spellCheck="false" resize="false" onChange={(e) => this.handleOnChange(e)} value={value} />
        </TextareaStyled>
      </ContainerStyled>
    )
  }
}

export default Textarea
