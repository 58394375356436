import Grid from '@material-ui/core/Grid'
import React from 'react'
import { Datagrid, EditButton, Filter, List, ReferenceField, ReferenceInput, Responsive, SimpleList, TextField, ImageField, SelectInput } from 'react-admin'

import * as Variables from '../../../config/variables'
import { ListActions, TableRowList } from '../../../components'
import { DateField, MatchStatusField } from '../../../components/field'
import { AutocompleteInput, DateRangeInput, MatchInput } from '../../../components/inputs'
import { getResource } from '../../../config/resources'
import { TeamContainerStyled } from './style'

const MatchesFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} md={4}>
          <DateRangeInput source="date" label="Date" fullWidth alwaysOn />
        </Grid>
        <Grid item xs={12} md={2}>
          <ReferenceInput source="status" label="Status" reference={getResource('competiciones', 'matchStatus')} alwaysOn allowEmpty fullWidth>
            <SelectInput />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={3}>
          <ReferenceInput
            source="hometeam"
            label="Home Team"
            reference={getResource('competiciones', 'teams')}
            sort={{ field: 'id', order: 'ASC' }}
            alwaysOn
            allowEmpty
            fullWidth
          >
            <AutocompleteInput optionText="selectorName" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={3}>
          <ReferenceInput
            source="awayteam"
            label="Away Team"
            reference={getResource('competiciones', 'teams')}
            sort={{ field: 'id', order: 'ASC' }}
            alwaysOn
            allowEmpty
            fullWidth
          >
            <AutocompleteInput optionText="selectorName" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <MatchInput
            sourceSeason="season"
            sourceCompetition="competitionId"
            sourceSubscription="subscription"
            sourceRound="round"
            sourceGameweek="gameweek"
            sourceMatch={false}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<MatchesFilter />} />
}

const TeamField = (props) => {
  const { source } = props
  return (
    <TeamContainerStyled>
      <ReferenceField {...props} label="Image" source={`${source}.shield`} reference={getResource('assets', 'images')} linkType={false} allowEmpty>
        <ImageField source="asset.fileDir" title="team shield" />
      </ReferenceField>
      <TextField {...props} source={`${source}.nickname`} label="Home Team" sortable={false} />
    </TeamContainerStyled>
  )
}

const MatchesList = (props) => (
  <List bulkActions={false} title="Clubs" actions={<Actions />} filterDefaultValues={{ season: Variables.CURRENT_SEASON }} {...props}>
    <Responsive
      small={
        <SimpleList
          primaryText={(record) => record.name}
          tertiaryText={(record) => (
            <div>
              <DateField source="date" label="Date" record={record} />
              <DateField
                source="time"
                label="Time"
                showTime
                record={record}
                options={{
                  hour: 'numeric',
                  minute: 'numeric',
                }}
              />
            </div>
          )}
        />
      }
      medium={
        <Datagrid>
          <MatchStatusField label={false} source="status" />
          <ReferenceField label="Season" source="gameweek.round.id" reference={getResource('competiciones', 'rounds')} linkType={false} sortable={false}>
            <TextField source="subscription.season.name" />
          </ReferenceField>
          <ReferenceField label="Competition" source="gameweek.round.id" reference={getResource('competiciones', 'rounds')} linkType={false} sortable={false}>
            <TextField source="subscription.competition.name" />
          </ReferenceField>
          <ReferenceField label="Subscription" source="gameweek.round.id" reference={getResource('competiciones', 'rounds')} linkType={false} sortable={false}>
            <TextField source="subscription.competitionName" />
          </ReferenceField>
          <ReferenceField label="Round" source="gameweek.round.id" reference={getResource('competiciones', 'rounds')} linkType={false} sortable={false}>
            <TextField source="name" />
          </ReferenceField>
          <TextField source="gameweek.name" label="Game Week" sortable={false} />
          <TeamField source="homeTeam" label="Home Team" sortable={false} />
          <TeamField source="awayTeam" label="Away Team" sortable={false} reverse />
          <DateField source="date" label="Date" />
          <DateField
            source="time"
            label="Time"
            showTime
            options={{
              hour: 'numeric',
              minute: 'numeric',
            }}
          />
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default MatchesList
