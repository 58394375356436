import { Button, Grid, Typography, withStyles } from '@material-ui/core'
import React, { Fragment } from 'react'
import { BooleanInput, FormDataConsumer, maxLength, ReferenceField, required, SelectInput, TextField, TextInput } from 'react-admin'
import { Link } from 'react-router-dom'
import { ClipboardField } from '../../../../components/formFields'
import { DateSingleInput, ImageInput, MatchInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'
import { getLink, getResource } from '../../../../config/resources'
import { choicesStates } from '../states'

const styles = (theme) => ({
  inputCurrent: {
    '& label': {
      justifyContent: 'flex-end',
    },
  },
  listLinks: {
    display: 'flex',
    flexDirection: 'column',
    '& h2': {
      marginBottom: '10px',
    },
    '& a': {
      margin: '5px 0',
    },
  },
})

const basePublicUrl = {
  es: `${process.env.REACT_APP_PUBLIC_FRONT}/laliga-fc-futures/`,
  en: `${process.env.REACT_APP_PUBLIC_FRONT}/en-ES/laliga-fc-futures/`,
}

export const SubscriptionInput = (props) => {
  const { id } = props

  if (id) {
    return (
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData && (
            <Fragment>
              <Grid item xs={12} md={4}>
                <ReferenceField
                  source="subscription"
                  reference={getResource('competiciones', 'subscriptions')}
                  basePath={getResource('competiciones', 'subscriptions')}
                  record={formData}
                  linkType={false}
                >
                  <TextField source="season.name" fullWidth />
                </ReferenceField>
              </Grid>
              <Grid item xs={12} md={4}>
                <ReferenceField
                  source="subscription"
                  reference={getResource('competiciones', 'subscriptions')}
                  basePath={getResource('competiciones', 'subscriptions')}
                  record={formData}
                  linkType={false}
                >
                  <TextField source="competition.name" />
                </ReferenceField>
              </Grid>
              <Grid item xs={12} md={4}>
                <ReferenceField
                  source="subscription"
                  reference={getResource('competiciones', 'subscriptions')}
                  basePath={getResource('competiciones', 'peopleroles')}
                  record={formData}
                  linkType={false}
                >
                  <TextField source="competitionName" />
                </ReferenceField>
              </Grid>
            </Fragment>
          )
        }
      </FormDataConsumer>
    )
  } else {
    return (
      <MatchInput
        validate={[required()]}
        sourceSeason="tempSeason"
        sourceCompetition="tempCompetition"
        sourceSubscription="subscription"
        sourceRound={false}
        sourceGameweek={false}
        sourceMatch={false}
      />
    )
  }
}

export const PromisesForm = ({ classes, ...props }) => (
  <LanguagesNav {...props}>
    <FormDataConsumer>
      {({ formData, ...rest }) =>
        formData && (
          <Grid container>
            {<SubscriptionInput classes={classes} {...props} />}
            <Grid item xs={6}>
              <SelectInput label="State" source="status" choices={choicesStates} validate={[required(), maxLength(255)]} />
            </Grid>
            <Grid item xs={6}>
              <BooleanInput label="Current" source="current" className={classes.inputCurrent} />
            </Grid>
            <Grid item xs={6}>
              <DateSingleInput label="Start date" source="startDate" validate={[required()]} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <DateSingleInput label="End date" source="endDate" validate={[required()]} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextInput label="Name" source="name" validate={[required(), maxLength(255)]} fullWidth />
            </Grid>
            {props.id && (
              <Grid item xs={12}>
                <ClipboardField urlShortener label="Url" value={`${basePublicUrl[formData.lang ? formData.lang : 'es']}${formData.slug}`} fullWidth />
              </Grid>
            )}
            <Grid item xs={12} md={8}>
              <ImageInput
                source="image"
                label="Image"
                accept="image/*"
                options={{
                  remove: true,
                }}
              />
            </Grid>
            {props.id && (
              <Grid item xs={12} md={4}>
                <div className={classes.listLinks}>
                  <Typography variant="title">Statics page</Typography>
                  {formData.home && (
                    <Link to={`/${getLink('layouts', 'pagesPromises', { id: formData.home.id })}`} target="_blank">
                      <Button variant="contained" color="primary" size="small" fullWidth>
                        Home
                      </Button>
                    </Link>
                  )}
                  {formData.tournament && (
                    <Link to={`/${getLink('layouts', 'pagesPromises', { id: formData.tournament.id })}`} target="_blank">
                      <Button variant="contained" color="primary" size="small" fullWidth>
                        Tournament
                      </Button>
                    </Link>
                  )}
                  {formData.venue && (
                    <Link to={`/${getLink('layouts', 'pagesPromises', { id: formData.venue.id })}`} target="_blank">
                      <Button variant="contained" color="primary" size="small" fullWidth>
                        Venue
                      </Button>
                    </Link>
                  )}
                </div>
              </Grid>
            )}
          </Grid>
        )
      }
    </FormDataConsumer>
  </LanguagesNav>
)

export default withStyles(styles)(PromisesForm)
