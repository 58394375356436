import React, { Component } from 'react'
import { Field } from 'redux-form'
import ScriptInput from './scriptInput'

class ScriptInputField extends Component {
  render() {
    const { source, ...rest } = this.props
    if (this.props.source) {
      return <Field {...rest} label={this.props.label} component={ScriptInput} name={source} />
    } else {
      return <ScriptInput {...this.props} />
    }
  }
}

export default ScriptInputField
