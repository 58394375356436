import { Grid } from '@material-ui/core'
import React from 'react'
import { TextInput, LongTextInput, BooleanInput, required, maxLength } from 'react-admin'

import LanguagesNav from '../../../../components/languagesNav'

export const MetadataOthersForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12}>
        <TextInput fullWidth source="name" label="Name" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <LongTextInput fullWidth source="description" label="Description" />
      </Grid>
      <Grid item xs={12}>
        <BooleanInput fullWidth source="multimedia" label="Multimedia Tag" />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default MetadataOthersForm
