import React, { Component } from 'react'
import GridImageLink from './GridImageLink'
import Plugin from '../plugin'

class TextPlugin extends Component {
  render() {
    return <Plugin name="grid_image_link" {...this.props} component={GridImageLink} />
  }
}

export default TextPlugin
