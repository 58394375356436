import React from 'react'
import { Loading } from 'react-admin'

import { history } from './app'

const LoginPage = () => {
  history.push('/')
  return <Loading loadingPrimary="Loading" loadingSecondary="The page is loading, just a moment please." />
}

export default LoginPage
