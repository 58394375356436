import React from 'react'
import { Switch, Typography, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  textField: {
    '& input': {
      backgroundColor: 'white',
      maxWidth: '48px',
    },
  },
  descriptionContainer: {
    position: 'absolute',
    right: '-25%',
    top: 0,
    zIndex: 2,
    backgroundColor: 'white',
    padding: '8px',
    maxWidth: '264px',
    [theme.breakpoints.down('lg')]: {
      right: '-53%',
      maxWidth: '200px',
    },
    [theme.breakpoints.down('md')]: {
      right: '-0%',
    },
  },
  triggerTitleContainer: {
    minWidth: '365px',
    paddingLeft: '8px',

    [theme.breakpoints.down('md')]: {
      minWidth: '280px',
      maxWidth: '300px',
    },
  },
})

class AppTriggerOption extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hover: false,
    }
  }
  render() {
    const { option, checked, description, title, handleGuestMode, index } = this.props

    return (
      <div
        key={option}
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
        }}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        <Typography>{index}</Typography>
        <div className={this.props.classes.triggerTitleContainer}>
          <Typography>{title}</Typography>
        </div>
        {typeof checked === 'boolean' ? (
          <Switch color="primary" checked={checked} value={option} onChange={(event) => handleGuestMode(event, 'appTriggers', option)} />
        ) : (
          <TextField
            key={option}
            value={checked}
            onChange={(event) => handleGuestMode(event, 'appTriggers', option)}
            type="number"
            className={this.props.classes.textField}
          />
        )}

        {this.state.hover && (
          <div className={this.props.classes.descriptionContainer}>
            <Typography variant="caption">{description}</Typography>
          </div>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(AppTriggerOption)
