import React from 'react'
import { Grid, List, ListItem, ListItemText, Collapse, Button, withStyles } from '@material-ui/core'
import { maxLength, required, TextInput } from 'react-admin'
import { arrayMove, List as MovableList } from 'react-movable'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector } from 'redux-form'
import AddIcon from '@material-ui/icons/Add'
import HistoryItem from './HistoryItem'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { RichTextInput } from '../../../../../../../components/inputs'

const FORM = 'record-form'
const selector = formValueSelector(FORM)

const styles = (theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing.unit,
    '& button': {
      textTransform: 'capitalize',
      paddingLeft: theme.spacing.unit,
      '& svg': {
        fontSize: 20,
        marginRight: theme.spacing.unit / 2,
      },
    },
  },
})

class History extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  handleClick = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  handleChangeOrder = ({ oldIndex, newIndex }) => {
    let newContent = [...this.props.field(this.props.source)]
    newContent = arrayMove(newContent, oldIndex, newIndex)
    newContent = newContent.map((item, index) => ({
      ...item,
      position: index,
    }))
    this.props.change(FORM, this.props.source, newContent)
  }

  handleClickAdd = () => {
    let newContent = this.props.field(this.props.source) ? [...this.props.field(this.props.source)] : []
    if (!newContent) {
      newContent = []
    }
    newContent.push({
      position: newContent.length,
      active: false,
      discr: 'history',
    })
    this.props.change(FORM, this.props.source, newContent)
  }

  handleRemove = (index) => {
    let newContent = [...this.props.field(this.props.source)]
    newContent.splice(index, 1)
    this.props.change(FORM, this.props.source, newContent)
  }

  render() {
    const { field, source, classes } = this.props
    return (
      <>
        <ListItem button onClick={this.handleClick}>
          <ListItemText primary="History Module" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List>
            <ListItem>
              <Grid container>
                <Grid item xs={12}>
                  <TextInput fullWidth source="historyModule.label" label="History Module Label" validate={[required(), maxLength(255)]} />
                </Grid>
                <Grid item xs={12}>
                  <TextInput fullWidth source="historyModule.title" label="History Module Title" validate={[required(), maxLength(255)]} />
                </Grid>
                <Grid item xs={12}>
                  <RichTextInput fullWidth source="historyModule.content" label="History Module Description" />
                </Grid>
                {field(source) && (
                  <Grid item xs={12}>
                    <MovableList
                      lockVertically
                      values={field(source)}
                      onChange={this.handleChangeOrder}
                      renderList={({ children, props }) => <div {...props}>{children}</div>}
                      renderItem={({ index, props, isDragged, value }) => (
                        <div {...props} key={value.id || index} style={{ ...props.style, zIndex: 2 }}>
                          <HistoryItem source={`${source}[${index}]`} isDragged={isDragged} handleRemove={this.handleRemove} index={index} />
                        </div>
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </ListItem>
          </List>
          <div className={classes.toolbar}>
            <Button color="primary" variant="contained" size="small" onClick={this.handleClickAdd}>
              <AddIcon />
              Add
            </Button>
          </div>
        </Collapse>
      </>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(History)
