import React, { Component } from 'react'
import _ from 'lodash'
import { GET_LIST } from 'react-admin'
import { getResource } from '../../../config/resources'
import { dataProvider } from '../../../providers'
import { ContainerStyled, ItemAllStyled, ItemStyled } from './style'

class SelectMarkets extends Component {
  constructor(props) {
    super(props)
    this.state = {
      markets: null,
      checkedAll: true,
    }
  }

  componentDidMount() {
    this.getMarkets()
  }

  getMarkets(idBrand, page, perPage) {
    dataProvider(GET_LIST, getResource('transversal', 'markets'), {
      sort: { field: 'name', order: 'ASC' },
      pagination: {},
      filter: {},
    }).then(({ data }) => {
      this.setState({
        markets: data,
      })
    })
  }

  handleClickItem(checked, market) {
    let { value, onChange } = this.props.input
    let newValue = [...value]

    if (checked) {
      newValue.push({ market: market })
    } else {
      let index = _.findIndex(newValue, ['market', market])
      newValue.splice(index, 1)
    }
    onChange(newValue)
  }

  handleClickAll(checkedAll) {
    const { markets } = this.state
    const { onChange } = this.props.input

    if (checkedAll) {
      onChange(
        markets.map((item) => {
          return { market: item.id }
        })
      )
    } else {
      onChange([])
    }
  }

  render() {
    const { markets, checkedAll } = this.state
    const { value } = this.props.input
    let nextCheckedAll = true

    return (
      <ContainerStyled>
        <ItemAllStyled className={checkedAll ? 'checked' : ''} onClick={() => this.handleClickAll(!checkedAll)}>
          All
        </ItemAllStyled>
        {markets &&
          markets.map((item, key) => {
            let checked = -1 !== _.findIndex(value, ['market', item.id])
            if (!checked) {
              nextCheckedAll = false
            }
            return (
              <ItemStyled className={checked ? 'checked' : ''} onClick={() => this.handleClickItem(!checked, item.id)} key={key}>
                {item.name}
              </ItemStyled>
            )
          })}

        {checkedAll !== nextCheckedAll && this.setState({ checkedAll: nextCheckedAll })}
      </ContainerStyled>
    )
  }
}

export default SelectMarkets
