import React from 'react'
import { Create } from 'react-admin'

import ChannelsForm from './form'

const ChannelTitle = ({ record }) => <span>Nuevo operador:</span>

export const ChannelsCreate = (props) => (
  <Create title={<ChannelTitle />} {...props}>
    <ChannelsForm />
  </Create>
)

export default ChannelsCreate
