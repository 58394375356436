import 'moment/locale/es'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'

import Select from '@material-ui/core/Select'
import moment from 'moment-timezone'
import React, { Component } from 'react'
import { DateRangePicker } from 'react-dates'
import { connect } from 'react-redux'
import { change } from 'redux-form'

import { DateGlobalStyled, SelectsGlobalStyled } from '../style'

class DateRange extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: null,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value } = nextProps.input ? nextProps.input : nextProps

    if (!value) {
      return {
        startDate: null,
        endDate: null,
      }
    }
  }

  componentDidMount() {
    const startDate = this.props.input ? moment.unix(this.props.input.value[0]) : moment.unix(this.props.value[0])
    const endDate = this.props.input ? moment.unix(this.props.input.value[1]) : moment.unix(this.props.value[1])

    if (!startDate && !endDate) {
      this.setState({
        startDate: moment(startDate),
        endDate: moment(endDate),
      })
    }
  }

  render() {
    const {
      input,
      rangeStartDate = moment('2010/01/01'),
      rangeEndDate = moment().add(1, 'year'),
      label = false,
      meta,
      source,
      fullWidth,
      filterForm,
      changeField,
      ...otherProps
    } = this.props
    return (
      <FormControl error={meta && meta.touched && meta.error} fullWidth={fullWidth}>
        <InputLabel>{label}</InputLabel>
        <DateGlobalStyled>
          <DateRangePicker
            block
            startDateId={`startDate_${this.props.name}`}
            endDateId={`endDate_${this.props.name}`}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onDatesChange={({ startDate, endDate }) => {
              if (startDate) {
                startDate.tz('UTC')
                startDate.hour(0).minute(0).second(0)
              }
              if (endDate) {
                endDate.tz('UTC')
                endDate.hour(0).minute(0).second(0)
              }
              this.setState({ startDate, endDate })
              const timestampStart = startDate !== null ? startDate.toISOString(true) : startDate
              const timestampEnd = endDate !== null ? endDate.toISOString(true) : endDate
              input.onChange([timestampStart, timestampEnd])
            }}
            focusedInput={this.state.focusedInput}
            onFocusChange={(focusedInput) => {
              this.setState({ focusedInput })
            }}
            numberOfMonths={1}
            startDatePlaceholderText="From"
            endDatePlaceholderText="To"
            isOutsideRange={(day) => {
              return parseInt(day.format('X')) < parseInt(rangeStartDate.format('X')) || parseInt(day.format('X')) > parseInt(rangeEndDate.format('X'))
            }}
            showClearDates
            hideKeyboardShortcutsPanel
            renderMonthElement={({ month, onMonthSelect, onYearSelect }) => {
              const arrayYears = []
              for (var i = rangeStartDate.year(); i <= rangeEndDate.year(); i++) arrayYears.push(i)

              return (
                <SelectsGlobalStyled>
                  <div className="selectMonth">
                    <Select
                      native
                      value={month.month()}
                      onChange={(e) => {
                        onMonthSelect(month, e.target.value)
                      }}
                    >
                      {moment.months().map((label, value) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="selectYear">
                    <Select
                      native
                      value={month.year()}
                      onChange={(e) => {
                        onYearSelect(month, e.target.value)
                      }}
                    >
                      {arrayYears.map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </Select>
                  </div>
                </SelectsGlobalStyled>
              )
            }}
            {...otherProps}
          />
        </DateGlobalStyled>
        {meta && meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
      </FormControl>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    filterForm: state.form.filterForm ? state.form.filterForm.values : null,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    changeField: (form, field, value) => dispatch(change(form, field, value)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DateRange)
