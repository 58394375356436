import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import LinearProgress from '@material-ui/core/LinearProgress'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { Component } from 'react'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { DialogTitleStyled, CloseButtonStyled } from './styles'

class ConfirmationDialog extends Component {
  static defaultProps = {
    fullWidth: true,
    maxWidth: 'sm',
    dialogActions: true,
  }

  handleClose = () => {
    const { onCancel } = this.props
    if (onCancel) onCancel()
  }

  handleSuccess = () => {
    const { onSuccess } = this.props
    if (onSuccess) onSuccess()
  }

  render() {
    const { className, dialogActions, disableSave, ...rest } = this.props
    const children = React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, {
        // onSubmit: this.handleSuccess,
        // onClose:  this.handleClose,
      })
    })
    return (
      <Dialog {...rest} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" scroll="body">
        {this.props.isFetching && <LinearProgress />}
        {this.props.title && (
          <DialogTitleStyled id="alert-dialog-title">
            <DialogTitle>{this.props.title}</DialogTitle>
            <CloseButtonStyled>
              <IconButton onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            </CloseButtonStyled>
          </DialogTitleStyled>
        )}
        <DialogContent className={className}>
          <React.Fragment>
            {this.props.content}
            {children}
          </React.Fragment>
        </DialogContent>
        {dialogActions && (
          <DialogActions>
            <Button disabled={this.props.isFetching} onClick={this.handleClose} variant="contained">
              Cancel
            </Button>
            <Button disabled={this.props.isFetching || disableSave} onClick={this.handleSuccess} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        )}
      </Dialog>
    )
  }
}

export default ConfirmationDialog
